// import React, { useState } from "react";
// import {
//   Accordion,
//   Button,
//   Col,
//   Container,
//   Dropdown,
//   Form,
//   Modal,
//   Navbar,
//   Row,
//   Tab,
//   Tabs,
// } from "react-bootstrap";
// import { Link } from "react-router-dom";

// import LoginLayout from "../components/LoginLayout";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import { TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
// import backgroundImage from "../Assets/images/playerbanner.png";
// export default function InternalSearchDirectorProfile() {
//   const backgroundImageStyle = {
//     backgroundImage: `url(${backgroundImage})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",

//     width: "100%",
//   };
//   return (
//     <LoginLayout>
//       <section>
//         <Container>
//           <div className="cmn-card">
//             <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
//               <h2>Director Profile</h2>
//             </div>
//             <div className="player-profile-main" style={backgroundImageStyle}>
//               <Row className="h-100">
//                 <Col
//                   lg={6}
//                   className="d-flex align-items-end justify-content-center"
//                 >
//                   <div className="player-left-info">
//                     <img
//                       className="play-img"
//                       src={require("../Assets/images/directorimage.png")}
//                     />
//                     <div>
//                       <h2>Andrew, 23</h2>
//                       <div className="d-flex align-items-center mb-4 mt-3">
//                         <p className="mb-0">Player ID:IVXJW0007</p>
//                         <button>
//                           <img
//                             src={
//                               require("../Assets/images/copywhite.svg").default
//                             }
//                           />
//                         </button>
//                       </div>
//                       <div className="socio-icons">
//                         <Link to="#">
//                           <img
//                             src={require("../Assets/images/fbblue.svg").default}
//                           />
//                         </Link>
//                         <Link to="#">
//                           <img
//                             src={
//                               require("../Assets/images/snapblue.svg").default
//                             }
//                           />
//                         </Link>
//                         <Link to="#">
//                           <img
//                             src={
//                               require("../Assets/images/instablue.svg").default
//                             }
//                           />
//                         </Link>
//                         <Link to="#">
//                           <img
//                             src={require("../Assets/images/xblue.svg").default}
//                           />
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col lg={6}>
//                   <div className="player-right-info">
//                     <div className="play-edit">
//                       <Link to="#">
//                         <img
//                           src={
//                             require("../Assets/images/editwhite.svg").default
//                           }
//                         />
//                         Edit Profile
//                       </Link>
//                     </div>
//                     <div className="play-info-box">
//                       <div>
//                         <h3>Male</h3>
//                         <p>Gender</p>
//                       </div>
//                       <div>
//                         <h3>24</h3>
//                         <p>Age</p>
//                       </div>
//                       <div>
//                         <h3>On Loan</h3>
//                         <p>Availability</p>
//                       </div>
//                       <div>
//                         <h3>English</h3>
//                         <p>Language</p>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </div>
//             <Tabs
//               defaultActiveKey="Profile"
//               id="uncontrolled-tab-example"
//               className="mb-3 mt-4"
//             >
//               <Tab eventKey="Profile" title="Profile" className="accord-tabs">
//                 <div className="inner-search-main-wrap mt-4">
//                   <Row>
//                     <Col lg={4}>
//                       <div className="search-cmn-box">
//                         <h2>Directors Information</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li>
//                               <h4>Place of Birth:</h4>
//                               <p>Manchester</p>
//                             </li>
//                             <li>
//                               <h4>Nationality (s):</h4>
//                               <p>United Kingdom</p>
//                             </li>

//                             <li>
//                               <h4>Previous Status:</h4>
//                               <p>Coach</p>
//                             </li>
//                             <li>
//                               <h4>Coaching Licenses:</h4>
//                               <p>No</p>
//                             </li>
//                             <li>
//                               <h4>Languages(s):</h4>
//                               <p>English, Turkish</p>
//                             </li>
//                             <li className="flex-column align-items-start mb-0">
//                               <h4>Other Notes:</h4>
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div className="search-cmn-box mt-3">
//                         <h2>Agents Notes</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li className="flex-column align-items-start mb-0">
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       <div className="search-cmn-box">
//                         <h2>Career Trophies</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li>
//                               <div className="d-flex">
//                                 <img
//                                   src={require("../Assets/images/smtrophy.png")}
//                                 />
//                                 <h4 className="ms-2">Youth Trophie</h4>
//                               </div>
//                               <p>12-Mar-2020</p>
//                             </li>
//                             <li className="mb-0">
//                               <div className="d-flex">
//                                 <img
//                                   src={require("../Assets/images/smtrophy.png")}
//                                 />
//                                 <h4 className="ms-2">Youth Trophie</h4>
//                               </div>
//                               <p>12-Mar-2020</p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div className="play-inner-box search-result mt-3">
//                         <div className="d-flex align-items-center">
//                           <img
//                             className="small-images"
//                             src={require("../Assets/images/user.png")}
//                           />
//                           <img
//                             className="small-images"
//                             src={require("../Assets/images/user.png")}
//                           />
//                           <img
//                             className="small-images"
//                             src={require("../Assets/images/user.png")}
//                           />
//                           <div className="ms-3">
//                             <h4>Photos</h4>
//                           </div>
//                         </div>
//                         <div
//                           className="badge-img"
//                           style={{ boxShadow: "none" }}
//                         >
//                           <img
//                             src={require("../Assets/images/arrow.svg").default}
//                           />
//                         </div>
//                       </div>
//                       <div className="search-cmn-box mt-3">
//                         <h2>Player’s CV</h2>

//                         <div className="play-inner-box mb-0">
//                           <div className="d-flex align-items-center">
//                             <img
//                               src={require("../Assets/images/pdf.svg").default}
//                             />
//                             <div className="ms-3">
//                               <h4>Director’s CV.pdf</h4>
//                             </div>
//                           </div>
//                           <button
//                             type="button"
//                             style={{
//                               backgroundColor: "transparent",
//                               border: "none",
//                             }}
//                           >
//                             <img
//                               src={
//                                 require("../Assets/images/downbtn.svg").default
//                               }
//                             />
//                           </button>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       {" "}
//                       <div className="play-inner-box search-result mt-3">
//                         <div className="d-flex align-items-center">
//                           <img src={require("../Assets/images/user.png")} />
//                           <div className="ms-3 d-flex align-items-center">
//                             <h4>M Mathew</h4>
//                             <p
//                               className="ms-2"
//                               style={{ color: "#1D161780", fontSize: "13px" }}
//                             >
//                               Agent
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="play-inner-box search-result mt-3">
//                         <div className="d-flex align-items-center">
//                           <img src={require("../Assets/images/user.png")} />
//                           <div className="ms-3 d-flex align-items-center">
//                             <h4>J K Rowling</h4>
//                             <p
//                               className="ms-2"
//                               style={{ color: "#1D161780", fontSize: "13px" }}
//                             >
//                               Associate Agent
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="search-cmn-box mt-3">
//                         <h2>Current Team</h2>
//                         <div className="play-scroll-box">
//                           <div className="play-inner-box mb-0">
//                             <div className="d-flex">
//                               <img
//                                 src={require("../Assets/images/tiger.png")}
//                               />
//                               <div className="ms-3">
//                                 <h4>Chelsea Football Club</h4>
//                                 <p>
//                                   <span>London / United Kingdom</span>
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="search-cmn-box mt-3">
//                         <h2>Previous Team</h2>
//                         <div className="play-scroll-box">
//                           <div className="play-inner-box mb-0">
//                             <div className="d-flex">
//                               <img
//                                 src={require("../Assets/images/tiger.png")}
//                               />
//                               <div className="ms-3">
//                                 <h4>Chelsea Football Club</h4>
//                                 <p>
//                                   <span>London / United Kingdom</span>
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Tab>
//               <Tab eventKey="Contract" title="Contract">
//                 {" "}
//                 <div className="inner-search-main-wrap mt-4">
//                   <Row>
//                     <Col lg={4}>
//                       <div className="search-cmn-box">
//                         <h2>Current Contract Requirements</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li>
//                               <h4>Current Annual Salary:</h4>
//                               <p>$10,000</p>
//                             </li>
//                             <li>
//                               <h4>Bonuses:</h4>
//                               <p>Lorem Ipsum</p>
//                             </li>

//                             <li>
//                               <h4>Contract Start Date:</h4>
//                               <p>DD/MM/YYYY</p>
//                             </li>
//                             <li>
//                               <h4>Contract End Date:</h4>
//                               <p>DD/MM/YYYY</p>
//                             </li>
//                             <li>
//                               <h4>Release Clause:</h4>
//                               <p>Yes</p>
//                             </li>

//                             <li className="flex-column align-items-start mb-0">
//                               <h4>Other Notes:</h4>
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       <div className="search-cmn-box">
//                         <h2>New Contract Requirements</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li>
//                               <h4>Annual Asking Salary:</h4>
//                               <p>$10,000</p>
//                             </li>
//                             <li>
//                               <h4>Contract Duration:</h4>
//                               <p>6 Months</p>
//                             </li>

//                             <li className="flex-column align-items-start mb-0">
//                               <h4>Other Notes:</h4>
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div className="search-cmn-box mt-3">
//                         <h2>Agents Notes</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li className="flex-column align-items-start mb-0">
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       {" "}
//                       <div className="search-inner-box p-0 mt-4">
//                         <div className="play-images">
//                           <img
//                             src={require("../Assets/images/smllbanner.png")}
//                           />
//                           <div className="players-value inner-tab">
//                             <h3>
//                               CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
//                             </h3>
//                           </div>
//                         </div>{" "}
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Tab>

//               <Tab eventKey="Proposals" title="Proposals">
//                 {" "}
//                 <div className="inner-search-main-wrap mt-4">
//                   <Row>
//                     <Col lg={4}>
//                       <div className="search-cmn-box">
//                         <h2>Proposals</h2>
//                         <div className="search-inner-box">
//                           <h3 className="in-heading">
//                             Teams that the Player has been proposed
//                           </h3>
//                           <div className="play-inner-box align-items-start">
//                             <div className="d-flex">
//                               <img src={require("../Assets/images/user.png")} />
//                               <div className="ms-3">
//                                 <h4>Team Name</h4>
//                                 <p>
//                                   <span>Country/State</span>
//                                 </p>
//                               </div>
//                             </div>
//                             <p>
//                               <span>1st division</span>
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       <div className="search-cmn-box ">
//                         <h2 className=""></h2>
//                         <div className="search-inner-box mt-4">
//                           <h3 className="in-heading">
//                             Teams that the Player has been proposed
//                           </h3>
//                           <div className="play-inner-box align-items-start">
//                             <div className="d-flex">
//                               <img src={require("../Assets/images/user.png")} />
//                               <div className="ms-3">
//                                 <h4>Team Name</h4>
//                                 <p>
//                                   <span>Country/State</span>
//                                 </p>
//                               </div>
//                             </div>
//                             <p>
//                               <span>1st division</span>
//                             </p>
//                           </div>
//                         </div>
//                       </div>

//                       <div className="search-cmn-box mt-4">
//                         <h2>Agents Notes</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li className="flex-column align-items-start mb-0">
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       {" "}
//                       <div className="search-inner-box p-0 mt-4">
//                         <div className="play-images">
//                           <img
//                             src={require("../Assets/images/smllbanner.png")}
//                           />
//                           <div className="players-value inner-tab">
//                             <h3>
//                               CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
//                             </h3>
//                           </div>
//                         </div>{" "}
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Tab>
//               <Tab eventKey="Contact" title="Contact">
//                 {" "}
//                 <div className="inner-search-main-wrap mt-4">
//                   <Row>
//                     <Col lg={4}>
//                       <div className="search-cmn-box">
//                         <h2>Contact Information</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li>
//                               <h4>Phone Number:</h4>
//                               <p>+61 234567976</p>
//                             </li>
//                             <li>
//                               <h4>Email Address:</h4>
//                               <p>dummy@gmail.com</p>
//                             </li>

//                             <li className="flex-column align-items-start mb-0">
//                               <h4>Home Address:</h4>
//                               <p className="mt-1">
//                                 765, lorem ipsum, state name, country name
//                                 Zipcode
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="search-inner-box mt-3">
//                           <ul>
//                             <li>
//                               <h4>Instagram:</h4>
//                               <p>lorem ipsum</p>
//                             </li>
//                             <li>
//                               <h4>Snapchat:</h4>
//                               <p>lorem ipsum</p>
//                             </li>
//                             <li>
//                               <h4>X:</h4>
//                               <p>lorem ipsum/12</p>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="search-inner-box mt-3">
//                           <ul>
//                             <li>
//                               <h4>Directors Lawyer:</h4>
//                               <p>lorem ipsum</p>
//                             </li>
//                             <li>
//                               <h4>Contact Number:</h4>
//                               <p>+61 234567976</p>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="search-inner-box mt-3">
//                           <ul>
//                             <li>
//                               <h4>Managing Company:</h4>
//                               <p>lorem ipsum</p>
//                             </li>
//                             <li>
//                               <h4>Contact Number:</h4>
//                               <p>lorem ipsum</p>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="search-inner-box mt-3">
//                           {" "}
//                           <ul>
//                             <li className="flex-column align-items-start mb-0">
//                               <h4>Other Notes:</h4>
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       <div className="search-cmn-box mt-4">
//                         <h2>Agents Notes</h2>
//                         <div className="search-inner-box">
//                           <ul>
//                             <li className="flex-column align-items-start mb-0">
//                               <p className="mt-1">
//                                 Lorem Ipsum is simply dummy text for printing
//                                 and typesetting industry. Lorem Ipsum has been
//                                 industry standard dummy text ever since 1500s.
//                               </p>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       {" "}
//                       <div className="search-inner-box p-0 mt-4">
//                         <div className="play-images">
//                           <img
//                             src={require("../Assets/images/smllbanner.png")}
//                           />
//                           <div className="players-value inner-tab">
//                             <h3>
//                               CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
//                             </h3>
//                           </div>
//                         </div>{" "}
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Tab>
//             </Tabs>
//           </div>
//         </Container>
//       </section>
//     </LoginLayout>
//   );
// }


import React, { useEffect } from "react";
import {
    Col,
    Container,
    Row,
    Tab,
    Tabs,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import backgroundImage from "../Assets/images/playerbanner.png";
import { getDirectorDataById } from "../Redux/Action/directorAction";
import { Decryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { calculateAge, dateOfBirth } from "../utils/helper";


export default function InternalSearchDirectorProfile() {
    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        width: "100%",
    };
    const { id } = useParams();
    const IdVal = id && Decryptedid(atob(id));
    const dispatch = useDispatch()


    const directorData = useSelector((e) => e?.directorAuthData?.directorDetail);

    console.log(directorData, "directorData666666666666666");
    useEffect(() => {
        dispatch(getDirectorDataById({ id: IdVal }));
    }, [dispatch]);



    return (
        <LoginLayout>
            <section>
                <Container>
                    <div className="cmn-card">
                        <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
                            <h2>Director Profile</h2>
                        </div>
                        <div className="player-profile-main" style={backgroundImageStyle}>
                            <Row className="h-100">
                                <Col
                                    lg={6}
                                    className="d-flex align-items-end justify-content-center"
                                >
                                    <div className="player-left-info">

                                        <img
                                            className="play-img"
                                            src={`${process.env.REACT_APP_IMAGE_PATH}${directorData?.profile_image}`}
                                        />
                                        <div>
                                            <h2>{directorData?.full_name}, {calculateAge(directorData?.dob)}</h2>
                                            <div className="d-flex align-items-center mb-4 mt-3">
                                                <p className="mb-0">Player ID:{directorData?.unique_id}</p>
                                                <button>
                                                    <img
                                                        src={
                                                            require("../Assets/images/copywhite.svg").default
                                                        }
                                                    />
                                                </button>
                                            </div>
                                            <div className="socio-icons">
                                                <Link to="#">
                                                    <img
                                                        src={require("../Assets/images/fbblue.svg").default}
                                                    />
                                                </Link>
                                                <Link to="#">
                                                    <img
                                                        src={
                                                            require("../Assets/images/snapblue.svg").default
                                                        }
                                                    />
                                                </Link>
                                                <Link to="#">
                                                    <img
                                                        src={
                                                            require("../Assets/images/instablue.svg").default
                                                        }
                                                    />
                                                </Link>
                                                <Link to="#">
                                                    <img
                                                        src={require("../Assets/images/xblue.svg").default}
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="player-right-info">
                                        <div className="play-edit">
                                            {/* <Link to="#">
                                                <img
                                                    src={
                                                        require("../Assets/images/editwhite.svg").default
                                                    }
                                                />
                                                Edit Profile
                                            </Link> */}
                                        </div>
                                        <div className="play-info-box">
                                            <div>
                                                <h3>{directorData?.gender === 0 ? "Male" : "Female"}</h3>
                                                <p>Gender</p>
                                            </div>
                                            <div>
                                                <h3>{calculateAge(directorData?.dob)}</h3>
                                                <p>Age</p>
                                            </div>
                                            <div>
                                                <h3>On Loan</h3>
                                                <p>Availability</p>
                                            </div>
                                            <div>
                                                {Array.isArray(directorData?.languages) && directorData?.languages?.map((ele, index) => {

                                                    return (
                                                        <>
                                                            <h3 key={index}>
                                                                {ele}
                                                            </h3>
                                                        </>
                                                    )
                                                })}
                                                <p>Language</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Tabs
                            defaultActiveKey="Profile"
                            id="uncontrolled-tab-example"
                            className="mb-3 mt-4"
                        >
                            <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                                <div className="inner-search-main-wrap mt-4">
                                    <Row>
                                        <Col lg={4}>
                                            <div className="search-cmn-box">
                                                <h2>Directors Information</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li>
                                                            <h4>Place of Birth:</h4>
                                                            <p>{directorData?.place_of_birth}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Nationality (s):</h4>
                                                            {Array.isArray(directorData?.nationality) && directorData?.nationality?.map((ele, index) => {
                                                                return (
                                                                    <>
                                                                        <h4 key={index}>
                                                                            {ele}
                                                                        </h4>
                                                                    </>
                                                                )
                                                            })}

                                                        </li>

                                                        <li>
                                                            <h4>Previous Status:</h4>
                                                            {Array.isArray(directorData?.previous_status) && directorData?.previous_status.map((ele, index) => {
                                                                return (
                                                                    <>
                                                                        <p key={index}>
                                                                            {ele}
                                                                        </p>
                                                                    </>
                                                                )
                                                            })}
                                                        </li>
                                                        <li>
                                                            <h4>Coaching Licenses:</h4>
                                                            {Array.isArray(directorData?.coaching_licenses) && directorData?.coaching_licenses.map((ele, index) => {
                                                                return (
                                                                    <>
                                                                        <p key={index}>
                                                                            {ele}
                                                                        </p>
                                                                    </>
                                                                )
                                                            })}
                                                        </li>
                                                        <li>
                                                            <h4>Languages(s):</h4>
                                                            {Array.isArray(directorData?.languages) && directorData?.languages.map((ele, index) => {

                                                                return (
                                                                    <>
                                                                        <p key={index}>
                                                                            {ele}
                                                                        </p>
                                                                    </>
                                                                )
                                                            })}
                                                        </li>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <h4>Other Notes:</h4>
                                                            <p className="mt-1">
                                                                {directorData?.main_info_other_notes ? directorData?.main_info_other_notes : "N/A"}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {/* <div className="search-cmn-box mt-3">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </Col>
                                        <Col lg={4}>
                                            <div className="search-cmn-box">
                                                <h2>Career Trophies</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        {Array.isArray(directorData?.carrier_trophies) && directorData?.carrier_trophies.length > 0 ?
                                                            <>
                                                                {directorData?.carrier_trophies.map((ele, index) => {
                                                                    return (
                                                                        <>
                                                                            <li key={index}>
                                                                                <div className="d-flex">
                                                                                    <img
                                                                                        src={require("../Assets/images/smtrophy.png")}
                                                                                    />
                                                                                    <h4 className="ms-2">Youth Trophie</h4>
                                                                                </div>
                                                                                <p>{dateOfBirth(ele?.date_of_winning)}</p>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })}
                                                            </> :
                                                            <li>No Data Found</li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="play-inner-box search-result mt-3">
                                                <div className="d-flex align-items-center">


                                                    {Array.isArray(directorData?.photos) && directorData?.photos.map((ele, index) => {
                                                        return (
                                                            <>
                                                                <img key={index}
                                                                    className="small-images"
                                                                    src={`${process.env.REACT_APP_IMAGE_PATH}${ele}`}
                                                                />
                                                            </>
                                                        )
                                                    })}
                                                    <div className="ms-3">
                                                        <h4>Photos</h4>
                                                    </div>
                                                </div>
                                                <div
                                                    className="badge-img"
                                                    style={{ boxShadow: "none" }}
                                                >
                                                    <img
                                                        src={require("../Assets/images/arrow.svg").default}
                                                    />
                                                </div>
                                            </div>
                                            <div className="search-cmn-box mt-3">
                                                <h2>Director’s CV</h2>

                                                <div className="play-inner-box mb-0">
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={require("../Assets/images/pdf.svg").default}
                                                        />
                                                        <div className="ms-3">
                                                            <h4>Director’s CV.pdf</h4>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                require("../Assets/images/downbtn.svg").default
                                                            }
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            {" "}
                                            <div className="play-inner-box search-result mt-3">
                                                <div className="d-flex align-items-center">
                                                    <img src={require("../Assets/images/user.png")} />
                                                     
                                                    {/* <img
                                                        src={`${process.env.REACT_APP_IMAGE_PATH}${directorData?.creator_id?.profile_image}`}
                                                        alt=""
                                                    /> */}

                                                    <div className="ms-3 d-flex align-items-center">
                                                        <h4>{directorData?.creator_id?.full_name}</h4>
                                                        <p
                                                            className="ms-2"
                                                            style={{ color: "#1D161780", fontSize: "13px" }}
                                                        >
                                                            Agent
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="play-inner-box search-result mt-3">
                                                <div className="d-flex align-items-center">
                                                    <img src={require("../Assets/images/user.png")} />
                                                    <div className="ms-3 d-flex align-items-center">
                                                        <h4>J K Rowling</h4>
                                                        <p
                                                            className="ms-2"
                                                            style={{ color: "#1D161780", fontSize: "13px" }}
                                                        >
                                                            Associate Agent
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="search-cmn-box mt-3">
                                                <h2>Current Team</h2>
                                                <div className="play-scroll-box">
                                                    <div className="play-inner-box mb-0">
                                                        <div className="d-flex">
                                                            <img
                                                                src={require("../Assets/images/tiger.png")}
                                                            />
                                                            <div className="ms-3">
                                                                <h4>{directorData?.current_team_name}</h4>
                                                                <p>
                                                                    <span> {directorData?.current_team_country}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="search-cmn-box mt-3">
                                                <h2>Previous Team</h2>
                                                <div className="play-scroll-box">
                                                    <div className="play-inner-box mb-0">


                                                        {Array.isArray(directorData?.previous_team) && directorData?.previous_team?.length > 0 ?
                                                            <>
                                                                {directorData?.previous_team.map((ele, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className="d-flex" key={index}>
                                                                                <img
                                                                                    src={require("../Assets/images/tiger.png")}
                                                                                />
                                                                                <div className="ms-3">
                                                                                    <h4>{ele?.previous_team_name}</h4>
                                                                                    {/* <p>
                                                                                        <span>London / United Kingdom</span>
                                                                                    </p> */}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </> :
                                                            <div>No Data Found</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey="Contract" title="Contract">
                                {" "}
                                <div className="inner-search-main-wrap mt-4">
                                    <Row>
                                        <Col lg={4}>
                                            <div className="search-cmn-box">
                                                <h2>Current Contract Requirements</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li>
                                                            <h4>Current Annual Salary:</h4>
                                                            <p>{directorData?.current_contract}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Bonuses:</h4>
                                                            <p>{directorData?.Bonuses}</p>
                                                        </li>

                                                        <li>
                                                            <h4>Contract Start Date:</h4>
                                                            <p>{dateOfBirth(directorData?.contract_start_date)}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Contract End Date:</h4>
                                                            <p>{dateOfBirth(directorData?.contract_end_date)}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Release Clause:</h4>
                                                            <p>{directorData?.release_clouse}</p>
                                                        </li>

                                                        <li className="flex-column align-items-start mb-0">
                                                            <h4>Other Notes:</h4>
                                                            <p className="mt-1">
                                                                {directorData?.current_contract_other_notes}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="search-cmn-box">
                                                <h2>New Contract Requirements</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li>
                                                            <h4>Annual Asking Salary:</h4>
                                                            <p>{directorData?.new_contract_annual_salary}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Contract Duration:</h4>
                                                            <p>{directorData?.new_contract_duration}</p>
                                                        </li>

                                                        <li className="flex-column align-items-start mb-0">
                                                            <h4>Other Notes:</h4>
                                                            <p className="mt-1">
                                                                {directorData?.new_contract_other_notes}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {/* <div className="search-cmn-box mt-3">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </Col>
                                        <Col lg={4}>
                                            {" "}
                                            <div className="search-inner-box p-0 mt-4">
                                                <div className="play-images">
                                                    <img
                                                        src={require("../Assets/images/smllbanner.png")}
                                                    />
                                                    <div className="players-value inner-tab">
                                                        <h3>
                                                            CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                                                        </h3>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>

                            <Tab eventKey="Proposals" title="Proposals">
                                {" "}
                                <div className="inner-search-main-wrap mt-4">
                                    <Row>
                                        <Col lg={4}>
                                            <div className="search-cmn-box">
                                                <h2>Proposals</h2>
                                                <div className="search-inner-box">
                                                    <h3 className="in-heading">
                                                        Teams that the Director has been proposed
                                                    </h3>

                                                    {Array.isArray(directorData?.proposed_team) && directorData?.proposed_team.length > 0 ?
                                                        <>
                                                            {directorData?.proposed_team.map((ele, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="play-inner-box align-items-start" key={index}>
                                                                            <div className="d-flex">
                                                                                <img
                                                                                    className="play-img"
                                                                                    src={`${process.env.REACT_APP_IMAGE_PATH}${ele?.proposed_team_id?.profile_image}`}
                                                                                />

                                                                                <div className="ms-3">
                                                                                    <h4>{ele?.proposed_team_id?.team_name}</h4>
                                                                                    <p>
                                                                                        <span> {ele?.proposed_team_id?.team_city}/{ele?.proposed_team_id?.team_country}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <p>
                                                                                <span>1st division</span>
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </> :
                                                        <div>No Data Found</div>
                                                    }

                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="search-cmn-box ">
                                                <h2 className=""></h2>
                                                <div className="search-inner-box mt-4">
                                                    <h3 className="in-heading">
                                                        Teams that are interested for the Director
                                                    </h3>
                                                    {Array.isArray(directorData?.interested_team) && directorData?.interested_team.length > 0 ?
                                                        <>
                                                            {directorData?.interested_team.map((ele, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="play-inner-box align-items-start" key={index}>
                                                                            <div className="d-flex">
                                                                                <img
                                                                                    className="play-img"
                                                                                    src={`${process.env.REACT_APP_IMAGE_PATH}${ele?.interested_team_id?.profile_image}`}
                                                                                />

                                                                                <div className="ms-3">
                                                                                    <h4>{ele?.interested_team_id?.team_name}</h4>
                                                                                    <p>
                                                                                        <span> {ele?.interested_team_id?.team_city}/{ele?.interested_team_id?.team_country}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <p>
                                                                                <span>1st division</span>
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </> :
                                                        <div>No Data Found</div>
                                                    }
                                                </div>
                                            </div>
                                            {/* 
                                            <div className="search-cmn-box mt-4">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </Col>
                                        <Col lg={4}>
                                            {" "}
                                            <div className="search-inner-box p-0 mt-4">
                                                <div className="play-images">
                                                    <img
                                                        src={require("../Assets/images/smllbanner.png")}
                                                    />
                                                    <div className="players-value inner-tab">
                                                        <h3>
                                                            CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                                                        </h3>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey="Contact" title="Contact">
                                {" "}
                                <div className="inner-search-main-wrap mt-4">
                                    <Row>
                                        <Col lg={4}>
                                            <div className="search-cmn-box">
                                                <h2>Contact Information</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li>
                                                            <h4>Phone Number:</h4>
                                                            <p>{directorData?.phone_number}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Email Address:</h4>
                                                            <p>{directorData?.email}</p>
                                                        </li>

                                                        <li className="flex-column align-items-start mb-0">
                                                            <h4>Home Address:</h4>
                                                            <p className="mt-1">
                                                                {directorData?.address}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="search-inner-box mt-3">
                                                    <ul>
                                                        <li>
                                                            <h4>Instagram:</h4>
                                                            <p>{directorData?.instagram || "N/A"}</p>

                                                        </li>
                                                        <li>
                                                            <h4>Snapchat:</h4>
                                                            <p>{directorData?.snapchat || "N/A"}</p>

                                                        </li>
                                                        <li>
                                                            <h4>X:</h4>
                                                            <p>{directorData?.twitter || "N/A"}</p>

                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="search-inner-box mt-3">
                                                    <ul>
                                                        <li>
                                                            <h4>Directors Lawyer:</h4>
                                                            <p>{directorData?.director_lawyer_name}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Contact Number:</h4>
                                                            <p>{directorData?.director_lawyer_phone_number}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="search-inner-box mt-3">
                                                    <ul>
                                                        <li>
                                                            <h4>Managing Company:</h4>
                                                            <p>{directorData?.director_managing_company_name}</p>
                                                        </li>
                                                        <li>
                                                            <h4>Contact Number:</h4>
                                                            <p>{directorData?.director_managing_company_phone_number}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="search-inner-box mt-3">
                                                    {" "}
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <h4>Other Notes:</h4>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        {/* <Col lg={4}>
                                            <div className="search-cmn-box mt-4">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col> */}
                                        <Col lg={4}>
                                            {" "}
                                            <div className="search-inner-box p-0 mt-4">
                                                <div className="play-images">
                                                    <img
                                                        src={require("../Assets/images/smllbanner.png")}
                                                    />
                                                    <div className="players-value inner-tab">
                                                        <h3>
                                                            CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                                                        </h3>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </section>
        </LoginLayout>
    );
}

