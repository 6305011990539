import React from "react";
import { Field, ErrorMessage } from "formik";
import { Col, Container, Form, Row } from "react-bootstrap";

export default function YourCompany({ setFieldValue, errors, touched, values }) {
  return (
    <div >
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Your Company</h2>
            <p className="sub-16 text-center mb-4">Fill in your details</p>

            <div className="cmn-form-fields">
              <Form.Group className="mb-3 form-inner">
                <Form.Label className="sub-16 mb-2">
                  Company Name (If Applicable)
                </Form.Label>
                <Field
                  name="company_name"
                  type="text"
                  placeholder="Enter here"
                  className={`form-control ${touched.company_name && errors.company_name ? 'is-invalid' : ''}`}
                />
                <ErrorMessage
                  name="company_name"
                  component="div"
                  className="invalid-feedback"
                />
              </Form.Group>

              <Form.Group className="mb-3 form-inner">
                <Form.Label className="sub-16 mb-2">
                  Website Link (Company or Individual)
                </Form.Label>
                <Field
                  name="website_link"
                  type="text"
                  placeholder="Enter here"
                  className={`form-control ${touched.website_link && errors.website_link ? 'is-invalid' : ''}`}
                />
                <ErrorMessage
                  name="website_link"
                  component="div"
                  className="invalid-feedback"
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
