import { createSlice } from "@reduxjs/toolkit";
import {
  getCompareDataAction,
  getExternalPlayers,
  getGlobalCompareDataAction,
  getPlayerDataById,
  getPlayerListAction,
} from "../Action/playerAction";

const initialState = {
  loading: false,
  playerDetail: [],
  playerList: [],
  playerCompareeData: [],
  externalPlayerData: [],
  globalPlayerCompareeData: [],

  player1Image: null,
  player2Image: null,
  player1Id: null,
  player2Id: null,

  globalPlayer1Image: null,
  globalPlayer2Image: null,
  globalPlayer1Id: null,
  globalPlayer2Id: null,

  genderGlobal : null,
  genderGlobal1 : null,

  error: false,
};

const playerAuthSlice = createSlice({
  name: "playerAuth",
  initialState,
  reducers: {
    setPlayer1Image: (state, action) => {
      state.player1Image = action?.payload?.image;
      state.player1Id = action.payload.id;
    },
    setPlayer2Image: (state, action) => {
      state.player2Image = action?.payload?.image;
      state.player2Id = action.payload.id;
    },
    clearPlayerImages: (state) => {
      state.player1Image = null;
      state.player2Image = null;
      state.player1Id = null;
      state.player2Id = null;
    },

    //  global player
    setGlobalPlayer1Image: (state, action) => {
      state.globalPlayer1Image = action?.payload?.image;
      state.globalPlayer1Id = action.payload.id;
      state.genderGlobal1 = action.payload.gender
    },
    setGlobalPlayer2Image: (state, action) => {
      state.globalPlayer2Image = action?.payload?.image;
      state.globalPlayer2Id = action.payload.id;
      state.genderGlobal = action.payload.gender
    },

    clearPlayerDetail: (state) => {
      state.playerDetail = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayerDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlayerDataById.fulfilled, (state, { payload }) => {
        state.playerDetail = payload.data;
        state.loading = false;
      })
      .addCase(getPlayerDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getPlayerListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlayerListAction.fulfilled, (state, { payload }) => {
        state.playerList = payload.data;
        state.loading = false;
      })
      .addCase(getPlayerListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      //  compare player data
      .addCase(getCompareDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompareDataAction.fulfilled, (state, { payload }) => {
        state.playerCompareeData = payload.data;
        state.loading = false;
      })
      .addCase(getCompareDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // global player data

      .addCase(getGlobalCompareDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGlobalCompareDataAction.fulfilled, (state, { payload }) => {
        state.globalPlayerCompareeData = payload.data;
        state.loading = false;
      })
      .addCase(getGlobalCompareDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // get external player

      .addCase(getExternalPlayers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExternalPlayers.fulfilled, (state, { payload }) => {
        state.externalPlayerData = payload.data;
        state.loading = false;
      })
      .addCase(getExternalPlayers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setPlayer1Image, setPlayer2Image, clearPlayerImages, setGlobalPlayer2Image , setGlobalPlayer1Image, clearPlayerDetail } = playerAuthSlice.actions;
export default playerAuthSlice.reducer;
