import axios from "axios";
import { getToken } from "../utils/helper";
import toast from "react-hot-toast";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: `${getToken()}`,
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.status_code == 501) {
      localStorage.clear();

      toast.error(error?.response?.data?.message);
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
    return error.response;
  }
);

export default api;
