import React from "react";
import LoginLayout from "../components/LoginLayout";
import { Container } from "react-bootstrap";

export default function BeInvolved() {
  return (
    <div>
      <LoginLayout>
        <section>
          <Container>
            <div className="cmn-card">
              <div className="decided-card">
                <p>Yet to be decided</p>
              </div>
            </div>
          </Container>
        </section>
      </LoginLayout>
    </div>
  );
}
