import React, { useEffect } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "../CommonModals/ProgressBar";

const Statistics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const validateForm = (values) => {
    const errors = {};

    if (!values.total_appearence) {
      errors.total_appearence = "required";
    } else if (values.total_appearence < 0) {
      errors.total_appearence = "Value cannot be negative";
    }

    if (!values.starting_eleven) {
      errors.starting_eleven = "required";
    } else if (values.starting_eleven < 0) {
      errors.starting_eleven = "Value cannot be negative";
    }

    // if (!values.enter_as_substitution) {
    //   errors.enter_as_substitution = "required";
    // } else if (values.enter_as_substitution < 0) {
    //   errors.enter_as_substitution = "Value cannot be negative";
    // }

    // if (!values.out_as_substitution) {
    //   errors.out_as_substitution = "required";
    // } else if (values.out_as_substitution < 0) {
    //   errors.out_as_substitution = "Value cannot be negative";
    // }

    // if (!values.goals) {
    //   errors.goals = "required";
    // } else if (values.goals < 0) {
    //   errors.goals = "Value cannot be negative";
    // }

    // if (!values.goals_cancelled) {
    //   errors.goals_cancelled = "required";
    // } else if (values.goals_cancelled < 0) {
    //   errors.goals_cancelled = "Value cannot be negative";
    // }

    // if (!values.clean_sheets) {
    //   errors.clean_sheets = "required";
    // } else if (values.clean_sheets < 0) {
    //   errors.clean_sheets = "Value cannot be negative";
    // }

    // if (!values.assists) {
    //   errors.assists = "required";
    // } else if (values.assists < 0) {
    //   errors.assists = "Value cannot be negative";
    // }

    // if (!values.red_card) {
    //   errors.red_card = "required";
    // } else if (values.red_card < 0) {
    //   errors.red_card = "Value cannot be negative";
    // }

    // if (!values.yellow_card) {
    //   errors.yellow_card = "required";
    // } else if (values.yellow_card < 0) {
    //   errors.yellow_card = "Value cannot be negative";
    // }

    // if (!values.transfer_market_link) {
    //   errors.transfer_market_link = "required";
    // } else if (!/^https?:\/\/.*$/.test(values.transfer_market_link)) {
    //   errors.transfer_market_link = "Invalid URL";
    // }

    return errors;
  };

  const initialValues = {
    total_appearence: playerData?.total_appearence || "",
    total_appearence_out_of: playerData?.total_appearence_out_of || "",
    starting_eleven: playerData?.starting_eleven || "",
    starting_eleven_out_of: playerData?.starting_eleven_out_of || "",
    enter_as_substitution: playerData?.enter_as_substitution || "",
    out_as_substitution: playerData?.out_as_substitution || "",
    goals: playerData?.goals || "",
    goals_cancelled: playerData?.goals_cancelled || "",
    clean_sheets: playerData?.clean_sheets || "",
    assists: playerData?.assists || "",
    red_card: playerData?.red_card || "",
    yellow_card: playerData?.yellow_card || "",
    transfer_market_link: playerData?.transfer_market_link || "",
    statistics_other_notes: playerData?.statistics_other_notes || "",
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const fieldMapping = {
    goalsconceded: "goals_cancelled",
    cleansheets: "clean_sheets",
    redcard: "red_card",
    yellowcard: "yellow_card",
    enterassubstitution: "enter_as_substitution",
    outassubstitution: "out_as_substitution",
  };

  const handleBack = () => {
    navigate(`/proposals/${Encryptedid(paylerId)}`);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="">
            <ProgressBar currentStep={5} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 5:</span> Statistics
              </p>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validateForm}
              onSubmit={(values) => {
                console.log(values);

                let formData = new FormData();

                // const fieldMapping = {
                //   "goalsconceded": "goals_cancelled",
                //   "cleansheets": "clean_sheets",
                //   "redcard": "red_card",
                //   "yellowcard": "yellow_card",
                //   "enterassubstitution": "enter_as_substitution",
                //   "outassubstitution": "out_as_substitution"
                // };

                // Object.entries(values).forEach(([key, value]) => {
                //   formData.append(key, value);
                // });

                Object.entries(values).forEach(([key, value]) => {
                  if (fieldMapping[key] && value !== "") {
                    const mappedKey = fieldMapping[key];
                    formData.append(mappedKey, value);
                  } else if (!fieldMapping[key] && value !== "") {
                    formData.append(key, value);
                  }
                });

                formData.append("playerId", paylerId);
                formData.append("is_profile_completed", 5);

                dispatch(editPlayerAction(formData)).then((res) => {
                  if (res?.payload?.status === 200) {
                    navigate(`/health/${Encryptedid(res?.payload?.data?._id)}`);
                  } else {
                    toast.error(res?.payload?.message);
                  }
                });
              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <FormikForm>
                  <div className=" mt-4">
                    {console.log(values, "a234234234234234")}
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">
                        Statistics on Current Football Session
                      </h3>
                    </div>

                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={6}>
                          <div className="common-shadow">
                            <div className="num-inputs">
                              <h3 className="total-app">Total Appearances</h3>
                              <Form.Group>
                                <Field
                                  type="number"
                                  placeholder="eg.10"
                                  name="total_appearence"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="total_appearence"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <div className="d-flex align-items-center  out-of">
                                <p className="mb-0 me-3 ">Out of</p>
                                <Form.Group>
                                  <Field
                                    type="number"
                                    placeholder="eg.10"
                                    name="total_appearence_out_of"
                                    className="form-control"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="common-shadow">
                            <div className="num-inputs">
                              <h3 className="total-app  ">Starting Eleven</h3>
                              <Form.Group>
                                <Field
                                  type="number"
                                  placeholder="eg.10"
                                  name="starting_eleven"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="starting_eleven"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <div className="d-flex align-items-center out-of">
                                <p className="mb-0 me-3">Out of</p>
                                <Form.Group>
                                  <Field
                                    type="number"
                                    placeholder="eg.10"
                                    name="starting_eleven_out_of"
                                    className="form-control"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <div className="grid-statistics-section">
                          {" "}
                          {[
                            "Enter as Substitution",
                            "Out as Substitution",
                            "Goals",
                            "Goals Conceded",
                            "Clean Sheets",
                            "Assists",
                            "Red Card",
                            "Yellow Card",
                          ].map((field, idx) => {
                            const fieldName = field
                              .toLowerCase()
                              .replace(/ /g, "");
                            const mappedKey =
                              fieldMapping[fieldName] || fieldName;

                            return (
                              <div className="mt-3" key={idx}>
                                <div className="profile-card">
                                  <div className="num-inputs outer">
                                    <Form.Group>
                                      <h3 className="h-20">{field}</h3>
                                      <Field
                                        type="number"
                                        placeholder={field}
                                        name={field
                                          .toLowerCase()
                                          .replace(/ /g, "")}
                                        className="form-control"
                                        value={values[mappedKey]}
                                        onChange={(e) =>
                                          setFieldValue(
                                            mappedKey,
                                            e.target.value
                                          )
                                        }
                                      />
                                      {/* <ErrorMessage
                                  name={ mappedKey }
                                  component="div"
                                  className="text-danger"
                                /> */}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div className="mt-3">
                            <div className="">
                              <h3 className="mb-2 h-20">
                                Transfer market Link
                              </h3>
                              <div className="num-inputs market-link">
                                <Form.Group>
                                  <Field
                                    type="url"
                                    placeholder="Add Link"
                                    name="transfer_market_link"
                                    className="form-control"
                                  />
                                  {/* <ErrorMessage
                                  name="transfer_market_link"
                                  component="div"
                                  className="text-danger"
                                /> */}
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                  <div className=" mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <div className="cmn-form-fields">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 form-inner">
                            <Field
                              as="textarea"
                              name="statistics_other_notes"
                              rows={1}
                              placeholder="Write here"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="statistics_other_notes"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button
                        className="back-btn-ps"
                        type="button"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                      <button className="next-btn-ps" type="submit">
                        Next
                      </button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default Statistics;
