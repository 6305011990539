import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Paper,
  Container,
} from "@mui/material";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CreateProfile from "./CreateProfile";
import YourCompany from "./YourCompany";
import CountrySelect from "./CountrySelect";
import SocialLinks from "./SocialLinks";
import AgentId from "./AgentId";
import { createAgentProfile } from "../Redux/Action/auth";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";
const steps = [
  "Personal Information",
  "Company Information",
  "Location Information",
  "Social Media Links",
  "Profile Confirmation",
];

const initialValues = {
  name: "",
  profile_image: {},
  licensed_agent: 0,
  licensed_doc: {},
  country_code: "+91",
  phone_number: "",
  company_name: "",
  website_link: "",
  country: "",
  city: "",
  instagram: "",
  facebook: "",
  snapchat: "",
  twitter: "",
  is_profile_created: 1,
  unique_id: "",
};

const SUPPORTED_FORMATS = ["application/pdf"];
const step1ValidationSchema = Yup.object({
  name: Yup.string().required("required"),
  phone_number: Yup.string()
    .matches(/^\+?\d{10,15}$/, "Phone number is not valid")
    .required("required"),
  licensed_agent: Yup.boolean()
    .oneOf([true], "required") // This ensures the checkbox is checked
    .required("required"),
  licensed_doc: Yup.mixed()
    .test("fileFormat", "PDF required", function (value) {
      if (!this.parent.licensed_agent) return true; // If not a licensed agent, skip validation
      return value && SUPPORTED_FORMATS.includes(value.type);
    })
    .required("Licensed Document is required when Licensed Agent is selected"),
});

const step3ValidationSchema = Yup.object({
  country: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
});

const getValidationSchema = (step) => {
  switch (step) {
    case 0:
      return step1ValidationSchema;
    case 2:
      return step3ValidationSchema;
    default:
      return Yup.object();
  }
};

const ProfileDetail = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentProgress, setcurrentProgress] = useState(0);

  const uniqueId = useSelector((state) => state.userAuthData.userUniqueId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLastStep = activeStep === steps.length - 1;
  const handleSubmit = async (values) => {
    if (isLastStep) {
      // Handle final submission
      console.log("Final values", values);
      const formDataToSubmit = new FormData();
      const updatedFormData = {
        ...values,
        unique_id: uniqueId,
        is_profile_created: 1,
      };

      Object.keys(updatedFormData).forEach((key) => {
        if (updatedFormData[key]) {
          formDataToSubmit.append(key, updatedFormData[key]);
        }
      });

      dispatch(createAgentProfile(formDataToSubmit)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          localStorage.removeItem("token1");
          navigate("/");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      if (!values.profile_image || !(values.profile_image instanceof File)) {
        toast.error("Profile Image is required");
        return;
      } else {
        setcurrentProgress(currentProgress + 25);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setcurrentProgress(currentProgress - 25);
      setActiveStep(activeStep - 1);
    }
  };

  const StepperHeading = ({ currentProgress, activeStep }) => {
    return (
      <div className="steps-formn">
        <ProgressBar
          completed={currentProgress}
          className="progress-set"
          height="4px"
          bgColor="#C8C8C8"
        />
        <ul>
          {steps.map((label, index) => (
            <li key={index} className={activeStep >= index ? "active-tab" : ""}>
              <span>{index + 1}</span> <p>{label}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="main-section height-set">
      <Container>
        {/* <div className="main-form d-flex justify-content-center"> */}
        {/* <div className="cmn-form"> */}
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema(activeStep)}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              {/* <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper> */}

              <StepperHeading
                activeStep={activeStep}
                currentProgress={currentProgress}
              />
              {activeStep === 0 && (
                <CreateProfile
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}
              {activeStep === 1 && (
                <YourCompany
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}
              {activeStep === 2 && (
                <CountrySelect
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}
              {activeStep === 3 && (
                <SocialLinks
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}
              {activeStep === 4 && (
                <AgentId
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}

              <div className="d-flex justify-content-between">
                <Button
                  id="previous-btn"
                  variant="contained"
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="next-btn"
                >
                  {isLastStep ? "Finish" : "Next"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {/* </div> */}
        {/* </div> */}
      </Container>
    </div>
  );
};

export default ProfileDetail;
