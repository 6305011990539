import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { socailSignupandLogin, userSighUpAction } from "../Redux/Action/auth";
import toast from "react-hot-toast";
import { Encryptedid } from "../utils/Becrypt";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../config/firebase";

export default function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [checked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      let userCredentials = {
        email: email,
        password: password,
      };

      if (!checked) {
        toast.error("Please Accept Terms & Conditions.");
        return;
      }

      dispatch(userSighUpAction(userCredentials)).then((res) => {
        console.log(res, "sdfsdfsdfsdfsdf");
        if (res?.payload?.status === 201) {
        } else if (res?.payload?.status === 400) {
          console.log(res, "sdfsdfsdfsdfsdf");
          toast.error(res?.payload?.message);
        } else if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate(`/otp-verification/${Encryptedid(res?.payload?.data?._id)}`);
        } else {
          toast.error(res?.payload?.error_description);
        }
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
    if (errors.checked) {
      setErrors((prevErrors) => ({ ...prevErrors, checked: "" }));
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const googleProvider = new GoogleAuthProvider();

    console.log("Initiating Google sign-in");

    try {
      const result = await signInWithPopup(auth, googleProvider);

      if (result.user) {
        let userData = {
          social_id: result?.user?.uid,
          full_name: result?.user?.displayName,
          login_type: 2,
          device_token: result?.user?.accessToken,
          device_type: 2,
          email: result?.user?.email,
        };
        dispatch(socailSignupandLogin(userData)).then((res) => {
          console.log(res, "ssssssssssssss");

          if (res?.payload?.status === 200) {
            toast.success(res?.payload?.message);
            navigate("/home");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      }
    } catch (error) {
      console.error("Error during login: ", error.message);
    }
  };
  return (
    <div className="main-section">
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Create Account</h2>
            <p className="sub-16 text-center mb-3">
              Your Ultimate Sports Management Hub
            </p>

            <Form onSubmit={handleSubmit}>
              <div className="cmn-form-fields">
                <Form.Group className="mb-1 form-inner">
                  <label className="sub-16 mb-2">Email Address</label>
                  <Form.Control
                    type="email"
                    placeholder="Enter here"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Group>
                {errors.email && <p className="text-danger">{errors.email}</p>}

                <Form.Group className="mb-1 form-inner mt-3">
                  <label className="sub-16 mb-2">Password</label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <button
                    type="button"
                    className="pass-show"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <img src={require("../Assets/images/eye.svg").default} />
                  </button>
                </Form.Group>
                {errors.password && (
                  <p className="text-danger">{errors.password}</p>
                )}

                <div className="accept-condition">
                  <input
                    type="checkbox"
                    name="termandcondition"
                    onChange={handleChangeCheckbox}
                  />

                  <p>
                    By creating an account I’m agreeing to the{" "}
                    <Link to="#">Terms & Policy</Link> of the application.
                  </p>
                </div>
              </div>

              <button type="submit" className="cmn-red-btn mt-4">
                Create Account
              </button>
            </Form>
            <div className="or-sec">
              <div className="form-bottom">
                <p>Or</p>
              </div>
              <div className="bottom-btns">
                <button type="button" onClick={handleSignup}>
                  <img src={require("../Assets/images/google.svg").default} />
                  <p>Google</p>
                </button>
              </div>
              <div className="no-acc">
                <h4>Have an Account? </h4>
                <Link to="/">Login </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
