import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import {
  createOtherAgentAction,
  editOtherAgentAction,
  getOtherAgentDataById,
} from "../Redux/Action/agentAction";
import { staticOtherAgentDataAction } from "../Redux/Action/staticAction";

export default function AddOtherAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const staticListForOtherAgent = useSelector(
    (e) => e.staticAuthData.otherAgentStaticDetail
  );

  console.log(staticListForOtherAgent, "staticListForOtherAgent");

  const getOtherAgentDetailById = useSelector(
    (e) => e.agentAuthData.otherAgentDetail
  );

  const validationSchema = Yup.object().shape({
    profile_image: Yup.mixed().required("required"),
    banner_image: Yup.mixed().required("required"),

    associate_name: Yup.mixed().required("required"),
    email: Yup.string().email("Invalid email format").required("required"),
    // country: Yup.string().required("required"),
    // role: Yup.string().required("required"),
    phone_number: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    const {
      role: { value: roleValue },
      country: { value: countryValue },
      profile_image,
      banner_image,
      associate_name,
      doctor_speciality,
      phone_number,
      team,
      other_speciality,
      email,
    } = values;

    const dataToSend = {
      role: roleValue,
      country: countryValue,
      associate_name,
      profile_image,
      banner_image,
      doctor_speciality,
      phone_number,
      team,
      other_speciality,
      email,
    };

    let otherAgentId = localStorage.getItem("otherAgentId");

    if (otherAgentId) {
      const formData = new FormData();

      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("otherAgentId", otherAgentId);

      dispatch(editOtherAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Success");
          navigate(`/other-associate-agent`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const formData = new FormData();

      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(key, value);
      });

      dispatch(createOtherAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Success");
          navigate(`/other-associate-agent`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const handleFileChange = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  const optionsCountry = staticListForOtherAgent?.country?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsRole = staticListForOtherAgent?.role?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const initialValues = {
    profile_image: getOtherAgentDetailById?.profile_image || "",
    banner_image: getOtherAgentDetailById?.banner_image || "",
    associate_name: getOtherAgentDetailById?.associate_name || "",
    country: getOtherAgentDetailById?.country || "",
    phone_number: getOtherAgentDetailById?.phone_number || "",
    team: getOtherAgentDetailById?.team || "",
    other_speciality: getOtherAgentDetailById?.other_speciality || "",
    doctor_speciality: getOtherAgentDetailById?.doctor_speciality || "",
    role:
      optionsCountry?.find(
        (elem) => elem.value === getOtherAgentDetailById?.country
      ) || [],
      email : ""
  };

  useEffect(() => {
    let otherAgentId = localStorage.getItem("otherAgentId");
    if (otherAgentId) {
      dispatch(getOtherAgentDataById({ id: otherAgentId }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(staticOtherAgentDataAction());
  }, []);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Other Associate Agent</h2>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, setFieldTouched, errors }) => {
                return (
                  <FormikForm>
                    {console.log(errors, "Dfgdfgdfgdfgdf")}
                    <div className="common-outer">
                      <Row>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Logo</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="profile_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "profile_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>

                              <div className="image-upload-placeholder">
                                {values?.profile_image &&
                                typeof values?.profile_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.profile_image &&
                                  values.profile_image instanceof File ? (
                                  <img
                                    src={
                                      values?.profile_image
                                        ? URL.createObjectURL(
                                            values?.profile_image
                                          )
                                        : ""
                                    }
                                    className="prev-img"
                                    alt="Profile Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.profile_image &&
                                (typeof values.profile_image === "string" ||
                                  values.profile_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("profile_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="profile_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Banner Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="banner_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <div className="image-upload-placeholder">
                                {values?.banner_image &&
                                typeof values.banner_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.banner_image &&
                                  values.banner_image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(
                                      values.banner_image
                                    )}
                                    className="prev-img"
                                    alt="Banner Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.banner_image &&
                                (typeof values.banner_image === "string" ||
                                  values.banner_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("banner_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="banner_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    </div>

                    <div className="common-outer mt-4">
                      <h3 className="h-20">Personal Details</h3>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="associate_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter name"
                              />
                              <ErrorMessage
                                name="associate_name"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                name="country"
                                placeholder="Country"
                                options={optionsCountry}
                                onChange={(option) =>
                                  setFieldValue("country", option)
                                }
                                value={values.country}
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                name="role"
                                placeholder="Role"
                                options={optionsRole}
                                onChange={(option) => {
                                  setFieldValue("role", option);
                                }}
                                value={values.role}
                              />
                              <ErrorMessage
                                name="role"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          {values.role.value === 7 ? (
                            <Col lg={4}>
                              <Form.Group className="mb-3 form-inner">
                                <Field
                                  name="doctor"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Doctor's Specialty"
                                />
                                <ErrorMessage
                                  name="doctor"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            ""
                          )}

                          {values.role.value === 10 ? (
                            <Col lg={4}>
                              <Form.Group className="mb-3 form-inner">
                                <Field
                                  name="other_speciality"
                                  type="text"
                                  className="form-control"
                                  placeholder="Status/ Specialty "
                                />
                                <ErrorMessage
                                  name="other_speciality"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="team"
                                type="text"
                                className="form-control"
                                placeholder="Enter team"
                              />
                              <ErrorMessage
                                name="team"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-inner mb-3">
                            <Field
            name="email"
            as={Form.Control}
            type="email"
            placeholder="Enter email here"
          />
          <ErrorMessage
            name="email"
            component="div"
            className="text-danger"
          />
                            </Form.Group>
                          </Col>

                    

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="phone_number"
                                type="number"
                                className="form-control"
                                placeholder="Enter phone number"
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button type="submit">Next</button>
                      </div>
                    </Col>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
