import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../Redux/Action/auth";
import { useDispatch } from "react-redux";
import { Decryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";

// Validation schema
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const userid = id && Decryptedid(atob(id));

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="main-section">
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Reset Password</h2>
            <p className="sub-16 text-center mb-3">
              Reclaim Access: Set a new password
            </p>

            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(
                  resetPassword({
                    userId: userid,
                    password: values?.password,
                  })
                ).then((res) => {
                  if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message);
                    navigate("/");
                  } else {
                    toast.error(res?.payload?.message);
                  }
                });
              }}
            >
              {({ errors, touched }) => (
                <FormikForm className="cmn-form-fields">
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">New Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="New Password"
                      className={`form-control`}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                    <button
                      type="button"
                      className="pass-show"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <img
                        src={require("../Assets/images/eye.svg").default}
                        alt="Toggle Password Visibility"
                      />
                    </button>
                  </Form.Group>
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Confirm Password</label>
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className={`form-control`}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-danger"
                    />
                    <button
                      type="button"
                      className="pass-show"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      <img
                        src={require("../Assets/images/eye.svg").default}
                        alt="Toggle Password Visibility"
                      />
                    </button>
                  </Form.Group>
                  <button type="submit" className="cmn-red-btn mb-0 mt-4">
                    Submit
                  </button>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
}
