import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// create Agent
export const createAgentAction = createAsyncThunk(
  "createAgentAction/user/createAgent",
  async (data) => {
    const response = await api.post("/user/createAgent", data);
    return response.data;
  }
);

// edit agent
export const editAgentAction = createAsyncThunk(
  "editAgentAction/user/editAgent",
  async (data) => {
    const response = await api.put("/user/editAgent", data);
    return response.data;
  }
);

// get agent detail by id

export const getAgentDataById = createAsyncThunk(
  "getAgentDataById/getAgentById",
  async ({ id }, thunkAPI) => {
    const response = await api.get(`/user/getAgentById`, {
      params: {
        agentId: id,
      },
    });
    return response.data;
  }
);


// get  agent details

export const getAgentDetailsById = createAsyncThunk(
  "getAgentDataById/getAgentData",
  async ({ id }, thunkAPI) => {
    const response = await api.get(`/user/getAgentData`, {
      params: {
        agentId: id,
      },
    });
    return response.data;
  }
);
// get  agent list
export const getAgentListAction = createAsyncThunk(
  "getAgentListAction/user/getMyAgents",
  async (data) => {
    const response = await api.get("user/getMyAgents", data);
    return response.data;
  }
);

// other agent create
export const createOtherAgentAction = createAsyncThunk(
  "createOtherAgentAction/user/createOtherAgent",
  async (data) => {
    const response = await api.post("/user/createOtherAgent", data);
    return response.data;
  }
);

// edit other agent
export const editOtherAgentAction = createAsyncThunk(
  "editOtherAgentAction/user/editOtherAgent",
  async (data) => {
    const response = await api.put("/user/editOtherAgent", data);
    return response.data;
  }
);



// get other agent detail by id

export const getOtherAgentDataById = createAsyncThunk(
  "getOtherAgentDataById/getOtherAgentById",
  async ({ id }, thunkAPI) => {
    const response = await api.get(`/user/getOtherAgentById`, {
      params: {
        otherAgentId: id,
      },
    });
    return response.data;
  }
);

// get other agent list
export const getOtherAgentListAction = createAsyncThunk(
  "getOtherAgentListAction/user/getMyOtherAgents",
  async (data) => {
    const response = await api.get("user/getMyOtherAgents", data);
    return response.data;
  }
);