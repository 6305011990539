import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { createAgentProfile, getProfileDetail } from "../Redux/Action/auth";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { countryCode } from "../utils/rawjson";
import Select from "react-select";
import { Country, State } from "country-state-city";
import ReactCountryFlag from "react-country-flag";
import Loader from "../components/Loader";
import { FaFilePdf } from "react-icons/fa6";
import { BiSolidEdit } from "react-icons/bi";

const validationSchema = Yup.object({
  // name: Yup.string().required("required"),
  // country: Yup.string().required("required"),
  // city: Yup.string().required("required"),
  // phone_number: Yup.string().required("Phone Number is required"),
  // emailAddress: Yup.string()
  //   .email("Invalid email address")
  //   .required("Email Address is required"),
  // website_link: Yup.string().url("Invalid URL"),
  // instagram: Yup.string().url("Invalid URL"),
  // snapchat: Yup.string().url("Invalid URL"),
  // x: Yup.string().url("Invalid URL"),
  // others: Yup.string().url("Invalid URL"),
  // profile_image: Yup.mixed(),
});

export default function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileDetail = useSelector(
    (state) => state.userAuthData.getProfileData
  );

  const loader = useSelector((state) => state.userAuthData.loading);

  const [file, setFile] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);

   const [show, setShow] = useState(false);


  useEffect(() => {
    dispatch(getProfileDetail());
  }, []);

  const initialValues = {
    name: profileDetail?.full_name || "",
    country: profileDetail?.country || "",
    city: profileDetail?.city || "",
    phone_number: profileDetail?.phone_number || "",
    // emailAddress: profileDetail?.email || "",
    website_link: profileDetail?.website_link || "",
    profile_image: profileDetail?.profile_image || null,
    instagram: profileDetail?.instagram || "",
    snapchat: profileDetail?.snapchat || "",
    twitter: profileDetail?.twitter || "",
    facebook: profileDetail?.facebook || "",
    licensed_agent: profileDetail?.is_licensed_agent || "",
    country_code: profileDetail?.country_code || "",
  };

  const formik = useFormik({
    initialValues,
    // validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("Form Values:", values);

      const formDataToSubmit = new FormData();

      Object.keys(values).forEach((key) => {
        if (values[key] && key !== "profile_image") {
          formDataToSubmit.append(key, values[key]);
        }
      });

      formDataToSubmit.append("profile_image", file);

      dispatch(createAgentProfile(formDataToSubmit)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Profile Updated Successfully.");
          navigate("/my-profile");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });

  const getImageSrc = () => {
    if (file) {
      return URL.createObjectURL(file);
    } else if (formik.values.profile_image) {
      return `${process.env.REACT_APP_IMAGE_PATH}${profileDetail?.profile_image}`;
    } else {
      return require("../Assets/images/biguser.png");
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      formik.setFieldValue("profile_image", file);
      setFile(file);
    } else {
      formik.setFieldValue("profile_image", null);
      setFile("");
    }
  };

  const transformedCountryCodeOptions = countryCode?.map((option) => ({
    value: option.dial_code,
    label: ` ${option.dial_code}`,
  }));

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  const handleCountryChange = async (
    selectedOption,
    setFieldValue,
    setFieldTouched
  ) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });

      setFieldValue("country", countryData.name);
      setFieldValue("city", "");
      setSelectedCity(null);
      setCities([]);

      const fetchedCities = await fetchCities(countryData.isoCode);

      setCities(fetchedCities);
      setFieldTouched("country", false);
    }
  };

  const fetchCities = async (countryCode) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryCode);
      const fetchedCities = stateList.map((state) => ({
        value: state.isoCode,
        label: state.name,
      }));
      return fetchedCities;
    } catch (error) {
      console.error("Error fetching cities:", error);
      return [];
    } finally {
      setLoadingCities(false);
    }
  };

  useEffect(() => {
    if (profileDetail?.country && countries.length > 0) {
      const countryData = countries.find(
        (country) => country.label === profileDetail.country
      );

      if (countryData) {
        setSelectedCountry({
          value: countryData.value,
          label: countryData.label,
        });

        fetchCities(countryData.value).then((fetchedCities) => {
          const cityData = fetchedCities.find(
            (city) =>
              city.label === profileDetail.city ||
              city.value === profileDetail.city
          );

          if (cityData) {
            setSelectedCity({
              value: cityData.value,
              label: cityData.label,
            });
            formik.setFieldValue("city", cityData.label);
          }
        });
      }
    }
  }, [profileDetail, countries]);

  const getFileName = (filePath) => {
    if (!filePath) return "No file available";
    return filePath.split("/").pop();
  };

  const getLicensedDocUrl = () => {
    if (formik.values.licensed_doc instanceof File) {
      return URL.createObjectURL(formik.values.licensed_doc);
    }
    return null;
  };

  const handleLicensedDocChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("licensed_doc", file);
  };


  const handleSwitchChange = (e) => {
    if (formik.values.licensed_agent == "1") {
      return;
    }
    const isChecked = e.target.checked ? "1" : "0";
    formik.setFieldValue("licensed_agent", isChecked);
    setShow(isChecked == "1");
  };

  return (
    <LoginLayout>
      <section>
        {loader && <Loader />}
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-4 d-flex justify-content-between align-items-center">
              <h2>Edit Profile</h2>
            </div>
            <div className="profile-user">
              <Row>
                <Col lg={2}>
                  <div className="image-upload-in">
                    <img
                      className="user-img"
                      src={getImageSrc()}
                      alt="Profile"
                    />
                    <Form.Control
                      type="file"
                      id="inputPassword5"
                      aria-describedby="passwordHelpBlock"
                      name="profile_image"
                      onChange={handleImageChange}
                    />
                  </div>
                </Col>

                <Col lg={3} className="d-flex align-items-center">
                  <div className="ms-4 ps-3">
                    <h3 className="h-30">{formik.values.name}</h3>
                    <div className="social-media">
                      {profileDetail?.facebook && (
                        <a
                          href={profileDetail.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../Assets/images/fb.svg").default}
                            alt="Facebook"
                          />
                        </a>
                      )}
                      {profileDetail?.snapchat && (
                        <a
                          href={profileDetail.snapchat}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../Assets/images/snap.svg").default}
                            alt="Snapchat"
                          />
                        </a>
                      )}
                      {profileDetail?.instagram && (
                        <a
                          href={profileDetail.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../Assets/images/insta.svg").default}
                            alt="Instagram"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Form
              onSubmit={formik.handleSubmit}
              className="cmn-form-fields mt-4"
            >
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      isInvalid={!!formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">Country</Form.Label>
                    {/* <Form.Control
                      type="text"
                      name="country"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.country}
                      isInvalid={!!formik.errors.country}
                    /> */}
                    {/* <Form.Control.Feedback type="invalid">
                      {formik.errors.country}
                    </Form.Control.Feedback> */}

                    <Select
                      isSearchable={true}
                      value={selectedCountry}
                      name="country"
                      onChange={(e) =>
                        handleCountryChange(
                          e,
                          formik.setFieldValue,
                          formik.setFieldTouched
                        )
                      }
                      options={countries}
                      placeholder="Select a country"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {/* <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">City</Form.Label> */}
                  {/* <Form.Control
                      type="text"
                      name="city"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      isInvalid={!!formik.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.city}
                    </Form.Control.Feedback> */}

                  <Form.Group className="mb-3 form-flag">
                    <label className="sub-16 mb-2">City</label>
                    <Select
                      name="city"
                      options={cities}
                      isDisabled={loadingCities || !selectedCountry}
                      isLoading={loadingCities}
                      value={selectedCity}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          formik.setFieldValue("city", selectedOption.label);
                          setSelectedCity(selectedOption);
                        }
                      }}
                      placeholder="Select a city"
                      className={`react-select`}
                    />
                  </Form.Group>

                  {/* </Form.Group> */}
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">
                      Phone Number
                    </Form.Label>

                    <div className="phone-select">
                      <Select
                        name="country_code"
                        className=" react-select-set"
                        aria-label="Default select example"
                        placeholder="+357"
                        isSearchable={false}
                        options={transformedCountryCodeOptions}
                        value={transformedCountryCodeOptions.find(
                          (option) =>
                            option.value === formik.values.country_code
                        )}
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "country_code",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                      />

                      <Form.Control
                        type="number"
                        name="phone_number"
                        placeholder="Please enter here..."
                        onChange={formik.handleChange}
                        value={formik.values.phone_number}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="emailAddress"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={profileDetail?.email}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">Website</Form.Label>
                    <Form.Control
                      type="url"
                      name="website_link"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.website_link}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <div className="licensed-sec">
                    <h3>Licensed Agent</h3>
                    <div className="form-check form-switch">
                      <Form.Check
                        type="switch"
                        id="licensed_agent"
                        name="licensed_agent"
                        // onChange={(e) =>
                        //   formik.setFieldValue(
                        //     "licensed_agent",
                        //     e.target.checked ? "1" : "0"
                        //   )
                        // }
                        onChange={handleSwitchChange}
                        checked={formik.values.licensed_agent == "1" }
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4}></Col>
                <Col lg={4}></Col>
                { show  &&
                  (
                <Row>
                  <Col lg={4}>
                    <div className="image-upload-main">
                      <div className="image-upload-inner profile">
                        <div className="image-upload-placeholder">
                          {getLicensedDocUrl() ? (
                            <div>
                              <FaFilePdf />
                            </div>
                          ) : (
                            <>
                              <img
                                src={
                                  require("../Assets/images/upload.svg").default
                                }
                                alt="Upload Placeholder"
                              />
                              <h4>Upload Licensed Document</h4>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          name="licensed_doc"
                          onChange={handleLicensedDocChange}
                          className={`form-control-file`}
                          accept="application/pdf"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                )}

                {profileDetail?.licensed_doc ? (
                  <Col lg={4} className="mt-3">
                    <Form.Label className="sub-16 mb-2">
                      License Document
                    </Form.Label>
                    <div className="play-inner-box mb-0">
                      <div className="d-flex align-items-center">
                        <img
                          src={require("../Assets/images/pdf.svg").default}
                        />
                        <div className="ms-3">
                          <h4>{getFileName(profileDetail?.licensed_doc)}</h4>
                        </div>
                        <div className="edit-img" onClick={()=>setShow(!show)}>
                          <BiSolidEdit />
                        </div>
                      </div>
                      <a
                        href={`${process.env.REACT_APP_IMAGE_PATH}${profileDetail?.licensed_doc}`}
                        download
                        target="_blank"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <img
                          src={require("../Assets/images/downbtn.svg").default}
                          alt="Download"
                        />
                      </a>
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                <div className="card-heading mb-4 d-flex justify-content-between align-items-center mt-4">
                  <h2>Social Media links</h2>
                </div>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">Instagram</Form.Label>
                    <Form.Control
                      type="url"
                      name="instagram"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.instagram}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">Snapchat</Form.Label>
                    <Form.Control
                      type="url"
                      name="snapchat"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.snapchat}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">X</Form.Label>
                    <Form.Control
                      type="url"
                      name="twitter"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.twitter}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3 form-inner">
                    <Form.Label className="sub-16 mb-2">Others</Form.Label>
                    <Form.Control
                      type="url"
                      name="facebook"
                      placeholder="Please enter here..."
                      onChange={formik.handleChange}
                      value={formik.values.facebook}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} className="d-flex justify-content-end mt-4">
                  <div className="schedule-btn">
                    <Button type="submit">Save</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
