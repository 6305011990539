import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Formik,
  Field,
  Form as FormikForm,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { staticTeamDataAction } from "../Redux/Action/staticAction";
import Select from "react-select";
import { Country, State } from "country-state-city";
import {
  createTeamAction,
  editTeamAction,
  getTeamDataById,
} from "../Redux/Action/teamAction";
import toast from "react-hot-toast";
import { Encryptedid } from "../utils/Becrypt";

export default function AddTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cities, setCities] = useState([]);
  const [cities1, setCities1] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [inputType1, setInputType1] = useState("text");

  const staticListForTeam = useSelector(
    (e) => e.staticAuthData.teamStaticDetail
  );
  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

  const optionsTeamLeague = staticListForTeam?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsAnnualBudget = staticListForTeam?.annual_budget?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const optionsTrophy = staticListForTeam?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const validateForm = (values) => {
    const errors = {};

    if (!values.profile_image) {
      errors.profile_image = "required";
    }

    if (!values.banner_image) {
      errors.banner_image = "required";
    }

    if (!values.team_name) {
      errors.team_name = "required";
    } else if (values.team_name.length < 2) {
      errors.team_name = "Must be at least 2 characters long";
    }

    if (!values.team_country) {
      errors.team_country = "required";
    }

    if (!values.team_city) {
      errors.team_city = "required";
    }


    if (!values.team_president) {
      errors.team_president = "required";
    }

    if (!values.team_owner) {
      errors.team_owner = "required";
    }

    if (values.carrier_trophies && values.carrier_trophies.length > 0) {
      values.carrier_trophies.forEach((item, index) => {
        if (!item.trophy) {
          if (!errors.carrier_trophies) errors.carrier_trophies = [];
          errors.carrier_trophies[index] = { trophy: "Trophy is required" };
        }
        if (!item.date_of_winning) {
          if (!errors.carrier_trophies) errors.carrier_trophies = [];
          errors.carrier_trophies[index] = {
            ...errors.carrier_trophies[index],
            date_of_winning: "Date is required",
          };
        }
      });
    }

    return errors;
  };

  const processFormData = (values) => {
    console.log(cities1, "fsdfgdfgdfgdsfs", values);
    return {
      ...values,
      team_city: values.team_city || "",
      team_league: values.team_league?.value || "",
      annual_budget: values.annual_budget?.value || "",
    };
  };

  const handleSubmit = (values) => {
    const processedValues = processFormData(values);

    let teamId = localStorage.getItem("teamId");

    if (teamId) {
      const formData = new FormData();

      const transformedData = [];

      values.carrier_trophies.forEach((item) => {
        const trophies = Array.isArray(item.trophy)
          ? item.trophy
          : [item.trophy];

        trophies.forEach((trophy) => {
          transformedData.push({
            trophy: trophy.value,
            date_of_winning: item.date_of_winning,
          });
        });
      });
      formData.append("carrier_trophies", JSON.stringify(transformedData));
      Object.entries(processedValues).forEach(([key, value]) => {
        if (key !== "carrier_trophies") {
          formData.append(key, value);
        }
      });
      formData.append("teamId", teamId);
      formData.append("is_profile_completed", 1);

      dispatch(editTeamAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate(`/add-team-two/${Encryptedid(res?.payload?.data?._id)}`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const transformedData = [];

      values.carrier_trophies.forEach((item) => {
        const trophies = Array.isArray(item.trophy)
          ? item.trophy
          : [item.trophy];

        trophies.forEach((trophy) => {
          transformedData.push({
            trophy: trophy.value,
            date_of_winning: item.date_of_winning,
          });
        });
      });

      const formData = new FormData();
      formData.append("carrier_trophies", JSON.stringify(transformedData));

      Object.entries(processedValues).forEach(([key, value]) => {
        if (key !== "carrier_trophies") {
          formData.append(key, value);
        }
      });

      formData.append("is_profile_completed", 1);

      dispatch(createTeamAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate(`/add-team-two/${Encryptedid(res?.payload?.data?._id)}`);
          localStorage.setItem("teamId", res?.payload?.data?._id);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const handleFileChange = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  const groupTrophiesByDate = (carrierTrophies, optionsTrophy) => {
    const groupedTrophies = [];

    carrierTrophies.forEach((elem) => {
      const existingGroup = groupedTrophies.find(
        (group) => group.date_of_winning === elem.date_of_winning.split("T")[0]
      );

      if (existingGroup) {
        existingGroup.trophy.push(
          optionsTrophy?.find((option) => option.value === elem.trophy)
        );
      } else {
        groupedTrophies.push({
          trophy: [
            optionsTrophy?.find((option) => option.value === elem.trophy),
          ],
          date_of_winning: elem.date_of_winning.split("T")[0],
        });
      }
    });

    return groupedTrophies.length > 0
      ? groupedTrophies
      : [{ trophy: null, date_of_winning: "" }];
  };

  const initialValues = {
    profile_image: getTeamDetails?.profile_image || "",
    banner_image: getTeamDetails?.banner_image || "",
    team_name: getTeamDetails?.team_name || "",
    team_country: getTeamDetails?.team_country || "",
    team_city: getTeamDetails?.team_city || "",
    team_president: getTeamDetails?.team_president || "",
    team_owner: getTeamDetails?.team_owner || "",
    team_league:
      optionsTeamLeague?.find(
        (elem) => elem.value === getTeamDetails?.team_league
      ) || "",
    annual_budget:
      optionsAnnualBudget?.find(
        (elem) => elem.value === getTeamDetails?.annual_budget
      ) || "",
    // carrier_trophies:
    //   getTeamDetails?.carrier_trophies?.length > 0
    //     ? getTeamDetails?.carrier_trophies?.map((elem) => ({
    //         trophy: optionsTrophy?.find(
    //           (option) => option.value === elem.trophy
    //         ),
    //         date_of_winning: elem?.date_of_winning?.split("T")[0],
    //       }))
    //     : [{ trophy: null, date_of_winning: "" }],
    carrier_trophies: groupTrophiesByDate(
      getTeamDetails?.carrier_trophies || [],
      optionsTrophy
    ),

    info_other_notes: getTeamDetails?.info_other_notes || "",
  };

  useEffect(() => {
    dispatch(staticTeamDataAction());
  }, []);

  useEffect(() => {
    let teamId = localStorage.getItem("teamId");
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId }));
    }
  }, [dispatch]);

  const optionsPlaceOfBirth = staticListForTeam?.country?.map((item) => ({
    label: item,
    value: item,
  }));

  // const fetchCities = async (countryName) => {
  //   setLoadingCities(true);
  //   try {
  //     const stateList = State.getStatesOfCountry(countryName);
  //     const cityMap = {};

  //     const cities = stateList.map((state) => {
  //       cityMap[state.isoCode] = state.name;
  //       return {
  //         value: state.name,
  //         label: state.name,
  //       };
  //     });

  //     setCities(cities);
  //     setCityMap(cityMap);
  //   } catch (error) {
  //     console.error("Error fetching cities:", error);
  //   } finally {
  //     setLoadingCities(false);
  //   }
  // };

  // const handleCountryChange = async (
  //   selectedOption,
  //   { setFieldValue, setFieldTouched }
  // ) => {
  //   if (!selectedOption) return;

  //   const countryData = Country.getAllCountries().find(
  //     (country) => country.isoCode === selectedOption.value
  //   );

  //   if (countryData) {
  //     setSelectedCountry({
  //       value: countryData.isoCode,
  //       label: countryData.name,
  //     });

  //     setFieldValue("team_country", countryData.name);
  //     setFieldValue("team_city", "");
  //     setCities([]);
  //     await fetchCities(countryData.isoCode);
  //     setCities1(null);
  //     setFieldTouched("team_country", false);
  //   }
  // };

  const fetchCities = async (countryIsoCode) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryIsoCode);
      const cityMap = {};
  
      const cities = stateList.map((state) => {
        cityMap[state.isoCode] = state.name;
        return {
          value: state.name,
          label: state.name,
        };
      });
  
      setCities(cities); // Update cities in the state
      setCityMap(cityMap); // Update the city map
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoadingCities(false);
    }
  };
  
  const handleCountryChange = async (
    selectedOption,
    { setFieldValue, setFieldTouched }
  ) => {
    if (!selectedOption) return;
  
    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );
  
    if (countryData) {
      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });
  
      setFieldValue("team_country", countryData.name); // Set the selected country
      setFieldValue("team_city", ""); // Reset city when country changes
      setCities([]); // Clear cities before fetching new ones
      await fetchCities(countryData.isoCode); // Fetch cities based on the country
      setCities1(null); // Reset selected city
      setFieldTouched("team_country", false); // Mark country field as untouched
    }
  };
  

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  // useEffect(() => {
  //   if (getTeamDetails?.team_country) {
  //     const countryOption = countries.find(
  //       (country) => country.label === getTeamDetails?.team_country
  //     );
  //     setSelectedCountry(countryOption || null);

  //     if (countryOption) {
  //       fetchCities(countryOption.value);
  //     }
  //   }
  // }, [getTeamDetails, countries]);

  // useEffect(() => {
  //   if (getTeamDetails?.team_city) {
  //     const selectedCityData = cities?.find(
  //       (city) => city.label === getTeamDetails.team_city
  //     );

  //     if (selectedCityData) {
  //       setCities1(selectedCityData);
  //     }
  //   }
  // }, [cities, getTeamDetails]);

  // useEffect(() => {
  //   if (selectedCountry) {
  //     fetchCities(selectedCountry.value);
  //   } else {
  //     setCities([]);
  //     setCities1(null);
  //   }
  // }, [selectedCountry]);

  useEffect(() => {
    if (getTeamDetails?.team_country) {
      const countryOption = countries.find(
        (country) => country.label === getTeamDetails?.team_country
      );
      setSelectedCountry(countryOption || null);
  
      if (countryOption) {
        fetchCities(countryOption.value);
      }
    }
  }, [getTeamDetails, countries]);
  
  useEffect(() => {
    if (getTeamDetails?.team_city) {
      const selectedCityData = cities?.find(
        (city) => city.label === getTeamDetails.team_city
      );
  
      if (selectedCityData) {
        setCities1(selectedCityData);
      }
    }
  }, [cities, getTeamDetails]);
  

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Add Team</h2>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, setFieldTouched }) => {
                return (
                  <FormikForm>
                    <div className="common-outer">
                      <Row>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Logo</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="profile_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "profile_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>

                              <div className="image-upload-placeholder">
                                {values?.profile_image &&
                                typeof values?.profile_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.profile_image &&
                                  values.profile_image instanceof File ? (
                                  <img
                                    src={
                                      values?.profile_image
                                        ? URL.createObjectURL(
                                            values?.profile_image
                                          )
                                        : ""
                                    }
                                    className="prev-img"
                                    alt="Profile Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.profile_image &&
                                (typeof values.profile_image === "string" ||
                                  values.profile_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("profile_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="profile_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Banner Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="banner_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <div className="image-upload-placeholder">
                                {values?.banner_image &&
                                typeof values.banner_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.banner_image &&
                                  values.banner_image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(
                                      values.banner_image
                                    )}
                                    className="prev-img"
                                    alt="Banner Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.banner_image &&
                                (typeof values.banner_image === "string" ||
                                  values.banner_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("banner_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="banner_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    </div>

                    <div className="common-outer mt-4">
                      <h3 className="h-20">Personal Details</h3>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="team_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter name here"
                              />
                              <ErrorMessage
                                name="team_name"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                name="team_country"
                                placeholder="Teams Country"
                                isSearchable={true}
                                value={selectedCountry}
                                onChange={(e) =>
                                  handleCountryChange(e, {
                                    setFieldValue,
                                    setFieldTouched,
                                  })
                                }
                                options={countries}
                              />
                              <ErrorMessage
                                name="team_country"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                // name="team_city"
                                // placeholder="Team's City"
                                // options={cities}
                                // onChange={(option) => {
                                //   setFieldValue("team_city", option);
                                //   setCities1(option);
                                // }}
                                // value={cities1 || ""}
                                // isLoading={loadingCities}
                                // isDisabled={!selectedCountry}
                                options={cities}
                                onChange={(selectedOption) => {
                                  setFieldValue("team_city", selectedOption?.value || "");
                                  setCities1(selectedOption); // Set the selected city in the state
                                }}
                                value={cities1 || cities?.find((city) => city.value === values.team_city)}
                                isDisabled={!selectedCountry}
                                isLoading={loadingCities}
                                placeholder="Select a city"
                              />
                              <ErrorMessage
                                name="team_city"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="team_owner"
                                type="text"
                                className="form-control"
                                placeholder="Enter team owner name"
                              />
                              <ErrorMessage
                                name="team_owner"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                name="team_league"
                                placeholder="Teams League"
                                options={optionsTeamLeague}
                                onChange={(option) =>
                                  setFieldValue("team_league", option)
                                }
                                value={values.team_league}
                              />
                              <ErrorMessage
                                name="team_league"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            {" "}
                            <div className="profile-card mb-3">
                              <Select
                                name="annual_budget"
                                placeholder="Annual Budget"
                                options={optionsAnnualBudget}
                                onChange={(option) =>
                                  setFieldValue("annual_budget", option)
                                }
                                value={values.annual_budget}
                              />
                              <ErrorMessage
                                name="annual_budget"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            {" "}
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="team_president"
                                type="text"
                                className="form-control"
                                placeholder="Enter President name here  "
                              />
                              <ErrorMessage
                                name="team_president"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="common-outer mt-4">
                      <FieldArray name="carrier_trophies">
                        {({ push, remove }) => (
                          <>
                            <div className="d-flex justify-content-between">
                              <h3 className="h-20">Carrier Trophies</h3>
                              <button
                                type="button"
                                onClick={() =>
                                  push({ trophy: null, date_of_winning: "" })
                                }
                                style={{
                                  border: "none",
                                  background: "none",
                                }}
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                    fill="#4C8BB4"
                                  />
                                </svg>
                              </button>
                            </div>
                            {values?.carrier_trophies?.map((trophy, index) => (
                              <div key={index} className="cmn-form-fields mt-2">
                                <Row>
                                  <Col lg={4}>
                                    <div className="profile-card mb-3">
                                      <Select
                                        name={`carrier_trophies[${index}].trophy`}
                                        placeholder="Tropies/Champanioship"
                                        options={optionsTrophy}
                                        isMulti
                                        onChange={(option) =>
                                          setFieldValue(
                                            `carrier_trophies[${index}].trophy`,
                                            option
                                          )
                                        }
                                        value={
                                          values.carrier_trophies[index]
                                            .trophy || ""
                                        }
                                      />
                                      <ErrorMessage
                                        name={`carrier_trophies[${index}].trophy`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <Form.Group className="mb-3 form-inner">
                                      <Field
                                        name={`carrier_trophies[${index}].date_of_winning`}
                                        className="form-control"
                                        placeholder="Date of Winning"
                                        type={inputType1}
                                        onFocus={() => setInputType1("date")}
                                        onBlur={() => setInputType1("text")}
                                      />
                                      <ErrorMessage
                                        name={`carrier_trophies[${index}].date_of_winning`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4}>
                                    {index !== 0 && (
                                      <button
                                        type="button"
                                        onClick={() => remove(index)}
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                      >
                                        <svg
                                          width="28"
                                          height="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                            fill="#4C8BB4"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </div>

                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Other Notes</h3>
                      </div>
                      <div className="cmn-form-fields ">
                        <Row>
                          <Col lg={12}>
                            {" "}
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                as="textarea"
                                name="info_other_notes"
                                rows={3}
                                className="form-control"
                                placeholder="Write here"
                              />
                              <ErrorMessage
                                name="info_other_notes"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button type="submit">Next</button>
                      </div>
                    </Col>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
