import React from "react";
import { Container, Form } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPasswordAction } from "../Redux/Action/auth";
import { Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export default function ForgotPassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className="main-section">
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Forgot Password?</h2>
            <p className="sub-16 text-center mb-4">
              Unlock Your Account: Reset Your Password, <br />
              Regain Access
            </p>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("Form data:", values);

                dispatch(forgotPasswordAction(values)).then((res) => {
                  console.log(res, "rrrrrrrrrrrrrrr");
                  if (res?.payload?.status === 200) {
                    localStorage.setItem("userEmail", values.email);
                    localStorage.setItem("forgotPassword_id", 0);
                    toast.success(res?.payload?.message);
                    navigate(`/otp-verification/${Encryptedid(res?.payload?.data?._id)}`);
                  } else {
                    toast.error(res?.payload?.error_description);
                  }
                })
              }}
            >
              {({ errors, touched }) => (
                <FormikForm className="cmn-form-fields">
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Email Address</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className={`form-control`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                  <button type="submit" className="cmn-red-btn mb-0 mt-4">
                    Continue
                  </button>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
}
