import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCoachesDataById,
  getCoachListAction,
} from "../Redux/Action/coachAction";
import { Encryptedid } from "../utils/Becrypt";
import { capitalize } from "../utils/helper";
import Loader from "../components/Loader";

export default function MyCoach() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllCoachList = useSelector((e) => e.coachAuthData.getCoachLists);
  const loader = useSelector((e) => e.coachAuthData.loading);

  useEffect(() => {
    dispatch(getCoachesDataById({ id: "" }));
    dispatch(getCoachListAction());
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
              <h2>My Coach</h2>
            </div>
            <div className="play-outer">
              <Row>
                {getAllCoachList?.length > 0 ? (
                  getAllCoachList?.map((item, index) => (
                    <Col
                      lg={4}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/my-coach/${Encryptedid(item?._id)}`)
                      }
                    >
                      <div className="player-box">
                        {/* <img src={require("../Assets/images/user.png")} /> */}
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item?.profile_image}`}
                        />
                        <div className="mt-2">
                          <h3>{capitalize(item?.full_name)}</h3>
                          <h5>{capitalize(item?.current_team_name)}</h5>
                          <p>
                            <span>Coach ID:</span>{" "}
                            {item?.unique_id.toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4 className="nodata">No Coach Data</h4>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
