import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import { getOtherAgentListAction } from "../Redux/Action/agentAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { role } from "../utils/rawjson";
import { Encryptedid } from "../utils/Becrypt";
import Loader from "../components/Loader";

export default function OtherAssociateAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otherAgentListData = useSelector((e) => e.agentAuthData.otherAgentList);
  const loader = useSelector((e) => e.agentAuthData.loading);


  useEffect(() => {
    dispatch(getOtherAgentListAction());
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
              <h2>Other Associate Agent</h2>
            </div>
            <div className="play-outer">
              {/* <Row>
                <Col lg={4} className="mb-3">
                  <div className="player-box align-items-center">
                    <img src={require("../Assets/images/user.png")} />
                    <div>
                      <h3>Associate Agent Name</h3>
                      <p>
                        <span>Agent ID:</span> IVXJW007
                      </p>
                      <p className="mt-1">
                        <span>Role: </span> Lorium Ipusm
                      </p>
                    </div>
                  </div>
                </Col>
      
              </Row> */}
              <Row>
                {otherAgentListData?.length > 0 ? (
                  otherAgentListData?.map((item, index) => (
                    <Col
                      lg={4}
                      key={index}
                      onClick={() =>
                        navigate(
                          `/other-associate-agent/${Encryptedid(item?._id)}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="player-box new-play-box">
                        <div className=" agent-box">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}${item?.profile_image}`}
                          />
                          <div>
                            <h3>{item?.associate_name}</h3>

                            <h5 className="mb-2">{role[item?.role]}</h5>
                          </div>
                        </div>
                        <div>
                          <p>{item?.unique_id.toUpperCase()}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4 className="nodata">No Other Associate Agent Data</h4>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
