import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { staticDataAction } from "../Redux/Action/staticAction";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";

const validateForm = (values) => {
  const errors = {};

  if (!values.Bonuses) {
    errors.Bonuses = "required";
  } else if (isNaN(values.Bonuses)) {
    errors.Bonuses = "must be a number";
  }

  if (!values.joined_date) {
    errors.joined_date = "required";
  } else if (isNaN(new Date(values.joined_date))) {
    errors.joined_date = "must be a valid date";
  }

  if (!values.contract_start_date) {
    errors.contract_start_date = "required";
  } else if (isNaN(new Date(values.contract_start_date))) {
    errors.contract_start_date = "must be a valid date";
  }

  if (!values.contract_end_date) {
    errors.contract_end_date = "required";
  } else if (isNaN(new Date(values.contract_end_date))) {
    errors.contract_end_date = "must be a valid date";
  }

  if (!values.new_contract_team_asking_amount) {
    errors.new_contract_team_asking_amount = "required";
  }

  if (!values.release_clouse) {
    errors.release_clouse = "required";
  }

  if (!values.buyout_clouse) {
    errors.buyout_clouse = "required";
  }

  if (!values.new_contract_annual_salary) {
    errors.new_contract_annual_salary = "required";
  }

  if (!values.new_contract_duration) {
    errors.new_contract_duration = "required";
  }

  if (!values.new_contract_team_asking_amount) {
    errors.new_contract_team_asking_amount = "required";
  }

  return errors;
};

export default function Contract() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [inputType2, setInputType2] = useState("text");

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const optionsCurrentSalary = staticListForPlayer?.current_contract?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsClause = staticListForPlayer?.is_current_injuries?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsAskingAmount = staticListForPlayer?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsContractDuration = staticListForPlayer?.duration?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  // const initialValues = {
  //   current_contract:
  //     optionsCurrentSalary?.find(
  //       (elem) => elem.value === playerData?.current_contract
  //     ) || "",
  //   new_contract_annual_salary:
  //     optionsAskingAmount?.find(
  //       (elem) => elem.value === playerData?.new_contract_annual_salary
  //     ) || "",
  //   Bonuses: playerData?.Bonuses || "",
  //   joined_date: playerData?.joined_date
  //     ? playerData?.joined_date.split("T")[0]
  //     : "",
  //   contract_start_date: playerData?.contract_start_date
  //     ? playerData?.contract_start_date.split("T")[0]
  //     : "",
  //   contract_end_date: playerData?.contract_end_date
  //     ? playerData?.contract_end_date.split("T")[0]
  //     : "",
  //   release_clouse:
  //     optionsClause?.find(
  //       (elem) => elem.value === playerData?.release_clouse
  //     ) || "",
  //   buyout_clouse:
  //     optionsClause?.find((elem) => elem.value === playerData?.buyout_clouse) ||
  //     "",

  //   current_contract_other_notes:
  //     playerData?.current_contract_other_notes || "",
  //   new_contract_other_notes: playerData?.new_contract_other_notes || "",
  //   new_contract_team_asking_amount:
  //     optionsAskingAmount?.find(
  //       (elem) => elem.value === playerData?.new_contract_team_asking_amount
  //     ) || "",
  //   new_contract_duration:
  //     optionsContractDuration?.find(
  //       (elem) => elem.value === playerData?.new_contract_duration
  //     ) || "",
  // };

  const initialValues = playerData?.is_profile_completed
    ? {
        current_contract:
          optionsCurrentSalary?.find(
            (elem) => elem.value === playerData?.current_contract
          ) || "",
        new_contract_annual_salary:
          optionsAskingAmount?.find(
            (elem) => elem.value === playerData?.new_contract_annual_salary
          ) || "",
        Bonuses: playerData?.Bonuses || "",
        joined_date: playerData?.joined_date
          ? playerData?.joined_date.split("T")[0]
          : "",
        contract_start_date: playerData?.contract_start_date
          ? playerData?.contract_start_date.split("T")[0]
          : "",
        contract_end_date: playerData?.contract_end_date
          ? playerData?.contract_end_date.split("T")[0]
          : "",
        release_clouse:
          optionsClause?.find(
            (elem) => elem.value === playerData?.release_clouse
          ) || "",
        buyout_clouse:
          optionsClause?.find(
            (elem) => elem.value === playerData?.buyout_clouse
          ) || "",
        current_contract_other_notes:
          playerData?.current_contract_other_notes || "",
        new_contract_other_notes: playerData?.new_contract_other_notes || "",
        new_contract_team_asking_amount:
          optionsAskingAmount?.find(
            (elem) => elem.value === playerData?.new_contract_team_asking_amount
          ) || "",
        new_contract_duration:
          optionsContractDuration?.find(
            (elem) => elem.value === playerData?.new_contract_duration
          ) || "",
      }
    : {
        current_contract: "",
        new_contract_annual_salary: "",
        Bonuses: "",
        joined_date: "",
        contract_start_date: "",
        contract_end_date: "",
        release_clouse: "",
        buyout_clouse: "",
        current_contract_other_notes: "",
        new_contract_other_notes: "",
        new_contract_team_asking_amount: "",
        new_contract_duration: "",
      };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const transformValues = (values) => {
    return {
      release_clouse: values.release_clouse ? values.release_clouse.value : "",
      current_contract: values.current_contract?.value || "",
      buyout_clouse: values.buyout_clouse ? values.buyout_clouse?.value : "",
      new_contract_annual_salary:
        values.new_contract_annual_salary?.value || "",
      new_contract_duration: values.new_contract_duration?.value || "",
      new_contract_team_asking_amount:
        values.new_contract_team_asking_amount?.value || "",
    };
  };

  const handleBack = () => {
    navigate(`/agent-relationship/${Encryptedid(paylerId)}`);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="">
            <ProgressBar currentStep={3} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 3:</span> Contract
              </p>
            </div>
            <div className=" mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={validateForm}
                onSubmit={(values) => {
                  console.log(values);
                  const transformedValues = transformValues(values);
                  let formData = new FormData();

                  for (const key in transformedValues) {
                    if (transformedValues.hasOwnProperty(key)) {
                      formData.append(key, transformedValues[key]);
                    }
                  }

                  console.log(transformedValues, "sdfgdgdfg34534534");

                  Object.entries(values).forEach(([key, value]) => {
                    if (
                      key !== "release_clouse" &&
                      key !== "current_contract" &&
                      key !== "buyout_clouse" &&
                      key !== "new_contract_annual_salary" &&
                      key !== "new_contract_duration" &&
                      key !== "new_contract_team_asking_amount"
                    ) {
                      formData.append(key, value);
                    }
                  });

                  formData.append("playerId", paylerId);
                  formData.append("is_profile_completed", 3);

                  dispatch(editPlayerAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/proposals/${Encryptedid(res?.payload?.data?._id)}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }}
              >
                {({ setFieldValue, values }) => (
                  <FormikForm>
                    {playerDataLoadingState && <Loader />}
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Current Contract Details</h3>
                    </div>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <div className="grid-section">
                          <div className="profile-card mb-3">
                            <Select
                              placeholder="Current Annual Salary"
                              name="current_contract"
                              options={optionsCurrentSalary}
                              onChange={(option) =>
                                setFieldValue("current_contract", option)
                              }
                              value={values.current_contract || ""}
                            />
                            <ErrorMessage
                              name="current_contract"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner ">
                            <Field
                              type="number"
                              name="Bonuses"
                              className="form-control"
                              placeholder="Bonus Amount"
                            />
                            <ErrorMessage
                              name="Bonuses"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner add-new-date-icon">
                            <Field
                              type={inputType}
                              name="joined_date"
                              className="form-control"
                              placeholder="Choose Date of Joining"
                              onFocus={() => setInputType("date")}
                              onBlur={() => setInputType("text")}
                              id="date"
                            />
                            <ErrorMessage
                              name="joined_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner add-new-date-icon">
                            <Field
                              type={inputType1}
                              name="contract_start_date"
                              className="form-control"
                              placeholder="Contract start date"
                              onFocus={() => setInputType1("date")}
                              onBlur={() => setInputType1("text")}
                            />
                            <ErrorMessage
                              name="contract_start_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner add-new-date-icon">
                            <Field
                              name="contract_end_date"
                              type={inputType2}
                              className="form-control"
                              placeholder="Contract end date"
                              onFocus={() => setInputType2("date")}
                              onBlur={() => setInputType2("text")}
                            />
                            <ErrorMessage
                              name="contract_end_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {/* <Select
                              placeholder="Current Annual Salary"
                              name="current_contract"
                              options={optionsCurrentSalary}
                              onChange={(option) =>
                                setFieldValue("current_contract", option)
                              }
                              value={values.current_contract || ""}
                            /> */}
                        <div className="content-second-row">
                          <div className="buyout-ps">
                            <div className="profile-card full-width mb-3">
                              <Select
                                name="release_clouse"
                                options={optionsClause}
                                placeholder="Release Clauses"
                                onChange={(option) =>
                                  setFieldValue("release_clouse", option)
                                }
                                value={values.release_clouse || ""}
                                // value={optionsClause?.find(
                                //   (opt) => opt.value === values.release_clouse
                                // )}
                              />
                              <ErrorMessage
                                name="release_clouse"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="profile-card full-width mb-3">
                              <Select
                                name="buyout_clouse"
                                options={optionsClause}
                                placeholder="Buyout Clause"
                                onChange={(option) =>
                                  setFieldValue("buyout_clouse", option)
                                }
                                value={values.buyout_clouse}
                                // value={options.buyout_clouse.find(
                                //   (opt) => opt.value === values.buyout_clouse
                                // )}
                              />
                              <ErrorMessage
                                name="buyout_clouse"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 form-inner other-notes-box">
                            <Field
                              type="text"
                              name="current_contract_other_notes"
                              className="form-control"
                              placeholder="Other notes write here"
                            />
                            <ErrorMessage
                              name="current_contract_other_notes"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className=" mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">
                          Player Requirement for new contract
                        </h3>
                      </div>
                      <Row>
                        <div className="grid-section-player">
                          <div className="profile-card mb-3">
                            <Select
                              options={optionsAskingAmount}
                              name="new_contract_annual_salary"
                              placeholder="Annual Asking Salary"
                              onChange={(option) =>
                                setFieldValue(
                                  "new_contract_annual_salary",
                                  option
                                )
                              }
                              value={values.new_contract_annual_salary}
                              // value={options.new_contract_annual_salary.find(
                              //   (opt) => opt.value === values.new_contract_annual_salary
                              // )}
                            />
                            <ErrorMessage
                              name="new_contract_annual_salary"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="profile-card mb-3">
                            <Select
                              options={optionsContractDuration}
                              name="new_contract_duration"
                              placeholder="Contract Duration Requirements"
                              onChange={(option) =>
                                setFieldValue("new_contract_duration", option)
                              }
                              value={values.new_contract_duration}
                              // value={options.new_contract_duration.find(
                              //   (opt) => opt.value === values.new_contract_duration
                              // )}
                            />
                            <ErrorMessage
                              name="new_contract_duration"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="profile-card mb-3">
                            {/* <Link to="#">
                            <h3>Team’s Asking Amount</h3>
                            <img
                              src={require("../Assets/images/arrowdown.svg").default}
                              alt="Arrow Down"
                            />
                          </Link>
                        </div> */}
                            <Select
                              placeholder="Team’s Asking Amount"
                              name="new_contract_team_asking_amount"
                              options={optionsAskingAmount}
                              onChange={(option) =>
                                setFieldValue(
                                  "new_contract_team_asking_amount",
                                  option
                                )
                              }
                              value={values.new_contract_team_asking_amount}
                              // value={optionsAskingAmount.find(
                              //   (opt) => opt.value === values.new_contract_team_asking_amount
                              // )}
                            />
                            <ErrorMessage
                              name="new_contract_team_asking_amount"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner last-notes-box">
                            <Field
                              type="text"
                              name="new_contract_other_notes"
                              className="form-control"
                              placeholder="Other notes write here"
                            />
                            <ErrorMessage
                              name="new_contract_other_notes"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </Row>
                    </div>

                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button
                          className="back-btn-ps"
                          type="button"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        <button type="submit" className="next-btn-ps">
                          Next
                        </button>
                      </div>
                    </Col>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
