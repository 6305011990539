import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getExternalPlayers,
  getGlobalCompareDataAction,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import {
  AvailabilityStatus,
  Children,
  educationLevel,
  filterKeyAchievement,
  filterKeyAppearance,
  filterKeyPlayers,
  martialStatus,
  playerFoot,
  playerNationality,
  playerTrophy,
  positions,
  releaseClouse,
  teamLeague,
} from "../utils/rawjson";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import {
  setGlobalPlayer1Image,
  setGlobalPlayer2Image,
} from "../Redux/Reducers/playerSlice";

export default function GlobalPlayers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [file, setFile] = useState();
  // const [fileTwo, setFileTwo] = useState(null);
  // const [player1Id, setPlayer1Id] = useState(null);
  // const [player2Id, setPlayer2Id] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [activePlayer, setActivePlayer] = useState(null);
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);

  const [filters, setFilters] = useState({});
  const location = useLocation();

  const globalPlayer1Image = useSelector(
    (state) => state.playerAuthData.globalPlayer1Image
  );
  const globalPlayer2Image = useSelector(
    (state) => state.playerAuthData.globalPlayer2Image
  );

  const player1Id = useSelector(
    (state) => state.playerAuthData.globalPlayer1Id
  );
  const player2Id = useSelector(
    (state) => state.playerAuthData.globalPlayer2Id
  );

  const player2Gender = useSelector(
    (state) => state.playerAuthData.genderGlobal
  );

  const player1Gender = useSelector(
    (state) => state.playerAuthData.genderGlobal1
  );

  const externalplayerList = useSelector(
    (e) => e.playerAuthData.externalPlayerData
  );
  const playerCompareDetail = useSelector(
    (e) => e.playerAuthData.globalPlayerCompareeData
  );

  useEffect(() => {
    dispatch(getExternalPlayers());
  }, [dispatch]);

  const handleFile = (playerNumber) => {
    if (playerNumber === 1) {
      // setFile(null);
      // setPlayer1Id(null);
      dispatch(setGlobalPlayer1Image({ image: null, id: null }));
      setSelectedPlayerIds((prev) => prev.filter((id) => id !== player1Id));
    } else if (playerNumber === 2) {
      // setFileTwo(null);
      // setPlayer2Id(null);
      dispatch(setGlobalPlayer2Image({ image: null, id: null }));
      setSelectedPlayerIds((prev) => prev.filter((id) => id !== player2Id));
    }
  };

  const handleChoosePlayer = (playerNumber) => {
    setActivePlayer(playerNumber);
    setShowModal(true);
  };

  const handleComparison = () => {
    if (player1Id && player2Id) {
      dispatch(getGlobalCompareDataAction({ id1: player1Id, id2: player2Id }));
    } else {
      toast.error("Please Select Player for Comparison");
    }
  };

  const handlePlayerSelect = (player) => {
    const imagePath = `${process.env.REACT_APP_IMAGE_PATH}${player.profile_image}`;

    if (selectedPlayerIds.includes(player._id)) {
      toast.error("This player is already selected.");
      return;
    }

    // if (activePlayer === 1) {
    //   setFile(imagePath);
    //   setPlayer1Id(player._id);
    // } else if (activePlayer === 2) {
    //   setFileTwo(imagePath);
    //   setPlayer2Id(player._id);
    // }

    if (activePlayer === 1) {
      dispatch(
        setGlobalPlayer1Image({
          image: imagePath,
          id: player._id,
          gender: player?.gender,
        })
      );
    } else if (activePlayer === 2) {
      dispatch(
        setGlobalPlayer2Image({
          image: imagePath,
          id: player._id,
          gender: player?.gender,
        })
      );
    }

    setSelectedPlayerIds((prev) => [...prev, player._id]);
    setShowModal(false);
  };

  const renderComparisonSection = (
    value,
    key,
    label,
    value1,
    value2,
    mapping
  ) => {
    let displayValue1, displayValue2;

    if (value === "dob") {
      displayValue1 = dateOfBirth(value1);
      displayValue2 = dateOfBirth(value2);
    } else if (value === "contract_end_date") {
      displayValue1 = dateOfBirth(value1);
      displayValue2 = dateOfBirth(value2);
    } else if (value === "contract_start_date") {
      displayValue1 = dateOfBirth(value1);
      displayValue2 = dateOfBirth(value2);
    } else if (value === "age") {
      displayValue1 = value1 ? calculateAge(value1) : "N/A";
      displayValue2 = value2 ? calculateAge(value2) : "N/A";
    } else if (mapping) {
      displayValue1 = mapping[value1] || "N/A";
      displayValue2 = mapping[value2] || "N/A";
    } else {
      displayValue1 = value1 || "N/A";
      displayValue2 = value2 || "N/A";
    }

    return (
      <div className="compare-players-bottom mb-3" key={key}>
        <div className="compare-box d-flex justify-content-between">
          <table>
            <tr>
              <td>
                <h4>{label}</h4>
              </td>
              <td>
                <p>
                  {Array.isArray(displayValue1)
                    ? displayValue1.map((value, index) => (
                        <React.Fragment key={index}>
                          {value}
                          <br />
                        </React.Fragment>
                      ))
                    : displayValue1 || "N/A"}
                </p>
              </td>
              <td>
                <p>
                  {Array.isArray(displayValue2)
                    ? displayValue2.map((value, index) => (
                        <React.Fragment key={index}>
                          {value}
                          <br />
                        </React.Fragment>
                      ))
                    : displayValue2 || "N/A"}
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  };

  const renderComparisonSection2 = (
    value,
    key,
    label,
    value1,
    value2,
    mapping
  ) => {
    let displayValue1, displayValue2;

    if (mapping) {
      displayValue1 = mapping[value1] || "N/A";
      displayValue2 = mapping[value2] || "N/A";
    } else {
      displayValue1 = value1 || "N/A";
      displayValue2 = value2 || "N/A";
    }

    return (
      <div className="compare-players-bottom mb-3" key={key}>
        <div className="compare-box d-flex justify-content-between">
          <h4>{label}</h4>
          <p>{displayValue1}</p>
          <p>{displayValue2}</p>
        </div>
      </div>
    );
  };

  const renderComparisonSection3 = (
    value,
    key,
    label,
    value1,
    value2,
    mapping
  ) => {
    let displayValue1, displayValue2;

    if (mapping) {
      displayValue1 = mapping[value1] || "N/A";
      displayValue2 = mapping[value2] || "N/A";
    } else {
      displayValue1 = value1 || "N/A";
      displayValue2 = value2 || "N/A";
    }

    return (
      <div className="compare-players-bottom mb-3" key={key}>
        <div className="compare-box d-flex justify-content-between">
          <h4>{label}</h4>
          <p>{displayValue1}</p>
          <p>{displayValue2}</p>
        </div>
      </div>
    );
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center compare-team">
              <h2>Compare the best</h2>
              {/* <Link to="/player-filter" className="filter">
                <img src={require("../Assets/images/filt.svg").default} />
              </Link> */}
            </div>
            <div className="compare">
              <Link to="/compare-players">Internal Comparison</Link>
              <Link to="/global-players" className="active-compare">
                Global Comparison
              </Link>
            </div>

            <div className="compare-bottom">
              <Row className="mt-4">
                <Col
                  lg={5}
                  className="d-flex justify-content-start flex-column align-items-end"
                >
                  <div>
                    <div className="player-upload-div">
                      {globalPlayer1Image && (
                        <button
                          type="button"
                          className="delete-button"
                          onClick={() => handleFile(1)}
                        >
                          <img
                            src={require("../Assets/images/delete.svg").default}
                            alt="Delete"
                          />
                        </button>
                      )}
                      <label
                        htmlFor="choosePlayer1"
                        style={{ cursor: "pointer", display: "inline-block" }}
                      >
                        <img
                          src={
                            require("../Assets/images/new-user-big.svg").default
                          }
                          alt="default"
                        />
                        <p>Choose Player 1</p>
                      </label>
                      <button
                        id="choosePlayer1"
                        style={{ display: "none" }}
                        onClick={() => handleChoosePlayer(1)}
                      ></button>
                      {/* {globalPlayer1Image && (
                      // <img
                      //   className="prev-img"
                      //   src={globalPlayer1Image}
                      //   alt="Player 1"
                      // />
                      <img
                        className="prev-img"
                        src={require("../Assets/images/compare-male.png")}
                      />
                    )} */}

                      {globalPlayer1Image && player1Gender === 0 && (
                        // <img
                        //   className="prev-img"
                        //   src={globalPlayer2Image}
                        //   alt="Player 2"
                        // />
                        <img
                          className="prev-img"
                          src={require("../Assets/images/compare-male.png")}
                        />
                        // <img  className="prev-img" src={require("../Assets/images/compare-female.png")} />
                      )}
                      {globalPlayer1Image && player1Gender === 1 && (
                        // <img
                        //   className="prev-img"
                        //   src={globalPlayer2Image}
                        //   alt="Player 2"
                        // />

                        <img
                          className="prev-img"
                          src={require("../Assets/images/compare-female.png")}
                        />
                      )}
                    </div>
                    <h4 className="name-fix">
                      {capitalize(playerCompareDetail?.playerData1?.full_name)}
                    </h4>
                  </div>
                </Col>
                <Col
                  lg={2}
                  className="d-flex align-items-center justify-content-center comp-vs"
                >
                  <p>VS</p>
                </Col>

                <Col
                  lg={5}
                  className="d-flex justify-content-end flex-column align-items-start"
                >
                  <div>
                    <div className="player-upload-div">
                      {globalPlayer2Image && (
                        <button
                          type="button"
                          className="delete-button"
                          onClick={() => handleFile(2)}
                        >
                          <img
                            src={require("../Assets/images/delete.svg").default}
                            alt="Delete"
                          />
                        </button>
                      )}
                      <label
                        htmlFor="choosePlayer2"
                        style={{ cursor: "pointer", display: "inline-block" }}
                      >
                        <img
                          src={
                            require("../Assets/images/new-user-big.svg").default
                          }
                          alt="default"
                        />
                        <p>Choose Player 2</p>
                      </label>
                      <button
                        id="choosePlayer2"
                        style={{ display: "none" }}
                        onClick={() => handleChoosePlayer(2)}
                      ></button>
                      {globalPlayer2Image && player2Gender === 0 && (
                        // <img
                        //   className="prev-img"
                        //   src={globalPlayer2Image}
                        //   alt="Player 2"
                        // />
                        <img
                          className="prev-img"
                          src={require("../Assets/images/compare-male.png")}
                        />
                        // <img  className="prev-img" src={require("../Assets/images/compare-female.png")} />
                      )}

                      {globalPlayer2Image && player2Gender === 1 && (
                        // <img
                        //   className="prev-img"
                        //   src={globalPlayer2Image}
                        //   alt="Player 2"
                        // />

                        <img
                          className="prev-img"
                          src={require("../Assets/images/compare-female.png")}
                        />
                      )}
                      {/* {player2Gender == 0 ? (
                      <img
                        className="prev-img"
                        src={require("../Assets/images/compare-male.png")}
                      />
                    ) : (
                      <img
                        className="prev-img"
                        src={require("../Assets/images/compare-female.png")}
                      />
                    )} */}
                    </div>
                    <h4 className="name-fix">
                      {capitalize(playerCompareDetail?.playerData2?.full_name)}
                    </h4>
                  </div>
                </Col>

                <Col lg={12} className="mt-5 d-flex justify-content-center ">
                  <div className="comp-btn cmn-light-btn">
                    <button onClick={handleComparison}>See Comparison</button>
                  </div>
                </Col>
              </Row>

              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Select Player</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="ad-pop-height-player">
                    {externalplayerList?.map((player) => (
                      <div
                        style={{ cursor: "pointer" }}
                        className="play-inner-box"
                        key={player.id}
                        onClick={() => handlePlayerSelect(player)}
                      >
                        <div className="d-flex">
                          {player?.gender == 0 ? (
                            <img
                              src={require("../Assets/images/user.png")}
                              alt="User"
                            />
                          ) : (
                            <img
                              src={require("../Assets/images/player-female.png")}
                              alt="Player Female"
                            />
                          )}

                          {/* <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${player?.profile_image}`}
                        /> */}
                          <div className="ms-3">
                            <h4>{capitalize(player?.full_name)}</h4>
                            <p>
                              Player ID:{" "}
                              <span>{player?.unique_id.toUpperCase()}</span>
                            </p>
                          </div>
                        </div>

                        <div className="badge-img">
                          <img src={require("../Assets/images/tiger.png")} />
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
              </Modal>

              {Object.keys(playerCompareDetail)?.length > 0 && (
                <Tabs
                  defaultActiveKey="Professional"
                  id="uncontrolled-tab-example"
                  className="mb-3 mt-4"
                >
                  <Tab eventKey="Professional" title="Professional Details">
                    {Object.entries(filterKeyPlayers)?.map(
                      ([key, { label, value }]) => {
                        const filterApplied = filters[value];
                        let value1, value2;

                        if (value === "age") {
                          const dob1 = playerCompareDetail?.playerData1?.dob;
                          const dob2 = playerCompareDetail?.playerData2?.dob;
                          value1 = dob1;
                          value2 = dob2;
                        } else {
                          value1 = playerCompareDetail?.playerData1?.[value];
                          value2 = playerCompareDetail?.playerData2?.[value];
                        }

                        let mapping;
                        if (value === "team_league") {
                          mapping = teamLeague;
                        } else if (value === "foot") {
                          mapping = playerFoot;
                        } else if (value === "nationality_id") {
                          mapping = playerNationality;
                        } else if (value === "education_level") {
                          mapping = educationLevel;
                        } else if (value === "release_clouse") {
                          mapping = releaseClouse;
                        } else if (value === "martial_status") {
                          mapping = martialStatus;
                        } else if (value === "children") {
                          mapping = Children;
                        } else if (value === "buyout_clouse") {
                          mapping = releaseClouse;
                        } else if (value === "availability_status") {
                          mapping = AvailabilityStatus;
                        } else if (value === "positions") {
                          mapping = positions;
                        }

                        return (
                          (!Object.keys(filters).length || filterApplied) &&
                          renderComparisonSection(
                            value,
                            key,
                            label,
                            value1,
                            value2,
                            mapping
                          )
                        );
                      }
                    )}
                  </Tab>

                  <Tab eventKey="Appearances" title="Appearances">
                    {Object.entries(filterKeyAppearance)?.map(
                      ([key, { label, value }]) => {
                        let value1 = playerCompareDetail?.playerData1?.[value];
                        let value2 = playerCompareDetail?.playerData2?.[value];

                        let mapping;

                        return (
                          (value1 || value2) &&
                          renderComparisonSection2(
                            value,
                            key,
                            label,
                            value1,
                            value2,
                            mapping
                          )
                        );
                      }
                    )}
                  </Tab>

                  <Tab eventKey="Achievements" title="Achievements">
                    {Object.entries(filterKeyAchievement).map(
                      ([key, { label, value }]) => {
                        const trophies1 =
                          playerCompareDetail?.playerData1?.[value];
                        const trophies2 =
                          playerCompareDetail?.playerData2?.[value];

                        if (trophies1?.length || trophies2?.length) {
                          return trophies1.map((trophy1, index) => {
                            const trophy2 = trophies2 ? trophies2[index] : null;
                            const date1 = playerTrophy[trophy1?.trophy];
                            const date2 = playerTrophy[trophy2?.trophy];

                            return renderComparisonSection3(
                              value,
                              `${key}-${index}`,
                              `${label} ${index + 1}`,
                              date1,
                              date2,
                              null
                            );
                          });
                        }
                        return null;
                      }
                    )}
                  </Tab>
                </Tabs>
              )}
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
