import React from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Encryptedid } from "../utils/Becrypt";

const SearchedComponent = () => {
  const navigate = useNavigate();
  const { query } = useParams();
  const internalSearchNameAndIdData = useSelector(
    (e) => e.internalSeachAuthData.internalSearchlist
  );

  const routePath = [
    { label: "player", path: "player-profile" }, 
    { label: "director", path: "my-directors" },
    { label: "coach", path: "my-coach" },
    { label: "team", path: "my-team" },
    { label: "agent", path: "associate-agent" },
    { label: "otherAgent", path: "other-associate-agent" },
  ];

  const getRoutePath = (type) => {
    const typeLetter = type?.[0]?.toLowerCase();
    const found = routePath.find((route) =>
      route.label[0].toLowerCase() === typeLetter
    );
    return found ? found.path : ''; 
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="card-heading mb-2">
            <div className="play-card-sec mt-3">
              <h2>Search Result</h2>

              {internalSearchNameAndIdData?.length > 0 ? (
                <div className="play-scroll-box mt-3">
                  <Row>
                    {internalSearchNameAndIdData?.map((elem, i) => (
                      <Col lg={4} key={i}>
                        <div
                          className="play-inner-box"
                          // onClick={() =>
                          //   navigate(
                          //     `/internalplayer-search-result/${Encryptedid(
                          //       elem?._id
                          //     )}`
                          //   )
                          // }
                          onClick={() => {
                            const route = getRoutePath(elem.type);
                            if (route) {
                              navigate(`/${route}/${Encryptedid(elem?._id)}`);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                              alt={`${elem?.full_name} profile`}
                            />
                            <div className="ms-3">
                              <h4>{elem?.full_name}</h4>
                              <p>
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          </div>

                          <div className="badge-img">
                            <img
                              src={require("../Assets/images/tiger.png")}
                              alt="badge"
                            />
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div className="no-data-found">No Data</div>
              )}
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default SearchedComponent;
