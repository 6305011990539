import React from "react";

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progressPercentage}%` }}>
        {/* Step {currentStep} of {totalSteps} */}
      </div>
    </div>
  );
};

export default ProgressBar;
