import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import Select from "react-select";
import { getAgentListAction } from "../Redux/Action/agentAction";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";

export default function AgentRelationship() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  // const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);
  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));

  const [playerData, setPlayerData] = useState({
    professional_relation1: "",
    who_brought_player1: "",
    associate_agent_id: "",
    agent_other_notes: "",
  });

  const initialValues = {
    professional_relation1: "",
    who_brought_player1: "",
    associate_agent_id: "",
    agent_other_notes: "",
  };

  // const validationSchema = Yup.object({
  //   professional_relation1: Yup.string().required("Required"),
  //   who_brought_player1: Yup.string().required("Required"),
  //   // associate_agent_id: Yup.string()
  //   //   .test("required-if-who_brought_player1", "Required", function (value) {
  //   //     const { who_brought_player1 } = this.parent;
  //   //     console.log(value, "Fdghfdgdfgdfg", who_brought_player1);
  //   //     return who_brought_player1 == 1 ? !!value : true;
  //   //   })
  //   //   .notRequired(),
  // });

  const validate = (values) => {
    const errors = {};

    // if (!values.professional_relation1) {
    //   errors.professional_relation1 = "required";
    // }

    if (values.who_brought_player1 == "1" && !values.associate_agent_id) {
      errors.associate_agent_id = "required";
    }

    return errors;
  };

  const handleSubmit = (values) => {
    console.log("valuea", values);

    let formData = new FormData();

    formData.append("professional_relation", values.professional_relation1);
    formData.append("who_brought_player", values.who_brought_player1);

    if (values.who_brought_player1 == 1) {
      formData.append(
        "associate_agent_id",
        values.associate_agent_id.value || values.associate_agent_id
      );
    }
    formData.append("agent_other_notes", values.agent_other_notes);

    formData.append("playerId", paylerId);
    formData.append("is_profile_completed", 2);

    dispatch(editPlayerAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate(`/contract/${Encryptedid(res?.payload?.data?._id)}`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {

    if (paylerId) {
    CallApi();
    dispatch(getAgentListAction());

    // Prevent back navigation
    // const handlePopState = (event) => {
    //   event.preventDefault();
    //   navigate(`/profile-info?playerId=${Encryptedid(paylerId)}`);
    // };

    // window.addEventListener("popstate", handlePopState);

    // return () => {
    //   window.removeEventListener("popstate", handlePopState);
    // };
    const handlePopState = () => {
      navigate(`/profile-info?playerId=${Encryptedid(paylerId)}`, { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }
  }, [dispatch, paylerId, navigate]);

  const CallApi = async () => {
    const data = await dispatch(getPlayerDataById({ id: paylerId }));

    if (data?.payload.status === 200) {
      const res = data?.payload?.data;

      setPlayerData({
        professional_relation1: res.professional_relation,
        who_brought_player1: res.who_brought_player,
        associate_agent_id: res.associate_agent_id,
        agent_other_notes: res.agent_other_notes,
      });
    }
  };

  const handleBack = () => {
    navigate(`/profile-info?playerId=${Encryptedid(paylerId)}`);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={2} totalSteps={8} />
          <div className="steps-heading">
            <p>
              <span>Step 2:</span> Agent Relationship
            </p>
          </div>
          <div className="">
            <div className=" mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={playerData || initialValues}
                validate={validate}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors }) => (
                  <FormikForm>
                    {playerDataLoadingState && <Loader />}
                    <div className="pro-relation">
                      <div className="cmn-form-fields mt-4 brought-current ">
                        <h3 className="h-20">Professional Relation</h3>
                        <div className="fist-left-flex new-radio-btn">
                          <div className="licensed-sec player-field">
                            <h3>My Player</h3>
                            <div className="form-check ">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="professional_relation1"
                                value="0"
                                as="input"
                                checked={values.professional_relation1 == "0"}
                              />
                            </div>
                          </div>
                          <div className="licensed-sec player-field">
                            <h3>Associate agent’s Player</h3>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="professional_relation1"
                                value={"1"}
                                as="input"
                                checked={values.professional_relation1 == "1"}
                              />
                            </div>
                          </div>
                        </div>

                        <ErrorMessage
                          name="professional_relation1"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="brought-current mt-4 cur-team-sec new-radio-btn">
                        <div className="">
                          <h3 className="h-20">
                            Who Brought Player to Current Team
                          </h3>
                        </div>

                        <div className="cmn-form-fields mt-2 agents-player">
                          <div className="licensed-sec player-field">
                            <h3>Me</h3>
                            <div className="form-check ">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="who_brought_player1"
                                value={"0"}
                                checked={values.who_brought_player1 == "0"}
                                onChange={(e) => {
                                  setFieldValue(
                                    "who_brought_player1",
                                    e.target.value
                                  );
                                  if (e.target.value == "0") {
                                    setFieldValue("associate_agent_name", "");
                                  }
                                }}
                                as="input"
                              />
                            </div>
                          </div>

                          <div className="licensed-sec player-field">
                            <h3>The Associate Agent</h3>
                            <div className="form-check ">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="who_brought_player1"
                                value={"1"}
                                as="input"
                                checked={values.who_brought_player1 == "1"}
                              />
                            </div>
                          </div>

                          <ErrorMessage
                            name="who_brought_player1"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="agent-name-box">
                      <div className=" player-field mt-4">
                        <div className="d-flex justify-content-between">
                          <h3 className="h-20">Name of Associate Agent</h3>
                        </div>
                        <div className="cmn-form-fields mt-2">
                          <Form.Group
                            className="mb-3"
                            controlId="associate_agent_name"
                          >
                            {/* <Field
                                as={Form.Control}
                                type="text"
                                disabled={values.who_brought_player1 != 1}
                                name="associate_agent_name"
                                placeholder="Search Agent Name"
                              /> */}

                            <Select
                              name="associate_agent_id"
                              placeholder="Search Agent Name"
                              options={associateAgentOptions}
                              isDisabled={values.who_brought_player1 != 1}
                              onChange={(option) =>
                                setFieldValue("associate_agent_id", option)
                              }
                              value={associateAgentOptions?.find(
                                (elem) =>
                                  elem.value == values.associate_agent_id
                              )}
                            />

                            {values.who_brought_player1 == 1 && (
                              <ErrorMessage
                                name="associate_agent_id"
                                component="div"
                                className="text-danger"
                              />
                            )}
                          </Form.Group>
                        </div>
                      </div>

                      <div className=" player-field mt-4">
                        <div className="d-flex justify-content-between">
                          <h3 className="h-20">Other Notes</h3>
                        </div>
                        <div className="cmn-form-fields">
                          <Row>
                            <Col lg={12} className="mt-2">
                              <Form.Group className="mb-3 form-inner">
                                <Field
                                  as="textarea"
                                  name="agent_other_notes"
                                  rows={1}
                                  className="form-control"
                                  placeholder="Write notes here"
                                />

                                {/* <ErrorMessage
                                name="agent_other_notes"
                                component="div"
                                className="text-danger"
                              /> */}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>

                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button
                          className="back-btn-ps"
                          type="button"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        <button className="next-btn-ps" type="submit">
                          Next
                        </button>
                      </div>
                    </Col>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
