import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { staticDataAction } from "../Redux/Action/staticAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  createPlayerAction,
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import { getYesterday } from "../utils/helper";
import { getTeamListAction } from "../Redux/Action/teamAction";
import { deletePictureAction } from "../Redux/Action/directorAction";
import PreviewModal from "../CommonModals/PreviewModal";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import { FaFileAlt } from "react-icons/fa";
import { clearPlayerDetail } from "../Redux/Reducers/playerSlice";

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();
  const [playerId, setPlayerId] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get("playerId");
    const id = ids && Decryptedid(atob(ids));

    if (id) {
      setPlayerId(id);
      dispatch(getPlayerDataById({ id }));
    }
  }, [dispatch, location.search]);

  const [age, setAge] = useState("");
  const [isFree, setIsFree] = useState(false);

  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [images1, setImages] = useState([]);

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);

  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  const teamOptions = teanListingData?.map((elem) => ({
    label: elem?.team_name,
    value: elem?._id,
  }));

  const optionsGender = staticListForPlayer?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlaceOfBirth = staticListForPlayer?.place_of_birth?.map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const optionsNationaity = staticListForPlayer?.nationality?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsNationaityId = staticListForPlayer?.nationality_id?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsLanguage = staticListForPlayer?.languages?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsHeight = staticListForPlayer?.hight?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const optionsFoot = staticListForPlayer?.foot?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPosition = staticListForPlayer?.positions?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsTeamLeague = staticListForPlayer?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsNationalTeam = staticListForPlayer?.national_team_player?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsTrophy = staticListForPlayer?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlayerStatus = staticListForPlayer?.availability_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const validateFile = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 2 * 1024 * 1024; // 2 MB for profile image
    const bannerMaxSize = 5 * 1024 * 1024; // 5 MB for banner image

    if (!file) return "required";

    if (file.size > (file ? bannerMaxSize : maxSize)) {
      return "File Size is too large";
    }
  };

  const validateForm = (values) => {
    const errors = {};

    const profileImageError = validateFile(values.profile_image);
    if (profileImageError) {
      errors.profile_image = profileImageError;
    }

    const bannerImageError = validateFile(values.banner_image);
    if (bannerImageError) {
      errors.banner_image = bannerImageError;
    }

    if (!values.gender || values.gender.length === 0) {
      errors.gender = "required";
    }

    if (!values.full_name || values.full_name.length === 0) {
      errors.full_name = "required";
    }

    if (!values.dob || values.dob.length === 0) {
      errors.dob = "required";
    }

    if (!values.place_of_birth || values.place_of_birth.length === 0) {
      errors.place_of_birth = "required";
    }

    if (!values.nationality || values.nationality.length === 0) {
      errors.nationality = "required";
    }

    if (!values.nationality_id || values.nationality_id.length === 0) {
      errors.nationality_id = "required";
    }

    if (!values.height || values.height.length === 0) {
      errors.height = "required";
    }

    if (!values.positions || values.positions.length === 0) {
      errors.positions = "required";
    }

    if (!values.foot || values.foot.length === 0) {
      errors.foot = "required";
    }

    if (!values.languages || values.languages.length === 0) {
      errors.languages = "required";
    }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    if (values.carrier_trophies && values.carrier_trophies.length > 0) {
      values.carrier_trophies.forEach((item, index) => {
        if (!item.trophy) {
          if (!errors.carrier_trophies) errors.carrier_trophies = [];
          errors.carrier_trophies[index] = { trophy: "required" };
        }
        if (!item.date_of_winning) {
          if (!errors.carrier_trophies) errors.carrier_trophies = [];
          errors.carrier_trophies[index] = {
            ...errors.carrier_trophies[index],
            date_of_winning: "required",
          };
        }
      });
    }
    return errors;
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDateObj = new Date(dob);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    setAge(age);
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (file && allowedTypes.includes(file.type)) {
      setFieldValue("player_cv", file);
    } else {
      toast.error("Please select a PDF, DOC, or DOCX file.");
    }
  };

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  useEffect(() => {

    dispatch(clearPlayerDetail());
    
    if (playerId) {
      dispatch(getPlayerDataById({ id: playerId }));
    }

    // to get team listing
    dispatch(getTeamListAction());
  }, [dispatch, playerId]);

  const initialValues = {
    gender:
      optionsGender?.find((elem) => elem.value === playerData?.gender) || [],
    full_name: playerData?.full_name || "",
    dob: playerData?.dob?.split("T")[0] || "",
    // team_name: playerData?.team_name || "",
    profile_other_notes: playerData?.profile_other_notes || "",
    place_of_birth:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === playerData?.place_of_birth
      ) || [],

    nationality:
      playerData?.nationality?.map((item) => ({
        label: item,
        value: item,
      })) || [],

    nationality_id:
      optionsNationaityId?.find(
        (elem) => elem.value === playerData?.nationality_id
      ) || [],

    languages:
      playerData?.languages?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    height:
      optionsHeight?.find((elem) => elem.value === playerData?.height) || "",

    foot:
      playerData?.foot
        ?.map((footValue) => {
          const matchedOption = optionsFoot?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    positions:
      playerData?.positions
        ?.map((footValue) => {
          const matchedOption = optionsPosition?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    is_player_free: playerData?.is_player_free || 0,

    team_id:
      teamOptions?.find((elem) => elem.value === playerData?.team_id) || "",

    team_country:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === playerData?.team_country
      ) || "",
    team_league:
      optionsTeamLeague?.find(
        (elem) => elem.value === playerData?.team_league
      ) || "",

    national_team_player:
      optionsNationalTeam?.find(
        (elem) => elem.value === playerData?.national_team_player
      ) || "",
    national_team:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === playerData?.national_team
      ) || "",

    availability_status:
      playerData?.availability_status
        ?.map((footValue) => {
          const matchedOption = optionsPlayerStatus?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    carrier_trophies:
      playerData?.carrier_trophies?.length > 0
        ? playerData?.carrier_trophies?.map((elem) => ({
            trophy: optionsTrophy?.find(
              (option) => option.value === elem.trophy
            ),
            date_of_winning: elem?.date_of_winning?.split("T")[0],
          }))
        : [{ trophy: null, date_of_winning: "" }],

    player_cv: playerData?.player_cv || null,
    // step2
    profile_image: playerData?.profile_image || "",
    banner_image: playerData?.banner_image || "",
    photos:
      playerData?.photos && playerData.photos.length > 0
        ? [
            ...playerData.photos,
            ...Array(5 - playerData.photos.length).fill(null),
          ]
        : Array(5).fill(null),
    video_link: playerData?.video_link || "",
  };

  // const handleRemoveImage = (index, values, setFieldValue) => {
  //   const updatedPhotos = [...values.photos];
  //   updatedPhotos[index] = null;
  //   setFieldValue("photos", updatedPhotos);
  // };

  const handleRemoveImage = (index, values, setFieldValue) => {
    const updatedPhotos = Array.isArray(values.photos) ? [...values.photos] : Array(5).fill(null);
  
    updatedPhotos[index] = null;
  
    const cleanedPhotos = updatedPhotos.filter(photo => photo !== null);
  
    const finalPhotos = [
      ...cleanedPhotos,
      ...Array(5 - cleanedPhotos.length).fill(null)
    ].slice(0, 5);
  
    setFieldValue("photos", finalPhotos);
  };
  

  const handleRemoveImage1 = (index, values, setFieldValue) => {
    const updatedFile = values.photos[index];
    dispatch(
      deletePictureAction({
        type: 1,
        pictureURL: updatedFile,
        userId: playerId,
      })
    ).then((res) => {
      if (res?.payload?.success) {
        const updatedPhotos = [...values.photos];
        updatedPhotos[index] = null;
        setFieldValue("photos", updatedPhotos);

        dispatch(getPlayerDataById({ id: playerId }));
      }
    });
  };

  const handleImageUpload = (e, setFieldValue, values) => {
    const files = Array.from(e.target.files);

    const newImages = files.map((file) => ({
      file,
      dataURL: URL.createObjectURL(file),
    }));

    let currentPhotos = values.photos || [];

    let updatedPhotos = currentPhotos
      .map((photo) => (photo === null ? newImages.shift() : photo))
      .filter(Boolean);

    updatedPhotos = [...updatedPhotos, ...newImages].slice(0, 5);

    setImages(updatedPhotos);

    setFieldValue(
      "photos",
      updatedPhotos.map((img) => (img && img.file) || img)
    );
  };

  const handleRemoveImage3 = (index, setFieldValue) => {
    const updatedImages = images1.filter((_, i) => i !== index);
    setImages(updatedImages);
    setFieldValue(
      "photos",
      updatedImages.map((img) => img.file)
    );
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const [dragging, setDragging] = useState(false);

  // const handleDrop = (e, setFieldValue, type, values) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  
  //   const files = Array.from(e.dataTransfer.files);
  
  //   if (type === "photos") {

  //     const existingPhotos = Array.isArray(values.photos) ? values.photos : Array(5).fill(null);

  //     const spaceLeft = 5 - existingPhotos.filter(photo => photo !== null).length;
  //     const newPhotos = files.slice(0, spaceLeft); 

  //     const updatedPhotos = [
  //       ...existingPhotos.filter(photo => photo !== null),
  //       ...newPhotos
  //     ].slice(0, 5); 
  
  //     setFieldValue("photos", updatedPhotos);
  //   } else if (type === "player_cv") {
  //     if (files.length > 0) {
  //       setFieldValue("player_cv", files[0]);
  //     }
  //   } else if (type === "profile_image") {
  //     if (files.length > 0) {
  //       setFieldValue("profile_image", files[0]);
  //     }
  //   } else if (type === "banner_image") {
  //     if (files.length > 0) {
  //       setFieldValue("banner_image", files[0]);
  //     }
  //   }
  
  //   setDragging(false);
  // };
  
  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();
  
    const files = Array.from(e.dataTransfer.files);
  
    if (type === "photos") {
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));
  
      if (imageFiles.length === 0) {
        return;
      }
  
      const existingPhotos = Array.isArray(values.photos) ? values.photos : Array(5).fill(null);
      const spaceLeft = 5 - existingPhotos.filter((photo) => photo !== null).length;
      const newPhotos = imageFiles.slice(0, spaceLeft); 
  
      const updatedPhotos = [
        ...existingPhotos.filter((photo) => photo !== null),
        ...newPhotos,
      ].slice(0, 5); 
  
      setFieldValue("photos", updatedPhotos);
    } else if (type === "player_cv") {
      const allowedFiles = files.filter((file) => 
        file.type === "application/pdf" ||
        file.type === "application/msword" || 
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
  
      if (allowedFiles.length === 0) {
        console.error("Only .pdf, .doc, or .docx files are allowed!");
        return;
      }
  
      if (allowedFiles.length > 0) {
        setFieldValue("player_cv", allowedFiles[0]);
      }
    } else if (type === "profile_image") {
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));
      if (imageFiles.length > 0) {
        setFieldValue("profile_image", imageFiles[0]);
      }
    } else if (type === "banner_image") {
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));
      if (imageFiles.length > 0) {
        setFieldValue("banner_image", imageFiles[0]);
      }
    }
  
    setDragging(false);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={1} totalSteps={8} />
          <div className="steps-heading">
            <p>
              <span>Step 1:</span> Profile Main Information
            </p>
          </div>
          <div className="over-ride-cmn">
            <Formik
              initialValues={initialValues}
              validate={validateForm}
              enableReinitialize={true}
              onSubmit={(values) => {
                if (playerId) {
                  const transformedData = [];

                  // Transform carrier_trophies
                  values.carrier_trophies.forEach((item) => {
                    const trophies = Array.isArray(item.trophy)
                      ? item.trophy
                      : [item.trophy];

                    trophies.forEach((trophy) => {
                      transformedData.push({
                        trophy: trophy.value,
                        date_of_winning: item.date_of_winning,
                      });
                    });
                  });

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (key === "team_id" && values.is_player_free === 1) {
                      return;
                    }

                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "carrier_trophies" && key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(`${key}[${index}]`, value.value);
                          } else {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      // Handle single fields like height
                      if (key === "height") {
                        formData.append(key, values[key].value);
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key].value !== undefined
                      ) {
                        formData.append(key, values[key].value);
                      } else {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  // Append carrier trophies as JSON string
                  formData.append(
                    "carrier_trophies",
                    JSON.stringify(transformedData)
                  );

                  // Handle photos - only append new File objects
                  if (Array.isArray(values.photos)) {
                    values.photos.forEach((file, index) => {
                      if (file instanceof File) {
                        formData.append(`photos`, file);
                      } else {
                        console.error(
                          `Photo at index ${index} is not a file: `,
                          file
                        );
                      }
                    });
                  }

                  formData.append("playerId", playerId);
                  formData.append("is_profile_completed", 1);

                  dispatch(editPlayerAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                } else {
                  const transformedData = [];

                  values.carrier_trophies.forEach((item) => {
                    const trophies = Array.isArray(item.trophy)
                      ? item.trophy
                      : [item.trophy];

                    trophies.forEach((trophy) => {
                      transformedData.push({
                        trophy: trophy.value,
                        date_of_winning: item.date_of_winning,
                      });
                    });
                  });

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        // Skip carrier_trophies and photos here, handle them separately
                        if (key !== "carrier_trophies" && key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(`${key}[${index}]`, value.value);
                          } else {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      // Handle single fields like height
                      if (key === "height") {
                        formData.append(key, values[key].value);
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key].value !== undefined
                      ) {
                        formData.append(key, values[key].value);
                      } else {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append(
                    "carrier_trophies",
                    JSON.stringify(transformedData)
                  );
                  if (Array.isArray(values.photos)) {
                    values.photos.forEach((file, index) => {
                      if (file instanceof File) {
                        formData.append(`photos`, file);
                      } else {
                        console.error(
                          `Photo at index ${index} is not a file: `,
                          file
                        );
                      }
                    });
                  }

                  // formData.append("playerId", paylerId);
                  formData.append("is_profile_completed", 1);
                  // dispatch(editPlayerAction(formData)).then((res) => {
                  dispatch(createPlayerAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <FormikForm>
                  {playerDataLoadingState && <Loader />}
                  <div className="personal-heading mt-2">
                    <h3>Personal Details</h3>

                    <div className="grid-section">
                      <Form.Group className="mb-3 form-inner">
                        <Field
                          name="full_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter full name here"
                        />
                        <ErrorMessage
                          name="full_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 form-inner ">
                        <div className="age-box">
                        <Field
                          name="dob"
                          placeholder="Date of Birth"
                          type={inputType}
                          className="form-control"
                          onChange={(e) => {
                            setFieldValue("dob", e.target.value);
                            calculateAge(e.target.value);
                          }}
                          onFocus={() => setInputType("date")}
                          onBlur={() => setInputType("text")}
                          max={getYesterday()}
                        />
                        {age ? (
                          <Form.Group className="form-inner">
                            <div className="form-control">{age}</div>
                          </Form.Group>
                        ) : (
                          ""
                        )}
                        </div>
                        {/* <div className="new-calendar">
                          <img
                            src={
                              require("../Assets/images/new-calendar.svg")
                                .default
                            }
                          />
                        </div> */}
                         <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <div className="profile-card">
                        <Select
                          name="gender"
                          placeholder="Gender"
                          options={optionsGender}
                          onChange={(option) => setFieldValue("gender", option)}
                          value={values.gender}
                        />
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3">
                        <Select
                          name="place_of_birth"
                          placeholder="Place of Birth"
                          options={optionsPlaceOfBirth}
                          onChange={(option) =>
                            setFieldValue("place_of_birth", option)
                          }
                          value={values.place_of_birth}
                        />
                        <ErrorMessage
                          name="place_of_birth"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3">
                        <Select
                          name="nationality"
                          placeholder="Nationality(s)"
                          isMulti
                          options={optionsNationaity}
                          onChange={(option) =>
                            setFieldValue("nationality", option)
                          }
                          value={values.nationality}
                        />
                        <ErrorMessage
                          name="nationality"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* </Col> */}
                    </div>

                    <div className="grid-section">
                      {/* <Col lg={3} md={6}> */}
                      <div className="profile-card mb-3">
                        <Select
                          name="nationality_id"
                          placeholder="Nationality ID"
                          options={optionsNationaityId}
                          onChange={(option) =>
                            setFieldValue("nationality_id", option)
                          }
                          value={values.nationality_id}
                        />
                        <ErrorMessage
                          name="nationality_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* </Col> */}

                      {/* <Col lg={3} md={6}> */}
                      <div className="profile-card mb-3">
                        <Select
                          name="Languages"
                          options={optionsLanguage}
                          placeholder="Languages"
                          isMulti
                          onChange={(option) =>
                            setFieldValue("languages", option)
                          }
                          value={values.languages}
                        />
                        <ErrorMessage
                          name="languages"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* </Col> */}

                      {/* <Col lg={3} md={6}> */}
                      <div className="profile-card mb-3">
                        <Select
                          name="height"
                          placeholder="Height"
                          options={optionsHeight}
                          onChange={(option) => setFieldValue("height", option)}
                          value={values.height}
                        />
                        <ErrorMessage
                          name="height"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* </Col> */}

                      {/* <Col lg={3} md={6}> */}
                      <div className="profile-card mb-3">
                        <Select
                          name="foot"
                          placeholder="Foot"
                          options={optionsFoot}
                          isMulti
                          onChange={(option) => setFieldValue("foot", option)}
                          value={values.foot}
                        />
                        <ErrorMessage
                          name="foot"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* </Col> */}

                      {/* <Col lg={3} md={6}> */}
                      <div className="profile-card mb-3">
                        <Select
                          name="positions"
                          placeholder="Position(s)"
                          options={optionsPosition}
                          isMulti
                          onChange={(option) =>
                            setFieldValue("positions", option)
                          }
                          value={values.positions}
                        />
                        <ErrorMessage
                          name="positions"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* </Col> */}
                    </div>

                    <div className="mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Players Team</h3>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="is_player_free"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFieldValue(
                                "is_player_free",
                                isChecked ? 1 : 0
                              );
                              setIsFree(isChecked ? 1 : 0);
                              if (isChecked) {
                                setFieldValue("team_id", []);
                                setFieldValue("team_country", []);
                                setFieldValue("team_league", []);
                                setFieldValue("national_team_player", []);
                                setFieldValue("national_team", []);
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="is_player_free"
                          >
                            Free
                          </label>
                        </div>
                      </div>

                      <div className="cmn-form-fields grid-section   mt-2">
                        {/* <Col lg={4} md={6}> */}
                        <Form.Group className="mb-3 form-inner">
                          {/* <Field
                                name="team_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Team Name"
                                disabled={isFree}
                              /> */}
                          <Select
                            name="team_id"
                            placeholder="Enter Team Name"
                            options={teamOptions}
                            onChange={(option) =>
                              setFieldValue("team_id", option)
                            }
                            value={values.team_id}
                            isDisabled={isFree}
                          />
                          <ErrorMessage
                            name="team_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                        {/* </Col> */}
                        {/* <Col lg={4} md={6}> */}
                        <div className="profile-card mb-3">
                          <Select
                            name="team_country"
                            placeholder="Teams Country"
                            options={optionsPlaceOfBirth}
                            onChange={(option) =>
                              setFieldValue("team_country", option)
                            }
                            value={values.team_country}
                            isDisabled={isFree}
                          />
                          <ErrorMessage
                            name="team_country"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* </Col> */}
                        {/* <Col lg={4} md={6}> */}
                        <div className="profile-card mb-3">
                          <Select
                            name="team_league"
                            placeholder="Teams League"
                            options={optionsTeamLeague}
                            onChange={(option) =>
                              setFieldValue("team_league", option)
                            }
                            value={values.team_league}
                            isDisabled={isFree}
                          />
                          <ErrorMessage
                            name="team_league"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* </Col> */}
                        {/* <Col lg={4} md={6}> */}
                        <div className="profile-card">
                          <Select
                            name="national_team_player"
                            placeholder="National Team Player"
                            options={optionsNationalTeam}
                            onChange={(option) =>
                              setFieldValue("national_team_player", option)
                            }
                            value={values.national_team_player}
                            isDisabled={isFree}
                          />
                          <ErrorMessage
                            name="national_team_player"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* </Col> */}
                        {/* <Col lg={4} md={6}> */}
                        <div className="profile-card mb-3">
                          <Select
                            name="national_team"
                            placeholder="National Team"
                            options={optionsPlaceOfBirth}
                            onChange={(option) =>
                              setFieldValue("national_team", option)
                            }
                            value={values.national_team}
                            isDisabled={isFree}
                          />
                          <ErrorMessage
                            name="national_team"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* </Col> */}
                      </div>

                      <div className="vidoe-link-box">
                        <div className="availability mt-4">
                          <h3 className="h-20">Availability Status</h3>
                          <div className="cmn-form-fields mt-2">
                            <div className="profile-card mb-3">
                              <Select
                                name="availability_status"
                                placeholder="Player Status"
                                options={optionsPlayerStatus}
                                isMulti
                                onChange={(option) =>
                                  setFieldValue("availability_status", option)
                                }
                                value={values.availability_status}
                              />
                              <ErrorMessage
                                name="availability_status"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="link-videos mt-4">
                          <div className="video-link new-video-link">
                            <h3>Video Links</h3>
                            <Form.Group controlId="formBasicEmail">
                              <Field name="video_link">
                                {({ field }) => (
                                  <Form.Control
                                    type="url"
                                    placeholder="Add Video Link (YouTube / Instagram Video link)."
                                    {...field}
                                    name="video_link"
                                    value={values.video_link}
                                  />
                                )}
                              </Field>
                              <Form.Text className="text-muted">
                                *You can paste here any YouTube / Instagram
                                video link
                              </Form.Text>
                            </Form.Group>
                          </div>
                          <ErrorMessage
                            name="video_link"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="cmn-form-fields uplode-click-box-main">
                      <Row>
                        <div className="box-flex-up">
                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Player’s CV</h3>
                              <div
                                className={`uplode-the-img  ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "player_cv")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="cv-upload">
                                      <button type="button">
                                        Click to Upload
                                        <input
                                          name="player_cv"
                                          type="file"
                                          accept=".pdf, .doc, .docx"
                                          id="cv-upload"
                                          className="hidden-input"
                                          onChange={(e) =>
                                            handleFileChange(e, setFieldValue)
                                          }
                                        />
                                      </button>
                                    </label>
                                  </div>
                                  <div className={"drag-drop"}>
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop file.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>
                                    {values.player_cv ? (
                                      <div>
                                        <FaFileAlt />
                                      </div>
                                    ) : (
                                      "No file Selected"
                                    )}
                                  </p>
                                  <ErrorMessage
                                    name="player_cv"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Profile Image</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "profile_image")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="file-upload">
                                      <button type="button">
                                        Click to Upload
                                        <Field name="profile_image">
                                          {({ field }) => (
                                            <input
                                              type="file"
                                              id="file-upload"
                                              onChange={(e) =>
                                                handleFileChange1(
                                                  e,
                                                  setFieldValue,
                                                  "profile_image"
                                                )
                                              }
                                              accept="image/*"
                                            />
                                          )}
                                        </Field>
                                      </button>
                                    </label>
                                  </div>
                                  <div className="drag-drop">
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop file.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {values?.profile_image &&
                                      (typeof values.profile_image ===
                                      "string" ? (
                                        <img
                                          className="inner-view"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                          alt="Profile Preview"
                                          onClick={() =>
                                            handleShowPreview(
                                              `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                            )
                                          }
                                        />
                                      ) : values.profile_image instanceof
                                        File ? (
                                        <img
                                          src={URL.createObjectURL(
                                            values?.profile_image
                                          )}
                                          className="prev-img"
                                          alt="Profile Preview"
                                          style={{ width: "50px" }}
                                          onClick={() =>
                                            handleShowPreview(
                                              URL.createObjectURL(
                                                values?.profile_image
                                              )
                                            )
                                          }
                                        />
                                      ) : null)}
                                  </div>
                                </div>
                              </div>
                              <ErrorMessage
                                name="profile_image"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="box-flex-up mt-4">
                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Banner Image</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "banner_image")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="banner-file-upload">
                                      <button type="button">
                                        Click to Upload
                                        <Field name="banner_image">
                                          {({ field }) => (
                                            <input
                                              type="file"
                                              id="banner-file-upload"
                                              onChange={(e) =>
                                                handleFileChange1(
                                                  e,
                                                  setFieldValue,
                                                  "banner_image"
                                                )
                                              }
                                              accept="image/*"
                                            />
                                          )}
                                        </Field>
                                      </button>
                                    </label>
                                  </div>
                                  <div className="drag-drop">
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop file.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {values?.banner_image &&
                                      (typeof values.banner_image ===
                                      "string" ? (
                                        <img
                                          className="inner-view"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                          alt="Banner Preview"
                                          onClick={() =>
                                            handleShowPreview(
                                              `${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`
                                            )
                                          }
                                        />
                                      ) : values.banner_image instanceof
                                        File ? (
                                        <img
                                          src={URL.createObjectURL(
                                            values.banner_image
                                          )}
                                          className="prev-img"
                                          alt="Banner Preview"
                                          style={{ width: "50px" }}
                                          onClick={() =>
                                            handleShowPreview(
                                              URL.createObjectURL(
                                                values.banner_image
                                              )
                                            )
                                          }
                                        />
                                      ) : null)}
                                  </div>
                                </div>
                              </div>
                              <ErrorMessage
                                name="banner_image"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>

                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Photos</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "photos", values)
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="image-upload">
                                      <button type="button">
                                        Click to Upload
                                        <input
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          onChange={(e) =>
                                            handleImageUpload(
                                              e,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                          id="image-upload"
                                          className="hidden-input"
                                        />
                                      </button>
                                    </label>
                                  </div>
                                  {console.log(values.photos, "sdfsdfsdf")}
                                  {Array.isArray(values.photos) && values.photos.filter(
                                    (photo) => photo !== null
                                  )?.length === 0 && (
                                    <div className="drag-drop">
                                      <p>
                                        <span>
                                          <img
                                            src={
                                              require("../Assets/images/drag-and-drop.svg")
                                                .default
                                            }
                                            alt="Upload Placeholder"
                                          />
                                        </span>
                                        Or drag and drop files (Upto 5).
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {/* Filter out null values before mapping */}
                                    {Array.isArray(values.photos)  &&
                                    values?.photos?.filter(
                                      (photo) => photo !== null
                                    )?.length > 0 ? (
                                      <div className="image-previews">
                                        {values.photos
                                          .filter((photo) => photo !== null)
                                          .map((photo, index) => (
                                            <div
                                              key={index}
                                              className="image-thumbnail"
                                            >
                                              {photo &&
                                              typeof photo === "object" &&
                                              photo instanceof File ? (
                                                // For new uploads (File object)
                                                <img
                                                  src={URL.createObjectURL(
                                                    photo
                                                  )}
                                                  className="prev-img"
                                                  alt={`Photo ${
                                                    index + 1
                                                  } Preview`}
                                                  onClick={() =>
                                                    handleShowPreview(
                                                      URL.createObjectURL(photo)
                                                    )
                                                  }
                                                />
                                              ) : photo &&
                                                typeof photo === "string" ? (
                                                <img
                                                  className="prev-img"
                                                  src={`${process.env.REACT_APP_IMAGE_PATH}${photo}`}
                                                  alt={`Photo ${
                                                    index + 1
                                                  } Preview`}
                                                  onClick={() =>
                                                    handleShowPreview(
                                                      `${process.env.REACT_APP_IMAGE_PATH}${photo}`
                                                    )
                                                  }
                                                />
                                              ) : null}

                                              <button
                                                className="remove-button"
                                                type="button"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  typeof photo === "object" &&
                                                  photo instanceof File
                                                    ? handleRemoveImage(
                                                        index,
                                                        values,
                                                        setFieldValue
                                                      )
                                                    : handleRemoveImage1(
                                                        index,
                                                        values,
                                                        setFieldValue
                                                      );
                                                }}
                                              >
                                                &times;
                                              </button>
                                            </div>
                                          ))}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <div className="carrier-notes">
                    <div className="trophies mt-4">
                      <FieldArray name="carrier_trophies">
                        {({ push, remove }) => (
                          <>
                            <div className="d-flex justify-content-between">
                              <h3 className="h-20">Carrier Trophies</h3>
                              <button
                                type="button"
                                onClick={() =>
                                  push({ trophy: null, date_of_winning: "" })
                                }
                                style={{
                                  border: "none",
                                  background: "none",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433289 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091V14.5455C10.9091 14.7866 10.8133 15.0178 10.6428 15.1883C10.4723 15.3588 10.2411 15.4545 10 15.4545C9.7589 15.4545 9.52767 15.3588 9.35718 15.1883C9.18669 15.0178 9.09091 14.7866 9.09091 14.5455V10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091V5.45454C9.09091 5.21344 9.18669 4.98221 9.35718 4.81172C9.52767 4.64123 9.7589 4.54545 10 4.54545C10.2411 4.54545 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 10.9091 5.21344 10.9091 5.45454V9.09091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                    fill="#103D56"
                                  />
                                </svg>
                              </button>
                            </div>
                            {values?.carrier_trophies?.map((trophy, index) => (
                              <div
                                key={index}
                                className="cmn-form-fields new-bottom-flex mt-2"
                              >
                                <div className="profile-card mb-3 profile-width">
                                  <Select
                                    name={`carrier_trophies[${index}].trophy`}
                                    placeholder="Tropies/Champanioship"
                                    options={optionsTrophy}
                                    isMulti
                                    onChange={(option) =>
                                      setFieldValue(
                                        `carrier_trophies[${index}].trophy`,
                                        option
                                      )
                                    }
                                    value={
                                      values.carrier_trophies[index].trophy
                                    }
                                  />
                                  <ErrorMessage
                                    name={`carrier_trophies[${index}].trophy`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="profile-width increment-decrement">
                                  <Form.Group className="mb-3 form-inner profile-width add-new-date-icon">
                                    <Field
                                      name={`carrier_trophies[${index}].date_of_winning`}
                                      className="form-control"
                                      placeholder="Date of Winning"
                                      type={inputType1}
                                      onFocus={() => setInputType1("date")}
                                      onBlur={() => setInputType1("text")}
                                    />
                                    <ErrorMessage
                                      name={`carrier_trophies[${index}].date_of_winning`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </Form.Group>

                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761209 6.17316C0.0043329 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091H7.05411C7.05411 11.1502 11.0796 14.1381 10.9091 14.3086C8.95963 12.4289 8.69336 13.8086 10 15.4545C9.7589 15.4545 11.6021 13.1035 11.4316 12.933C11.2612 12.7625 10.6934 14.0477 10.6934 13.8066L9.09091 10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091L10.4316 6.80859C10.4316 6.56749 11.2612 6.97908 11.4316 6.80859C11.6021 6.63811 11.6905 6.30859 11.9316 6.30859C12.1727 6.30859 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 7.16322 8.8498 7.16322 9.09091H10.9091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                          fill="#103D56"
                                        />
                                      </svg>
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </div>

                    <div className="all-notes mt-4">
                      <h3 className="h-20">Other Notes</h3>
                      <div className="cmn-form-fields">
                        <Form.Group className="mb-3 form-inner">
                          <Field
                            as="textarea"
                            name="profile_other_notes"
                            rows={1}
                            className="form-control"
                            placeholder="Write notes here"
                          />
                          <ErrorMessage
                            name="profile_other_notes"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button type="submit" className="next-btn-ps">
                        Next
                      </button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
      <PreviewModal
        show={showPreview}
        onHide={handleClosePreview}
        previewImage={previewImage}
      />
    </LoginLayout>
  );
};

export default ProfileInfo;
