import React, { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
export default function InternalSearchAssociateProfile() {
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };
  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Associate Agent’s Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={require("../Assets/images/agent.png")}
                    />
                    <div>
                      <h2>Manchester United</h2>

                      <div className="socio-icons mt-3">
                        <Link to="#">
                          <img
                            src={require("../Assets/images/fbblue.svg").default}
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src={
                              require("../Assets/images/snapblue.svg").default
                            }
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src={
                              require("../Assets/images/instablue.svg").default
                            }
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src={require("../Assets/images/xblue.svg").default}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      {/* <Link to="#">
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link> */}
                    </div>
                    <div className="play-info-box">
                      <div>
                        <h3>Male</h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>24</h3>
                        <p>Age</p>
                      </div>
                      <div>
                        <h3>On Loan</h3>
                        <p>Availability</p>
                      </div>
                      <div>
                        <h3>English</h3>
                        <p>Language</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Country:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>City:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>+61 234567976</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>Fax:</h4>
                              <p>Lorem Ipsum</p>
                            </li>

                            <li>
                              <h4>Website</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>Fax:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-4">
                        <h2>Main Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Role:</h4>
                              <p>Trainer</p>
                            </li>
                            <li>
                              <h4>Gender:</h4>
                              <p>Male</p>
                            </li>
                            <li>
                              <h4>Team:</h4>
                              <p>Manchester United</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>dummy@gmail.com</p>
                            </li>
                            <li>
                              <h4>Phone Address:</h4>
                              <p>+61 5678907544</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Social Media Links</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>X:</h4>
                              <p>+61 234567976</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Players" title="Players">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Players I have in teams from this Agent</h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Players that this Agent has in teams</h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          My Players that have been proposed to this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>
                          Players have been proposed to me from this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  <span>Team Name</span>
                                </p>
                              </div>
                            </div>
                            <div className="ms-3">
                              <p>
                                Player ID:
                                <span>IVXJW007</span>
                              </p>
                              <p>
                                Position:
                                <span>Ivyx56364</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Directors" title="Directors">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Directors I have in teams from this Agent</h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Directors that this Agent has in teams</h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          My Directors that have been proposed to this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>
                          Directors have been proposed to me from this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Director ID: <span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Coaches" title="Coaches">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coaches I have in teams from this Agent</h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Coaches that this Agent has in teams</h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          My Coaches that have been proposed to this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>
                          Coaches have been proposed to me from this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="play-inner-box">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Andrew Jason</h4>
                                <p>
                                  Coach ID:<span>IVXJW007</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
