import React, { useEffect, useRef } from "react";
import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserUniqueId } from "../Redux/Action/auth";
import toast from "react-hot-toast";

export default function AgentId({ values }) {
  const dispatch = useDispatch();
  const uniqueId = useSelector((state) => state.userAuthData.userUniqueId);
  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(getUserUniqueId());
  }, [dispatch]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(uniqueId);
      toast.success("Agent ID copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy!", err);
      toast.error("Failed to copy Agent ID.");
    }
  };

  return (
    <div >
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">
              Hi {values?.name} 
            </h2>
            <p className="sub-16 text-center mb-3">
              Let’s start to build Your Player Powerhouse: Make
              <br /> Your Dream Team Today
            </p>

            <div className="cmn-form-fields">
              <Form.Group className="mb-3 form-inner">
                <Form.Label className="sub-16 mb-2">Agent ID</Form.Label>
                <Form.Control
                  type="text"
                  name="unique_id"
                  placeholder="Agent ID"
                  value={uniqueId}
                  readOnly
                  ref={inputRef}
                />
                <button
                  type="button"
                  className="pass-show"
                  onClick={copyToClipboard}
                >
                  <img
                    style={{ top: "12px" }}
                    src={require("../Assets/images/copy.svg").default}
                    alt="Copy"
                  />
                </button>
              </Form.Group>
              <p className="text-muted">*Agent ID is system generated</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
