import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Container fluid>
      <div className="page-not-found">
        <Row className="box-center">
          <Col lg={12} className="text-center">
            <div className=""></div>
            <h2 className="">404 - Page Not Found</h2>
            <p>Sorry, the page you are looking for could not be found.</p>

            <Link to="/home" className="cmn-pink w-10 text-center">
              Back to Home
            </Link>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
