import { createSlice } from "@reduxjs/toolkit";
import { getDirectorDataById, getDirectorListAction } from "../Action/directorAction";


const initialState = {
  loading: false,
  directorDetail: [],
  getListOfDirectors: [],
  error: false,
};

const directorAuthSlice = createSlice({
  name: "directorAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDirectorDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDirectorDataById.fulfilled, (state, { payload }) => {
        state.directorDetail = payload.data
        state.loading = false;
      })
      .addCase(getDirectorDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getDirectorListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDirectorListAction.fulfilled, (state, { payload }) => {
        state.getListOfDirectors = payload.data
        state.loading = false;
      })
      .addCase(getDirectorListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
    },
});


export default directorAuthSlice.reducer;
