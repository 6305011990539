import { createSlice } from "@reduxjs/toolkit";
import { getAgentDataById, getAgentDetailsById, getAgentListAction, getOtherAgentDataById, getOtherAgentListAction } from "../Action/agentAction";


const initialState = {
  loading: false,
  agentDetail: [],
  agentListDetail: [],
  otherAgentDetail : [],
  otherAgentList : [],
  error: false,
};

const agentAuthSlice = createSlice({
  name: "agentAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgentDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgentDataById.fulfilled, (state, { payload }) => {
        state.agentDetail = payload.data
        state.loading = false;
      })
      .addCase(getAgentDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })




      .addCase(getAgentDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgentDetailsById.fulfilled, (state, { payload }) => {
        state.agentDetail = payload.data
        state.loading = false;
      })
      .addCase(getAgentDetailsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })



      .addCase(getAgentListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgentListAction.fulfilled, (state, { payload }) => {
        state.agentListDetail = payload.data
        state.loading = false;
      })
      .addCase(getAgentListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // other agent data by Id
      .addCase(getOtherAgentDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOtherAgentDataById.fulfilled, (state, { payload }) => {
        state.otherAgentDetail = payload.data
        state.loading = false;
      })
      .addCase(getOtherAgentDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // other agent list

      .addCase(getOtherAgentListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOtherAgentListAction.fulfilled, (state, { payload }) => {
        state.otherAgentList = payload.data
        state.loading = false;
      })
      .addCase(getOtherAgentListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
    },
});


export default agentAuthSlice.reducer;
