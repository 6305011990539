import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// internal search for player
export const internalPlayerSearchAction = createAsyncThunk("internalPlayerSearchAction/user/internalPlayerSearch", async (data) => {
    const response = await api.post("/user/internalPlayerSearch", data);
    return response.data;
  }
);

// intyernal search for player
export const internalDirectorSearchAction = createAsyncThunk("internalDirectorSearchAction/user/internalDirectorSearch", async (data) => {
  const response = await api.post("/user/internalDirectorSearch", data)
  return response.data;
}
);


// internal search for coach
export const internalCoachSearchAction = createAsyncThunk("internalCoachSearchAction/user/internalCoachSearch", async (data) => {
  const response = await api.post("/user/internalCoachSearch", data)
  return response.data;
}
);

// internal search for team
export const internalTeamSearchAction = createAsyncThunk("internalTeamSearchAction/user/internalTeamSearch", async (data) => {
  const response = await api.post("/user/internalTeamSearch", data)
  return response.data;
}
);

// internal search for agent
export const internalAgentSearchAction = createAsyncThunk("internalAgentSearchAction/user/internalAgentSearch", async (data) => {
  const response = await api.post("/user/internalAgentSearch", data)
  return response.data;
}
);


// internal search 
export const internalSearchAction = createAsyncThunk("internalSearchAction/user/internalSearch", async ({ searchText }, thunkAPI) => {
  const response = await api.get("/user/internalSearch", {
    params: {
      word: searchText,
    },
  })
  return response.data;
}
);