import React, { useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import backgroundImage from "../Assets/images/playerbanner.png";
import { Decryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { getCoachesDataById } from "../Redux/Action/coachAction";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import toast from "react-hot-toast";
import {
  AvailabilityStatus,
  coachingLicense,
  previousStatus,
  teamLeague,
} from "../utils/rawjson";
import Loader from "../components/Loader";

export default function MycoachDetail() {
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();

  const coachData = useSelector((e) => e?.coachAuthData?.coachDetail);
  const loader = useSelector((e) => e?.coachAuthData?.loading);

  useEffect(() => {
    dispatch(getCoachesDataById({ id: IdVal }));
  }, [dispatch]);

  const copyToClipboard = () => {
    if (coachData?.unique_id) {
      navigator.clipboard
        .writeText(coachData.unique_id)
        .then(() => {
          toast.success("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleCoachEdit = (id) => {
    if (id) {
      localStorage.setItem("coachId", id);
    }
  };

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Coach Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${coachData?.profile_image}`}
                    />
                    <div>
                      <h2>
                        {capitalize(coachData?.full_name)},{" "}
                        {calculateAge(coachData?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0">Coach ID:{coachData?.unique_id}</p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                      <div className="socio-icons">
                        {/* Instagram */}
                        {coachData?.instagram && (
                          <Link
                            to={coachData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {coachData?.snapchat && (
                          <Link
                            to={coachData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {coachData?.twitter && (
                          <Link
                            to={coachData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {coachData?.facebook && (
                          <Link
                            to={coachData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        to="/add-coaches"
                        onClick={() => handleCoachEdit(coachData?._id)}
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                    <div className="play-info-box">
                      <div>
                        <h3>{coachData?.gender === 0 ? "Male" : "Female"}</h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>{calculateAge(coachData?.dob)}</h3>
                        <p>Age</p>
                      </div>
                      <div>
                        <h3>
                          {AvailabilityStatus[coachData?.availability_status]
                            ? AvailabilityStatus[coachData?.availability_status]
                            : "N/A"}
                        </h3>
                        <p>Availability</p>
                      </div>
                      <div>
                        <h3>
                          {coachData?.languages?.length > 1
                            ? `${coachData.languages[0]} +${
                                coachData.languages.length - 1
                              }`
                            : coachData?.languages?.[0]}
                        </h3>
                        <p>Language</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coach’s Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Place of Birth:</h4>
                              <p>{coachData?.place_of_birth}</p>
                            </li>
                            <li>
                              <h4>Nationality (s):</h4>

                              <p> {coachData?.nationality?.join(", ")}</p>
                            </li>

                            <li>
                              <h4>Previous Status:</h4>

                              <p>
                                {coachData?.previous_status
                                  ?.map((elem) => previousStatus[elem])
                                  .join(", ")}
                              </p>
                            </li>
                            <li>
                              <h4>Coaching Licenses:</h4>

                              <p>
                                {coachData?.coaching_licenses
                                  ?.map((elem) => coachingLicense[elem])
                                  .join(", ")}
                              </p>
                            </li>
                            <li>
                              <h4>Languages(s):</h4>
                              <p>{coachData?.languages?.join(", ")}</p>
                            </li>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {coachData?.main_info_other_notes
                                  ? coachData?.main_info_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Career Trophies</h2>
                        <div className="search-inner-box">
                          <ul>
                            {Array.isArray(coachData?.carrier_trophies) &&
                            coachData?.carrier_trophies.length > 0 ? (
                              <>
                                {coachData?.carrier_trophies.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <div className="d-flex">
                                            <img
                                              src={require("../Assets/images/smtrophy.png")}
                                            />
                                            <h4 className="ms-2">
                                              Youth Trophie
                                            </h4>
                                          </div>
                                          <p>
                                            {dateOfBirth(ele?.date_of_winning)}
                                          </p>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <li>No Data Found</li>
                            )}
                          </ul>
                        </div>
                      </div>
                      {Array.isArray(coachData?.photos) &&
                        coachData?.photos.length > 0 && (
                          <div className="play-inner-box search-result mt-3">
                            <div className="d-flex align-items-center">
                              {coachData?.photos.map((ele, index) => {
                                return (
                                  <div key={index}>
                                    <img
                                      className="small-images"
                                      src={`${process.env.REACT_APP_IMAGE_PATH}${ele}`}
                                    />
                                  </div>
                                );
                              })}
                              <div className="ms-3">
                                <h4>Photos</h4>
                              </div>
                            </div>
                            <div
                              className="badge-img"
                              style={{ boxShadow: "none" }}
                            >
                              <img
                                src={
                                  require("../Assets/images/arrow.svg").default
                                }
                              />
                            </div>
                          </div>
                        )}
                      {/* <div className="search-cmn-box mt-3">
                        <h2>Coach’s CV</h2>

                        <div className="play-inner-box mb-0">
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../Assets/images/pdf.svg").default}
                            />
                            <div className="ms-3">
                              <h4>Coach’s CV.pdf</h4>
                            </div>
                          </div>
                          <button
                            type="button"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <img
                              src={
                                require("../Assets/images/downbtn.svg").default
                              }
                            />
                          </button>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="play-inner-box search-result mt-3">
                        <div className="d-flex align-items-center">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}${coachData?.creator_id?.profile_image}`}
                          />
                          <div className="ms-3 d-flex align-items-center">
                            <h4>{coachData?.creator_id?.full_name}</h4>
                            <p
                              className="ms-2"
                              style={{ color: "#1D161780", fontSize: "13px" }}
                            >
                              Agent
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="play-inner-box search-result mt-3">
                        <div className="d-flex align-items-center">
                          <img src={require("../Assets/images/user.png")} />
                          <div className="ms-3 d-flex align-items-center">
                            <h4>J K Rowling</h4>
                            <p
                              className="ms-2"
                              style={{ color: "#1D161780", fontSize: "13px" }}
                            >
                              Associate Agent
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="search-cmn-box mt-3">
                        <h2>Current Team</h2>
                        <div className="play-scroll-box">
                          <div className="play-inner-box mb-0">
                            <div className="d-flex">
                              <img
                                src={require("../Assets/images/tiger.png")}
                              />
                              <div className="ms-3">
                                <h4>{coachData?.current_team_name}</h4>
                                <p>
                                  <span>
                                    {" "}
                                    {coachData?.current_team_country}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        <h2>Previous Team</h2>
                        <div className="play-scroll-box">
                          <div className="play-inner-box mb-0">
                            {Array.isArray(coachData?.previous_team) &&
                            coachData?.previous_team?.length > 0 ? (
                              <>
                                {coachData?.previous_team.map((ele, index) => {
                                  return (
                                    <>
                                      <div className="d-flex" key={index}>
                                        <img
                                          src={require("../Assets/images/tiger.png")}
                                        />
                                        <div className="ms-3">
                                          <h4>{ele?.previous_team_name}</h4>
                                          {/* <p>
                                            <span>London / United Kingdom</span>
                                          </p> */}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <div>No Data Found</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contract" title="Contract">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Current Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Current Annual Salary:</h4>
                              <p>{coachData?.current_contract}</p>
                            </li>
                            <li>
                              <h4>Bonuses:</h4>
                              <p>{coachData?.Bonuses}</p>
                            </li>
                            <li>
                              <h4>Joining Date:</h4>
                              <p>
                                {dateOfBirth(coachData?.contract_start_date)}
                              </p>
                            </li>
                            <li>
                              <h4>Contract Start Date:</h4>
                              <p>
                                {dateOfBirth(coachData?.contract_start_date)}
                              </p>
                            </li>
                            <li>
                              <h4>Contract End Date:</h4>
                              <p>{dateOfBirth(coachData?.contract_end_date)}</p>
                            </li>
                            <li>
                              <h4>Release Clause:</h4>
                              <p>{coachData?.release_clouse}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {coachData?.current_contract_other_notes}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>New Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Annual Asking Salary:</h4>
                              <p>{coachData?.new_contract_annual_salary}</p>
                            </li>
                            <li>
                              <h4>Contract Duration:</h4>
                              <p>{coachData?.new_contract_duration}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {coachData?.new_contract_other_notes}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>

              <Tab eventKey="Proposals" title="Proposals">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Proposals</h2>
                        <div className="search-inner-box">
                          <h3 className="in-heading">
                            Teams that the Coach has been proposed
                          </h3>

                          {Array.isArray(coachData?.proposed_team) &&
                          coachData?.proposed_team.length > 0 ? (
                            <>
                              {coachData?.proposed_team.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box align-items-start"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={require("../Assets/images/user.png")}
                                        />

                                        <div className="ms-3">
                                          <h4>
                                            {capitalize(
                                              ele?.proposed_associate_agent_name
                                            )}
                                          </h4>
                                          <p>
                                            <span>
                                              {" "}
                                              {ele?.proposed_team_country}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <p>
                                        <span>
                                          {" "}
                                          {
                                            teamLeague[
                                              ele?.proposed_team_league
                                            ]
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box ">
                        <h2 className=""></h2>
                        <div className="search-inner-box mt-4">
                          <h3 className="in-heading">
                            Teams that are interested for the Coach
                          </h3>
                          {Array.isArray(coachData?.interested_team) &&
                          coachData?.interested_team.length > 0 ? (
                            <>
                              {coachData?.interested_team.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box align-items-start"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={require("../Assets/images/user.png")}
                                        />

                                        <div className="ms-3">
                                          <h4>
                                            {capitalize(
                                              ele?.interested_team_name
                                            )}
                                          </h4>
                                          <p>
                                            <span>
                                              {ele?.interested_team_country}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <p>
                                        <span>
                                          {" "}
                                          {
                                            teamLeague[
                                              ele?.interested_team_league
                                            ]
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contact" title="Contact">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>{coachData?.phone_number}</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>{coachData?.email}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Home Address:</h4>
                              <p className="mt-1">{coachData?.address}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>{coachData?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>{coachData?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h4>X:</h4>
                              <p>{coachData?.facebook || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Coach’s Lawyer:</h4>
                              <p>{coachData?.coach_lawyer_name}</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>{coachData?.coach_lawyer_phone_number}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Managing Company:</h4>
                              <p>{coachData?.coach_managing_company_name}</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>
                                {coachData?.coach_managing_company_phone_number}
                              </p>
                            </li>
                          </ul>
                        </div>
                        {/* <div className="search-inner-box mt-3">
                          {" "}
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </Col>
                    <Col lg={4}>
                      {/* <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
