import React, { useEffect } from "react";
import {
  Formik,
  Field,
  Form as FormikForm,
  FieldArray,
  ErrorMessage,
} from "formik";
import { Container, Row, Col } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { staticTeamDataAction } from "../Redux/Action/staticAction";
import Select from "react-select";
import { editTeamAction, getTeamDataById } from "../Redux/Action/teamAction";
import toast from "react-hot-toast";

const TeamAgentRelationship = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const teamId = id && Decryptedid(atob(id));

  const staticListForTeam = useSelector(
    (e) => e.staticAuthData.teamStaticDetail
  );

  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

  console.log(getTeamDetails, "dfgdfgdfg45645");

  useEffect(() => {
    dispatch(staticTeamDataAction());
  }, []);

  const optionsGender = staticListForTeam?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsNationality = staticListForTeam?.nationality?.map((item, i) => ({
    label: item,
    value: i,
  }));

  const optionsAge = staticListForTeam?.age?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const optionsNationaityId = staticListForTeam?.nationality_id?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsTeamLeague = staticListForTeam?.looking_players_team_league?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsNationalTeam = staticListForTeam?.national_team_player?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsHeight = staticListForTeam?.height?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const optionsFoot = staticListForTeam?.foot?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsLanguage = staticListForTeam?.languages?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsTrophy = staticListForTeam?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPosition = staticListForTeam?.positions?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlayerStatus =
    staticListForTeam?.looking_players_availability_status?.map((item) => ({
      label: item?.val,
      value: item?.id,
    }));

  const initialValues = {
    is_looking_for_player: getTeamDetails?.is_looking_for_player || "0",
    looking_players:
      getTeamDetails?.looking_players &&
      getTeamDetails.looking_players?.length > 0
        ? getTeamDetails.looking_players?.map((player) => ({
            age:
              optionsAge?.find((option) => option.value === player.age) || "",

            gender:
              optionsGender?.find((option) => option.value === player.gender) ||
              "",

            // nationality:
            // player?.nationality?.map((item) => ({
            //     label: item,
            //     value: Number(item),
            //   })) || [],

            nationality:
              player?.nationality?.map((item) => {
                const matchedOption = optionsNationality?.find(
                  (option) => option.value == item
                );
                return matchedOption || [];
              }) || [],

            nationality_id:
              optionsNationaityId?.find(
                (elem) => elem.value === player.nationality_id
              ) || [],

            looking_players_team_league:
              optionsTeamLeague?.find(
                (option) => option.value === player.looking_players_team_league
              ) || "",
            national_team_player:
              optionsNationalTeam?.find(
                (option) => option.value === player.national_team_player
              ) || "",

            height:
              optionsHeight?.find((elem) => elem.value === player.height) || [],

            foot:
              optionsFoot?.find((option) => option.value === player.foot) || "",

            languages:
              player?.languages?.map((item) => ({
                label: item,
                value: item,
              })) || [],

            positions:
              player.positions
                ?.map((footValue) => {
                  const matchedOption = optionsPosition?.find(
                    (option) => option.value === footValue
                  );
                  return matchedOption || null;
                })
                .filter((option) => option !== null) || [],

            // looking_players_carrier_trophies:
            // player.looking_players_carrier_trophies > 0

            //   ?
            //    player.looking_players_carrier_trophies?.map((elem) => ({
            //       trophy: optionsTrophy?.find(
            //         (option) => option.value == elem
            //       ),
            //     }))
            //   : [{ trophy: null }],

            looking_players_carrier_trophies:
              player.looking_players_carrier_trophies?.length > 0
                ? player.looking_players_carrier_trophies.map((elem) => ({
                    trophy:
                      optionsTrophy?.find((option) => option.value == elem) ||
                      null,
                  }))
                : [{ trophy: null }],

            looking_players_availability_status:
              player?.looking_players_availability_status
                ?.map((footValue) => {
                  const matchedOption = optionsPlayerStatus?.find(
                    (option) => option.value === footValue
                  );
                  return matchedOption || null;
                })
                .filter((option) => option !== null) || [],

            looking_players_carrier_trophies:
              player.looking_players_carrier_trophies.length > 0
                ? player.looking_players_carrier_trophies.map((trophyId) => ({
                    trophy:
                      optionsTrophy?.find(
                        (option) => option.value === trophyId
                      ) || [],
                  }))
                : [{ trophy: null }],

            looking_players_other_notes:
              player.looking_players_other_notes || "",
          }))
        : [
            {
              age: "",
              gender: "",
              nationality: [],
              nationality_id: "",
              looking_players_team_league: "",
              national_team_player: "",
              height: "",
              foot: "",
              languages: [],
              positions: [],
              looking_players_carrier_trophies: [
                {
                  trophy: null,
                },
              ],
              looking_players_availability_status:
                // optionsPlayerStatus?.find((option) => option.value === 0) ||
                [],
              looking_players_other_notes: "",
            },
          ],
  };

  const validateForm = (values) => {
    const errors = {};

    if (values.is_looking_for_player === null) {
      errors.is_looking_for_player = "required";
    }

    // Validate looking_players only when is_looking_for_player is 1
    if (values.is_looking_for_player === "1") {
      if (!values.looking_players || values.looking_players.length === 0) {
        errors.looking_players = "At least one player is required";
      } else {
        const lookingPlayersErrors = values.looking_players.map(
          (player, index) => {
            const playerErrors = {};

            if (!player.age) {
              playerErrors.age = "required";
            }

            if (!player.gender) {
              playerErrors.gender = "required";
            }

            if (!player.nationality || player.nationality.length === 0) {
              playerErrors.nationality = "required";
            }

            if (!player.nationality_id) {
              playerErrors.nationality_id = "required";
            }

            if (!player.looking_players_team_league) {
              playerErrors.looking_players_team_league = "required";
            }

            if (player.national_team_player === undefined) {
              playerErrors.national_team_player = "required";
            }

            if (!player.height) {
              playerErrors.height = "required";
            }

            if (!player.foot) {
              playerErrors.foot = "required";
            }

            if (!player.languages || player.languages.length === 0) {
              playerErrors.languages = "required";
            }

            if (!player.positions || player.positions.length === 0) {
              playerErrors.positions = "required";
            }

            if (
              !player.looking_players_availability_status ||
              player.looking_players_availability_status.length === 0
            ) {
              playerErrors.looking_players_availability_status = "required";
            }

            if (Object.keys(playerErrors).length > 0) {
              return playerErrors;
            } else {
              return undefined;
            }
          }
        );

        if (lookingPlayersErrors.some((error) => error)) {
          errors.looking_players = lookingPlayersErrors;
        }
      }
    }

    console.log(errors, "Validation Errors");
    return errors;
  };

  const transformedValues = (values) => {
    return {
      looking_players: values.looking_players.map((player) => ({
        age: player.age.value,
        gender: player.gender.value,
        nationality: player.nationality.map((n) => n.value),
        nationality_id: player.nationality_id.value,
        looking_players_team_league: player.looking_players_team_league.value,
        national_team_player: player.national_team_player.value,
        height: player.height.value,
        foot: player.foot.value,
        languages: player.languages.map((lang) => lang.value),
        positions: player.positions.map((position) => position.value),
        // looking_players_carrier_trophies:
        //   player.looking_players_carrier_trophies
        //     .map((trophy) => trophy.trophy.map((t) => t.value || t))
        //     .flat(),

        looking_players_carrier_trophies:
          player.looking_players_carrier_trophies &&
          player.looking_players_carrier_trophies.length > 0
            ? player.looking_players_carrier_trophies
                .map((trophy) => {
                  if (trophy && trophy.trophy) {
                    if (Array.isArray(trophy.trophy)) {
                      return trophy.trophy.map((t) => t.value || t);
                    } else {
                      return trophy.trophy.value || trophy.trophy;
                    }
                  }
                  return null;
                })
                .flat()
            : [],

        looking_players_availability_status:
          player.looking_players_availability_status.map(
            (status) => status.value || status
          ),
        looking_players_other_notes: player.looking_players_other_notes,
      })),
    };
  };

  const handleSubmit = (values) => {
    console.log(values, "fghfghfgh3445345");
    // const transformedData = transformedValues(values);
    const transformedData =
      values.is_looking_for_player == 1 ? transformedValues(values) : {};
    let formData = new FormData();

    if (values.is_looking_for_player == 1) {
      formData.append("is_looking_for_player", values.is_looking_for_player);
      formData.append(
        "looking_players",
        JSON.stringify(transformedData.looking_players)
      );
    } else {
      formData.append("is_looking_for_player", values.is_looking_for_player);
    }

    formData.append("teamId", teamId);
    formData.append("is_profile_completed", 3);

    dispatch(editTeamAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate(`/add-team-three/${Encryptedid(res?.payload?.data?._id)}`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  // const handleSubmit = (values) => {

  //   const transformedData =
  //     values.is_looking_for_player == 1 ? transformedValues(values) : {};

  //   let formData = new FormData();

  //   formData.append("is_looking_for_player", values.is_looking_for_player);

  //   if (values.is_looking_for_player == 1) {
  //     formData.append(
  //       "looking_players",
  //       JSON.stringify(transformedData.looking_players)
  //     );
  //   }

  //   formData.append("teamId", teamId);
  //   formData.append("is_profile_completed", 3);

  //   dispatch(editTeamAction(formData)).then((res) => {
  //     if (res?.payload?.status === 200) {
  //       navigate(`/add-team-three/${Encryptedid(res?.payload?.data?._id)}`);
  //     } else {
  //       toast.error(res?.payload?.message);
  //     }
  //   });
  // };

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId }));
    }
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Add Team</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FormikForm>
                  {console.log(values, "Dfgdfg345345345")}
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">
                        Is this team looking for players?
                      </h3>
                    </div>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={4}>
                          <div className="licensed-sec mb-3">
                            <h3>Yes</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="is_looking_for_player"
                                value="1"
                                checked={values.is_looking_for_player == "1"}
                                onChange={() =>
                                  setFieldValue("is_looking_for_player", "1")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>No</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="is_looking_for_player"
                                value="0"
                                checked={values.is_looking_for_player == "0"}
                                onChange={() =>
                                  setFieldValue("is_looking_for_player", "0")
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <ErrorMessage
                        name={`is_looking_for_player`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  {values.is_looking_for_player == "1" && (
                    <FieldArray name="looking_players">
                      {({ push, remove }) => (
                        <>
                          {values.looking_players.map((player, index) => (
                            <div key={index} className="common-outer mt-4">
                              <div className="d-flex justify-content-between">
                                <h3 className="h-20">Player {index + 1}</h3>
                                <button
                                  type="button"
                                  style={{
                                    border: "none",
                                    background: "none",
                                  }}
                                  onClick={() =>
                                    push({
                                      age: "",
                                      gender: "",
                                      nationality: "",
                                      nationality_id: "",
                                      looking_players_team_league: "0",
                                      national_team_player: "0",
                                      height: "",
                                      foot: "",
                                      languages: "",
                                      positions: "",
                                      looking_players_carrier_trophies: [
                                        { trophy: null },
                                      ],
                                      looking_players_availability_status: 0,
                                      looking_players_other_notes: "",
                                    })
                                  }
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                      fill="#4C8BB4"
                                    />
                                  </svg>
                                </button>
                                {index !== 0 && (
                                  <button
                                    type="button"
                                    style={{
                                      background: "none",
                                      border: "none",
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                        fill="#4C8BB4"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </div>
                              <div className="cmn-form-fields mt-2">
                                <Row>
                                  <Col lg={4}>
                                    <div className="profile-card mb-3">
                                      <Select
                                        name={`looking_players[${index}].age`}
                                        placeholder="Age"
                                        className="form-control"
                                        options={optionsAge}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].age`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index].age
                                        }
                                      />
                                      <ErrorMessage
                                        name={`looking_players[${index}].age`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="profile-card mb-3">
                                      <Select
                                        className="form-control"
                                        name={`looking_players[${index}].gender`}
                                        placeholder="Gender"
                                        options={optionsGender}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].gender`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index].gender
                                        }
                                      />
                                      <ErrorMessage
                                        name={`looking_players[${index}].gender`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="profile-card">
                                      <Select
                                        name={`looking_players[${index}].nationality`}
                                        placeholder="Nationality(s)"
                                        isMulti
                                        className="form-control"
                                        options={optionsNationality}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].nationality`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index]
                                            .nationality
                                        }
                                      />
                                      <ErrorMessage
                                        name={`looking_players[${index}].nationality`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <div className="profile-card mb-3">
                                      <Select
                                        name={`looking_players[${index}].nationality_id`}
                                        placeholder="Nationality ID"
                                        className="form-control"
                                        options={optionsNationaityId}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].nationality_id`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index]
                                            .nationality_id
                                        }
                                      />

                                      <ErrorMessage
                                        name={`looking_players[${index}].nationality_id`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="profile-card mb-3">
                                      <Select
                                        name={`looking_players[${index}].looking_players_team_league`}
                                        placeholder="Teams League"
                                        options={optionsTeamLeague}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].looking_players_team_league`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index]
                                            .looking_players_team_league
                                        }
                                      />
                                    </div>
                                    <ErrorMessage
                                      name={`looking_players[${index}].looking_players_team_league`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <div className="profile-card mb-3">
                                      <Select
                                        name={`looking_players[${index}].national_team_player`}
                                        placeholder="National Team Player"
                                        options={optionsNationalTeam}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].national_team_player`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index]
                                            .national_team_player || ""
                                        }
                                      />

                                      <ErrorMessage
                                        name={`looking_players[${index}].national_team_player`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={4}>
                                    <div className="profile-card">
                                      <Select
                                        name={`looking_players[${index}].height`}
                                        placeholder="Height"
                                        className="form-control"
                                        options={optionsHeight}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].height`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index].height
                                        }
                                      />
                                      <ErrorMessage
                                        name={`looking_players[${index}].height`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="profile-card">
                                      <Select
                                        name={`looking_players[${index}].foot`}
                                        placeholder="Foot"
                                        className="form-control"
                                        options={optionsFoot}
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].foot`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index].foot ||
                                          ""
                                        }
                                      />
                                      <ErrorMessage
                                        name={`looking_players[${index}].foot`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="profile-card">
                                      <Select
                                        className="form-control"
                                        name={`looking_players[${index}].languages`}
                                        options={optionsLanguage}
                                        placeholder="Languages"
                                        isMulti
                                        onChange={(option) =>
                                          setFieldValue(
                                            `looking_players[${index}].languages`,
                                            option
                                          )
                                        }
                                        value={
                                          values.looking_players[index]
                                            .languages
                                        }
                                      />
                                      <ErrorMessage
                                        name={`looking_players[${index}].languages`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="common-outer mt-4">
                                  <FieldArray
                                    name={`looking_players[${index}].looking_players_carrier_trophies`}
                                  >
                                    {({ push, remove, form }) => (
                                      <>
                                        <div className="d-flex justify-content-between">
                                          <h3 className="h-20">
                                            Carrier Trophies
                                          </h3>
                                          <button
                                            style={{
                                              border: "none",
                                              background: "none",
                                            }}
                                            type="button"
                                            onClick={() =>
                                              push({
                                                trophy: null,
                                              })
                                            }
                                          >
                                            <svg
                                              width="28"
                                              height="28"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                                fill="#4C8BB4"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                        {form.values.looking_players[
                                          index
                                        ].looking_players_carrier_trophies.map(
                                          (trophy, trophyIndex) => (
                                            <div
                                              key={index}
                                              className="cmn-form-fields mt-2"
                                            >
                                              <Row>
                                                <Col lg={4}>
                                                  <div className="profile-card mb-3">
                                                    <Select
                                                      name={`looking_players[${index}].looking_players_carrier_trophies[${trophyIndex}].trophy`}
                                                      placeholder="Tropies/Champanioship"
                                                      options={optionsTrophy}
                                                      isMulti
                                                      onChange={(option) =>
                                                        setFieldValue(
                                                          `looking_players[${index}].looking_players_carrier_trophies[${trophyIndex}].trophy`,
                                                          option
                                                        )
                                                      }
                                                      value={
                                                        values.looking_players[
                                                          index
                                                        ]
                                                          .looking_players_carrier_trophies[
                                                          trophyIndex
                                                        ].trophy &&
                                                        Array.isArray(
                                                          values
                                                            .looking_players[
                                                            index
                                                          ]
                                                            .looking_players_carrier_trophies[
                                                            trophyIndex
                                                          ].trophy
                                                        )
                                                          ? values.looking_players[
                                                              index
                                                            ].looking_players_carrier_trophies[
                                                              trophyIndex
                                                            ].trophy.map(
                                                              (trophy) => ({
                                                                label:
                                                                  trophy.label,
                                                                value:
                                                                  trophy.value,
                                                              })
                                                            )
                                                          : values
                                                              .looking_players[
                                                              index
                                                            ]
                                                              .looking_players_carrier_trophies[
                                                              trophyIndex
                                                            ].trophy
                                                          ? {
                                                              label:
                                                                values
                                                                  .looking_players[
                                                                  index
                                                                ]
                                                                  .looking_players_carrier_trophies[
                                                                  trophyIndex
                                                                ].trophy.label,
                                                              value:
                                                                values
                                                                  .looking_players[
                                                                  index
                                                                ]
                                                                  .looking_players_carrier_trophies[
                                                                  trophyIndex
                                                                ].trophy.value,
                                                            }
                                                          : null
                                                      }
                                                    />
                                                  </div>
                                                </Col>

                                                <Col lg={4}>
                                                  {trophyIndex !== 0 && (
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        remove(trophyIndex)
                                                      }
                                                      style={{
                                                        border: "none",
                                                        background: "none",
                                                      }}
                                                    >
                                                      <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          fill-rule="evenodd"
                                                          clip-rule="evenodd"
                                                          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                          fill="#4C8BB4"
                                                        />
                                                      </svg>
                                                    </button>
                                                  )}
                                                </Col>
                                              </Row>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                  </FieldArray>
                                </div>

                                <div className="common-outer mt-4">
                                  <div className="d-flex justify-content-between">
                                    <h3 className="h-20">Playing Position</h3>
                                  </div>
                                  <Row>
                                    <Col lg={4}>
                                      <div className="profile-card mb-3">
                                        <Select
                                          name={`looking_players[${index}].positions`}
                                          placeholder="Position(s)"
                                          className="form-control"
                                          options={optionsPosition}
                                          isMulti
                                          onChange={(option) =>
                                            setFieldValue(
                                              `looking_players[${index}].positions`,
                                              option
                                            )
                                          }
                                          value={
                                            values.looking_players[index]
                                              .positions
                                          }
                                        />
                                        <ErrorMessage
                                          name={`looking_players[${index}].positions`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="common-outer mt-4">
                                  <div className="d-flex justify-content-between">
                                    <h3 className="h-20">
                                      Availability Status
                                    </h3>
                                  </div>
                                  <Row>
                                    <Col lg={4}>
                                      <div className="profile-card mb-3">
                                        <Select
                                          name={`looking_players[${index}].looking_players_availability_status`}
                                          placeholder="Status"
                                          options={optionsPlayerStatus}
                                          isMulti
                                          onChange={(option) =>
                                            setFieldValue(
                                              `looking_players[${index}].looking_players_availability_status`,
                                              option
                                            )
                                          }
                                          value={
                                            values.looking_players[index]
                                              .looking_players_availability_status
                                          }
                                        />
                                        <ErrorMessage
                                          name={`looking_players[${index}].looking_players_availability_status`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="common-outer mt-4">
                                  <div className="d-flex justify-content-between">
                                    <h3 className="h-20">Other Notes</h3>
                                  </div>
                                  <Row>
                                    <Col lg={12}>
                                      <div className="profile-card">
                                        <Field
                                          as="textarea"
                                          name={`looking_players[${index}].looking_players_other_notes`}
                                          placeholder="Write here"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  )}

                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button type="submit">Next</button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default TeamAgentRelationship;
