import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Select from "react-select";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import { staticDataAction } from "../Redux/Action/staticAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import { getTeamListAction } from "../Redux/Action/teamAction";
import { getAgentListAction } from "../Redux/Action/agentAction";
import ProgressBar from "../CommonModals/ProgressBar";

const Proposals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));

  const teamOptions = teanListingData?.map((elem) => ({
    label: elem?.team_name,
    value: elem?._id,
  }));

  const [isProposed, setIsProposed] = useState(false);
  const [isInterested, setIsInterested] = useState(false);

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const validate = (values) => {
    const errors = {};
    if (values.is_player_proposed) {
      const proposedTeamErrors = {};

      values.proposed_team?.forEach((team, index) => {
        const teamErrors = {};

        if (team.proposed_team_id === "") {
          teamErrors.proposed_team_id = "required";
        }
        if (!team.proposed_team_country) {
          teamErrors.proposed_team_country = "required";
        }
        if (!team.proposed_team_league) {
          teamErrors.proposed_team_league = "required";
        }

        if (
          team.is_agent_proposed_player_to_team !== 0 &&
          team.is_agent_proposed_player_to_team !== 1
        ) {
          teamErrors.is_agent_proposed_player_to_team = "required";
        }

        if (team.is_agent_proposed_player_to_team === 1) {
          if (team.proposed_associate_agent_id === "") {
            teamErrors.proposed_associate_agent_id = "required";
          }
        }

        if (Object.keys(teamErrors).length > 0) {
          proposedTeamErrors[index] = teamErrors;
        }
      });

      if (Object.keys(proposedTeamErrors).length > 0) {
        errors.proposed_team = proposedTeamErrors;
      }
    } else {
      errors.is_player_proposed = "required";
    }

    if (values.is_team_interested_to_player) {
      values.interested_team?.forEach((team, index) => {
        if (team.interested_team_id === "") {
          if (!errors.interested_team) errors.interested_team = [];
          errors.interested_team[index] = {
            interested_team_id: "required",
          };
        }
        if (!team.interested_team_country) {
          if (!errors.interested_team) errors.interested_team = [];
          errors.interested_team[index] = {
            ...errors.interested_team[index],
            interested_team_country: "required",
          };
        }
        // if (!team.interested_team_league) {
        //   if (!errors.interested_team) errors.interested_team = [];
        //   errors.interested_team[index] = {
        //     ...errors.interested_team[index],
        //     interested_team_league: "required",
        //   };
        // }
      });
    } else {
      errors.is_team_interested_to_player = "required";
    }
    console.log(errors, "errrrrr");
    return errors;
  };

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const optionsCountry = staticListForPlayer?.place_of_birth?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsLeague = staticListForPlayer?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
    dispatch(getTeamListAction());
    dispatch(getAgentListAction());
  }, [dispatch]);

  console.log(playerData, "fdgwereeeeeeeeee");

  const handleBack = () => {
    navigate(`/contract/${Encryptedid(paylerId)}`);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="box-container">
            <ProgressBar currentStep={4} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 4:</span> Proposals
              </p>
            </div>
            <Formik
              initialValues={{
                is_player_proposed: playerData?.is_player_proposed || 0,
                proposed_team:
                  playerData?.proposed_team?.length > 0
                    ? playerData?.proposed_team
                    : [
                        {
                          proposed_team_id: "",
                          proposed_team_country: null,
                          proposed_team_league: null,
                          is_agent_proposed_player_to_team: null,
                          proposed_associate_agent_id: "",
                          proposed_associate_agent_note: "",
                        },
                      ],

                is_team_interested_to_player:
                  playerData?.is_team_interested_to_player || 0,
                interested_team:
                  playerData?.interested_team?.length > 0
                    ? playerData?.interested_team
                    : [
                        {
                          interested_team_id: "",
                          interested_team_country: "",
                          interested_team_league: "",
                          interested_team_notes: "",
                        },
                      ],
              }}
              enableReinitialize={true}
              // validate={validate}
              // onSubmit={(values) => {
              //   let formData = new FormData();

              //   Object.entries(values).forEach(([key, value]) => {
              //     if (key !== "proposed_team" && key !== "interested_team") {
              //       formData.append(key, value);
              //     }
              //   });

              //   formData.append(
              //     "proposed_team",
              //     JSON.stringify(values.proposed_team)
              //   );
              //   formData.append(
              //     "interested_team",
              //     JSON.stringify(values.interested_team)
              //   );

              //   formData.append("playerId", paylerId);
              //   formData.append("is_profile_completed", 5);

              //   dispatch(editPlayerAction(formData)).then((res) => {
              //     if (res?.payload?.status === 200) {
              //       navigate(
              //         `/statistics/${Encryptedid(res?.payload?.data?._id)}`
              //       );
              //     } else {
              //       toast.error(res?.payload?.message);
              //     }
              //   });
              // }}
              onSubmit={(values) => {
                let formData = new FormData();

                console.log(values, "fghfghfghfghfgh");

                Object.entries(values).forEach(([key, value]) => {
                  if (key !== "proposed_team" && key !== "interested_team") {
                    if (
                      typeof value === "object" &&
                      value !== null &&
                      !Array.isArray(value)
                    ) {
                      formData.append(key, value.value || value);
                    } else {
                      formData.append(key, value);
                    }
                  }
                });

                if (values.is_player_proposed == 1) {
                  formData.append(
                    "proposed_team",
                    JSON.stringify(
                      values.proposed_team.map((team) => {
                        const teamData = {
                          proposed_team_id: team.proposed_team_id,
                          proposed_team_country: team.proposed_team_country,
                          proposed_team_league: team.proposed_team_league,
                          is_agent_proposed_player_to_team:
                            team.is_agent_proposed_player_to_team,
                          proposed_associate_agent_note:
                            team.proposed_associate_agent_note,
                        };

                        if (team.is_agent_proposed_player_to_team !== 0) {
                          teamData.proposed_associate_agent_id =
                            team.proposed_associate_agent_id;
                        }

                        return teamData;
                      })
                    )
                  );
                }

                if (values.is_team_interested_to_player == 1) {
                  formData.append(
                    "interested_team",
                    JSON.stringify(
                      values.interested_team.map((team) => ({
                        interested_team_id: team.interested_team_id,
                        interested_team_country: team.interested_team_country,
                        interested_team_league: team.interested_team_league,
                        interested_team_notes: team.interested_team_notes,
                      }))
                    )
                  );
                }

                formData.append("playerId", paylerId);
                formData.append("is_profile_completed", 4);

                dispatch(editPlayerAction(formData)).then((res) => {
                  if (res?.payload?.status === 200) {
                    navigate(
                      `/statistics/${Encryptedid(res?.payload?.data?._id)}`
                    );
                  } else {
                    toast.error(res?.payload?.message);
                  }
                });
              }}
            >
              {({ setFieldValue, values, errors, touched }) => (
                <Form>
                  {/* Proposed to Team Section */}
                  {console.log(values, "wserwerwerwerwe")}

                  <div className="pro-posals-ps">
                    <div className="pro-posals-left mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">
                          Teams that the player has been proposed
                        </h3>
                      </div>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <div className="licensed-sec">
                            <h3>Has the player been proposed to team</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="is_player_proposed"
                                checked={values.is_player_proposed === 1}
                                onChange={() => {
                                  const newValue =
                                    values.is_player_proposed === 1 ? 0 : 1;
                                  setFieldValue("is_player_proposed", newValue);
                                  setIsProposed(newValue === 1);
                                }}
                              />
                            </div>
                          </div>
                        </Row>

                        <ErrorMessage
                          component="div"
                          name="is_player_proposed"
                          className="text-danger"
                        />

                        {values.is_player_proposed ? (
                          <FieldArray name="proposed_team">
                            {({ push, remove }) => (
                              <div className="mt-3">
                                <div className="d-flex justify-content-between">
                                  <h3 className="h-20 mt-2">Teams</h3>
                                  <button
                                    type="button"
                                    style={{
                                      border: "none",
                                      background: "none",
                                    }}
                                    onClick={() =>
                                      push({
                                        proposed_team_id: null,
                                        proposed_team_country: null,
                                        proposed_team_league: null,
                                        is_agent_proposed_player_to_team: null,
                                        // is_agent_proposed_player_to_team: 1,
                                        proposed_associate_agent_id: "",
                                        proposed_associate_agent_note: "",
                                      })
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433289 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091V14.5455C10.9091 14.7866 10.8133 15.0178 10.6428 15.1883C10.4723 15.3588 10.2411 15.4545 10 15.4545C9.7589 15.4545 9.52767 15.3588 9.35718 15.1883C9.18669 15.0178 9.09091 14.7866 9.09091 14.5455V10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091V5.45454C9.09091 5.21344 9.18669 4.98221 9.35718 4.81172C9.52767 4.64123 9.7589 4.54545 10 4.54545C10.2411 4.54545 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 10.9091 5.21344 10.9091 5.45454V9.09091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                        fill="#103D56"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {values.proposed_team.map((team, index) => (
                                  <div
                                    key={index}
                                    className="cmn-form-fields mt-2"
                                  >
                                    <div className="d-flex justify-content-end mt-2 mb-2">
                                      {index > 0 && (
                                        <div className="d-flex justify-content-between">
                                          <button
                                            type="button"
                                            style={{
                                              border: "none",
                                              background: "none",
                                            }}
                                            onClick={() => remove(index)}
                                          >
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761209 6.17316C0.0043329 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091H7.05411C7.05411 11.1502 11.0796 14.1381 10.9091 14.3086C8.95963 12.4289 8.69336 13.8086 10 15.4545C9.7589 15.4545 11.6021 13.1035 11.4316 12.933C11.2612 12.7625 10.6934 14.0477 10.6934 13.8066L9.09091 10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091L10.4316 6.80859C10.4316 6.56749 11.2612 6.97908 11.4316 6.80859C11.6021 6.63811 11.6905 6.30859 11.9316 6.30859C12.1727 6.30859 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 7.16322 8.8498 7.16322 9.09091H10.9091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                                fill="#103D56"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <Row>
                                      <Col lg={4}>
                                        <div className="form-group mb-3 form-inner">
                                          {/* <Field
                                          type="text"
                                          name={`proposed_team.${index}.proposed_team_id`}
                                          placeholder="Enter name"
                                          className={`form-control`}
                                        /> */}
                                          <Select
                                            className={`form-control`}
                                            // name={`proposed_team.${index}.proposed_team_id`}
                                            placeholder="Enter name"
                                            options={teamOptions}
                                            onChange={(option) =>
                                              setFieldValue(
                                                `proposed_team.${index}.proposed_team_id`,
                                                option ? option.value : ""
                                              )
                                            }
                                            // value={
                                            //   values.proposed_team[index]
                                            //     .proposed_team_id || ""
                                            // }
                                            value={
                                              teamOptions?.find(
                                                (elem) =>
                                                  elem.value ==
                                                  values.proposed_team[index]
                                                    .proposed_team_id
                                              ) || null
                                            }
                                          />
                                          <ErrorMessage
                                            name={`proposed_team[${index}].proposed_team_id`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={4}>
                                        <div className="form-group mb-3 form-inner">
                                          <Select
                                            options={optionsCountry}
                                            placeholder="Select Team’s Country"
                                            onChange={(option) =>
                                              setFieldValue(
                                                `proposed_team.${index}.proposed_team_country`,
                                                option ? option.value : ""
                                              )
                                            }
                                            value={
                                              optionsCountry?.find(
                                                (opt) =>
                                                  opt.value ===
                                                  values.proposed_team[index]
                                                    .proposed_team_country
                                              ) || null
                                            }
                                          />
                                          {touched.proposed_team?.[index]
                                            ?.proposed_team_country &&
                                            errors.proposed_team?.[index]
                                              ?.proposed_team_country && (
                                              <div className="invalid-feedback d-block">
                                                {
                                                  errors.proposed_team[index]
                                                    .proposed_team_country
                                                }
                                              </div>
                                            )}
                                        </div>
                                      </Col>
                                      <Col lg={4}>
                                        <div className="form-group mb-3 form-inner">
                                          <Select
                                            options={optionsLeague}
                                            placeholder="Select Team League"
                                            onChange={(option) =>
                                              setFieldValue(
                                                `proposed_team.${index}.proposed_team_league`,
                                                option ? option.value : ""
                                              )
                                            }
                                            value={
                                              optionsLeague?.find(
                                                (opt) =>
                                                  opt.value ===
                                                  values.proposed_team[index]
                                                    .proposed_team_league
                                              ) || null
                                            }
                                          />
                                          {touched.proposed_team?.[index]
                                            ?.proposed_team_league &&
                                            errors.proposed_team?.[index]
                                              ?.proposed_team_league && (
                                              <div className="invalid-feedback d-block">
                                                {
                                                  errors.proposed_team[index]
                                                    .proposed_team_league
                                                }
                                              </div>
                                            )}
                                        </div>
                                      </Col>
                                      <Col lg={12} className="mt-2">
                                        {/* Agent Proposed Player Section */}
                                        <div className="mt-3">
                                          <div className="d-flex justify-content-between">
                                            <h3 className="h-20">
                                              Agent Proposed Player to this team
                                            </h3>
                                          </div>
                                          <div className="cmn-form-fields mt-2">
                                            <Row>
                                              <Col lg={6}>
                                                <div className="licensed-sec mb-3">
                                                  <h3>Me</h3>
                                                  <div className="form-check form-switch">
                                                    <Field
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      role="switch"
                                                      // name="proposed_team[0].is_agent_proposed_player_to_team"
                                                      name={`proposed_team[${index}].is_agent_proposed_player_to_team`}
                                                      value={
                                                        values.proposed_team[
                                                          index
                                                        ]
                                                          .is_agent_proposed_player_to_team
                                                      }
                                                      checked={
                                                        values.proposed_team[
                                                          index
                                                        ]
                                                          .is_agent_proposed_player_to_team ===
                                                        0
                                                      }
                                                      onChange={() =>
                                                        setFieldValue(
                                                          `proposed_team[${index}].is_agent_proposed_player_to_team`,
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .is_agent_proposed_player_to_team ===
                                                            0
                                                            ? 1
                                                            : 0
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <ErrorMessage
                                                  name={`proposed_team.${index}.is_agent_proposed_player_to_team`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Col>
                                              <Col lg={6}>
                                                <div className="licensed-sec">
                                                  <h3>The Associate Agent</h3>
                                                  <div className="form-check form-switch">
                                                    <Field
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      role="switch"
                                                      name={`proposed_team[${index}].is_agent_proposed_player_to_team`}
                                                      checked={
                                                        values.proposed_team[
                                                          index
                                                        ]
                                                          .is_agent_proposed_player_to_team ===
                                                        1
                                                      }
                                                      value={
                                                        values.proposed_team[
                                                          index
                                                        ]
                                                          .is_agent_proposed_player_to_team
                                                      }
                                                      onChange={() =>
                                                        setFieldValue(
                                                          `proposed_team.${index}.is_agent_proposed_player_to_team`,
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .is_agent_proposed_player_to_team ===
                                                            1
                                                            ? 0
                                                            : 1
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>

                                        {values.proposed_team[index]
                                          .is_agent_proposed_player_to_team ===
                                          1 && (
                                          <div className=" mt-3">
                                            <div className="d-flex justify-content-between">
                                              <h3 className="h-20">
                                                Name of Associate Agent
                                              </h3>
                                            </div>
                                            <Row>
                                              <Col lg={6}>
                                                <div className="form-group mb-3 form-inner mt-2">
                                                  {/* <Field
                                                  type="text"
                                                  name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                  placeholder="Enter Associate Agent Name"
                                                  className={`form-control `}
                                                /> */}

                                                  <Select
                                                    className={`form-control`}
                                                    name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                    placeholder="Enter name"
                                                    options={
                                                      associateAgentOptions
                                                    }
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `proposed_team.${index}.proposed_associate_agent_id`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    // value={
                                                    //   values.proposed_team[index]
                                                    //     .proposed_associate_agent_id ||
                                                    //   ""
                                                    // }
                                                    value={associateAgentOptions?.find(
                                                      (elem) =>
                                                        elem.value ==
                                                        values.proposed_team[
                                                          index
                                                        ]
                                                          .proposed_associate_agent_id
                                                    )}
                                                  />
                                                  <ErrorMessage
                                                    name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg={6}>
                                                <div className="form-group mb-3 form-inner">
                                                  <h3
                                                    style={{
                                                      visibility: "none",
                                                    }}
                                                  ></h3>
                                                  <Field
                                                    type="text"
                                                    name={`proposed_team.${index}.proposed_associate_agent_note`}
                                                    placeholder="Enter Notes"
                                                    className={`form-control`}
                                                  />
                                                  <ErrorMessage
                                                    name={`proposed_team.${index}.proposed_associate_agent_note`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="pro-posals-right mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">
                          Teams that are interested for the players
                        </h3>
                      </div>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <div className="licensed-sec">
                            <h3>Any team interested for players?</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="is_team_interested_to_player"
                                checked={
                                  values.is_team_interested_to_player === 1
                                }
                                onChange={() => {
                                  const newValue =
                                    values.is_team_interested_to_player === 1
                                      ? 0
                                      : 1;
                                  setFieldValue(
                                    "is_team_interested_to_player",
                                    newValue
                                  );
                                  setIsInterested(newValue === 1);
                                }}
                              />
                            </div>
                          </div>
                          <ErrorMessage
                            component="div"
                            name="is_team_interested_to_player"
                            className="text-danger"
                          />
                        </Row>

                        {values.is_team_interested_to_player ? (
                          <div className=" mt-3">
                            <FieldArray name="interested_team">
                              {({ push, remove }) => (
                                <div>
                                  <div className="d-flex justify-content-between">
                                    <h3 className="h-20 mt-2">Teams</h3>
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() =>
                                        push({
                                          interested_team_id: "",
                                          interested_team_country: "",
                                          interested_team_league: "",
                                          interested_team_notes: "",
                                        })
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433289 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091V14.5455C10.9091 14.7866 10.8133 15.0178 10.6428 15.1883C10.4723 15.3588 10.2411 15.4545 10 15.4545C9.7589 15.4545 9.52767 15.3588 9.35718 15.1883C9.18669 15.0178 9.09091 14.7866 9.09091 14.5455V10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091V5.45454C9.09091 5.21344 9.18669 4.98221 9.35718 4.81172C9.52767 4.64123 9.7589 4.54545 10 4.54545C10.2411 4.54545 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 10.9091 5.21344 10.9091 5.45454V9.09091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                          fill="#103D56"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                  {values.interested_team.map((team, index) => (
                                    <>
                                      <Col lg={12}>
                                        {" "}
                                        <div className="d-flex justify-content-end mt-2 ">
                                          {index !== 0 && (
                                            <button
                                              type="button"
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              onClick={() => remove(index)}
                                              className="btn btn-danger"
                                            >
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761209 6.17316C0.0043329 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091H7.05411C7.05411 11.1502 11.0796 14.1381 10.9091 14.3086C8.95963 12.4289 8.69336 13.8086 10 15.4545C9.7589 15.4545 11.6021 13.1035 11.4316 12.933C11.2612 12.7625 10.6934 14.0477 10.6934 13.8066L9.09091 10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091L10.4316 6.80859C10.4316 6.56749 11.2612 6.97908 11.4316 6.80859C11.6021 6.63811 11.6905 6.30859 11.9316 6.30859C12.1727 6.30859 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 7.16322 8.8498 7.16322 9.09091H10.9091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                                  fill="#103D56"
                                                />
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                      </Col>

                                      <div key={index} className=" mt-2">
                                        <Row>
                                          <Col lg={6}>
                                            <div className="form-group mb-3 form-inner">
                                              {/* <Field
                                          type="text"
                                          name={`interested_team.${index}.interested_team_id`}
                                          placeholder="Team Name"
                                          className={`form-control`}
                                        /> */}

                                              <Select
                                                className={`form-control`}
                                                name={`interested_team.${index}.interested_team_id`}
                                                placeholder="Team Name"
                                                options={teamOptions}
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `interested_team.${index}.interested_team_id`,
                                                    option ? option.value : ""
                                                  )
                                                }
                                                // value={
                                                //   values.interested_team[index]
                                                //     .interested_team_id || ""
                                                // }
                                                value={teamOptions?.find(
                                                  (elem) =>
                                                    elem.value ==
                                                    values.interested_team[
                                                      index
                                                    ].interested_team_id
                                                )}
                                              />

                                              <ErrorMessage
                                                name={`interested_team.${index}.interested_team_id`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </Col>

                                          <Col lg={6}>
                                            <div className="form-group mb-3 form-inner">
                                              <Select
                                                options={optionsCountry}
                                                placeholder="Select Team’s Country"
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `interested_team.${index}.interested_team_country`,
                                                    option ? option.value : ""
                                                  )
                                                }
                                                value={
                                                  optionsCountry?.find(
                                                    (opt) =>
                                                      opt.value ===
                                                      values.interested_team[
                                                        index
                                                      ].interested_team_country
                                                  ) || null
                                                }
                                              />
                                              {touched.interested_team?.[index]
                                                ?.interested_team_country &&
                                                errors.interested_team?.[index]
                                                  ?.interested_team_country && (
                                                  <div className="invalid-feedback d-block">
                                                    {
                                                      errors.interested_team[
                                                        index
                                                      ].interested_team_country
                                                    }
                                                  </div>
                                                )}
                                            </div>
                                          </Col>

                                          <Col lg={6}>
                                            <div className="form-group mb-3 form-inner">
                                              <Select
                                                options={optionsLeague}
                                                placeholder="Select Team League"
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `interested_team[${index}].interested_team_league`,
                                                    option ? option.value : ""
                                                  )
                                                }
                                                value={
                                                  optionsLeague?.find(
                                                    (opt) =>
                                                      opt.value ===
                                                      values.interested_team[
                                                        index
                                                      ].interested_team_league
                                                  ) || null
                                                }
                                              />
                                              {/* {touched.interested_team?.[index]
                                          ?.interested_team_league &&
                                          errors.interested_team?.[index]
                                            ?.interested_team_league && (
                                            <div className="invalid-feedback d-block">
                                              {
                                                errors.interested_team[index]
                                                  .interested_team_league
                                              }
                                            </div>
                                          )} */}
                                            </div>
                                          </Col>

                                          <Col lg={6}>
                                            <div className="form-group mb-3 form-inner">
                                              <Field
                                                type="text"
                                                name={`interested_team.${index}.interested_team_notes`}
                                                placeholder="Notes"
                                                className={`form-control ${
                                                  touched.interested_team?.[
                                                    index
                                                  ]?.interested_team_notes &&
                                                  errors.interested_team?.[
                                                    index
                                                  ]?.interested_team_notes
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                              />
                                              <ErrorMessage
                                                name={`interested_team[${index}].interested_team_notes`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button
                        className="back-btn-ps"
                        type="button"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                      <button className="next-btn-ps" type="submit">
                        Next
                      </button>
                    </div>
                  </Col>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default Proposals;
