import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// home listing add ptofile
export const homeListAction = createAsyncThunk(
  "homeListAction/user/homeScreen",
  async () => {
    const response = await api.get("/user/homeScreen");
    return response.data;
  }
);
