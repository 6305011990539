import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import "react-tabs/style/react-tabs.css";
import {
  GlobalfilterKeyAgent,
  GlobalfilterKeyCoach,
  GlobalfilterKeyDirector,
  GlobalfilterKeyPlayers,
} from "../utils/rawjson";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  staticDataAction,
  staticDirectorDataAction,
} from "../Redux/Action/staticAction";
import { getYesterday } from "../utils/helper";
import { Country, State } from "country-state-city";
import { setActiveTab, setSelectedFilters, setSelectedFilters1, setSelectedFilters2, setSelectedFilters3 } from "../Redux/Reducers/globalSearchSlice";

export default function GlobalSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [selectedFilters, setSelectedFilters] = useState({});
  // const [selectedFilters1, setSelectedFilters1] = useState({});
  // const [selectedFilters2, setSelectedFilters2] = useState({});
  // const [selectedFilters3, setSelectedFilters3] = useState({});




  const [inputType, setInputType] = useState("text");

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);

  const selectedFilters = useSelector(state => state.globalSearchAuthData.selectedFilters || {});
  const selectedFilters1 = useSelector(state => state.globalSearchAuthData.selectedFilters1 || {});
  const selectedFilters2 = useSelector(state => state.globalSearchAuthData.selectedFilters2 || {});
  const selectedFilters3 = useSelector(state => state.globalSearchAuthData.selectedFilters3 || {});

  const activeTab = useSelector(state => state.globalSearchAuthData.activeTab);


  console.log(selectedFilters, "selectedFilters")

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const staticListForDirector = useSelector(
    (e) => e.staticAuthData.directorStaticDetail
  );

  const optionsGender = staticListForPlayer?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsNationaityId = staticListForPlayer?.nationality_id?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsLanguage = staticListForPlayer?.languages?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsHeight = staticListForPlayer?.hight?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const optionsFoot = staticListForPlayer?.foot?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPosition = staticListForPlayer?.positions?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsTeamLeague = staticListForPlayer?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsNationalTeam = staticListForPlayer?.national_team_player?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsTrophy = staticListForPlayer?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlayerStatus = staticListForPlayer?.availability_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsAskingAmount = staticListForPlayer?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsCurrentSalary = staticListForPlayer?.current_contract?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const handleClearFilters = () => {
    dispatch(setSelectedFilters({
      gender: null,
      dob: "",
      nationality_id: null,
      national_team_player: null,
      playing_positions: [],
      height: "",
      foot: [],
      languages: [],
      carrier_trophies: [],
      availability_status: [],
      annual_salary: "",
      team_amount: "",
      current_contract: "",
      asking_amount: [],
      team_league: "",
      positions: []
    }))

    dispatch(setSelectedFilters1({
      gender: null,
      dob: "",
      previous_status: [],
      coaching_licenses: [],
      languages: [],
      interested_team_league: [],
      trophy: [],
      availability_status: [],
      current_contract: [],
    }))

    setSelectedCountry(null);
    setSelectedCity(null);
    setCities([]); 
    dispatch(setSelectedFilters2({
      name: "",
      country: null,
      city: null,
    }))


    dispatch(setSelectedFilters3({
      gender: null,
      dob: "",
      previous_status: [],
      coaching_licenses: [],
      languages: [],
      interested_team_league: [],
      trophy: [],
      availability_status: [],
      current_contract: [],
    }))
  };

  const optionsForFilters = {
    gender: optionsGender,
    nationality_id: optionsNationaityId,
    foot: optionsFoot,
    height: optionsHeight,
    languages: optionsLanguage,
    team_league: optionsTeamLeague,
    positions: optionsPosition,
    national_team_player: optionsNationalTeam,
    carrier_trophies: optionsTrophy,
    availability_status: optionsPlayerStatus,
    asking_amount: optionsAskingAmount,
    current_contract: optionsCurrentSalary,
  };

  const handleDateChange = (dateValue) => {
    dispatch(setSelectedFilters({
      dob: dateValue,
    }))
  };

  const handleSelectChange = (key, selectedOption) => {
    dispatch(setSelectedFilters({
      ...selectedFilters,
      [key]: selectedOption,
    }))
  };

  const renderSelect = (key) => {
    const filterKey = GlobalfilterKeyPlayers[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "dob":
        return (
          <input
            name="dob"
            className="accordion-content form-control"
            placeholder="Choose Date of Birth"
            type={inputType}
            value={selectedFilters[filterKey] || ""}
            onChange={(e) => handleDateChange(e.target.value)}
            max={getYesterday()}
            onFocus={() => setInputType("date")}
            onBlur={() => setInputType("text")}
          />
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${GlobalfilterKeyPlayers[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            value={selectedFilters[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  useEffect(() => {
    //for player
    dispatch(staticDataAction());
    // for director
    dispatch(staticDirectorDataAction());
  }, [dispatch]);

  const transformFiltersToPayload = (filters) => {
    const payload = {
      gender: filters.gender?.value ?? null,
      dob: filters.dob,
      nationality_id: filters.nationality_id?.value,
      team_league: filters.team_league?.value,
      national_team_player:
        filters.national_team_player?.map((item) => item.value) || [],
      playing_positions: filters.positions?.map((item) => item.value) || [],
      height: filters.height?.value,
      foot: filters.foot?.value,
      languages: filters.languages?.map((item) => item.value) || [],
      carrier_trophies:
        filters.carrier_trophies?.map((item) => item.value) || [],
      availability_status:
        filters.availability_status?.map((item) => item.value) || [],
      annual_salary: filters.current_contract?.value,
      team_amount: filters.asking_amount?.value,
    };

    return Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0)
      )
    );
  };

  // const handleSearch = async () => {
  //   try {
  //     const payload = transformFiltersToPayload(selectedFilters);

  //     dispatch(globalPlayerSearchAction(payload)).then((res) => {
  //       console.log(res);
  //       if (res?.payload?.status === 200) {
  //         navigate(`/global-search/${encodeURIComponent(res?.payload?.message)}`);
  //       }
  //     });

  //     console.log(payload, "SDfsdfsdfsdfsdfds");
  //   } catch (error) {
  //     console.error("Error fetching search results:", error);
  //   }
  // };

  const handleSearch = async () => {
    try {
      const payload = transformFiltersToPayload(selectedFilters);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) =>
                  `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");
      console.log(payload, "dfgdfgdfgdfgdfgdfg", queryString);

      navigate(`/global-search-result?type=player&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  const optionsCoachingLicense = staticListForDirector?.coaching_licenses?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsPreciousStatus =
    staticListForDirector?.interested_team_league?.map((item, i) => ({
      label: item?.val,
      value: i,
    }));

  const optionsGenderDirec = staticListForDirector?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  // for director filter

  const optionsForFiltersDirector = {
    gender: optionsGenderDirec,
    previous_status: optionsPreciousStatus, // MS
    coaching_licenses: optionsCoachingLicense,
    languages: optionsLanguage, // MS
    interested_team_league: optionsTeamLeague,
    trophy: optionsTrophy, //MS
    availability_status: optionsPlayerStatus, //MS
    current_contract: optionsCurrentSalary,
  };

  const renderSelectDirector = (key) => {
    const filterKey = GlobalfilterKeyDirector[key].value;
    const isMultiSelect = [
      "previous_status",
      "languages",
      "trophy",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "dob":
        return (
          <input
            name="dob"
            className="accordion-content form-control"
            placeholder="Choose Date of Birth"
            type={inputType}
            value={selectedFilters1[filterKey] || ""}
            onChange={(e) => handleDateChange1(e.target.value)}
            max={getYesterday()}
            onFocus={() => setInputType("date")}
            onBlur={() => setInputType("text")}
          />
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${GlobalfilterKeyDirector[key].label}`}
            options={optionsForFiltersDirector[filterKey] || []}
            onChange={(option) => handleSelectChange1(filterKey, option)}
            value={selectedFilters1[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const handleDateChange1 = (dateValue) => {
    dispatch(setSelectedFilters1({
      dob: dateValue,
    }))
  };

  const handleSelectChange1 = (key, selectedOption) => {
    dispatch(setSelectedFilters1({
      ...selectedFilters1,
      [key]: selectedOption,
    }))
  };

  const transformFiltersToPayloadForDirector = (filters) => {
    const payload = {
      gender: filters.gender?.value ?? null,
      dob: filters.dob,
      previous_status: filters.previous_status?.map((item) => item.value) || [],
      coaching_licenses: filters.coaching_licenses?.value,
      languages: filters.languages?.map((item) => item.value) || [],
      interested_team_league: filters.interested_team_league?.value,
      trophy: filters.trophy?.map((item) => item.value) || [],
      availability_status:
        filters.availability_status?.map((item) => item.value) || [],
      current_contract: filters.current_contract?.value,
    };

    return Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0)
      )
    );
  };

  const handleSearchForDirector = async () => {
    try {
      const payload = transformFiltersToPayloadForDirector(selectedFilters1);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/global-search-result?type=director&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  // for agent filter
  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  const fetchCities = async (countryCode) => {
    setLoadingCities(true);
    const stateList = State.getStatesOfCountry(countryCode);
    const cities = stateList.map((state) => {
      cityMap[state.isoCode] = state.name;
      return {
        value: state.isoCode,
        label: state.name,
      };
    });
    setCities(cities);
    setCityMap(cityMap);
    setLoadingCities(false);
  };
  
  // Handle country change
  const handleCountryChange = async (selectedOption) => {
    if (!selectedOption) return;
    
    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    
    if (countryData) {
      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });
      dispatch(setSelectedFilters2(({
        countryVal : countryData.isoCode,
        country: countryData.name,
      })))
      setSelectedCity(null);
      setCities([]);
      await fetchCities(countryData.isoCode);
    }
  };

  const handleSelectChange2 = (name, e) => {
    dispatch(setSelectedFilters2({
      name: e.target.value,
    }))
  };


  useEffect(() => {
    if (selectedFilters2?.country && countries.length > 0) {
      const countryData = countries.find(
        (country) => country.value === selectedFilters2?.countryVal
      );
  
      if (countryData) {
        setSelectedCountry({
          value: countryData.value,
          label: countryData.label,
        });
  

        fetchCities(countryData.value);
      }
    }
  }, [selectedFilters2, countries]);
  

  useEffect(() => {
    if (selectedFilters2?.city && cities.length > 0) {
      const cityData = cities.find(
        (city) => city.label === selectedFilters2.City
      );
  
      if (cityData) {
        setSelectedCity(cityData);
      }
    }
  }, [selectedFilters2?.city, cities]);
  
  
  
  
  
  
  
  

  const renderSelectAgent = (key) => {
    if (!GlobalfilterKeyAgent[key]) {
      return null;
    }

    const filterKey = GlobalfilterKeyAgent[key].value;

    switch (filterKey) {
      case "country":
        return (
          <Select
            isSearchable={true}
            value={selectedCountry || []}
            onChange={handleCountryChange}
            options={countries}
            placeholder="Country"
            className={`react-select`}
            name="country"
          />
        );

      case "city":
        return (
          <Select
            name="city"
            options={cities}
            isDisabled={loadingCities || !selectedCountry}
            isLoading={loadingCities}
            value={selectedCity}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setSelectedCity(selectedOption);
                dispatch(setSelectedFilters2(({
                  City: selectedOption.label,
                  city: selectedOption.value,
                })))
              }
            }}
            placeholder="city"
            className={`react-select`}
          />
        );

      default:
        return (
          <input
            className="accordion-content form-control"
            name={filterKey}
            placeholder={`${GlobalfilterKeyAgent[key].label}`}
            onChange={(e) => handleSelectChange2("name", e)}
            value={selectedFilters2[filterKey]}
          />
        );
    }
  };

  const handleSearchForAgent = async () => {
    try {
      const payload = selectedFilters2;

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key] !== ""
        )
        .map((key) => {
          const value = payload[key];
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/global-search-result?type=agent&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  // coach search

  const optionsForFiltersCoach = {
    gender: optionsGenderDirec,
    previous_status: optionsPreciousStatus, // MS
    coaching_licenses: optionsCoachingLicense,
    languages: optionsLanguage, // MS
    interested_team_league: optionsTeamLeague,
    trophy: optionsTrophy, //MS
    availability_status: optionsPlayerStatus, //MS
    current_contract: optionsCurrentSalary,
  };

  const handleDateChange2 = (dateValue) => {
    dispatch(setSelectedFilters3({
      dob: dateValue,
    }))
  };

  const handleSelectChange3 = (key, selectedOption) => {
    dispatch(setSelectedFilters3({
      ...selectedFilters3,
      [key]: selectedOption,
    }))
  };

  const renderSelectCoach = (key) => {
    const filterKey = GlobalfilterKeyCoach[key].value;
    const isMultiSelect = [
      "previous_status",
      "languages",
      "trophy",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "dob":
        return (
          <input
            name="dob"
            className="accordion-content form-control"
            placeholder="Choose Date of Birth"
            type={inputType}
            value={selectedFilters3[filterKey] || ""}
            onChange={(e) => handleDateChange2(e.target.value)}
            max={getYesterday()}
            onFocus={() => setInputType("date")}
            onBlur={() => setInputType("text")}
          />
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${GlobalfilterKeyCoach[key].label}`}
            options={optionsForFiltersCoach[filterKey] || []}
            onChange={(option) => handleSelectChange3(filterKey, option)}
            value={selectedFilters3[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const transformFiltersToPayloadForCoach = (filters) => {
    const payload = {
      gender: filters.gender?.value ?? null,
      dob: filters.dob,
      previous_status: filters.previous_status?.map((item) => item.value) || [],
      coaching_licenses: filters.coaching_licenses?.value,
      languages: filters.languages?.map((item) => item.value) || [],
      interested_team_league: filters.interested_team_league?.value,
      trophy: filters.trophy?.map((item) => item.value) || [],
      availability_status:
        filters.availability_status?.map((item) => item.value) || [],
      current_contract: filters.current_contract?.value,
    };

    return Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0)
      )
    );
  };

  const handleSearchForCoach = async () => {
    try {
      const payload = transformFiltersToPayloadForCoach(selectedFilters3);
      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/global-search-result?type=coach&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    dispatch(setActiveTab(activeTab));
  }, [dispatch, activeTab]);

  const handleTabSelect = (key) => {
    dispatch(setActiveTab(key));
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Global Search Filter</h2>
              <Link
                to="#"
                className="light-blue-btn"
                onClick={handleClearFilters}
              >
                Clear Filter
              </Link>
              {/* <p>{Object.keys(selectedFilters).length}</p> */}
            </div>
            <Tabs
            onSelect={handleTabSelect}
            activeKey={activeTab}
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Players" title="Players" className="accord-tabs">
                <div className="cmn-form-fields mt-2">
                  <Row>
                    {Object.keys(GlobalfilterKeyPlayers).map((key, index) => (
                      <Col lg={4} key={index}>
                        <div className="profile-card mt-3">
                          <div className="accordion-content">
                            {renderSelect(key)}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Col lg={12} className="d-flex justify-content-end mt-4">
                    <div className="schedule-btn">
                      <button type="button" onClick={handleSearch}>
                        Search
                      </button>
                    </div>
                  </Col>
                </div>
              </Tab>

              {/* directors */}
              <Tab eventKey="Directors" title="Directors">
                <div className="cmn-form-fields mt-2">
                  <Row>
                    {Object.keys(GlobalfilterKeyDirector).map((key, index) => (
                      <Col lg={4} key={index}>
                        <div className="profile-card mt-3">
                          <div className="accordion-content">
                            {renderSelectDirector(key)}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Col lg={12} className="d-flex justify-content-end mt-4">
                    <div className="schedule-btn">
                      <button type="button" onClick={handleSearchForDirector}>
                        Search
                      </button>
                    </div>
                  </Col>
                </div>
              </Tab>

              <Tab eventKey="Agents" title="Agents">
                <div className="cmn-form-fields mt-2">
                  <Row>
                    {Object.keys(GlobalfilterKeyDirector).map((key, index) => (
                      <Col lg={4} key={index}>
                        <div className="profile-card mt-3">
                          <div className="accordion-content">
                            {renderSelectAgent(key)}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Col lg={12} className="d-flex justify-content-end mt-4">
                    <div className="schedule-btn">
                      <button type="button" onClick={handleSearchForAgent}>
                        Search
                      </button>
                    </div>
                  </Col>
                </div>
              </Tab>

              <Tab eventKey="Coaches" title="Coaches">
                <div className="cmn-form-fields mt-2">
                  <Row>
                    {Object.keys(GlobalfilterKeyCoach).map((key, index) => (
                      <Col lg={4} key={index}>
                        <div className="profile-card mt-3">
                          <div className="accordion-content">
                            {renderSelectCoach(key)}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Col lg={12} className="d-flex justify-content-end mt-4">
                    <div className="schedule-btn">
                      <button type="button" onClick={handleSearchForCoach}>
                        Search
                      </button>
                    </div>
                  </Col>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
