import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteModal(props) {
  const handleYes = () => {
    props.onConfirm();
    props.onHide();
  };

  const handleNo = () => {
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      className="cmn-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="">
          <div className="container">
            <div className="form-inner text-center mt-2">
              <form>
              <h4 className="mb-4">Are you sure you want to delete this meeting?</h4>
                <div className="d-flex gap-2 justify-content-center">
                  <Button className="btn-outline " onClick={handleYes}>
                    Yes
                  </Button>
                  <Button className="btn-fill" onClick={handleNo}>
                    No
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
