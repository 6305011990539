import React from "react";
import { PuffLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader_page">
      <PuffLoader
        color="#3457D5"
        cssOverride={{
          width: "100px",
        }}
      />
      <span className="loading">Loading...</span>
    </div>
  );
};

export default Loader;
