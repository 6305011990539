import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Formik,
  Field,
  Form as FormikForm,
  ErrorMessage,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlayerAction,
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import { Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { deletePictureAction } from "../Redux/Action/directorAction";
import { clearPlayerDetail } from "../Redux/Reducers/playerSlice";

const AddPlayer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const validationSchema = Yup.object().shape({
    profile_image: Yup.mixed().required("required"),
    banner_image: Yup.mixed().required("required"),
    // photos:Yup.array()
    // .of(Yup.mixed().test(
    //   "file",
    //   "Invalid file format",
    //   (value) => !value || value instanceof File
    // ))
    // .min(1, "At least one photo is required")
    // .max(5, "You can upload up to 5 photos"),

    video_link: Yup.string()
      .url("Invalid URL")
      .required("Video link is required"),
  });

  const handleFileChange = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  const handleMultipleFileChange = (e, setFieldValue, values) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...values.photos, ...files];
    setFieldValue("photos", updatedFiles.slice(0, 5));
  };

  // const handleRemoveImage = (index, values, setFieldValue) => {
  //   const updatedFiles = values.photos.filter((_, i) => i !== index);
  //   setFieldValue("photos", updatedFiles);
  // };

  const handleRemoveImage = (index, values, setFieldValue) => {
    const updatedPhotos = [...values.photos];
    updatedPhotos[index] = null; // or set to empty string based on your need
    setFieldValue("photos", updatedPhotos);
  };

  const handleRemoveImage1 = (index, values, setFieldValue) => {
    let playerId = localStorage.getItem("playerId");
    const updatedFiles = values.photos.find((_, i) => i === index);

    dispatch(
      deletePictureAction({
        type: 1,
        pictureURL: updatedFiles,
        userId: playerId,
      })
    ).then((res) => {
      if (res?.payload?.success) {
        dispatch(getPlayerDataById({ id: playerId }));
      }
    });
  };

  const handleSubmit = (values) => {
    let playerId = localStorage.getItem("playerId");

    if (playerId) {
      const formData = new FormData();
      formData.append("profile_image", values.profile_image);
      formData.append("banner_image", values.banner_image);
      // values.photos.forEach((file, index) => {
      //   formData.append(`photos`, file);
      // });
      values.photos.forEach((file, index) => {
        if (file instanceof File) {
          formData.append(`photos`, file); // Use indexed key if needed by backend
        }
      });

      formData.append("video_link", values.video_link);
      formData.append("is_profile_completed", 1);
      formData.append("playerId", playerId);

      dispatch(editPlayerAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate(`/profile-info/${Encryptedid(res?.payload?.data?._id)}`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const formData = new FormData();
      formData.append("profile_image", values.profile_image);
      formData.append("banner_image", values.banner_image);
      // values.photos.forEach((file, index) => {
      //   formData.append(`photos`, file);
      // });

      values.photos.forEach((file) => {
        if (file && file instanceof File) {
          formData.append("photos", file);
        }
      });

      formData.append("video_link", values.video_link);
      formData.append("is_profile_completed", 1);

      dispatch(createPlayerAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate(`/profile-info/${Encryptedid(res?.payload?.data?._id)}`);
          localStorage.setItem("playerId", res?.payload?.data?._id);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const SingleImageUpload = ({ index }) => {
    const { setFieldValue, values } = useFormikContext();

    const colSize = index === 0 ? 4 : 3;

    const photo = values.photos && values.photos[index];

    return (
      <Col lg={colSize}>
        <div className="image-upload-main">
          <h3>{index === 0 ? "Photos" : ""}</h3>
          <div className="image-upload-inner">
            <div className="image-upload-placeholder">
              <img
                src={require("../Assets/images/upload.svg").default}
                alt="Upload Placeholder"
              />
              <h4>Upload Image</h4>
            </div>
            <input
              type="file"
              onChange={(e) =>
                handleFileChange(e, setFieldValue, `photos[${index}]`)
              }
            />
            {photo && typeof photo === "object" && photo instanceof File ? (
              <img
                src={URL.createObjectURL(photo)}
                className="prev-img"
                alt={`Photo ${index + 1} Preview`}
              />
            ) : photo && typeof photo === "string" ? (
              <img
                className="prev-img"
                src={`${process.env.REACT_APP_IMAGE_PATH}${photo}`}
                alt={`Photo ${index + 1} Preview`}
              />
            ) : null}

            {photo && typeof photo === "object" && photo instanceof File ? (
              <button
                type="button"
                onClick={() => handleRemoveImage(index, values, setFieldValue)}
              >
                <img
                  src={require("../Assets/images/delete.svg").default}
                  alt="Delete"
                />
              </button>
            ) : photo && typeof photo === "string" ? (
              <button
                type="button"
                onClick={() => handleRemoveImage1(index, values, setFieldValue)}
              >
                <img
                  src={require("../Assets/images/delete.svg").default}
                  alt="Delete"
                />
              </button>
            ) : null}

            {/* {photo && (
              <button
                type="button"
                onClick={() => handleRemoveImage(index, values, setFieldValue)}
              >
                <img
                  src={require("../Assets/images/delete.svg").default}
                  alt="Delete"
                />
              </button>
            )} */}
          </div>
        </div>
      </Col>
    );
  };

  useEffect(() => {
    let playerID = localStorage.getItem("playerId");
    if (playerID) {
      dispatch(getPlayerDataById({ id: playerID }));
    }
  }, [dispatch]);


  // to clear state 
  useEffect(() => {
    const playerID = localStorage.getItem("playerId");

    if (!playerID) {
      return () => {
        dispatch(clearPlayerDetail());
      };
    }
  }, [dispatch]);

  const initialValues = {
    profile_image: playerData?.profile_image || "",
    banner_image: playerData?.banner_image || "",
    // photos: playerData?.photos || [],
    // photos:
    //   playerData?.photos && playerData.photos.length === 5
    //     ? playerData.photos
    //     : Array(5).fill(null),
    photos:
      playerData?.photos && playerData.photos.length > 0
        ? [
            ...playerData.photos,
            ...Array(5 - playerData.photos.length).fill(null),
          ]
        : Array(5).fill(null),

    video_link: playerData?.video_link || "",
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Add Player</h2>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, touched, errors }) => {
                return (
                  <FormikForm> 
                    STEP 1
                    <div className="common-outer">
                      <Row>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Profile Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="profile_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "profile_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>

                              <div className="image-upload-placeholder">
                                {values?.profile_image &&
                                typeof values?.profile_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.profile_image &&
                                  values.profile_image instanceof File ? (
                                  <img
                                    src={
                                      values?.profile_image
                                        ? URL.createObjectURL(
                                            values?.profile_image
                                          )
                                        : ""
                                    }
                                    className="prev-img"
                                    alt="Profile Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              {values?.profile_image &&
                                (typeof values.profile_image === "string" ||
                                  values.profile_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("profile_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="profile_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Banner Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="banner_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <div className="image-upload-placeholder">
                                {values?.banner_image &&
                                typeof values.banner_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.banner_image &&
                                  values.banner_image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(
                                      values.banner_image
                                    )}
                                    className="prev-img"
                                    alt="Banner Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              {values?.banner_image &&
                                (typeof values.banner_image === "string" ||
                                  values.banner_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("banner_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="banner_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>

                        {values?.photos?.map((_, index) => (
                          <SingleImageUpload
                            key={index}
                            name="photos"
                            index={index}
                          />
                        ))}

                        {values.photos?.length < 5 && (
                          <Col lg={4}>
                            <div className="image-upload-main">
                              <h3>Photo</h3>
                              <div className="image-upload-inner">
                                <div className="image-upload-placeholder">
                                  <img
                                    src={
                                      require("../Assets/images/upload.svg")
                                        .default
                                    }
                                    alt="Upload Placeholder"
                                  />

                                  <h4>Upload Image</h4>
                                </div>
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    handleMultipleFileChange(
                                      e,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                />
                              </div>
                            </div>
                            {/* {errors.photos && touched.photos ? (
  <div className="text-danger">{errors.photos}</div>
) : null} */}
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="common-outer mt-4">
                            <div className="video-link">
                              <h3>Video Links</h3>
                              <Form.Group controlId="formBasicEmail">
                                <Field name="video_link">
                                  {({ field }) => (
                                    <Form.Control
                                      type="url"
                                      placeholder="Add video Link"
                                      {...field}
                                      name="video_link"
                                      value={values.video_link}
                                    />
                                  )}
                                </Field>
                                <Form.Text className="text-muted">
                                  *You can paste here any YouTube / Instagram
                                  video link
                                </Form.Text>
                              </Form.Group>
                            </div>
                            <ErrorMessage
                              name="video_link"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>

                        <Col
                          lg={12}
                          className="mt-4 d-flex justify-content-end"
                        >
                          <div className="cmn-light-btn">
                            <button type="submit">Next</button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddPlayer;
