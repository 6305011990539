import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Select from "react-select";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import { staticDataAction } from "../Redux/Action/staticAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import {
  editDirectorAction,
  getDirectorDataById,
} from "../Redux/Action/directorAction";
import {
  editCoachAction,
  getCoachesDataById,
} from "../Redux/Action/coachAction";
import { getTeamListAction } from "../Redux/Action/teamAction";
import { getAgentListAction } from "../Redux/Action/agentAction";

const CoachProposals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const coachID = id && Decryptedid(atob(id));

  const coachData = useSelector((e) => e.coachAuthData.coachDetail);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);

  const [isProposed, setIsProposed] = useState(false);
  const [isInterested, setIsInterested] = useState(false);

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const validate = (values) => {
    const errors = {};
    if (values.has_coach_proposed) {
      const proposedTeamErrors = {};

      values.proposed_team?.forEach((team, index) => {
        const teamErrors = {};

        if (team.proposed_team_id === "") {
          teamErrors.proposed_team_id = "required";
        }
        if (!team.proposed_team_country) {
          teamErrors.proposed_team_country = "required";
        }
        if (!team.proposed_team_league) {
          teamErrors.proposed_team_league = "required";
        }

        if (
          team.is_agent_proposed_coach !== 0 &&
          team.is_agent_proposed_coach !== 1
        ) {
          teamErrors.is_agent_proposed_coach = "required";
        }

        if (team.is_agent_proposed_coach === 1) {
          if (team.proposed_associate_agent_id === "") {
            teamErrors.proposed_associate_agent_id = "required";
          }
        }

        if (Object.keys(teamErrors).length > 0) {
          proposedTeamErrors[index] = teamErrors;
        }
      });

      if (Object.keys(proposedTeamErrors).length > 0) {
        errors.proposed_team = proposedTeamErrors;
      }
    } else {
      errors.has_coach_proposed = "required";
    }

    if (values.is_team_interested_to_director) {
      values.interested_team?.forEach((team, index) => {
        if (team.interested_team_id === "") {
          if (!errors.interested_team) errors.interested_team = [];
          errors.interested_team[index] = {
            interested_team_id: "required",
          };
        }
        if (!team.interested_team_country) {
          if (!errors.interested_team) errors.interested_team = [];
          errors.interested_team[index] = {
            ...errors.interested_team[index],
            interested_team_country: "required",
          };
        }
        if (!team.interested_team_league) {
          if (!errors.interested_team) errors.interested_team = [];
          errors.interested_team[index] = {
            ...errors.interested_team[index],
            interested_team_league: "required",
          };
        }
      });
    } else {
      errors.is_team_interested_to_director = "required";
    }

    return errors;
  };

  useEffect(() => {
    dispatch(staticDataAction());
    dispatch(getTeamListAction());
    dispatch(getAgentListAction());
  }, [dispatch]);

  const optionsCountry = staticListForPlayer?.place_of_birth?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsLeague = staticListForPlayer?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const teamOptions = teanListingData?.map((elem) => ({
    label: elem?.team_name,
    value: elem?._id,
  }));

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));

  useEffect(() => {
    dispatch(getCoachesDataById({ id: coachID }));
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Proposals</h2>
            </div>
            <Formik
              initialValues={{
                has_coach_proposed: coachData?.has_coach_proposed || false,
                proposed_team:
                  coachData?.proposed_team?.length > 0
                    ? coachData?.proposed_team
                    : [
                        {
                          proposed_team_id: "",
                          proposed_team_country: null,
                          proposed_team_league: null,
                          is_agent_proposed_coach: null,
                          proposed_associate_agent_id: "",
                          proposed_associate_agent_note: "",
                        },
                      ],

                is_team_interested_to_director:
                  coachData?.has_coach_proposed || false,
                interested_team:
                  coachData?.interested_team?.length > 0
                    ? coachData?.interested_team
                    : [
                        {
                          interested_team_id: "",
                          interested_team_country: "",
                          interested_team_league: "",
                          interested_team_notes: "",
                        },
                      ],
              }}
              enableReinitialize={true}
              validate={validate}
              // onSubmit={(values) => {
              //   let formData = new FormData();

              //   Object.entries(values).forEach(([key, value]) => {
              //     if (key !== "proposed_team" && key !== "interested_team") {
              //       formData.append(key, value);
              //     }
              //   });

              //   formData.append(
              //     "proposed_team",
              //     JSON.stringify(values.proposed_team)
              //   );
              //   formData.append(
              //     "interested_team",
              //     JSON.stringify(values.interested_team)
              //   );

              //   formData.append("coachId", coachID);
              //   formData.append("is_profile_completed", 5);

              //   dispatch(editCoachAction(formData)).then((res) => {
              //     if (res?.payload?.status === 200) {
              //       navigate(
              //         `/coaches-contact-information/${Encryptedid(
              //           res?.payload?.data?._id
              //         )}`
              //       );
              //     } else {
              //       toast.error(res?.payload?.message);
              //     }
              //   });
              // }}

              onSubmit={(values) => {
                let formData = new FormData();

                Object.entries(values).forEach(([key, value]) => {
                  if (key !== "proposed_team" && key !== "interested_team") {
                    if (
                      typeof value === "object" &&
                      value !== null &&
                      !Array.isArray(value)
                    ) {
                      formData.append(key, value.value || value);
                    } else {
                      formData.append(key, value);
                    }
                  }
                });

                // formData.append(
                //   "proposed_team",
                //   JSON.stringify(
                //     values.proposed_team.map((team) => ({
                //       proposed_team_id: team.proposed_team_id,
                //       proposed_team_country: team.proposed_team_country,
                //       proposed_team_league: team.proposed_team_league,
                //       is_agent_proposed_coach: team.is_agent_proposed_coach,
                //       proposed_associate_agent_id:
                //         team.proposed_associate_agent_id,
                //       proposed_associate_agent_note:
                //         team.proposed_associate_agent_note,
                //     }))
                //   )
                // );

                formData.append(
                  "proposed_team",
                  JSON.stringify(
                    values.proposed_team.map((team) => {
                      const teamData = {
                        proposed_team_id: team.proposed_team_id,
                        proposed_team_country: team.proposed_team_country,
                        proposed_team_league: team.proposed_team_league,
                        is_agent_proposed_coach: team.is_agent_proposed_coach,
                        proposed_associate_agent_note:
                          team.proposed_associate_agent_note,
                      };

                      if (team.is_agent_proposed_coach != 0) {
                        teamData.proposed_associate_agent_id =
                          team.proposed_associate_agent_id;
                      }

                      return teamData;
                    })
                  )
                );

                formData.append(
                  "interested_team",
                  JSON.stringify(
                    values.interested_team.map((team) => ({
                      interested_team_id: team.interested_team_id,
                      interested_team_country: team.interested_team_country,
                      interested_team_league: team.interested_team_league,
                      interested_team_notes: team.interested_team_notes,
                    }))
                  )
                );

                formData.append("coachId", coachID);
                formData.append("is_profile_completed", 5);
                dispatch(editCoachAction(formData)).then((res) => {
                  if (res?.payload?.status === 200) {
                    navigate(
                      `/coaches-contact-information/${Encryptedid(
                        res?.payload?.data?._id
                      )}`
                    );
                  } else {
                    toast.error(res?.payload?.message);
                  }
                });
              }}
            >
              {({ setFieldValue, values, errors, touched }) => (
                <Form>
                  {/* Proposed to Team Section */}
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">
                        Teams that the Coach has been proposed
                      </h3>
                    </div>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>Has the Coach been proposed to team</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="has_coach_proposed"
                                checked={values.has_coach_proposed === 1}
                                onChange={() => {
                                  const newValue =
                                    values.has_coach_proposed === 1 ? 0 : 1;
                                  setFieldValue("has_coach_proposed", newValue);
                                  setIsProposed(newValue === 1);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <ErrorMessage
                        component="div"
                        name="has_coach_proposed"
                        className="text-danger"
                      />

                      {values.has_coach_proposed ? (
                        <FieldArray name="proposed_team">
                          {({ push, remove }) => (
                            <div>
                              <div className="d-flex justify-content-between">
                                <h3 className="h-20 mt-2">Teams</h3>
                                <button
                                  type="button"
                                  style={{ border: "none", background: "none" }}
                                  onClick={() =>
                                    push({
                                      proposed_team_id: "",
                                      proposed_team_country: null,
                                      proposed_team_league: null,
                                      is_agent_proposed_coach: 0,
                                      is_agent_proposed_coach: 1,
                                      proposed_associate_agent_id: "",
                                      proposed_associate_agent_note: "",
                                    })
                                  }
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                      fill="#4C8BB4"
                                    />
                                  </svg>
                                </button>
                              </div>

                              {values.proposed_team.map((team, index) => (
                                <div
                                  key={index}
                                  className="cmn-form-fields mt-2"
                                >
                                  <div className="d-flex justify-content-end mt-3 mb-3">
                                    {index > 0 && (
                                      <div className="d-flex justify-content-between">
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() => remove(index)}
                                        >
                                          <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  <Row>
                                    <Col lg={4}>
                                      <div className="form-group mb-3 form-inner">
                                        {/* <Field
                                          type="text"
                                          name={`proposed_team.${index}.proposed_team_id`}
                                          placeholder="Enter name"
                                          className={`form-control ${
                                            touched.proposed_team?.[index]
                                              ?.proposed_team_id &&
                                            errors.proposed_team?.[index]
                                              ?.proposed_team_id
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        /> */}
                                        <Select
                                          className={`form-control`}
                                          // name={`proposed_team.${index}.proposed_team_id`}
                                          placeholder="Enter name"
                                          options={teamOptions}
                                          onChange={(option) =>
                                            setFieldValue(
                                              `proposed_team.${index}.proposed_team_id`,
                                              option ? option.value : ""
                                            )
                                          }
                                          value={
                                            teamOptions?.find(
                                              (elem) =>
                                                elem.value ==
                                                values.proposed_team[index]
                                                  .proposed_team_id
                                            ) || null
                                          }
                                        />
                                        <ErrorMessage
                                          name={`proposed_team.${index}.proposed_team_id`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="form-group mb-3 form-inner">
                                        <Select
                                          options={optionsCountry}
                                          placeholder="Select Team’s Country"
                                          onChange={(option) =>
                                            setFieldValue(
                                              `proposed_team.${index}.proposed_team_country`,
                                              option ? option.value : ""
                                            )
                                          }
                                          value={
                                            optionsCountry?.find(
                                              (opt) =>
                                                opt.value ===
                                                values.proposed_team[index]
                                                  .proposed_team_country
                                            ) || null
                                          }
                                        />
                                        {touched.proposed_team?.[index]
                                          ?.proposed_team_country &&
                                          errors.proposed_team?.[index]
                                            ?.proposed_team_country && (
                                            <div className="invalid-feedback d-block">
                                              {
                                                errors.proposed_team[index]
                                                  .proposed_team_country
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="form-group mb-3 form-inner">
                                        <Select
                                          options={optionsLeague}
                                          placeholder="Select Team League"
                                          onChange={(option) =>
                                            setFieldValue(
                                              `proposed_team.${index}.proposed_team_league`,
                                              option ? option.value : ""
                                            )
                                          }
                                          value={
                                            optionsLeague?.find(
                                              (opt) =>
                                                opt.value ===
                                                values.proposed_team[index]
                                                  .proposed_team_league
                                            ) || null
                                          }
                                        />
                                        {touched.proposed_team?.[index]
                                          ?.proposed_team_league &&
                                          errors.proposed_team?.[index]
                                            ?.proposed_team_league && (
                                            <div className="invalid-feedback d-block">
                                              {
                                                errors.proposed_team[index]
                                                  .proposed_team_league
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                      {/* Agent Proposed Player Section */}
                                      <div className="common-outer mt-4">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="h-20">
                                            Agent Proposed Coach to this team
                                          </h3>
                                        </div>
                                        <div className="cmn-form-fields mt-2">
                                          <Row>
                                            <Col lg={4}>
                                              <div className="licensed-sec">
                                                <h3>Me</h3>
                                                <div className="form-check form-switch">
                                                  <Field
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    name={`proposed_team[${index}].is_agent_proposed_coach`}
                                                    checked={
                                                      values.proposed_team[0]
                                                        .is_agent_proposed_coach ===
                                                      0
                                                    }
                                                    onChange={() =>
                                                      setFieldValue(
                                                        `proposed_team[${index}].is_agent_proposed_coach`,
                                                        values.proposed_team[0]
                                                          .is_agent_proposed_coach ===
                                                          0
                                                          ? 1
                                                          : 0
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <ErrorMessage
                                                name={`proposed_team.${index}.is_agent_proposed_coach`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </Col>
                                            <Col lg={4}>
                                              <div className="licensed-sec">
                                                <h3>The Associate Agent</h3>
                                                <div className="form-check form-switch">
                                                  <Field
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    name={`proposed_team[${index}].is_agent_proposed_coach`}
                                                    checked={
                                                      values.proposed_team[
                                                        index
                                                      ]
                                                        .is_agent_proposed_coach ===
                                                      1
                                                    }
                                                    onChange={() =>
                                                      setFieldValue(
                                                        `proposed_team[${index}].is_agent_proposed_coach`,
                                                        values.proposed_team[
                                                          index
                                                        ]
                                                          .is_agent_proposed_coach ===
                                                          1
                                                          ? 0
                                                          : 1
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>

                                      {values.proposed_team[0]
                                        .is_agent_proposed_coach === 1 && (
                                        <div className="common-outer mt-4">
                                          <div className="d-flex justify-content-between">
                                            <h3 className="h-20">
                                              Name of Associate Agent
                                            </h3>
                                          </div>
                                          <Row>
                                            <Col lg={4}>
                                              <div className="form-group mb-3 form-inner mt-2">
                                                <Select
                                                  className={`form-control`}
                                                  name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                  placeholder="Enter name"
                                                  options={
                                                    associateAgentOptions
                                                  }
                                                  onChange={(option) =>
                                                    setFieldValue(
                                                      `proposed_team.${index}.proposed_associate_agent_id`,
                                                      option ? option.value : ""
                                                    )
                                                  }
                                                  value={associateAgentOptions?.find(
                                                    (elem) =>
                                                      elem.value ==
                                                      values.proposed_team[
                                                        index
                                                      ]
                                                        .proposed_associate_agent_id
                                                  )}
                                                />
                                                <ErrorMessage
                                                  name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                  component="div"
                                                  className="text-danger"
                                                />

                                                {/* <Field
                                                  type="text"
                                                  name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                  placeholder="Enter Associate Agent Name"
                                                  className={`form-control `}
                                                />
                                                <ErrorMessage
                                                  name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                  component="div"
                                                  className="text-danger"
                                                /> */}
                                              </div>
                                            </Col>
                                            <Col lg={4}>
                                              <div className="form-group mb-3 form-inner">
                                                <h3
                                                  style={{ visibility: "none" }}
                                                ></h3>
                                                <Field
                                                  type="text"
                                                  name={`proposed_team.${index}.proposed_associate_agent_note`}
                                                  placeholder="Enter Notes"
                                                  className={`form-control`}
                                                />
                                                <ErrorMessage
                                                  name={`proposed_team.${index}.proposed_associate_agent_note`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                          )}
                        </FieldArray>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">
                        Teams that are interested for the Coach
                      </h3>
                    </div>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>Any team interested for Coach?</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="is_team_interested_to_director"
                                checked={
                                  values.is_team_interested_to_director === 1
                                }
                                onChange={() => {
                                  const newValue =
                                    values.is_team_interested_to_director === 1
                                      ? 0
                                      : 1;
                                  setFieldValue(
                                    "is_team_interested_to_director",
                                    newValue
                                  );
                                  setIsInterested(newValue === 1);
                                }}
                              />
                            </div>
                          </div>
                          <ErrorMessage
                            component="div"
                            name="is_team_interested_to_director"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>

                  {values.is_team_interested_to_director ? (
                    <div className="common-outer mt-2">
                      <FieldArray name="interested_team">
                        {({ push, remove }) => (
                          <div>
                            <div className="d-flex justify-content-between">
                              <h3 className="h-20 mt-2">Teams</h3>
                              <button
                                type="button"
                                style={{ border: "none", background: "none" }}
                                onClick={() =>
                                  push({
                                    interested_team_id: "",
                                    interested_team_country: "",
                                    interested_team_league: "",
                                    interested_team_notes: "",
                                  })
                                }
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                    fill="#4C8BB4"
                                  />
                                </svg>
                              </button>
                            </div>
                            {values.interested_team.map((team, index) => (
                              <>
                                <div className="d-flex justify-content-end mt-3 mb-3">
                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() => remove(index)}
                                      className="btn btn-danger"
                                    >
                                      <svg
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                          fill="#4C8BB4"
                                        />
                                      </svg>
                                    </button>
                                  )}
                                </div>

                                <div key={index} className="common-outer mt-2">
                                  <Row>
                                    <Col lg={3}>
                                      <div className="form-group mb-3 form-inner">
                                        <Select
                                          className={`form-control`}
                                          name={`interested_team.${index}.interested_team_id`}
                                          placeholder="Team Name"
                                          options={teamOptions}
                                          onChange={(option) =>
                                            setFieldValue(
                                              `interested_team.${index}.interested_team_id`,
                                              option ? option.value : ""
                                            )
                                          }
                                          value={teamOptions?.find(
                                            (elem) =>
                                              elem.value ==
                                              values.interested_team[index]
                                                .interested_team_id
                                          )}
                                        />

                                        <ErrorMessage
                                          name={`interested_team.${index}.interested_team_id`}
                                          component="div"
                                          className="text-danger"
                                        />
                                        {/* <Field
                                        type="text"
                                        name={`interested_team.${index}.interested_team_id`}
                                        placeholder="Team Name"
                                        className={`form-control`}
                                      />
                                      <ErrorMessage
                                        name={`interested_team.${index}.interested_team_id`}
                                        component="div"
                                        className="text-danger"
                                      /> */}
                                      </div>
                                    </Col>

                                    <Col lg={4}>
                                      <div className="form-group mb-3 form-inner">
                                        <Select
                                          options={optionsCountry}
                                          placeholder="Select Team’s Country"
                                          onChange={(option) =>
                                            setFieldValue(
                                              `interested_team.${index}.interested_team_country`,
                                              option ? option.value : ""
                                            )
                                          }
                                          value={
                                            optionsCountry?.find(
                                              (opt) =>
                                                opt.value ===
                                                values.interested_team[index]
                                                  .interested_team_country
                                            ) || null
                                          }
                                        />
                                        {touched.interested_team?.[index]
                                          ?.interested_team_country &&
                                          errors.interested_team?.[index]
                                            ?.interested_team_country && (
                                            <div className="invalid-feedback d-block">
                                              {
                                                errors.interested_team[index]
                                                  .interested_team_country
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </Col>

                                    <Col lg={4}>
                                      <div className="form-group mb-3 form-inner">
                                        <Select
                                          options={optionsLeague}
                                          placeholder="Select Team League"
                                          onChange={(option) =>
                                            setFieldValue(
                                              `interested_team.${index}.interested_team_league`,
                                              option ? option.value : ""
                                            )
                                          }
                                          value={
                                            optionsLeague?.find(
                                              (opt) =>
                                                opt.value ===
                                                values.interested_team[index]
                                                  .interested_team_league
                                            ) || null
                                          }
                                        />
                                        {touched.interested_team?.[index]
                                          ?.interested_team_league &&
                                          errors.interested_team?.[index]
                                            ?.interested_team_league && (
                                            <div className="invalid-feedback d-block">
                                              {
                                                errors.interested_team[index]
                                                  .interested_team_league
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </Col>

                                    <Col lg={3}>
                                      <div className="form-group mb-3 form-inner">
                                        <Field
                                          type="text"
                                          name={`interested_team.${index}.interested_team_notes`}
                                          placeholder="Notes"
                                          className={`form-control ${
                                            touched.interested_team?.[index]
                                              ?.interested_team_notes &&
                                            errors.interested_team?.[index]
                                              ?.interested_team_notes
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                        <ErrorMessage
                                          name={`interested_team.${index}.interested_team_notes`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  ) : null}

                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button type="submit">Next</button>
                    </div>
                  </Col>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default CoachProposals;
