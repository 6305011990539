import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
export default function Transfer() {
  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Transfer’s</h2>
              <Link to="3" className="light-blue-btn">
                Schedule Transfer
              </Link>
            </div>
            <Tabs
              defaultActiveKey="Professional"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Professional" title="Already Transferred">
                <Row>
                  <Col lg={4}>
                    <div className="play-inner-box">
                      <div className="d-flex">
                        <img src={require("../Assets/images/user.png")} />
                        <div className="ms-3">
                          <h4>Andrew Addison</h4>
                          <p>
                            Player ID: <span>IVXJW007</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="play-inner-box">
                      <div className="d-flex">
                        <img src={require("../Assets/images/user.png")} />
                        <div className="ms-3">
                          <h4>Andrew Addison</h4>
                          <p>
                            Player ID: <span>IVXJW007</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="play-inner-box">
                      <div className="d-flex">
                        <img src={require("../Assets/images/user.png")} />
                        <div className="ms-3">
                          <h4>Andrew Addison</h4>
                          <p>
                            Player ID: <span>IVXJW007</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="Appearances" title="Under Process"></Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
