import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
import { Decryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { getOtherAgentDataById } from "../Redux/Action/agentAction";
import { capitalize } from "../utils/helper";
import { role } from "../utils/rawjson";
import Loader from "../components/Loader";

export default function OtherAssociateAgentDetail() {
  const otherAssociateData = useSelector(
    (e) => e?.agentAuthData?.otherAgentDetail
  );  
  const loader = useSelector(
    (e) => e?.agentAuthData?.loading
  );
  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOtherAgentDataById({ id: IdVal }));
  }, [dispatch]);


  const backgroundImageStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${otherAssociateData?.banner_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };

  const handleOtherAssociateEdit = (id) => {
    if (id) {
      localStorage.setItem("otherAgentId", id);
    }
  };

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2> Other Associate Agent’s Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${otherAssociateData?.profile_image}`}
                    />
                    <div>
                      <h2>{capitalize(otherAssociateData?.associate_name)}</h2>

                      <div className="socio-icons mt-3">
                        {/* Instagram */}
                        {otherAssociateData?.instagram && (
                          <Link
                            to={otherAssociateData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {otherAssociateData?.snapchat && (
                          <Link
                            to={otherAssociateData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {otherAssociateData?.twitter && (
                          <Link
                            to={otherAssociateData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {otherAssociateData?.facebook && (
                          <Link
                            to={otherAssociateData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        to="/add-other-agent"
                        onClick={() =>
                          handleOtherAssociateEdit(otherAssociateData?._id)
                        }
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Main Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Role:</h4>
                              <p>
                                {otherAssociateData?.role
                                  ? role[otherAssociateData?.role]
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Gender:</h4>
                              <p>Male</p>
                            </li>
                            <li>
                              <h4>Team:</h4>
                              <p>
                                {otherAssociateData?.team
                                  ? otherAssociateData?.team
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>
                                {otherAssociateData?.email
                                  ? otherAssociateData?.email
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Phone Address:</h4>
                              <p>
                                {otherAssociateData?.phone_number
                                  ? otherAssociateData?.phone_number
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Social Media Links</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>{otherAssociateData?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>{otherAssociateData?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h4>X:</h4>
                              <p>{otherAssociateData?.facebook || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
