import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createMeetingAction,
  editMeeingAction,
  getMeetingIdAction,
} from "../Redux/Action/calenderAction";
import toast from "react-hot-toast";
import { globalAgentSearchAction } from "../Redux/Action/globalSearchAction";
import Select from "react-select";
import { Decryptedid } from "../utils/Becrypt";
import Loader from "../components/Loader";

export default function EditMeeting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const meetingID = id && Decryptedid(atob(id));
  const meetingData = useSelector((e) => e.calenderAuthData.getMeetingDataById);
  const loader = useSelector((e) => e.calenderAuthData.loading);

  const queryParams = new URLSearchParams(location.search);
  const urlDate = queryParams.get("date");

  const agentlist = useSelector(
    (e) => e.globalSearchAuthData.globalSearchAgentResult
  );

  const initialValues = {
    title: meetingData?.title || "",
    participants: meetingData?.participants?._id || "",
    notes: meetingData?.notes || "",
    date: meetingData?.date?.split("T")[0] || "",
    time: meetingData?.time || "",
    // location: meetingData?.location || "",
    meeting_link: meetingData?.meeting_link || "",
    is_daily_reminder: false,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("required"),
    participants: Yup.string().required("required"),
    notes: Yup.string(),
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    // location: Yup.string().required("required"),
    meeting_link: Yup.string().url("Enter a valid URL").required("required"),
  });

  const handleSubmit = (values) => {
    const transformedValues = {
      ...values,
      is_daily_reminder: values.is_daily_reminder ? 1 : 0,
      meetingId: meetingID,
    };

    dispatch(editMeeingAction(transformedValues)).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        navigate("/calendor");
      }
    });
  };

  useEffect(() => {
    dispatch(globalAgentSearchAction());
    if (meetingID) {
      dispatch(getMeetingIdAction({ meetingId: meetingID }));
    }
  }, [dispatch, meetingID]);

  const AgentOptions = agentlist?.map((elem) => ({
    label: elem?.full_name,
    value: elem?._id,
  }));

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Create Meeting</h2>
              <Link to="/calendor">
                <img
                  src={require("../Assets/images/closebtn.png")}
                  alt="Close Button"
                />
              </Link>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="create-met">
                    <div className="cmn-form-fields">
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3 form-inner">
                            <label className="sub-16 mb-2">Title </label>
                            <Field
                              name="title"
                              className="form-control"
                              placeholder="Please enter here..."
                            />
                            <ErrorMessage
                              name="title"
                              component="text"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3 form-inner">
                            <label className="sub-16 mb-2">
                              Add Participants
                            </label>
                            {/* <Field
                              name="participants"
                              className="form-control"
                              placeholder="Enter ID/Name here"
                            /> */}
                            <Select
                              name="participants"
                              className="form-control"
                              placeholder="Enter ID/Name here"
                              options={AgentOptions}
                              onChange={(option) =>
                                setFieldValue(
                                  `participants`,
                                  option ? option.value : ""
                                )
                              }
                              value={AgentOptions?.find(
                                (data) => data?.value === values?.participants
                              )}
                            />
                            <ErrorMessage
                              name="participants"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <label className="sub-16 mb-2">Reminder</label>
                          <div className="licensed-sec">
                            <h3>Daily Reminder</h3>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={values.is_daily_reminder}
                                onChange={() =>
                                  setFieldValue(
                                    "is_daily_reminder",
                                    !values.is_daily_reminder
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3 form-inner">
                            <label className="sub-16 mb-2">Notes</label>
                            <Field
                              name="notes"
                              as="textarea"
                              rows={3}
                              className="form-control"
                              placeholder="Please enter here"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3 form-inner">
                            <label className="sub-16 mb-2">Meeting Time</label>
                            <Field
                              name="time"
                              type="time"
                              className="form-control mb-3"
                            />
                            <ErrorMessage
                              name="time"
                              component="text"
                              className="text-danger"
                            />

                            {/* <ErrorMessage
                              name="date"
                              component="div"
                              className="text-danger"
                            /> */}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3 form-inner">
                            <label
                              className="sub-16 mb-2"
                              style={{ visibility: "hidden" }}
                            >
                              Date
                            </label>
                            <Field
                              name="date"
                              type="date"
                              className="form-control"
                            />
                          </div>
                        </Col>

                        {/* <Col lg={4}>
                          <div className="mb-3 form-inner">
                            <label className="sub-16 mb-2">Location</label>
                            <Field
                              name="location"
                              className="form-control"
                              placeholder="Enter location here"
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col> */}
                        <Col lg={4}>
                          <div className="mb-1 form-inner">
                            <label className="sub-16 mb-2">
                              Add Meeting Link
                            </label>
                            <Field
                              name="meeting_link"
                              type="url"
                              className="form-control"
                              placeholder="Add Link"
                            />
                            <ErrorMessage
                              name="meeting_link"
                              component="div"
                              className="text-danger"
                            />
                            <p style={{ fontSize: "10px" }}>
                              *You can paste here any Google meets/ Zoom meeting
                              link to schedule the meeting
                            </p>
                          </div>
                        </Col>
                        <Col
                          lg={12}
                          className="d-flex justify-content-end mt-4"
                        >
                          <div className="schedule-btn">
                            <button type="submit">Schedule</button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
