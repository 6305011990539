import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./Reducers/userSlice";
import staticAuthReducer from "./Reducers/staticSlice";
import playerAuthReducer from "./Reducers/playerSlice";
import directorAuthReducer from "./Reducers/directorSlice";
import coachAuthReducer from "./Reducers/coachSlice";
import teamAuthReducer from "./Reducers/teamSlice";
import agentAuthReducer from "./Reducers/agentSlice";
import homeAuthReducer from "./Reducers/homeSlice";
import globalAuthReducer from "./Reducers/globalSearchSlice"
import internalSearchAuthReducer from "./Reducers/internalSearchSlice"
import calenderAuthReducer from "./Reducers/calenderSlice"

export const store = configureStore({
  reducer: {
    userAuthData: userAuthReducer,
    staticAuthData: staticAuthReducer,
    playerAuthData: playerAuthReducer,
    directorAuthData: directorAuthReducer,
    coachAuthData: coachAuthReducer,
    teamAuthData: teamAuthReducer,
    agentAuthData: agentAuthReducer,
    homeAuthData: homeAuthReducer,
    globalSearchAuthData : globalAuthReducer,
    internalSeachAuthData : internalSearchAuthReducer,
    calenderAuthData : calenderAuthReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

