import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// global search for player
export const globalPlayerSearchAction = createAsyncThunk("globalPlayerSearchAction/user/globalPlayerSearch", async (data) => {
    const response = await api.post("/user/globalPlayerSearch", data);
    return response.data;
  }
);

// global search for director

export const globalDirectorSearchAction = createAsyncThunk("globalDirectorSearchAction/user/globalDirectorSearch", async (data) => {
  const response = await api.post("/user/globalDirectorSearch", data);
  return response.data;
}
);

// global search for agent

export const globalAgentSearchAction = createAsyncThunk("globalAgentSearchAction/user/globalAgentSearch", async (data) => {
  const response = await api.post("/user/globalAgentSearch", data);
  return response.data;
}
);

// global search for coach

export const globalCoachSearchAction = createAsyncThunk("globalCoachSearchAction/user/globalCoachSearch", async (data) => {
  const response = await api.post("/user/globalCoachSearch", data);
  return response.data;
}
);