import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { internalSearchAction } from "../Redux/Action/internalSearch";
import { useDispatch } from "react-redux";

const SearchBar = ({ setSearchValue }) => {
  const [searchText, setSearchText] = useState("");
  const timeout = 800; // Debounce timeout
  const { query } = useParams();
  const dispatch = useDispatch();


  const handleSearchChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      setSearchValue(searchText); 
    }
  };


  useEffect(() => {
    if (searchText.trim() === "") return; 

    const handler = setTimeout(() => {
      setSearchValue(searchText);
    }, timeout);

    return () => clearTimeout(handler);
  }, [searchText, setSearchValue, timeout]);


  useEffect(() => {
    if (query) {
      setSearchText(query);
      dispatch(internalSearchAction({ searchText: query }));
    }
  }, [query, dispatch]);

  return (
    <input
      type="search"
      value={searchText}
      placeholder="Search by name"
      onChange={handleSearchChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchBar;
