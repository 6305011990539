import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  Formik,
  Field,
  FieldArray,
  Form as FormikForm,
  ErrorMessage,
} from "formik";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { editAgentAction, getAgentDataById } from "../Redux/Action/agentAction";
import Select from "react-select";
import { getCoachListAction } from "../Redux/Action/coachAction";

const AddAssociateAgentFourteen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const agentId = id && Decryptedid(atob(id));

  const getAgentDetailById = useSelector((e) => e.agentAuthData.agentDetail);


  const CoachListData = useSelector((e) => e.coachAuthData.getCoachLists);
  const coachList = CoachListData.map((state) => {
    return {
      value: state._id,
      label: state.full_name,
    };
  });

  console.log(getAgentDetailById, "getAgentDetailById");

  const validate = (values) => {
    const errors = {};

    if (values.have_any_coach_proposed_from_this_agent === null) {
      errors.have_any_coach_proposed_from_this_agent = "Required";
    }

    if (values.have_any_coach_proposed_from_this_agent === 1) {
      values.agent.forEach((agent, index) => {
        if (!agent.proposed_coaches_name) {
          errors[`agent[${index}].proposed_coaches_name`] = "Required";
        }
      });
    }

    return errors;
  };

  const handleSubmit = (values) => {
    debugger
    let formData = new FormData();

    formData.append(
      "have_any_coach_proposed_from_this_agent",
      values.have_any_coach_proposed_from_this_agent
    );

    if (values.have_any_coach_proposed_from_this_agent === 1) {
      values.agent.forEach((agent, index) => {
        formData.append(
          `proposed_coaches_name[${index}]`,
          agent.proposed_coaches_name
        );
      });
    }

    formData.append("agentId", agentId);
    formData.append("is_profile_completed", 13);

    dispatch(editAgentAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Success");
        localStorage.removeItem("agentId");
        navigate(
          `/manage-associate-agent`
        );
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const initialValues = {
    have_any_coach_proposed_from_this_agent:
      getAgentDetailById?.have_any_coach_proposed_from_this_agent || null,
    agent:
      getAgentDetailById?.proposed_coaches_name?.length > 0
        ? getAgentDetailById.proposed_coaches_name?.map((elem) => ({
            proposed_coaches_name: elem?._id || "",
          }))
        : [
            {
              proposed_coaches_name: "",
            },
          ],
  };



  useEffect(() => {
    if (agentId) {
      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(getAgentDataById({ id: agentId })),
            dispatch(getCoachListAction())
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }
  }, [dispatch, agentId]);
  

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>My Associate Agent</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FormikForm>
 
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">
                      Does this agent proposed to me any coaches?
                      </h3>
                    </div>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>Yes</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="have_any_coach_proposed_from_this_agent"
                                checked={
                                  values.have_any_coach_proposed_from_this_agent === 1
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "have_any_coach_proposed_from_this_agent",
                                    1
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>No</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="have_any_coach_proposed_from_this_agent"
                                checked={
                                  values.have_any_coach_proposed_from_this_agent === 0
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "have_any_coach_proposed_from_this_agent",
                                    0
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <ErrorMessage
                          name="have_any_coach_proposed_from_this_agent"
                          component="div"
                          className="text-danger"
                        />
                      </Row>
                    </div>
                  </div>
                  {values.have_any_coach_proposed_from_this_agent === 1 && (
                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        {/* <h3 className="h-20">agent</h3> */}
                        <button
                          type="button"
                          style={{ background: "none", border: "none" }}
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {/* SVG content */}
                          </svg>
                        </button>
                      </div>
                      <div className="cmn-form-fields mt-2">
                        <FieldArray name="agent">
                          {({ push, remove }) => (
                            <>
                              <div className="d-flex justify-content-between">
                                {" "}
                                <h3 className="h-20">Coaches</h3>
                                <button
                                  type="button"
                                  style={{
                                    border: "none",
                                    background: "none",
                                  }}
                                  onClick={() =>
                                    push({
                                      proposed_coaches_name: "",
                                    })
                                  }
                                >
                                  <svg
                                    width="28"
                                    coaching_licenses="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                      fill="#4C8BB4"
                                    />
                                  </svg>
                                </button>
                              </div>

                              {values.agent.map((player, index) => (
                                <div key={index}>
                                  <Col lg={4}>
                                    {index !== 0 && (
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        onClick={() => remove(index)}
                                      >
                                        <svg
                                          width="28"
                                          height="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                            fill="#4C8BB4"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                  </Col>
                                  <Row>
                                    {/* <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Field
                                          as={Form.Control}
                                          type="text"
                                          name={`agent[${index}].proposed_coaches_name`}
                                          placeholder="Enter name"
                                        />
                                      </Form.Group>
                                      <ErrorMessage
                                        name={`agent[${index}].proposed_coaches_name`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Col> */}

                                    <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                       name={`agent[${index}].proposed_coaches_name`}
                                placeholder="coach"
                                options={coachList}
                                value={coachList.find(
                                  (option) => option.value === values.agent[index].proposed_coaches_name
                                )}
                                onChange={(option) =>
                                  setFieldValue(`agent[${index}].proposed_coaches_name`, option.value)
                                }
                              />
                              <ErrorMessage
                name={`agent[${index}].proposed_coaches_name`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                                  </Row>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  )}
                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button type="submit">Next</button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddAssociateAgentFourteen;
