import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import Select from "react-select";
import {
  agentMainInformation,
  coachCurrentContractDetails,
  coachinThisTeam,
  coachMainInformation,
  coachNewContractDetails,
  coachProfessionalRelations,
  coachProposedtoThisTeam,
  coachRelation,
  currentContractDetails,
  directorCurrentContractDetails,
  directorInThisTeam,
  directorNewContractDetails,
  DirectorProfessionalRelations,
  directorRelation,
  directorsMainInformation,
  healthStatus,
  myPlayerInTeam,
  newContractDetails,
  otherInformation,
  playerProposalDetails,
  playerProposedtoteam,
  playerRelation,
  playersMainInformation,
  playersStatistics,
  playerWantedByTeam,
  professionalRelations,
  teamMainInformation,
  teamsInterestedInCoach,
  teamsInterestedInDirector,
  teamsInterestedInPlayer,
  teamsProposedDirector,
  whoBroughtPlayer,
} from "../utils/rawjson";
import { useDispatch, useSelector } from "react-redux";
import { getYesterday, mapOptions, mapSimpleOptions } from "../utils/helper";
import {
  staticDataAction,
  staticDirectorDataAction,
  staticTeamDataAction,
} from "../Redux/Action/staticAction";
import { getTeamListAction } from "../Redux/Action/teamAction";
import { getAgentListAction } from "../Redux/Action/agentAction";
import PlayersFilterTab from "./PlayersFilterTab";
import DirectorFilterTab from "./DirectorFilterTab";
import TeamFilterTab from "./TeamFilterTab";
import CoachFilterTab from "./CoachFilterTab";
import { Country, State } from "country-state-city";
import AgentFilterTab from "./AgentFilterTab";
import {
  clearSelectedFilter,
  clearSelectedFilter1,
  clearSelectedFilter2,
  clearSelectedFilter3,
  clearSelectedFilter4,
  setActiveTab1,
  setSelectedFilters,
  setSelectedFilters1,
  setSelectedFilters2,
  setSelectedFilters3,
  setSelectedFilters4,
} from "../Redux/Reducers/internalSearchSlice";

export default function PlayerSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // const [selectedFilters, setSelectedFilters] = useState({});
  // const [selectedFilters1, setSelectedFilters1] = useState({});
  // const [selectedFilters2, setSelectedFilters2] = useState({});
  // const [selectedFilters3, setSelectedFilters3] = useState({});
  // const [selectedFilters4, setSelectedFilters4] = useState({});

  const selectedFilters = useSelector(
    (state) => state.internalSeachAuthData.selectedFilters || {}
  );
  const selectedFilters1 = useSelector(
    (state) => state.internalSeachAuthData.selectedFilters1 || {}
  );
  const selectedFilters2 = useSelector(
    (state) => state.internalSeachAuthData.selectedFilters2 || {}
  );
  const selectedFilters3 = useSelector(
    (state) => state.internalSeachAuthData.selectedFilters3 || {}
  );
  const selectedFilters4 = useSelector(
    (state) => state.internalSeachAuthData.selectedFilters4 || {}
  );

  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [inputType2, setInputType2] = useState("text");
  const [inputType3, setInputType3] = useState("text");
  const [inputType4, setInputType4] = useState("text");
  const [inputType5, setInputType5] = useState("text");
  const [inputType6, setInputType6] = useState("text");
  const [inputType7, setInputType7] = useState("text");
  const [inputType8, setInputType8] = useState("text");
  const [inputType9, setInputType9] = useState("text");
  const [inputType10, setInputType10] = useState("text");
  const [inputType11, setInputType11] = useState("text");
  const [inputType12, setInputType12] = useState("text");

  const [cities, setCities] = useState([]);
  const [cities1, setCities1] = useState([]);
  const [cities2, setCities2] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountry1, setSelectedCountry1] = useState(null);
  const [countries, setCountries] = useState([]);
  const [teamData, setTeamData] = useState({
    team_country: "",
    team_city: "",
  });

  const [showAssociateAgentName, setShowAssociateAgentName] = useState(false);
  const [showDirectorAssociateAgentName, setShowDirectorAssociateAgentName] =
    useState(false);
  const [showProposedAssociateAgentName, setShowProposedAssociateAgentName] =
    useState(false);

  const [showCoachAssociateAgentName, setShowCoachAssociateAgentName] =
    useState(false);

  const [showTeamAssociateAgentName, setShowTeamAssociateAgentName] =
    useState(false);
  const [showTeamAssociateAgentName1, setShowTeamAssociateAgentName1] =
    useState(false);

  const [showTeamAssociateAgentName2, setShowTeamAssociateAgentName2] =
    useState(false);

  const [showTeamAssociateAgentName3, setShowTeamAssociateAgentName3] =
    useState(false);

  const activeTab1 = useSelector(
    (state) => state.internalSeachAuthData.activeTab1
  );

  // player filter
  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);
  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);

  const staticListForDirector = useSelector(
    (e) => e.staticAuthData.directorStaticDetail
  );

  const staticListForTeam = useSelector(
    (e) => e.staticAuthData.teamStaticDetail
  );

  const teamOptions = teanListingData?.map((elem) => ({
    label: elem?.team_name,
    value: elem?._id,
  }));

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));

  const optionsGender = mapOptions(staticListForPlayer?.gender);
  const optionsNationaityId = mapOptions(
    staticListForPlayer?.nationality_id,
    "val",
    "index"
  );
  const optionsLanguage = mapSimpleOptions(staticListForPlayer?.languages);
  const optionsHeight = mapOptions(staticListForPlayer?.hight);
  const optionsFoot = mapOptions(staticListForPlayer?.foot);
  const optionsPosition = mapOptions(staticListForPlayer?.positions);
  const optionsTeamLeague = mapOptions(staticListForPlayer?.team_league);
  const optionsNationalTeam = mapOptions(
    staticListForPlayer?.national_team_player
  );
  const optionsTrophy = mapOptions(staticListForPlayer?.trophy);
  const optionsPlayerStatus = mapOptions(
    staticListForPlayer?.availability_status
  );
  const optionsAskingAmount = mapOptions(
    staticListForPlayer?.asking_amount,
    "val",
    "val"
  );
  const optionsCurrentSalary = mapOptions(
    staticListForPlayer?.current_contract,
    "val",
    "val"
  );
  const optionsNationaity = mapSimpleOptions(staticListForPlayer?.nationality);
  const optionsPlaceOfBirth = mapSimpleOptions(
    staticListForPlayer?.place_of_birth
  );
  const optionsClause = mapOptions(staticListForPlayer?.is_current_injuries);
  const optionsContractDuration = mapOptions(
    staticListForPlayer?.duration,
    "val",
    "val"
  );
  const optionsMaritalStatus = mapOptions(staticListForPlayer?.martial_status);
  const optionsChildren = mapOptions(staticListForPlayer?.children);
  const optionsPreviousStatus = mapOptions(
    staticListForDirector?.previous_status,
    "val",
    "index"
  );
  const optionsCoachingLicense = mapOptions(
    staticListForDirector?.coaching_licenses
  );

  const optionsAnnualBudget = mapOptions(staticListForTeam?.annual_budget);
  const optionsAge = mapOptions(staticListForTeam?.age);

  const handleDateChange = (key, dateValue) => {
    dispatch(
      setSelectedFilters({
        [key]: dateValue,
      })
    );
  };

  const handleChange = (key, dateValue) => {
    dispatch(
      setSelectedFilters({
        [key]: dateValue,
      })
    );
  };

  const handleSelectChange = (key, selectedOption) => {
    dispatch(
      setSelectedFilters({
        ...selectedFilters,
        [key]: selectedOption,
      })
    );
  };

  const optionsForFilters = {
    gender: optionsGender,
    nationality: optionsNationaity,
    nationality_id: optionsNationaityId,
    place_of_birth: optionsPlaceOfBirth,
    foot: optionsFoot,
    height: optionsHeight,
    languages: optionsLanguage,
    team_league: optionsTeamLeague,
    positions: optionsPosition,
    national_team_player: optionsNationalTeam,
    national_team: optionsPlaceOfBirth,
    carrier_trophies: optionsTrophy,
    availability_status: optionsPlayerStatus,
    asking_amount: optionsAskingAmount,
    current_contract: optionsCurrentSalary,
    who_brought_player: whoBroughtPlayer,
    release_clouse: optionsClause,
    buyout_clouse: optionsClause,

    new_contract_duration: optionsContractDuration,
    new_contract_team_asking_amount: optionsAskingAmount,
    new_contract_annual_salary: optionsAskingAmount,

    proposed_team_id: teamOptions,
    proposed_team_country: optionsPlaceOfBirth,

    is_agent_proposed_player_to_team: whoBroughtPlayer,
    proposed_associate_agent_id: associateAgentOptions,

    interested_team_id: teamOptions,
    interested_team_country: optionsPlaceOfBirth,
    interested_team_league: optionsTeamLeague,

    martial_status: optionsMaritalStatus,
    children: optionsChildren,

    // director

    previous_status: optionsPreviousStatus,
    coaching_licenses: optionsCoachingLicense,
    country: optionsPlaceOfBirth,
    carrier_trophies: optionsTrophy,

    current_annual_salary: optionsCurrentSalary,
    annual_askig_salary: optionsAskingAmount,
    contract_duration: optionsContractDuration,

    annual_budget: optionsAnnualBudget,

    player_age: optionsAge,
    player_gender: optionsGender,
    player_nationality: optionsNationaity,
    player_nationality_id: optionsNationaityId,
    player_national_team_player: optionsNationalTeam,
    player_foot: optionsFoot,
    player_height: optionsHeight,
    player_foot: optionsFoot,
    player_languages: optionsLanguage,
    player_playing_positions: optionsPosition,
    player_availability_status: optionsPlayerStatus,
  };

  const renderSelect = (key) => {
    const filterKey = playersMainInformation[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      // "foot",
      "nationality",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "dob":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="dob"
                className="accordion-content form-control"
                placeholder="Choose Date of Birth"
                type={inputType3}
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleDateChange(filterKey, e.target.value)}
                max={getYesterday()}
                onFocus={() => setInputType3("date")}
                onBlur={() => setInputType3("text")}
              />
            </div>
          </div>
        );

      case "name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleChange(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${playersMainInformation[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            value={selectedFilters[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const renderSelect1 = (key) => {
    const filterKey = professionalRelations[key].value;

    switch (filterKey) {
      case "my_player":
        return (
          <div className="licensed-sec">
            <h3>My Player</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="radio"
                name="professional_relation"
                value="0"
                checked={selectedFilters["professional_relation"] === "0"}
                onChange={(e) => handleRadioChange1("professional_relation", e)}
              />
            </div>
          </div>
        );

      case "associate_agent_player":
        return (
          <div className="licensed-sec">
            <h3>Associate Agent’s Player</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="radio"
                name="professional_relation"
                value="1"
                checked={selectedFilters["professional_relation"] === "1"}
                onChange={(e) => handleRadioChange1("professional_relation", e)}
              />
            </div>
          </div>
        );

      case "associate_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="associate_agent_name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleChange1(e.target.value)}
                disabled={!showAssociateAgentName}
              />
            </div>
          </div>
        );

      case "who_brought_player":
        return (
          <Select
            className="accordion-content"
            name={"who_brought_player"}
            placeholder="Who Brought Player to Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectChange1("who_brought_player", option)
            }
            value={selectedFilters[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const renderSelect2 = (key) => {
    const filterKey = currentContractDetails[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "joined_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="joined_date"
                className="accordion-content form-control"
                placeholder="Choose Date of Joining"
                type={inputType}
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleDateChange(filterKey, e.target.value)}
                // max={getYesterday()}
                onFocus={() => setInputType("date")}
                onBlur={() => setInputType("text")}
              />
            </div>
          </div>
        );

      case "contract_start_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="contract_start_date"
                className="accordion-content form-control"
                placeholder="Contract Start Date"
                type={inputType1}
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleDateChange(filterKey, e.target.value)}
                // max={getYesterday()}
                onFocus={() => setInputType1("date")}
                onBlur={() => setInputType1("text")}
              />
            </div>
          </div>
        );

      case "contract_end_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="contract_end_date"
                className="accordion-content form-control"
                placeholder="Contract End Date"
                type={inputType2}
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleDateChange(filterKey, e.target.value)}
                // max={getYesterday()}
                onFocus={() => setInputType2("date")}
                onBlur={() => setInputType2("text")}
              />
            </div>
          </div>
        );

      case "bonus":
        return (
          <div className="licensed-sec">
            <h3>Bonus</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="bonus"
                checked={selectedFilters["bonus"] === "1"}
                onChange={(e) => handleRadioChange1("bonus", e)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${currentContractDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            value={selectedFilters[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const renderSelect3 = (key) => {
    const filterKey = newContractDetails[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${newContractDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            value={selectedFilters[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const renderSelect4 = (key) => {
    const filterKey = playerProposalDetails[key].value;

    switch (filterKey) {
      case "is_player_proposed":
        return (
          <div className="licensed-sec">
            <h3>Player has been proposed ?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_player_proposed"
                checked={selectedFilters["is_player_proposed"] === "1"}
                onChange={(e) => handleIsProposed(e)}
              />
            </div>
          </div>
        );

      case "is_agent_proposed_player_to_team":
        return (
          <Select
            className="accordion-content"
            name={"is_agent_proposed_player_to_team"}
            placeholder="Who Proposed Player to Team"
            options={optionsForFilters.is_agent_proposed_player_to_team}
            onChange={(option) =>
              handleSelectChange2("is_agent_proposed_player_to_team", option)
            }
            value={selectedFilters[filterKey] || null}
          />
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${playerProposalDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            isDisabled={
              filterKey == "is_agent_proposed_player_to_team"
                ? !showProposedAssociateAgentName
                : false
            }
            value={selectedFilters[filterKey] || null}
          />
        );
    }
  };

  const renderSelect5 = (key) => {
    const filterKey = teamsInterestedInPlayer[key].value;

    switch (filterKey) {
      case "is_team_interested_to_player":
        return (
          <div className="licensed-sec">
            <h3>Any Team interested for the player ?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_team_interested_to_player"
                checked={
                  selectedFilters["is_team_interested_to_player"] === "1"
                }
                onChange={(e) => handleIsInsterested(e)}
              />
            </div>
          </div>
        );

      case "is_agent_proposed_player_to_team":
        return (
          <Select
            className="accordion-content"
            name={"is_agent_proposed_player_to_team"}
            placeholder="Who Proposed Player to Team"
            options={optionsForFilters.is_agent_proposed_player_to_team}
            onChange={(option) =>
              handleSelectChange2("is_agent_proposed_player_to_team", option)
            }
            value={selectedFilters[filterKey] || null}
          />
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${teamsInterestedInPlayer[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            isDisabled={
              filterKey == "is_agent_proposed_player_to_team"
                ? !showProposedAssociateAgentName
                : false
            }
            value={selectedFilters[filterKey] || null}
          />
        );
    }
  };

  const renderSelect6 = (key) => {
    const filterKey = playersStatistics[key].value;

    switch (filterKey) {
      case "total_appearence":
        return (
          <div className="common-shadow">
            <h3 className="mb-3">Total Appearances</h3>
            <div className="num-inputs">
              <input
                type="number"
                placeholder="eg.10"
                name="total_appearence"
                className="form-control"
                onChange={(e) =>
                  handleChange("total_appearence", e.target.value)
                }
              />

              <div className="d-flex align-items-center">
                <p className="mb-0 me-3 w-50">Out of</p>

                <input
                  type="number"
                  placeholder="eg.10"
                  name="total_appearence_out_of"
                  className="form-control"
                  onChange={(e) =>
                    handleChange("total_appearence_out_of", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        );

      case "starting_eleven":
        return (
          <div className="common-shadow">
            <h3 className="mb-3">Starting Eleven</h3>
            <div className="num-inputs">
              <input
                type="number"
                placeholder="eg.10"
                name="starting_eleven"
                className="form-control"
                onChange={(e) =>
                  handleChange("starting_eleven", e.target.value)
                }
              />
              <div className="d-flex align-items-center">
                <p className="mb-0 me-3 w-50">Out of</p>

                <input
                  type="number"
                  placeholder="eg.10"
                  name="starting_eleven_out_of"
                  className="form-control"
                  onChange={(e) =>
                    handleChange("starting_eleven_out_of", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                className="form-control"
                name={filterKey}
                type="number"
                placeholder={`${playersStatistics[key].label}`}
                // options={optionsForFilters[filterKey] || []}
                onChange={(e) => handleChange(filterKey, e.target.value)}
                // value={selectedFilters[filterKey] || null}
                // isMulti={isMultiSelect}
              />
            </div>
          </div>
        );
    }
  };

  const renderSelect7 = (key) => {
    const filterKey = healthStatus[key].value;

    switch (filterKey) {
      case "is_current_injuries":
        return (
          <div className="licensed-sec">
            <h3>Current Injuries</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_current_injuries"
                checked={selectedFilters["is_current_injuries"] === "1"}
                onChange={(e) => handleIsCurrentInjuries(e)}
              />
            </div>
          </div>
        );

      case "past_injuries":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                className="accordion-content form-control"
                name="past_injuries"
                placeholder="Past Injuries"
                onChange={(e) => handleChange("past_injuries", e.target.value)}
                value={selectedFilters[filterKey] || null}
              />
            </div>
          </div>
        );

      default:
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                className="accordion-content form-control"
                name={filterKey}
                placeholder={`${healthStatus[key].label}`}
                onChange={(e) => handleChange(filterKey, e.target.value)}
                // value={selectedFilters[filterKey] || null}
              />
            </div>
          </div>
        );
    }
  };

  const renderSelect8 = (key) => {
    const filterKey = otherInformation[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "player_hobbies":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="player_hobbies"
                className="accordion-content form-control"
                placeholder="Players Hobbies and Interests"
                type="text"
                value={selectedFilters[filterKey] || ""}
                onChange={(e) => handleChange(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${otherInformation[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChange(filterKey, option)}
            value={selectedFilters[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const handleChange1 = (value) => {
    dispatch(
      setSelectedFilters({
        associate_agent_name: value,
      })
    );
  };

  const handleSelectChange1 = (filterKey, option) => {
    dispatch(
      setSelectedFilters({
        who_brought_player: option,
      })
    );
    if (filterKey === "who_brought_player") {
      setShowAssociateAgentName(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters({
            associate_agent_name: "",
          })
        );
      }
    }
  };

  const handleSelectChange2 = (filterKey, option) => {
    dispatch(
      setSelectedFilters({
        is_agent_proposed_player_to_team: option,
      })
    );

    if (filterKey === "is_agent_proposed_player_to_team") {
      setShowProposedAssociateAgentName(
        option.value === "proposed_associate_agent_id"
      );
    }
  };

  const handleIsProposed = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters({
        is_player_proposed: isChecked ? "1" : "0",
      })
    );
  };

  const handleIsInsterested = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters({
        is_team_interested_to_player: isChecked ? "1" : "0",
      })
    );
  };

  const handleIsCurrentInjuries = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters({
        is_current_injuries: isChecked ? "1" : "0",
      })
    );
  };

  const handleRadioChange1 = (key, e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (key === "bonus") {
      dispatch(
        setSelectedFilters({
          [key]: isChecked ? "1" : "0",
        })
      );
    } else {
      dispatch(
        setSelectedFilters({
          [key]: value,
        })
      );
    }
  };

  useEffect(() => {
    //for player
    dispatch(staticDataAction());
    // for director
    dispatch(staticDirectorDataAction());
    // team
    dispatch(getTeamListAction());
    //agent
    dispatch(getAgentListAction());
    // team static
    dispatch(staticTeamDataAction());
  }, [dispatch]);

  const transformFilters = (filters) => {
    const result = {};

    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "object" && !Array.isArray(value)) {
        result[key] = value.value;
      } else if (Array.isArray(value)) {
        if (typeof value[0] === "object") {
          result[key] = value.map((item) => item.value);
        } else {
          result[key] = value;
        }
      } else {
        result[key] = value;
      }
    }
    return result;
  };

  const handleSearchForPlayer = async () => {
    try {
      const payload = transformFilters(selectedFilters);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) =>
                  `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/internal-search-result?type=player&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilters = () => {
    dispatch(clearSelectedFilter({}));
    dispatch(clearSelectedFilter1({}));
    setSelectedCountry(null);
    setSelectedCountry1(null)
    setCities2(null)
    setCities1(null)
    dispatch(clearSelectedFilter2({}));
    dispatch(clearSelectedFilter3({}));
    dispatch(clearSelectedFilter4({}));
  };

  // director filter

  const renderSelectDirector = (key) => {
    const filterKey = directorsMainInformation[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      // "foot",
      "nationality",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "dob":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="dob"
                className="accordion-content form-control"
                placeholder="Choose Date of Birth"
                type={inputType3}
                value={selectedFilters1[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeDirector(filterKey, e.target.value)
                }
                max={getYesterday()}
                onFocus={() => setInputType3("date")}
                onBlur={() => setInputType3("text")}
              />
            </div>
          </div>
        );

      case "name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters1[filterKey] || ""}
                onChange={(e) =>
                  handleChangeDirector(filterKey, e.target.value)
                }
              />
            </div>
          </div>
        );

      case "team_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="team_name"
                className="accordion-content form-control"
                placeholder="Team name"
                type="text"
                value={selectedFilters1[filterKey] || ""}
                onChange={(e) =>
                  handleChangeDirector(filterKey, e.target.value)
                }
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${directorsMainInformation[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeDirector(filterKey, option)}
            value={selectedFilters1[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const renderSelectDirector1 = (key) => {
    const filterKey = DirectorProfessionalRelations[key].value;

    switch (filterKey) {
      case "my_director":
        return (
          <div className="licensed-sec">
            <h3>My Director</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="radio"
                name="my_director"
                value="0"
                checked={selectedFilters1["my_director"] === "0"}
                onChange={(e) => handleRadioDirectorChange("my_director", e)}
              />
            </div>
          </div>
        );

      case "associate_agent_director":
        return (
          <div className="licensed-sec">
            <h3>Associate Agent’s Director</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="radio"
                name="my_director"
                value="1"
                checked={selectedFilters1["my_director"] === "1"}
                onChange={(e) => handleRadioDirectorChange("my_director", e)}
              />
            </div>
          </div>
        );

      case "agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="agent_name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters1[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeDirector(filterKey, e.target.value)
                }
                disabled={!showDirectorAssociateAgentName}
              />
            </div>
          </div>
        );

      case "who_brought_director":
        return (
          <Select
            className="accordion-content"
            name={"who_brought_director"}
            placeholder="Who Brought Director to Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectDirectorChange("who_brought_director", option)
            }
            value={selectedFilters1[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const renderSelectDirector2 = (key) => {
    const filterKey = directorCurrentContractDetails[key].value;

    switch (filterKey) {
      case "contract_start_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="contract_start_date"
                className="accordion-content form-control"
                placeholder="Contract Start Date"
                type={inputType5}
                value={selectedFilters1[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeDirector(filterKey, e.target.value)
                }
                // max={getYesterday()}
                onFocus={() => setInputType5("date")}
                onBlur={() => setInputType5("text")}
              />
            </div>
          </div>
        );

      case "contract_end_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="contract_end_date"
                className="accordion-content form-control"
                placeholder="Contract End Date"
                type={inputType4}
                value={selectedFilters1[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeDirector(filterKey, e.target.value)
                }
                // max={getYesterday()}
                onFocus={() => setInputType4("date")}
                onBlur={() => setInputType4("text")}
              />
            </div>
          </div>
        );

      case "Bonus":
        return (
          <div className="licensed-sec">
            <h3>Bonus</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="Bonus"
                checked={selectedFilters1["Bonus"] === "1"}
                onChange={(e) => handleRadioDirectorChange("Bonus", e)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${directorCurrentContractDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeDirector(filterKey, option)}
            value={selectedFilters1[filterKey] || null}
          />
        );
    }
  };

  const renderSelectDirector3 = (key) => {
    const filterKey = directorNewContractDetails[key].value;

    switch (filterKey) {
      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${directorNewContractDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeDirector(filterKey, option)}
            value={selectedFilters1[filterKey] || null}
          />
        );
    }
  };

  const renderSelectDirector4 = (key) => {
    const filterKey = "is_director_proposed";

    switch (filterKey) {
      case "is_director_proposed":
        return (
          <div className="licensed-sec">
            <h3>Director has been proposed ?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_director_proposed"
                checked={selectedFilters1["is_director_proposed"] === "1"}
                onChange={(e) => handleDirectorIsProposed(e)}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectDirector5 = (key) => {
    const filterKey = teamsInterestedInDirector[key].value;

    switch (filterKey) {
      case "is_team_interested_to_director":
        return (
          <div className="licensed-sec">
            <h3>Any Team interested for the DIrector ?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_team_interested_to_director"
                checked={
                  selectedFilters1["is_team_interested_to_director"] === "1"
                }
                onChange={(e) => handleDirectorIsInsterested(e)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${teamsInterestedInDirector[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeDirector(filterKey, option)}
            value={selectedFilters1[filterKey] || null}
          />
        );
    }
  };

  const handleSearchForDirector = async () => {
    try {
      const payload = transformFilters(selectedFilters1);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) =>
                  `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/internal-search-result?type=director&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDirectorIsInsterested = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters1({
        is_team_interested_to_director: isChecked ? "1" : "0",
      })
    );
  };

  const handleDirectorIsProposed = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters1({
        is_director_proposed: isChecked ? "1" : "0",
      })
    );
  };

  const handleSelectDirectorChange = (filterKey, option) => {
    dispatch(
      setSelectedFilters1({
        who_brought_director: option,
      })
    );

    if (filterKey === "who_brought_director") {
      setShowDirectorAssociateAgentName(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters1({
            agent_name: "",
          })
        );
      }
    }
  };

  const handleRadioDirectorChange = (key, e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (key === "Bonus") {
      dispatch(
        setSelectedFilters1({
          [key]: isChecked ? "1" : "0",
        })
      );
    } else {
      dispatch(
        setSelectedFilters1({
          [key]: value,
        })
      );
    }
  };

  const handleDateChangeDirector = (key, dateValue) => {
    dispatch(
      setSelectedFilters1({
        [key]: dateValue,
      })
    );
  };

  const handleChangeDirector = (key, dateValue) => {
    dispatch(
      setSelectedFilters1({
        [key]: dateValue,
      })
    );
  };

  const handleSelectChangeDirector = (key, selectedOption) => {
    dispatch(
      setSelectedFilters1({
        ...selectedFilters1,
        [key]: selectedOption,
      })
    );
  };

  // coach filter

  const renderSelectCoach = (key) => {
    const filterKey = coachMainInformation[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      // "foot",
      "nationality",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "dob":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="dob"
                className="accordion-content form-control"
                placeholder="Choose Date of Birth"
                type={inputType6}
                value={selectedFilters2[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeCoach(filterKey, e.target.value)
                }
                max={getYesterday()}
                onFocus={() => setInputType6("date")}
                onBlur={() => setInputType6("text")}
              />
            </div>
          </div>
        );

      case "coach_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="coach_name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters2[filterKey] || ""}
                onChange={(e) => handleChangeCoach(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      // case "team_name":
      //   return (
      //     <div className="profile-card">
      //       <div className="num-inputs outer">
      //         <input
      //           name="team_name"
      //           className="accordion-content form-control"
      //           placeholder="Team name"
      //           type="text"
      //           value={selectedFilters2[filterKey] || ""}
      //           onChange={(e) =>
      //             handleChangeCoach(filterKey, e.target.value)
      //           }
      //         />
      //       </div>
      //     </div>
      //   );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${coachMainInformation[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeCoach(filterKey, option)}
            value={selectedFilters2[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const renderSelectCoach1 = (key) => {
    const filterKey = coachProfessionalRelations[key].value;

    switch (filterKey) {
      case "my_coach":
        return (
          <div className="licensed-sec">
            <h3>My Coach</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="radio"
                name="my_coach"
                value="0"
                checked={selectedFilters2["my_coach"] === "0"}
                onChange={(e) => handleRadioCoachChange("my_coach", e)}
              />
            </div>
          </div>
        );

      case "associate_agent_coach":
        return (
          <div className="licensed-sec">
            <h3>Associate Agent’s Director</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="radio"
                name="my_coach"
                value="1"
                checked={selectedFilters2["my_coach"] === "1"}
                onChange={(e) => handleRadioCoachChange("my_coach", e)}
              />
            </div>
          </div>
        );

      case "agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="agent_name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters2[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeCoach(filterKey, e.target.value)
                }
                disabled={!showCoachAssociateAgentName}
              />
            </div>
          </div>
        );

      case "who_brought_coach":
        return (
          <Select
            className="accordion-content"
            name={"who_brought_coach"}
            placeholder="Who Brought Coach to Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectCoachChange("who_brought_coach", option)
            }
            value={selectedFilters2[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const renderSelectCoach2 = (key) => {
    const filterKey = coachCurrentContractDetails[key].value;

    switch (filterKey) {
      case "contract_start_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="contract_start_date"
                className="accordion-content form-control"
                placeholder="Contract Start Date"
                type={inputType7}
                value={selectedFilters2[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeCoach(filterKey, e.target.value)
                }
                // max={getYesterday()}
                onFocus={() => setInputType7("date")}
                onBlur={() => setInputType7("text")}
              />
            </div>
          </div>
        );

      case "contract_end_date":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="contract_end_date"
                className="accordion-content form-control"
                placeholder="Contract End Date"
                type={inputType8}
                value={selectedFilters2[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeCoach(filterKey, e.target.value)
                }
                // max={getYesterday()}
                onFocus={() => setInputType8("date")}
                onBlur={() => setInputType8("text")}
              />
            </div>
          </div>
        );

      case "Bonus":
        return (
          <div className="licensed-sec">
            <h3>Bonus</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="Bonus"
                checked={selectedFilters2["Bonus"] === "1"}
                onChange={(e) => handleRadioCoachChange("Bonus", e)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${coachCurrentContractDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeCoach(filterKey, option)}
            value={selectedFilters2[filterKey] || null}
          />
        );
    }
  };

  const renderSelectCoach3 = (key) => {
    const filterKey = coachNewContractDetails[key].value;

    switch (filterKey) {
      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${coachNewContractDetails[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeCoach(filterKey, option)}
            value={selectedFilters2[filterKey] || null}
          />
        );
    }
  };

  const renderSelectCoach4 = (key) => {
    const filterKey = "is_coach_proposed";

    switch (filterKey) {
      case "is_coach_proposed":
        return (
          <div className="licensed-sec">
            <h3>Coach has been proposed ?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_coach_proposed"
                checked={selectedFilters2["is_coach_proposed"] === "1"}
                onChange={(e) => handleCoachIsProposed(e)}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectCoach5 = (key) => {
    const filterKey = teamsInterestedInCoach[key].value;

    switch (filterKey) {
      case "is_team_interested":
        return (
          <div className="licensed-sec">
            <h3>Any Team interested for the Coach ?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_team_interested"
                checked={selectedFilters2["is_team_interested"] === "1"}
                onChange={(e) => handleCoachIsInsterested(e)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${teamsInterestedInCoach[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeCoach(filterKey, option)}
            value={selectedFilters2[filterKey] || null}
          />
        );
    }
  };

  const handleSearchForCoach = async () => {
    try {
      const payload = transformFilters(selectedFilters2);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) =>
                  `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/internal-search-result?type=coach&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCoachIsInsterested = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters2({
        is_team_interested: isChecked ? "1" : "0",
      })
    );
  };

  const handleCoachIsProposed = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setSelectedFilters2({
        is_coach_proposed: isChecked ? "1" : "0",
      })
    );
  };

  const handleSelectCoachChange = (filterKey, option) => {
    dispatch(
      setSelectedFilters2({
        who_brought_coach: option,
      })
    );
    if (filterKey === "who_brought_coach") {
      setShowCoachAssociateAgentName(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters2({
            agent_name: "",
          })
        );
      }
    }
  };

  const handleRadioCoachChange = (key, e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (key === "Bonus") {
      dispatch(
        setSelectedFilters2({
          [key]: isChecked ? "1" : "0",
        })
      );
    } else {
      dispatch(
        setSelectedFilters2({
          [key]: value,
        })
      );
    }
  };

  const handleDateChangeCoach = (key, dateValue) => {
    dispatch(
      setSelectedFilters2({
        [key]: dateValue,
      })
    );
  };

  const handleChangeCoach = (key, dateValue) => {
    dispatch(
      setSelectedFilters2({
        [key]: dateValue,
      })
    );
  };

  const handleSelectChangeCoach = (key, selectedOption) => {
    dispatch(
      setSelectedFilters2({
        ...selectedFilters2,
        [key]: selectedOption,
      })
    );
  };

  // team filter

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  console.log(selectedFilters3, "dsfgdsfgdfgdf");

  const renderSelectTeam = (key) => {
    const filterKey = teamMainInformation[key].value;
    const isMultiSelect = [
      "national_team_player",
      "positions",
      "languages",
      // "foot",
      "nationality",
      "carrier_trophies",
      "availability_status",
    ].includes(filterKey);

    switch (filterKey) {
      case "country_name":
        return (
          <Select
            name="team_country"
            placeholder="Teams Country"
            isSearchable={true}
            value={selectedCountry}
            onChange={handleCountryChange}
            options={countries}
          />
        );

      case "city_name":
        return (
          <Select
            name="team_city"
            placeholder="Team's City"
            options={cities}
            onChange={handleCityChange}
            value={cities1 || ""}
            isLoading={loadingCities}
            isDisabled={!selectedCountry}
          />
        );

      case "team_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="team_name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      case "owner_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="owner_name"
                className="accordion-content form-control"
                placeholder="Team owner name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      case "president_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="president_name"
                className="accordion-content form-control"
                placeholder="Team President/Director"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      default:
        return (
          <Select
            className="accordion-content"
            name={filterKey}
            placeholder={`${teamMainInformation[key].label}`}
            options={optionsForFilters[filterKey] || []}
            onChange={(option) => handleSelectChangeTeam(filterKey, option)}
            value={selectedFilters3[filterKey] || (isMultiSelect ? [] : null)}
            isMulti={isMultiSelect}
          />
        );
    }
  };

  const renderSelectTeam1 = (key) => {
    const filterKey = myPlayerInTeam[key].value;

    const isMyPlayerDisabled = selectedFilters3["is_my_player"] === "0";
    const isWhoBroughtPlayerDisabled =
      selectedFilters3["who_brought_player"]?.value === "0";

    switch (filterKey) {
      case "is_my_player":
        return (
          <div className="licensed-sec">
            <h3>Do I have player in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_my_player"
                checked={selectedFilters3["is_my_player"] === "1"}
                onChange={(e) => handleRadioTeamChange("is_my_player", e)}
              />
            </div>
          </div>
        );

      case "my_player_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_player_name"
                placeholder="Enter player's full name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isMyPlayerDisabled}
              />
            </div>
          </div>
        );

      case "who_brought_player":
        return (
          <Select
            className="accordion-content"
            name={"who_brought_player"}
            placeholder="Who Brought player to current team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectTeamChange("who_brought_player", option)
            }
            value={selectedFilters3[filterKey] || null}
          />
        );

      case "player_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Name of Associate Agent</h6>
              <input
                name="player_agent_name"
                className="accordion-content form-control"
                placeholder="Enter agent's name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "player_agent_fee":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Agent's Fees Charged</h6>
              <input
                name="player_agent_fee"
                className="accordion-content form-control"
                placeholder="Enter fees Charged"
                type="number"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "empty":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "player_agent_fee_received":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Agent's Fees Received</h6>
              <input
                name="player_agent_fee_received"
                className="accordion-content form-control"
                placeholder="Enter fees Received"
                type="number"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "player_agent_fee_received_on":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                info
              </h6>
              <input
                name="player_agent_fee_received_on"
                className="accordion-content form-control"
                placeholder="Received on"
                type={inputType9}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                max={getYesterday()}
                onFocus={() => setInputType9("date")}
                onBlur={() => setInputType9("text")}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );
      case "empty1":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "player_agent_fee_pending":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Agent's Fees Pending</h6>
              <input
                name="player_agent_fee_pending"
                className="accordion-content form-control"
                placeholder="Enter Fees Pending"
                type="number"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "player_agent_fee_will_received_on":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                info
              </h6>
              <input
                name="player_agent_fee_will_received_on"
                className="accordion-content form-control"
                placeholder="Will Received on"
                type={inputType10}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                max={getYesterday()}
                onFocus={() => setInputType10("date")}
                onBlur={() => setInputType10("text")}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "empty2":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "player_team_person_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Team Contact Person</h6>
              <input
                name="player_team_person_name"
                className="accordion-content form-control"
                placeholder="Person's name"
                type={"text"}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "player_team_conatact_info":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                info
              </h6>
              <input
                name="player_team_conatact_info"
                className="accordion-content form-control"
                placeholder="Contact info"
                type={"text"}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectTeam2 = (key) => {
    const filterKey = playerWantedByTeam[key].value;
    const isMultiSelect = [
      "player_national_team_player",
      "player_playing_positions",
      "player_languages",
      // "foot",
      "player_nationality",
      "carrier_trophies",
      "player_availability_status",
    ].includes(filterKey);

    const isPlayerwantedDisabled =
      selectedFilters3["is_looking_for_player"] === "0";

    switch (filterKey) {
      case "is_looking_for_player":
        return (
          <div className="licensed-sec">
            <h3>Do I have player in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_looking_for_player"
                checked={selectedFilters3["is_looking_for_player"] === "1"}
                onChange={(e) =>
                  handleRadioTeamChange("is_looking_for_player", e)
                }
              />
            </div>
          </div>
        );

      case "empty":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "empty1":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      default:
        return (
          <div>
            {filterKey === "player_age" && <h6 className="h-20">Players</h6>}
            {filterKey === "player_gender" && (
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                Players
              </h6>
            )}
            {filterKey === "player_nationality" && (
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                Players
              </h6>
            )}

            {filterKey !== "empty2" && (
              <Select
                className="accordion-content"
                name={filterKey}
                placeholder={`${playerWantedByTeam[key].label}`}
                options={optionsForFilters[filterKey] || []}
                onChange={(option) => handleSelectChangeTeam(filterKey, option)}
                value={
                  selectedFilters3[filterKey] || (isMultiSelect ? [] : null)
                }
                isMulti={isMultiSelect}
                isDisabled={isPlayerwantedDisabled}
              />
            )}
          </div>
        );
    }
  };

  const renderSelectTeam3 = (key) => {
    const filterKey = playerProposedtoteam[key].value;

    const isWhoBroughtProposedPlayerDisabled =
      selectedFilters3["is_player_proposed"] === "0";

    switch (filterKey) {
      case "is_player_proposed":
        return (
          <div className="licensed-sec">
            <h3>Do I have Proposed Player in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_player_proposed"
                checked={selectedFilters3["is_player_proposed"] === "1"}
                onChange={(e) => handleRadioTeamChange("is_player_proposed", e)}
              />
            </div>
          </div>
        );

      case "player_full_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="player_full_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtProposedPlayerDisabled}
              />
            </div>
          </div>
        );
      case "player_associate_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Name of Associate Agent</h6>
              <input
                name="player_associate_agent_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={!showTeamAssociateAgentName1}
              />
            </div>
          </div>
        );

      case "who_proposed_player":
        return (
          <Select
            className="accordion-content"
            name={"who_proposed_player"}
            placeholder="Who Proposed player to Current Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectTeamChange1("who_proposed_player", option)
            }
            value={selectedFilters3[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const renderSelectTeam4 = (key) => {
    const filterKey = directorInThisTeam[key].value;

    const isMyPlayerDisabled =
      selectedFilters3["have_director_in_team"] === "0";
    const isWhoBroughtPlayerDisabled =
      selectedFilters3["who_brought_director"]?.value === "0";

    switch (filterKey) {
      case "have_director_in_team":
        return (
          <div className="licensed-sec">
            <h3>Do I have director in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="have_director_in_team"
                checked={selectedFilters3["have_director_in_team"] === "1"}
                onChange={(e) =>
                  handleRadioTeamChange("have_director_in_team", e)
                }
              />
            </div>
          </div>
        );

      case "director_full_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="director_full_name"
                placeholder="Enter player's full name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isMyPlayerDisabled}
              />
            </div>
          </div>
        );

      case "who_brought_director":
        return (
          <Select
            className="accordion-content"
            name={"who_brought_director"}
            placeholder="Who Brought director to current team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectTeamChange("who_brought_director", option)
            }
            value={selectedFilters3[filterKey] || null}
          />
        );

      case "director_associate_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Name of Associate Agent</h6>
              <input
                name="director_associate_agent_name"
                className="accordion-content form-control"
                placeholder="Enter agent's name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "director_agent_fee":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Agent's Fees Charged</h6>
              <input
                name="director_agent_fee"
                className="accordion-content form-control"
                placeholder="Enter fees Charged"
                type="number"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "empty":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "director_agent_fee_received":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Agent's Fees Received</h6>
              <input
                name="director_agent_fee_received"
                className="accordion-content form-control"
                placeholder="Enter fees Received"
                type="number"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "director_agent_fee_received_on":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                info
              </h6>
              <input
                name="director_agent_fee_received_on"
                className="accordion-content form-control"
                placeholder="Received on"
                type={inputType11}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                max={getYesterday()}
                onFocus={() => setInputType11("date")}
                onBlur={() => setInputType11("text")}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );
      case "empty1":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "director_agent_fee_pending":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Agent's Fees Pending</h6>
              <input
                name="director_agent_fee_pending"
                className="accordion-content form-control"
                placeholder="Enter Fees Pending"
                type="number"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "director_agent_fee_will_receive":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                info
              </h6>
              <input
                name="director_agent_fee_will_receive"
                className="accordion-content form-control"
                placeholder="Will Received on"
                type={inputType12}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                max={getYesterday()}
                onFocus={() => setInputType12("date")}
                onBlur={() => setInputType12("text")}
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "empty2":
        return (
          <div className="profile-card">
            <div className="num-inputs outer"></div>
          </div>
        );

      case "director_team_conatct_person_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Team Contact Person</h6>
              <input
                name="director_team_conatct_person_name"
                className="accordion-content form-control"
                placeholder="Person's name"
                type={"text"}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      case "director_team_contact_info":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20" style={{ visibility: "hidden" }}>
                info
              </h6>
              <input
                name="director_team_contact_info"
                className="accordion-content form-control"
                placeholder="Contact info"
                type={"text"}
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={isWhoBroughtPlayerDisabled}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectTeam5 = (key) => {
    const filterKey = teamsProposedDirector[key].value;

    const isWhoBroughtProposedDirectorDisabled =
      selectedFilters3["is_director_proposed"] === "0";

    switch (filterKey) {
      case "is_director_proposed":
        return (
          <div className="licensed-sec">
            <h3>Do I have Proposed Director in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_director_proposed"
                checked={selectedFilters3["is_director_proposed"] === "1"}
                onChange={(e) =>
                  handleRadioTeamChange("is_director_proposed", e)
                }
              />
            </div>
          </div>
        );

      case "proposed_director_full_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="proposed_director_full_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtProposedDirectorDisabled}
              />
            </div>
          </div>
        );
      case "proposed_director_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Name of Associate Agent</h6>
              <input
                name="proposed_director_agent_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={!showTeamAssociateAgentName1}
              />
            </div>
          </div>
        );

      case "who_proposed_director":
        return (
          <Select
            className="accordion-content"
            name={"who_proposed_director"}
            placeholder="Who Proposed director to Current Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectTeamChange2("who_proposed_director", option)
            }
            value={selectedFilters3[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const renderSelectTeam6 = (key) => {
    const filterKey = coachinThisTeam[key].value;

    const isWhoBroughtProposedDirectorDisabled =
      selectedFilters3["have_coach_in_team"] === "0";

    switch (filterKey) {
      case "have_coach_in_team":
        return (
          <div className="licensed-sec">
            <h3>Do I have a Coach in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="have_coach_in_team"
                checked={selectedFilters3["have_coach_in_team"] === "1"}
                onChange={(e) => handleRadioTeamChange("have_coach_in_team", e)}
              />
            </div>
          </div>
        );

      case "coach_full_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="coach_full_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtProposedDirectorDisabled}
              />
            </div>
          </div>
        );
      case "coach_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Name of Associate Agent</h6>
              <input
                name="coach_agent_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={!showTeamAssociateAgentName2}
              />
            </div>
          </div>
        );

      case "who_brought_coach":
        return (
          <Select
            className="accordion-content"
            name={"who_brought_coach"}
            placeholder="Who Proposed Coach to Current Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectTeamChange3("who_brought_coach", option)
            }
            value={selectedFilters3[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const renderSelectTeam7 = (key) => {
    const filterKey = coachProposedtoThisTeam[key].value;

    const isWhoBroughtProposedDirectorDisabled =
      selectedFilters3["is_coach_proposed"] === "0";

    switch (filterKey) {
      case "is_coach_proposed":
        return (
          <div className="licensed-sec">
            <h3>Do I have proposed coach in this team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_coach_proposed"
                checked={selectedFilters3["is_coach_proposed"] === "1"}
                onChange={(e) => handleRadioTeamChange("is_coach_proposed", e)}
              />
            </div>
          </div>
        );

      case "proposed_coach_full_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="proposed_coach_full_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) => handleChangeTeam(filterKey, e.target.value)}
                disabled={isWhoBroughtProposedDirectorDisabled}
              />
            </div>
          </div>
        );
      case "proposed_coach_agent_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <h6 className="h-20">Name of Associate Agent</h6>
              <input
                name="proposed_coach_agent_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters3[filterKey] || ""}
                onChange={(e) =>
                  handleDateChangeTeam(filterKey, e.target.value)
                }
                disabled={!showTeamAssociateAgentName3}
              />
            </div>
          </div>
        );

      case "who_proposed_coach":
        return (
          <Select
            className="accordion-content"
            name={"who_proposed_coach"}
            placeholder="Who Proposed Coach to Current Team"
            options={optionsForFilters.who_brought_player}
            onChange={(option) =>
              handleSelectTeamChange4("who_proposed_coach", option)
            }
            value={selectedFilters3[filterKey] || null}
          />
        );

      default:
        return null;
    }
  };

  const fetchCities = async (countryName) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryName);
      const cityMap = {};

      const cities = stateList.map((state) => {
        cityMap[state.isoCode] = state.name;
        return {
          value: state.name,
          label: state.name,
        };
      });

      setCities(cities);
      setCityMap(cityMap);
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoadingCities(false);
    }
  };

  const handleCountryChange = async (selectedOption) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      dispatch(
        setSelectedFilters3({
          teamCountry: countryData.isoCode,
          team_country: countryData.name,
          team_city: "",
        })
      );

      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });

      setCities([]);
      await fetchCities(countryData.isoCode);
      setCities1(null);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedFilters3).length > 0) {
      setSelectedCountry({
        value: selectedFilters3?.teamCountry,
        label: selectedFilters3?.team_country,
      });

      setCities1({
        label: selectedFilters3?.team_city,
        value: selectedFilters3?.teamCity,
      });
    }
  }, []);

  const handleCityChange = (selectedOption) => {
    if (!selectedOption) return;

    dispatch(
      setSelectedFilters3({
        teamCity: selectedOption.value,
        team_city: selectedOption.label,
      })
    );

    setCities1(selectedOption);
  };

  const handleSelectTeamChange = (filterKey, option) => {
    dispatch(
      setSelectedFilters3({
        [filterKey]: option,
      })
    );
  };

  const handleSelectTeamChange1 = (filterKey, option) => {
    dispatch(
      setSelectedFilters3({
        who_proposed_player: option,
      })
    );

    if (filterKey === "who_proposed_player") {
      setShowTeamAssociateAgentName1(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters3({
            player_associate_agent_name: "",
          })
        );
      }
    }
  };

  const handleSelectTeamChange2 = (filterKey, option) => {
    dispatch(
      setSelectedFilters3({
        who_proposed_director: option,
      })
    );

    if (filterKey === "who_proposed_director") {
      setShowTeamAssociateAgentName1(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters3({
            proposed_director_agent_name: "",
          })
        );
      }
    }
  };

  const handleSelectTeamChange3 = (filterKey, option) => {
    dispatch(
      setSelectedFilters3({
        who_brought_coach: option,
      })
    );

    if (filterKey === "who_brought_coach") {
      setShowTeamAssociateAgentName2(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters3({
            coach_agent_name: "",
          })
        );
      }
    }
  };

  const handleSelectTeamChange4 = (filterKey, option) => {
    dispatch(
      setSelectedFilters3({
        who_proposed_coach: option,
      })
    );

    if (filterKey === "who_proposed_coach") {
      setShowTeamAssociateAgentName3(option.value === "1");
      if (option.value === "0") {
        dispatch(
          setSelectedFilters3({
            proposed_coach_agent_name: "",
          })
        );
      }
    }
  };

  const handleDateChangeTeam = (key, dateValue) => {
    dispatch(
      setSelectedFilters3({
        [key]: dateValue,
      })
    );
  };

  const handleChangeTeam = (key, dateValue) => {
    dispatch(
      setSelectedFilters3({
        [key]: dateValue,
      })
    );
  };

  const handleSelectChangeTeam = (key, selectedOption) => {
    dispatch(
      setSelectedFilters3({
        [key]: selectedOption,
      })
    );
  };

  const handleRadioTeamChange = (key, e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    // if (key === "Bonus") {
    dispatch(
      setSelectedFilters3({
        [key]: isChecked ? "1" : "0",
      })
    );
  };

  const handleSearchForTeam = async () => {
    try {
      const payload = transformFilters(selectedFilters3);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) =>
                  `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/internal-search-result?type=team&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  // agent filter

  const renderSelectAgent = (key) => {
    const filterKey = agentMainInformation[key].value;

    switch (filterKey) {
      case "country":
        return (
          <Select
            name="country"
            placeholder="Country"
            isSearchable={true}
            value={selectedCountry1}
            onChange={handleCountryChange1}
            options={countries}
          />
        );

      case "city":
        return (
          <Select
            name="city"
            placeholder="City"
            options={cities}
            onChange={handleCityChange1}
            value={cities2 || ""}
            isLoading={loadingCities}
            isDisabled={!selectedCountry1}
          />
        );

      case "name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="name"
                className="accordion-content form-control"
                placeholder="Enter name here"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectAgent1 = (key) => {
    const filterKey = playerRelation[key].value;

    const isAgentPlayerProposed = selectedFilters4["player_from_agent"] !== "1";
    const isAgentPlayertoAgent = selectedFilters4["player_to_agent"] !== "1";
    const isAgentProposedtoPlayer =
      selectedFilters4["proposed_player_to_agent"] !== "1";
    const isProposedPlayerFromAgent =
      selectedFilters4["proposed_player_from_agent"] !== "1";

    switch (filterKey) {
      case "player_from_agent":
        return (
          <div className="licensed-sec">
            <h3>Do I have Player in team from this Agent?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="player_from_agent"
                checked={selectedFilters4["player_from_agent"] === "1"}
                onChange={(e) => handleRadioAgentChange("player_from_agent", e)}
              />
            </div>
          </div>
        );

      case "agent_players_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="agent_players_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentPlayerProposed}
              />
            </div>
          </div>
        );

      case "player_to_agent":
        return (
          <div className="licensed-sec">
            <h3>Does this Agent have any of My Players in team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="player_to_agent"
                checked={selectedFilters4["player_to_agent"] === "1"}
                onChange={(e) => handleRadioAgentChange("player_to_agent", e)}
              />
            </div>
          </div>
        );

      case "my_players_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_players_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentPlayertoAgent}
              />
            </div>
          </div>
        );

      case "proposed_player_to_agent":
        return (
          <div className="licensed-sec">
            <h3>Do I have Proposed any of My Players to this Agent?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="proposed_player_to_agent"
                checked={selectedFilters4["proposed_player_to_agent"] === "1"}
                onChange={(e) =>
                  handleRadioAgentChange("proposed_player_to_agent", e)
                }
              />
            </div>
          </div>
        );

      case "proposed_players_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="proposed_players_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentProposedtoPlayer}
              />
            </div>
          </div>
        );

      case "proposed_player_from_agent":
        return (
          <div className="licensed-sec">
            <h3>Does this Agent Proposed to me any Players?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="proposed_player_from_agent"
                checked={selectedFilters4["proposed_player_from_agent"] === "1"}
                onChange={(e) =>
                  handleRadioAgentChange("proposed_player_from_agent", e)
                }
              />
            </div>
          </div>
        );

      case "my_proposed_players_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_proposed_players_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isProposedPlayerFromAgent}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectAgent2 = (key) => {
    const filterKey = directorRelation[key].value;

    const isAgentPlayertoAgent = selectedFilters4["director_to_agent"] !== "1";
    const isAgentPlayerProposed =
      selectedFilters4["director_from_agent"] !== "1";
    const isAgentProposedtoPlayer =
      selectedFilters4["proposed_director_to_agent"] !== "1";
    const isProposedPlayerFromAgent =
      selectedFilters4["proposed_director_from_agent"] !== "1";

    switch (filterKey) {
      case "director_from_agent":
        return (
          <div className="licensed-sec">
            <h3>Do I have Director in team from this Agent?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="director_from_agent"
                checked={selectedFilters4["director_from_agent"] === "1"}
                onChange={(e) =>
                  handleRadioAgentChange("director_from_agent", e)
                }
              />
            </div>
          </div>
        );

      case "agent_directors_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="agent_directors_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentPlayerProposed}
              />
            </div>
          </div>
        );

      case "director_to_agent":
        return (
          <div className="licensed-sec">
            <h3>Does this Agent have any of My Director in team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="director_to_agent"
                checked={selectedFilters4["director_to_agent"] === "1"}
                onChange={(e) => handleRadioAgentChange("director_to_agent", e)}
              />
            </div>
          </div>
        );

      case "my_directors_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_directors_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentPlayertoAgent}
              />
            </div>
          </div>
        );

      case "proposed_director_to_agent":
        return (
          <div className="licensed-sec">
            <h3>Do I have Proposed any of My Director to this Agent?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="proposed_director_to_agent"
                checked={selectedFilters4["proposed_director_to_agent"] === "1"}
                onChange={(e) =>
                  handleRadioAgentChange("proposed_director_to_agent", e)
                }
              />
            </div>
          </div>
        );

      case "proposed_directors_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="proposed_directors_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentProposedtoPlayer}
              />
            </div>
          </div>
        );

      case "proposed_director_from_agent":
        return (
          <div className="licensed-sec">
            <h3>Does this Agent Proposed to me any Directors?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="proposed_director_from_agent"
                checked={
                  selectedFilters4["proposed_director_from_agent"] === "1"
                }
                onChange={(e) =>
                  handleRadioAgentChange("proposed_director_from_agent", e)
                }
              />
            </div>
          </div>
        );

      case "my_proposed_directors_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_proposed_directors_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isProposedPlayerFromAgent}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSelectAgent3 = (key) => {
    const filterKey = coachRelation[key].value;

    const isAgentPlayertoAgent = selectedFilters4["coach_to_agent"] !== "1";
    const isAgentPlayerProposed = selectedFilters4["coach_from_agent"] !== "1";
    const isAgentProposedtoPlayer =
      selectedFilters4["proposed_coach_to_agent"] !== "1";
    const isProposedPlayerFromAgent =
      selectedFilters4["proposed_coach_from_agent"] !== "1";

    switch (filterKey) {
      case "coach_from_agent":
        return (
          <div className="licensed-sec">
            <h3>Do I have Coach in team from this Agent?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="coach_from_agent"
                checked={selectedFilters4["coach_from_agent"] === "1"}
                onChange={(e) => handleRadioAgentChange("coach_from_agent", e)}
              />
            </div>
          </div>
        );

      case "agent_coaches_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="agent_coaches_name"
                placeholder="Enter full Name"
                className="accordion-content form-control"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentPlayerProposed}
              />
            </div>
          </div>
        );

      case "coach_to_agent":
        return (
          <div className="licensed-sec">
            <h3>Does this Agent have any of My Coach in team?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="coach_to_agent"
                checked={selectedFilters4["coach_to_agent"] === "1"}
                onChange={(e) => handleRadioAgentChange("coach_to_agent", e)}
              />
            </div>
          </div>
        );

      case "my_coaches_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_coaches_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentPlayertoAgent}
              />
            </div>
          </div>
        );

      case "proposed_coach_to_agent":
        return (
          <div className="licensed-sec">
            <h3>Do I have Proposed any of My Coach to this Agent?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="proposed_coach_to_agent"
                checked={selectedFilters4["proposed_coach_to_agent"] === "1"}
                onChange={(e) =>
                  handleRadioAgentChange("proposed_coach_to_agent", e)
                }
              />
            </div>
          </div>
        );

      case "proposed_coaches_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="proposed_coaches_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isAgentProposedtoPlayer}
              />
            </div>
          </div>
        );

      case "proposed_coach_from_agent":
        return (
          <div className="licensed-sec">
            <h3>Does this Agent Proposed to me any Coach?</h3>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="proposed_coach_from_agent"
                checked={selectedFilters4["proposed_coach_from_agent"] === "1"}
                onChange={(e) =>
                  handleRadioAgentChange("proposed_coach_from_agent", e)
                }
              />
            </div>
          </div>
        );

      case "my_proposed_coaches_name":
        return (
          <div className="profile-card">
            <div className="num-inputs outer">
              <input
                name="my_proposed_coaches_name"
                className="accordion-content form-control"
                placeholder="Enter full Name"
                type="text"
                value={selectedFilters4[filterKey] || ""}
                onChange={(e) => handleChangeAgent(filterKey, e.target.value)}
                disabled={isProposedPlayerFromAgent}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleChangeAgent = (key, dateValue) => {
    dispatch(
      setSelectedFilters4({
        [key]: dateValue,
      })
    );
  };

  const handleCountryChange1 = async (selectedOption) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      dispatch(
        setSelectedFilters4({
          teamCountry1 : countryData.isoCode,
          country: countryData.name,
          city: "",
        })
      );

      setSelectedCountry1({
        value: countryData.isoCode,
        label: countryData.name,
      });

      setCities([]);
      await fetchCities(countryData.isoCode);
      setCities1(null);
    }
  };

  const handleCityChange1 = (selectedOption) => {
    if (!selectedOption) return;

    dispatch(
      setSelectedFilters4({
        agentCity : selectedOption.value,
        city: selectedOption.label,
      })
    );
    setCities2(selectedOption);
  };

  useEffect(() => {
    if (Object.keys(selectedFilters4).length > 0) {
      setSelectedCountry1({
        value: selectedFilters4?.teamCountry1,
        label: selectedFilters4?.country,
      });

      setCities2({
        label: selectedFilters4?.city,
        value: selectedFilters4?.agentCity,
      });
    }
  }, []);

  const handleRadioAgentChange = (key, e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    // if (key === "Bonus") {
    dispatch(
      setSelectedFilters4({
        [key]: isChecked ? "1" : "0",
      })
    );
  };

  const handleSearchForAgent = async () => {
    try {
      const payload = transformFilters(selectedFilters4);

      const queryString = Object.keys(payload)
        .filter(
          (key) =>
            payload[key] !== null &&
            payload[key] !== undefined &&
            payload[key].length !== 0
        )
        .map((key) => {
          const value = payload[key];
          if (Array.isArray(value)) {
            return value
              .map(
                (val) =>
                  `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
              )
              .join("&");
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      navigate(`/internal-search-result?type=agent&${queryString}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(setActiveTab1(activeTab1));
  }, [dispatch, activeTab1]);

  const handleTabSelect = (key) => {
    dispatch(setActiveTab1(key));
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Player Search</h2>
              <Link
                to="#"
                className="light-blue-btn"
                onClick={handleClearFilters}
              >
                Clear Filter
              </Link>
            </div>

            <Tabs
              onSelect={handleTabSelect}
              activeKey={activeTab1}
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Players" title="Players" className="accord-tabs">
                <PlayersFilterTab
                  playersMainInformation={playersMainInformation}
                  professionalRelations={professionalRelations}
                  currentContractDetails={currentContractDetails}
                  newContractDetails={newContractDetails}
                  playerProposalDetails={playerProposalDetails}
                  teamsInterestedInPlayer={teamsInterestedInPlayer}
                  playersStatistics={playersStatistics}
                  healthStatus={healthStatus}
                  otherInformation={otherInformation}
                  renderSelect={renderSelect}
                  renderSelect1={renderSelect1}
                  renderSelect2={renderSelect2}
                  renderSelect3={renderSelect3}
                  renderSelect4={renderSelect4}
                  renderSelect5={renderSelect5}
                  renderSelect6={renderSelect6}
                  renderSelect7={renderSelect7}
                  renderSelect8={renderSelect8}
                  handleSearchForPlayer={handleSearchForPlayer}
                />
              </Tab>

              <Tab eventKey="Directors" title="Directors">
                <DirectorFilterTab
                  renderSelectDirector={renderSelectDirector}
                  renderSelectDirector1={renderSelectDirector1}
                  renderSelectDirector2={renderSelectDirector2}
                  renderSelectDirector3={renderSelectDirector3}
                  renderSelectDirector4={renderSelectDirector4}
                  renderSelectDirector5={renderSelectDirector5}
                  handleSearchForDirector={handleSearchForDirector}
                />
              </Tab>
              <Tab eventKey="Teams" title="Teams">
                <TeamFilterTab
                  renderSelectTeam={renderSelectTeam}
                  renderSelectTeam1={renderSelectTeam1}
                  renderSelectTeam2={renderSelectTeam2}
                  renderSelectTeam3={renderSelectTeam3}
                  renderSelectTeam4={renderSelectTeam4}
                  renderSelectTeam5={renderSelectTeam5}
                  renderSelectTeam6={renderSelectTeam6}
                  renderSelectTeam7={renderSelectTeam7}
                  handleSearchForTeam={handleSearchForTeam}
                />
              </Tab>
              <Tab eventKey="Associate Agent" title="Associate Agent">
                <AgentFilterTab
                  renderSelectAgent={renderSelectAgent}
                  renderSelectAgent1={renderSelectAgent1}
                  renderSelectAgent2={renderSelectAgent2}
                  renderSelectAgent3={renderSelectAgent3}
                  handleSearchForAgent={handleSearchForAgent}
                />
              </Tab>

              <Tab eventKey="Coaches" title="Coaches">
                <CoachFilterTab
                  renderSelectCoach={renderSelectCoach}
                  renderSelectCoach1={renderSelectCoach1}
                  renderSelectCoach2={renderSelectCoach2}
                  renderSelectCoach3={renderSelectCoach3}
                  renderSelectCoach4={renderSelectCoach4}
                  renderSelectCoach5={renderSelectCoach5}
                  handleSearchForCoach={handleSearchForCoach}
                />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
