import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { socailSignupandLogin, userLoginAction } from "../Redux/Action/auth";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../config/firebase";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export default function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleSignin = async (e) => {
    e.preventDefault();
    const googleProvider = new GoogleAuthProvider();

    console.log("Initiating Google sign-in");

    try {
      const result = await signInWithPopup(auth, googleProvider);

      if (result.user) {
        let userData = {
          social_id: result?.user?.uid,
          full_name: result?.user?.displayName,
          login_type: 2,
          device_token: result?.user?.accessToken,
          device_type: 2,
          email: result?.user?.email,
        };
        dispatch(socailSignupandLogin(userData)).then((res) => {
          const { _id, token } = res?.payload?.data || {};
          if (res?.payload?.status === 200) {
            if (_id && token) {
              localStorage.setItem("userId", _id);
              localStorage.setItem("token", token);

              // window.location.href = "/home";
              window.location.replace("/home");
              toast.success(res?.payload?.message);
            } else {
              toast.error(res?.payload?.message);
            }
          } else {
            toast.error(res?.payload?.message);
          }
        });
      }
    } catch (error) {
      console.error("Error during login: ", error.message);
    }
  };

  return (
    <div className="main-section">
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Welcome back</h2>
            <p className="sub-16 text-center mb-2">
              Fill your details to login to your account
            </p>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(userLoginAction(values))
                  .then((res) => {
                    if (res?.payload?.status === 200) {
                      const { _id, token, is_profile_created } =
                        res?.payload?.data || {};
                      if (_id && token) {
                        localStorage.setItem("userId", _id);
                        localStorage.setItem("token", token);
  
                        if (is_profile_created === 0) {
                          window.location.href = "/profile-detail";
                          return;
                        }

                        // window.location.href = "/home";
                        window.location.replace("/home");
                        toast.success(res?.payload?.message);
                      } else {
                        toast.error(res?.payload?.message);
                      }
                    } else {
                      if (res?.payload?.message) {
                        toast.error(res?.payload?.message);
                      } else {
                        toast.error(res?.payload?.error_description);
                      }
                    }
                  })
                  .catch((error) => {
                    console.error("Error", error);
                  });
              }}
            >
              {({ errors, touched, handleChange, handleBlur }) => (
                <FormikForm className="cmn-form-fields mt-4">
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Email Address</label>
                    <Field
                      type="text"
                      name="email"
                      placeholder="Enter here"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter here"
                      className={`form-control`}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                    <button
                      type="button"
                      className="pass-show"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <img
                          src={
                            require("../Assets/images/hide_password.svg")
                              .default
                          }
                          alt="Hide Password"
                        />
                      ) : (
                        <img
                          src={require("../Assets/images/eye.svg").default}
                          alt="Show Password"
                        />
                      )}
                    </button>
                  </Form.Group>
                  <div className="d-flex justify-content-end mb-3">
                    <Link to="/forgot-password" className="forgot-password">
                      Forgot Password?
                    </Link>
                  </div>
                  <button type="submit" className="cmn-red-btn">
                    Login
                  </button>
                </FormikForm>
              )}
            </Formik>

            <div className="form-bottom">
              <div className="or-sec">
                <p>Or</p>
              </div>
              <div className="bottom-btns">
                <button type="button" onClick={handleSignin}>
                  <img src={require("../Assets/images/google.svg").default} />
                  <p>Google</p>
                </button>
              </div>
              <div className="no-acc">
                <h4>Don’t have Account? </h4>
                <Link to="/create-account">Sign Up</Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
