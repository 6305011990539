
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
apiKey: "AIzaSyDI64D_Qg9WN4qDwBtH53G-4QcQTAFdBpc",
authDomain: "sports-fan-89ee3.firebaseapp.com",
projectId: "sports-fan-89ee3",
storageBucket: "sports-fan-89ee3.appspot.com",
messagingSenderId: "8833439142",
appId: "1:8833439142:web:2363f4fa3405d4cd4b6b67"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;