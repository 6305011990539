import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const LogoutModal = (props) => {
  const handleYes = () => {
    props.onConfirm();
    props.onHide();
  };

  const handleNo = () => {
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        className="cmn-modal"
        size="xs"
        // show={show}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="create-met">
            <div className="cmn-form-fields">
              <Row>
                <Col lg={12}>
                  {" "}
                  <Form.Group className="form-inner text-center">
                    <label className="sub-16 mb-2 ">
                      Are you sure You Want to Logout ?
                    </label>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: "none" }}
          className="d-flex flex-nowrap"
        >
          <Button variant="primary" className="w-100" onClick={handleYes}>
            Yes
          </Button>
          <Button variant="primary" className="w-100" onClick={handleNo}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LogoutModal;
