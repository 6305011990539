// import React from 'react'
// import { Navigate, Outlet } from 'react-router-dom'

// const PrivatePublicRoute = () => {
// const localStorageToken = localStorage.getItem('token')

// return (
// !localStorageToken ? <Outlet /> : <Navigate to={'/home'} />
// )
// }

// export default PrivatePublicRoute

import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivatePublicRoute = () => {
  const localStorageToken = localStorage.getItem("token");
  const location = useLocation();

  // useEffect(() => {
  //   if (localStorageToken) {
  //     window.history.replaceState({}, "", "/home");
  //   }
  // }, [location, localStorageToken]);
  
  useEffect(() => {
    if (localStorageToken && location.pathname === "/") {
      window.history.replaceState({}, "", "/home");
      window.location.reload(); 
    }
  }, [location, localStorageToken]);

  return !localStorageToken ? <Outlet /> : <Navigate to="/home" />;
};

export default PrivatePublicRoute;
