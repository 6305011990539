import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editTeamAction, getTeamDataById } from "../Redux/Action/teamAction";

const validationSchema = Yup.object({
  address: Yup.string().required("required"),
  email: Yup.string().email("Invalid email format").required("required"),
  phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  instagram: Yup.string().url("Invalid URL format"),
  facebook: Yup.string().url("Invalid URL format"),
  snapchat: Yup.string().url("Invalid URL format"),
  twitter: Yup.string().url("Invalid URL format"),
});

const AddTeamEight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const teamId = id && Decryptedid(atob(id));

  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

  const handleSubmit = (values) => {
    console.log("dfgdfgdf", values);

    let formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("teamId", teamId);
    formData.append("is_profile_completed", 9);

    dispatch(editTeamAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Success");
        localStorage.removeItem("teamId");
        navigate(`/my-team`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId }));
    }
  }, [dispatch]);

  const initialValues = {
    address: getTeamDetails?.address || "",
    email: getTeamDetails?.email || "",
    phone_number: getTeamDetails?.phone_number || "",
    instagram: getTeamDetails?.instagram || "",
    facebook: getTeamDetails?.facebook || "",
    snapchat: getTeamDetails?.snapchat || "",
    twitter: getTeamDetails?.twitter || "",

    current_contract_other_notes:
      getTeamDetails?.current_contract_other_notes || "",
    contact_info_agent_notes: getTeamDetails?.contact_info_agent_notes || "",

    web: getTeamDetails?.web || "",
    contact_person_name: getTeamDetails?.contact_person_name || "",
    contact_person_phone_number:
      getTeamDetails?.contact_person_phone_number || "",
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Contact Information</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <FormikForm>
                  <div className="common-outer mt-4">
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="address"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter address"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="email"
                            as={Form.Control}
                            type="email"
                            placeholder="Enter email here"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Enter phone number"
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={4} className="mt-3">
                        <Form.Group className="form-inner">
                          <Field
                            name="fax"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter fax"
                          />
                          <ErrorMessage
                            name="fax"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={4} className="mt-3">
                        <Form.Group className="form-inner">
                          <Field
                            name="web"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter web"
                          />
                          <ErrorMessage
                            name="web"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Social Media Links</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="instagram"
                            as={Form.Control}
                            type="url"
                            placeholder="Instagram"
                          />
                          <ErrorMessage
                            name="instagram"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="facebook"
                            as={Form.Control}
                            type="url"
                            placeholder="Facebook"
                          />
                          <ErrorMessage
                            name="facebook"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="snapchat"
                            as={Form.Control}
                            type="url"
                            placeholder="Snapchat"
                          />
                          <ErrorMessage
                            name="snapchat"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} className="mt-3">
                        <Form.Group className="form-inner">
                          <Field
                            name="twitter"
                            as={Form.Control}
                            type="url"
                            placeholder="twitter"
                          />
                          <ErrorMessage
                            name="twitter"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Contact Person</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="contact_person_name"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter name"
                          />
                          <ErrorMessage
                            name="contact_person_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="contact_person_phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Phone no enter here"
                          />
                          <ErrorMessage
                            name="contact_person_phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <Form.Group className="mb-3 form-inner">
                      <Field
                        as="textarea"
                        name="current_contract_other_notes"
                        rows={3}
                        placeholder="Write here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="current_contract_other_notes"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>

                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button type="submit">Save</button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddTeamEight;
