import { createSlice } from "@reduxjs/toolkit";
import {
  globalAgentSearchAction,
  globalCoachSearchAction,
  globalDirectorSearchAction,
  globalPlayerSearchAction,
} from "../Action/globalSearchAction";

const initialState = {
  loading: false,
  globalSearchResult: [],
  globalSearchDirectorResult: [],
  globalSearchAgentResult: [],
  globalSearchCoachResult: [],
  selectedFilters: {},
  selectedFilters1: {},
  selectedFilters2: {},
  selectedFilters3: {},
  error: false,
  activeTab: 'Players',
};

const globalSearchAuthSlice = createSlice({
  name: "globalSearchAuth",
  initialState,
  reducers: {
    clearSearchPlayerData: (state) => {
      state.globalSearchResult = [];
    },

    clearSearchDirectorData: (state) => {
      state.globalSearchDirectorResult = [];
    },

    clearSearchAgentData: (state) => {
      state.globalSearchAgentResult = [];
    },

    clearSearchCoachData: (state) => {
      state.globalSearchCoachResult = [];
    },

    // global filter
    // setSelectedFilters: (state, action) => {
    //   state.selectedFilters = action.payload;
    // },

     setSelectedFilters: (state, action) => {
      state.selectedFilters = { ...state.selectedFilters, ...action.payload };
    },
    setSelectedFilters1: (state, action) => {
      state.selectedFilters1 = {...state.selectedFilters1, ...action.payload}
    },
    setSelectedFilters2: (state, action) => {
      state.selectedFilters2 =  {...state.selectedFilters2, ...action.payload}
    },
    setSelectedFilters3: (state, action) => {
      state.selectedFilters3 =  {...state.selectedFilters3, ...action.payload}
    },


    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(globalPlayerSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(globalPlayerSearchAction.fulfilled, (state, { payload }) => {
        state.globalSearchResult = payload.data;
        state.loading = false;
      })
      .addCase(globalPlayerSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // for directors
      .addCase(globalDirectorSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(globalDirectorSearchAction.fulfilled, (state, { payload }) => {
        state.globalSearchDirectorResult = payload.data;
        state.loading = false;
      })
      .addCase(globalDirectorSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // for agent
      .addCase(globalAgentSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(globalAgentSearchAction.fulfilled, (state, { payload }) => {
        state.globalSearchAgentResult = payload.data;
        state.loading = false;
      })
      .addCase(globalAgentSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // for coach

      .addCase(globalCoachSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(globalCoachSearchAction.fulfilled, (state, { payload }) => {
        state.globalSearchCoachResult = payload.data;
        state.loading = false;
      })
      .addCase(globalCoachSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  clearSearchPlayerData,
  clearSearchDirectorData,
  clearSearchAgentData,
  clearSearchCoachData,
  setSelectedFilters,
  setSelectedFilters1,
  setSelectedFilters2,
  setSelectedFilters3,
  setActiveTab 
} = globalSearchAuthSlice.actions;
export default globalSearchAuthSlice.reducer;
