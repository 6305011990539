import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row, Form } from "react-bootstrap";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "../CommonModals/ProgressBar";

const validationSchema = Yup.object({
  address: Yup.string().required("required"),
  email: Yup.string().email("Invalid email format").required("required"),
  phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  instagram: Yup.string().url("Invalid URL format"),
  facebook: Yup.string().url("Invalid URL format"),
  snapchat: Yup.string().url("Invalid URL format"),
  twitter: Yup.string().url("Invalid URL format"),
  player_lawyer_name: Yup.string().required("required"),
  player_lawyer_phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  player_managing_company_name: Yup.string().required("required"),
  player_managing_company_phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  player_sponsors_name: Yup.string().required("required"),
  player_sponsors_phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  contact_info_other_notes: Yup.string(),
  contact_info_agent_notes: Yup.string(),
});

const ContactInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  console.log(playerData, "dfgdfgdfgdfgdfgdfgd");

  const handleSubmit = (values) => {
    console.log("Form values:", values);

    let formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("playerId", paylerId);
    formData.append("is_profile_completed", 8);

    dispatch(editPlayerAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Success");
        localStorage.removeItem("playerId");
        navigate("/my-players",  { replace: true });
        
        window.history.pushState(null, null, "/my-players");
    
        // Prevent further back navigation by listening to the popstate event
        window.addEventListener('popstate', function (event) {
          window.history.pushState(null, null, "/my-players");
        });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const initialValues = {
    address: playerData?.address || "",
    email: playerData?.email || "",
    phone_number: playerData?.phone_number || "",
    instagram: playerData?.instagram || "",
    facebook: playerData?.facebook || "",
    snapchat: playerData?.snapchat || "",
    twitter: playerData?.twitter || "",
    player_lawyer_name: playerData?.player_lawyer_name || "",
    player_lawyer_phone_number: playerData?.player_lawyer_phone_number || "",
    player_managing_company_name:
      playerData?.player_managing_company_name || "",
    player_managing_company_phone_number:
      playerData?.player_managing_company_phone_number || "",
    player_sponsors_name: playerData?.player_sponsors_name || "",
    player_sponsors_phone_number:
      playerData?.player_sponsors_phone_number || "",
    contact_info_other_notes: playerData?.contact_info_other_notes || "",
    contact_info_agent_notes: playerData?.contact_info_agent_notes || "",
  };


  const handleBack = () => {
    navigate(`/other-information/${Encryptedid(paylerId)}`);
  }

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
          <ProgressBar currentStep={8} totalSteps={8} />
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Contact Information</h2>
            </div>
            <Formik
              // initialValues={{
              //   address: "",
              //   email: "",
              //   phone_number: "",
              //   instagram: "",
              //   facebook: "",
              //   snapchat: "",
              //   twitter: "",
              //   player_lawyer_name: "",
              //   player_lawyer_phone_number: "",
              //   player_managing_company_name: "",
              //   player_managing_company_phone_number: "",
              //   player_sponsors_name: "",
              //   player_sponsors_phone_number: "",
              //   contact_info_other_notes: "",
              //   contact_info_agent_notes: "",
              // }}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <FormikForm>
                  <div className="common-outer mt-4">
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="address"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter address"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="email"
                            as={Form.Control}
                            type="email"
                            placeholder="Enter email here"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Enter phone number"
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Social Media Links</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="instagram"
                            as={Form.Control}
                            type="url"
                            placeholder="Instagram"
                          />
                          <ErrorMessage
                            name="instagram"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="facebook"
                            as={Form.Control}
                            type="url"
                            placeholder="Facebook"
                          />
                          <ErrorMessage
                            name="facebook"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="snapchat"
                            as={Form.Control}
                            type="url"
                            placeholder="Snapchat"
                          />
                          <ErrorMessage
                            name="snapchat"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} className="mt-3">
                        <Form.Group className="form-inner">
                          <Field
                            name="twitter"
                            as={Form.Control}
                            type="url"
                            placeholder="twitter"
                          />
                          <ErrorMessage
                            name="twitter"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Player’s Lawyer</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="player_lawyer_name"
                            as={Form.Control}
                            type="text"
                            placeholder="Lawyer’s name enter here"
                          />
                          <ErrorMessage
                            name="player_lawyer_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="player_lawyer_phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Phone number enter here"
                          />
                          <ErrorMessage
                            name="player_lawyer_phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Managing Company</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="player_managing_company_name"
                            as={Form.Control}
                            type="text"
                            placeholder="Company name enter here"
                          />
                          <ErrorMessage
                            name="player_managing_company_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="player_managing_company_phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Phone number enter here"
                          />
                          <ErrorMessage
                            name="player_managing_company_phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Sponsors</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="player_sponsors_name"
                            as={Form.Control}
                            type="text"
                            placeholder="Sponsor’s name enter here"
                          />
                          <ErrorMessage
                            name="player_sponsors_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="player_sponsors_phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Phone number enter here"
                          />
                          <ErrorMessage
                            name="player_sponsors_phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <Form.Group className="mb-3 form-inner">
                      <Field
                        as="textarea"
                        name="contact_info_other_notes"
                        rows={3}
                        placeholder="Write here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="contact_info_other_notes"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Agents Notes</h3>
                    </div>
                    <Form.Group className="mb-3 form-inner">
                      <Field
                        as="textarea"
                        name="contact_info_agent_notes"
                        rows={3}
                        placeholder="Write here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="contact_info_agent_notes"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                    <button type="button" onClick={handleBack}>Back</button>
                      <button type="submit">Save</button>

                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default ContactInformation;
