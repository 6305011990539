import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import backgroundImage from "../Assets/images/stadium.png";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../utils/Becrypt";
import { getAgentDataById, getAgentDetailsById } from "../Redux/Action/agentAction";
import { AvailabilityStatus } from "../utils/rawjson";

export default function GlobalSearchAgentProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { id } = useParams();
  const agentId = id && Decryptedid(atob(id));

  const agentData = useSelector((e) => e.agentAuthData.agentDetail);

  console.log(agentData, "Sdfsdfsd3423423423");

  const backgroundImageStyle = {
    // backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${agentData?.banner_image})`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  useEffect(() => {
    if (agentId) {
      dispatch(getAgentDetailsById({ id: agentId })); //changeeeeeee
    }
  }, [dispatch]);

  const copyToClipboard = () => {
    if (agentData?.unique_id) {
      navigator.clipboard
        .writeText(agentData.unique_id)
        .then(() => {
          console.log("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Agent </h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {/* <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${agentData?.profile_image}`}
                    /> */}
                    <img src={require("../Assets/images/global-agent.png")} />
                    <div>
                      <h2> {agentData?.agent_name}</h2>
                      <div className="d-flex align-items-center mb-1 mt-3">
                        <p className="mb-0">Agent ID:{agentData?.unique_id}</p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                      <div className="d-flex align-items-center mb-4 ">
                        {/* <p className="mb-0">Company: Manchester</p> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center flex-column"
                >
                  <div className="agent-socio-icons">
                    <Link to="#">
                      <img
                        src={require("../Assets/images/fbwhite.svg").default}
                      />
                    </Link>
                    <Link to="#">
                      <img
                        src={require("../Assets/images/snapcht.svg").default}
                      />
                    </Link>
                    <Link to="#">
                      <img
                        src={require("../Assets/images/instagrm.svg").default}
                      />
                    </Link>
                  </div>
                  <div className="agent-socio-icons mt-3">
                    <Link to="#">
                      {/* <img
                        src={require("../Assets/images/country.svg").default}
                      /> */}
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="mt-4">
              <Col lg={4}>
                <div className="search-cmn-box">
                  <h2>Profile Details</h2>
                  <div className="search-inner-box mt-2">
                    <ul>
                      <li>
                        <h4>City:</h4>
                        <p>{agentData?.city}</p>
                      </li>
                      <li>
                        <h4>Country:</h4>
                        <p>{agentData?.country}</p>
                      </li>
                      <li>
                        <h4>Email Address:</h4>
                        <p>{agentData?.email}</p>
                      </li>
                      <li>
                        <h4>Website:</h4>
                        <p>{agentData?.website_link}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                {" "}
                <div className="search-cmn-box">
                  {Array.isArray(agentData?.players) && agentData?.players?.length > 0 ? (
                    <h2>Players</h2>
                  ) : (
                    ""
                  )}
                  <div className="search-inner-box mt-2">
                    {Array.isArray(agentData?.players) && agentData?.players.map((elem, index) => {
                      return (
                        <div className="play-inner-box" key={index}>
                          <div className="d-flex">
                            {elem?.gender === 0 ?
                              <img src={require("../Assets/images/user.png")} /> :
                              <img src={require("../Assets/images/player-female.png")} />
                            }
                            <div className="ms-3">
                              <h4>{elem?.full_name}</h4>
                              <p>
                                Player ID: <span>{elem?.unique_id}</span>
                              </p>
                            </div>
                          </div>
                          <div className="badge-img">
                            <img src={require("../Assets/images/tiger.png")} />
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </div>
              </Col>
              <Col lg={4}>
                {" "}
                <div className="search-inner-box p-0 mt-4">
                  <div className="play-images">
                    <img src={require("../Assets/images/smllbanner.png")} />
                    <div className="players-value inner-tab">
                      <h3>CHECK THE MOST VALUABLE PLAYERS IN THE WORLD</h3>
                    </div>
                  </div>{" "}
                </div>
                <Col lg={12} className="mt-5 d-flex justify-content-center ">
                  <div className="comp-btn msg-agent">
                    <button>Message Agent</button>
                  </div>
                </Col>
              </Col>
              <Col lg={4} className="mt-3">
                {" "}
                <div className="search-cmn-box">
                  {Array.isArray(agentData?.directors) && agentData?.directors?.length > 0 ? (
                    <h2>Directors</h2>
                  ) : (
                    ""
                  )}
                  <div className="search-inner-box mt-2">
                    {Array.isArray(agentData?.directors) && agentData?.directors.map((elem, index) => {
                      return (
                        <div className="play-inner-box" key={index}>
                          <div className="d-flex">
                            {elem?.gender === 0 ?
                              <img src={require("../Assets/images/director.png")} /> :
                              <img src={require("../Assets/images/female-director.png")} />
                            }

                            <div className="ms-3">
                              <h4>{elem?.full_name}</h4>
                              <p>
                                Player ID: <span>{elem?.unique_id}</span>
                              </p>
                            </div>
                          </div>
                          <div className="badge-img">
                            <img src={require("../Assets/images/tiger.png")} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                {" "}
                <div className="search-cmn-box">
                  {Array.isArray(agentData?.coaches) && agentData?.coaches?.length > 0 ? (
                    <h2>Coaches</h2>
                  ) : (
                    ""
                  )}
                  {Array.isArray(agentData?.coaches) && agentData?.coaches.map((elem, index) => {
                    return (
                      <div className="search-inner-box mt-2" key={index}>
                        <div className="play-inner-box">
                          <div className="d-flex">
                            {elem?.gender === 0 ?
                              <img src={require("../Assets/images/coach-male.png")} /> :
                              <img src={require("../Assets/images/coach-female.png")} />
                            }
                            <div className="ms-3">
                              <h4>{elem?.full_name}</h4>
                              <p>
                                Player ID: <span>{elem?.unique_id}</span>
                              </p>
                            </div>
                          </div>
                          <div className="badge-img">
                            <img src={require("../Assets/images/tiger.png")} />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
