import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { filterKeyPlayers } from "../utils/rawjson";

export default function PlayerFilter({ onApply, onClear, changeTabs }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({});

  const handleFilterChange = (filterName, newValue) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: newValue,
    }));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialFilters = {};

    Object.entries(filterKeyPlayers).forEach(([key, { value }]) => {
      if (urlParams.has(value)) {
        initialFilters[value] = urlParams.get(value) === "true";
      }
    });

    setSelectedFilters(initialFilters);
  }, []);

  const applyFilters = () => {
    onApply(selectedFilters);
    changeTabs("Professional");
    setIsFilterOpen(!isFilterOpen);
  };

  const clearFilters = () => {
    setSelectedFilters({});
    onClear();
    changeTabs("Professional");
    window.history.replaceState(null, "", window.location.pathname);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <div className="card-heading mb-3 d-flex justify-content-between align-items-center compare-team">
        <h2>Compare the best</h2>
        <p
          className="filter"
          onClick={toggleFilter}
          style={{ cursor: "pointer" }}
        >
          <img src={require("../Assets/images/filt.svg").default} />
        </p>
      </div>

      {isFilterOpen && (
        <div className="cmn-card">
          <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
            <h2>Filters</h2>
            <button className="light-blue-btn" onClick={clearFilters}>
              Clear Filter
            </button>
          </div>

          <Row>
            {Object.entries(filterKeyPlayers).map(([key, { label, value }]) => (
              <Col lg={4} className="mb-3">
                <div className="licensed-sec" key={key}>
                  <h3 htmlFor={`${value}-toggle`}>{label}</h3>
                  <div className="form-check form-switch">
                    <input
                      id={`${value}-toggle`}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      name={value}
                      checked={!!selectedFilters[value]}
                      onChange={() =>
                        handleFilterChange(value, !selectedFilters[value])
                      }
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <Col lg={12} className="d-flex justify-content-end mt-4">
            <button className="light-blue-btn-big" onClick={applyFilters}>
              Apply
            </button>
          </Col>
        </div>
      )}
    </>
  );
}
