import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Country, State } from "country-state-city";
import toast from "react-hot-toast";
import { Encryptedid } from "../utils/Becrypt";
import {
  createAgentAction,
  editAgentAction,
  getAgentDataById,
} from "../Redux/Action/agentAction";

export default function AddAssociateAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cities, setCities] = useState([]);
  const [cities1, setCities1] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  const getAgentDetailById = useSelector((e) => e.agentAuthData.agentDetail);

  const validationSchema = Yup.object().shape({
    profile_image: Yup.mixed().required("required"),
    banner_image: Yup.mixed().required("required"),

    agent_name: Yup.mixed().required("required"),
    email: Yup.mixed().required("required"),
    country: Yup.string().required("required"),
    city: Yup.string().required("required"),
    phone_number: Yup.string().required("required"),
  });

  const processFormData = (values) => {
    return {
      ...values,
      city: values.city || "",
    };
  };

  const handleSubmit = (values) => {
    const processedValues = processFormData(values);

    let agentId = localStorage.getItem("agentId");

    if (agentId) {
      const formData = new FormData();

      Object.entries(processedValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("agentId", agentId);
      formData.append("is_profile_completed", 1);

      dispatch(editAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate(
            `/add-associate-two/${Encryptedid(res?.payload?.data?._id)}`
          );
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const formData = new FormData();

      Object.entries(processedValues).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("is_profile_completed", 1);

      dispatch(createAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate(
            `/add-associate-two/${Encryptedid(res?.payload?.data?._id)}`
          );
          localStorage.setItem("agentId", res?.payload?.data?._id);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const handleFileChange = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  const initialValues = {
    profile_image: getAgentDetailById?.profile_image || "",
    banner_image: getAgentDetailById?.banner_image || "",
    agent_name: getAgentDetailById?.agent_name || "",
    country: getAgentDetailById?.country || "",
    city: getAgentDetailById?.city || "",
    phone_number: getAgentDetailById?.phone_number || "",
    web: getAgentDetailById?.web || "",
    email: getAgentDetailById?.email || "",
    address: getAgentDetailById?.address || "",
    fax: getAgentDetailById?.fax || "",
    instagram: getAgentDetailById?.instagram || "",
    facebook: getAgentDetailById?.facebook || "",
    snapchat: getAgentDetailById?.snapchat || "",
    twitter: getAgentDetailById?.twitter || "",
  };

  useEffect(() => {
    let agentId = localStorage.getItem("agentId");
    if (agentId) {
      dispatch(getAgentDataById({ id: agentId }));
    }
  }, [dispatch]);

  // const fetchCities = async (countryName) => {
  //   setLoadingCities(true);
  //   try {
  //     const stateList = State.getStatesOfCountry(countryName);
  //     const cityMap = {};

  //     const cities = stateList.map((state) => {
  //       cityMap[state.isoCode] = state.name;
  //       return {
  //         value: state.name,
  //         label: state.name,
  //       };
  //     });

  //     setCities(cities);
  //     setCityMap(cityMap);
  //   } catch (error) {
  //     console.error("Error fetching cities:", error);
  //   } finally {
  //     setLoadingCities(false);
  //   }
  // };

  // const handleCountryChange = async (
  //   selectedOption,
  //   { setFieldValue, setFieldTouched }
  // ) => {
  //   if (!selectedOption) return;

  //   const countryData = Country.getAllCountries().find(
  //     (country) => country.isoCode === selectedOption.value
  //   );

  //   if (countryData) {
  //     setSelectedCountry({
  //       value: countryData.isoCode,
  //       label: countryData.name,
  //     });

  //     setFieldValue("country", countryData.name);
  //     setFieldValue("city", "");
  //     setCities([]);
  //     await fetchCities(countryData.isoCode);
  //     setCities1(null);
  //     setFieldTouched("country", false);
  //   }
  // };

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  // useEffect(() => {
  //   if (getAgentDetailById?.country) {
  //     const countryOption = countries.find(
  //       (country) => country.label === getAgentDetailById?.country
  //     );
  //     setSelectedCountry(countryOption || null);

  //     if (countryOption) {
  //       fetchCities(countryOption.value);
  //     }
  //   }
  // }, [getAgentDetailById, countries]);

  // useEffect(() => {
  //   if (getAgentDetailById?.city) {
  //     const selectedCityData = cities?.find(
  //       (city) => city.label === getAgentDetailById.city
  //     );

  //     if (selectedCityData) {
  //       setCities1(selectedCityData);
  //     }
  //   }
  // }, [cities, getAgentDetailById]);

  // useEffect(() => {
  //   if (selectedCountry) {
  //     fetchCities(selectedCountry.value);
  //   } else {
  //     setCities([]);
  //     setCities1(null);
  //   }
  // }, [selectedCountry]);

  const fetchCities = async (countryCode) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryCode);
      const cities = stateList.map((state) => ({
        value: state.name,
        label: state.name,
      }));
      setCities(cities);
      setCityMap(stateList.reduce((map, state) => {
        map[state.name] = state.isoCode;
        return map;
      }, {}));
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoadingCities(false);
    }
  };

  const handleCountryChange = async (selectedOption, { setFieldValue, setFieldTouched }) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });

      setFieldValue("country", countryData.name);
      setFieldValue("city", "");
      setCities([]);
      await fetchCities(countryData.isoCode);
      setFieldTouched("country", false);
    }
  };

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  useEffect(() => {
    if (getAgentDetailById?.country) {
      const countryOption = countries.find(
        (country) => country.label === getAgentDetailById?.country
      );
      setSelectedCountry(countryOption || null);

      if (countryOption) {
        fetchCities(countryOption.value);
      }
    }
  }, [getAgentDetailById, countries]);

  useEffect(() => {
    if (selectedCountry) {
      fetchCities(selectedCountry.value);
    } else {
      setCities([]);
      setCities1(null);
    }
  }, [selectedCountry]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>My Associate Agent</h2>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
               validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, setFieldTouched }) => {
                return (
                  <FormikForm>
                    <div className="common-outer">
                      <Row>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Logo</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="profile_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "profile_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>

                              <div className="image-upload-placeholder">
                                {values?.profile_image &&
                                typeof values?.profile_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.profile_image &&
                                  values.profile_image instanceof File ? (
                                  <img
                                    src={
                                      values?.profile_image
                                        ? URL.createObjectURL(
                                            values?.profile_image
                                          )
                                        : ""
                                    }
                                    className="prev-img"
                                    alt="Profile Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.profile_image &&
                                (typeof values.profile_image === "string" ||
                                  values.profile_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("profile_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="profile_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Banner Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="banner_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <div className="image-upload-placeholder">
                                {values?.banner_image &&
                                typeof values.banner_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.banner_image &&
                                  values.banner_image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(
                                      values.banner_image
                                    )}
                                    className="prev-img"
                                    alt="Banner Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.banner_image &&
                                (typeof values.banner_image === "string" ||
                                  values.banner_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("banner_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="banner_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    </div>

                    <div className="common-outer mt-4">
                      <h3 className="h-20">Personal Details</h3>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="agent_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter name"
                              />
                              <ErrorMessage
                                name="agent_name"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                name="country"
                                placeholder="Country"
                                isSearchable={true}
                                value={selectedCountry}
                                onChange={(e) =>
                                  handleCountryChange(e, {
                                    setFieldValue,
                                    setFieldTouched,
                                  })
                                }
                                options={countries}
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="profile-card mb-3">
                              <Select
                                // name="city"
                                // placeholder="City"
                                // options={cities}
                                // onChange={(option) => {
                                //   setFieldValue("city", option);
                                //   setCities1(option);
                                // }}
                                // value={cities1 || ""}
                                // isLoading={loadingCities}
                                // isDisabled={!selectedCountry}
                                options={cities}
                                onChange={(selectedOption) => setFieldValue("city", selectedOption?.value || "")}
                                value={cities?.find(city => city.value === values.city)}
                                isDisabled={!cities.length}
                                placeholder="City"
                                name="city"
                              />
                               <ErrorMessage
                                name="city"
                                component="div"
                                className="text-danger"
                              /> 
                            </div>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="phone_number"
                                type="number"
                                className="form-control"
                                placeholder="Enter phone number"
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="fax"
                                type="text"
                                className="form-control"
                                placeholder="Enter fax"
                              />
                              <ErrorMessage
                                name="fax"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-inner mb-3">
                              <Field
                                name="email"
                                as={Form.Control}
                                type="email"
                                placeholder="Enter email here"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-inner mb-3">
                              <Field
                                name="address"
                                as={Form.Control}
                                type="text"
                                placeholder="Enter address"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                name="web"
                                type="text"
                                className="form-control"
                                placeholder="Enter web"
                              />
                              <ErrorMessage
                                name="web"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Social Media Links</h3>
                      </div>
                      <Row>
                        <Col lg={4}>
                          <Form.Group className="form-inner mb-3">
                            <Field
                              name="instagram"
                              as={Form.Control}
                              type="url"
                              placeholder="Instagram"
                            />
                            <ErrorMessage
                              name="instagram"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="form-inner mb-3">
                            <Field
                              name="facebook"
                              as={Form.Control}
                              type="url"
                              placeholder="Facebook"
                            />
                            <ErrorMessage
                              name="facebook"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="form-inner mb-3">
                            <Field
                              name="snapchat"
                              as={Form.Control}
                              type="url"
                              placeholder="Snapchat"
                            />
                            <ErrorMessage
                              name="snapchat"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4} className="mt-3">
                          <Form.Group className="form-inner">
                            <Field
                              name="twitter"
                              as={Form.Control}
                              type="url"
                              placeholder="X"
                            />
                            <ErrorMessage
                              name="twitter"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button type="submit">Next</button>
                      </div>
                    </Col>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
