import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { staticDataAction } from "../Redux/Action/staticAction";
import {
  globalAgentSearchAction,
  globalCoachSearchAction,
  globalDirectorSearchAction,
  globalPlayerSearchAction,
} from "../Redux/Action/globalSearchAction";
import { Encryptedid } from "../utils/Becrypt";
import {
  clearSearchAgentData,
  clearSearchCoachData,
  clearSearchDirectorData,
  clearSearchPlayerData,
} from "../Redux/Reducers/globalSearchSlice";

export default function GlobalSearchResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const globalSearchedData = useSelector(
    (e) => e.globalSearchAuthData.globalSearchResult
  );

  const globalDirectorSearchedData = useSelector(
    (e) => e.globalSearchAuthData.globalSearchDirectorResult
  );

  const globalAgentSearchedData = useSelector(
    (e) => e.globalSearchAuthData.globalSearchAgentResult
  );

  const globalCoachSearchedData = useSelector(
    (e) => e.globalSearchAuthData.globalSearchCoachResult
  );

  console.log(globalCoachSearchedData, "sdfsdfsdfsd234234234");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const queryParams = {};
    let type = null;

    for (let [key, value] of searchParams.entries()) {
      if (key === "type") {
        type = value;
        continue;
      }

      if (key.endsWith("[]")) {
        const trimmedKey = key.slice(0, -2);
        if (!queryParams[trimmedKey]) {
          queryParams[trimmedKey] = [];
        }
        queryParams[trimmedKey].push(value);
      } else if (queryParams[key]) {
        queryParams[key] = Array.isArray(queryParams[key])
          ? [...queryParams[key], value]
          : [queryParams[key], value];
      } else {
        queryParams[key] = value;
      }
    }

    if (type === "player") {
      dispatch(clearSearchDirectorData());
      dispatch(clearSearchAgentData());
      dispatch(clearSearchCoachData());

      dispatch(globalPlayerSearchAction(queryParams));
    } else if (type === "director") {
      dispatch(clearSearchPlayerData());
      dispatch(clearSearchAgentData());
      dispatch(clearSearchCoachData());

      dispatch(globalDirectorSearchAction(queryParams));
    } else if (type === "agent") {
      dispatch(clearSearchPlayerData());
      dispatch(clearSearchDirectorData());
      dispatch(clearSearchCoachData());
      dispatch(globalAgentSearchAction(queryParams));
    } else {
      dispatch(clearSearchAgentData());
      dispatch(clearSearchPlayerData());
      dispatch(clearSearchDirectorData());

      dispatch(globalCoachSearchAction(queryParams));
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2">
              <div className="play-card-sec">
                <h2>Global Search Result</h2>

                {/* <div className="play-scroll-box mt-3">
                  <Row>
                    {globalSearchedData?.length > 0 ? (
                      globalSearchedData?.map((elem, i) => (
                        <Col lg={4}>
                          <div
                            className="play-inner-box"
                            key={i}
                            onClick={() =>
                              navigate(
                                `/global-player-profile/${Encryptedid(
                                  elem?._id
                                )}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                              />
                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Player ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                              />
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div className="no-data-found">No Data</div>
                    )}
                  </Row>

                  <Row>
                    {globalDirectorSearchedData?.length > 0 ? (
                      globalDirectorSearchedData?.map((elem, i) => (
                        <Col lg={4}>
                          <div
                            className="play-inner-box"
                            key={i}
                            onClick={() =>
                              navigate(
                                `/global-director-profile/${Encryptedid(
                                  elem?._id
                                )}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                              />
                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Director ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                              />
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div className="no-data-found">No Data</div>
                    )}
                  </Row>
                </div> */}

                {globalSearchedData?.length > 0 &&
                !globalDirectorSearchedData?.length &&
                !globalAgentSearchedData?.length && !globalCoachSearchedData.length ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {globalSearchedData?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box"
                            onClick={() =>
                              navigate(
                                `/global-player-profile/${Encryptedid(
                                  elem?._id
                                )}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png") }  alt="" />
                              {/* <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                                alt={`${elem?.full_name} profile`}
                              /> */}
                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Player ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : globalDirectorSearchedData?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {globalDirectorSearchedData?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box"
                            onClick={() =>
                              navigate(
                                `/global-director-profile/${Encryptedid(
                                  elem?._id
                                )}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                            <img src={require("../Assets/images/user.png") }  alt="" />
                              {/* <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                                alt={`${elem?.full_name} profile`}
                              /> */}
                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Director ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : globalAgentSearchedData?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {globalAgentSearchedData?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box"
                            onClick={() =>
                              navigate(
                                `/global-agent-profile/${Encryptedid(
                                  elem?._id
                                )}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                            <img src={require("../Assets/images/user.png") }  alt="" />
                              {/* <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                                alt={`${elem?.agent_name} profile`}
                              /> */}
                              <div className="ms-3">
                                <h4>{elem?.agent_name}</h4>
                                <p>
                                  Agent ID:{" "}
                                  <span>{elem?.unique_id?.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : globalCoachSearchedData?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {globalCoachSearchedData?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box"
                            onClick={() =>
                              navigate(
                                `/global-coach-profile/${Encryptedid(
                                  elem?._id
                                )}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                            <img src={require("../Assets/images/user.png") }  alt="" />
                              {/* <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                                alt={`${elem?.full_name} profile`}
                              /> */}
                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Coach ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : (
                  <div className="no-data-found">No Data</div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
