import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  editDirectorAction,
  getDirectorDataById,
} from "../Redux/Action/directorAction";
import {
  editCoachAction,
  getCoachesDataById,
} from "../Redux/Action/coachAction";
import { getAgentListAction } from "../Redux/Action/agentAction";
import Select from "react-select";

export default function CoachAgentRelationship() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const coachID = id && Decryptedid(atob(id));

  // const coachData = useSelector((e) => e.coachAuthData.coachDetail);

  const [coachData, setCoachData] = useState({
    // professional_relation: "",
    // who_brought_director: "",
    // associate_agent_id: "",
    // relationship_other_notes: "",

    professional_relation: "",
    who_brought_director: "",
    associate_agent_id: "",
    relationship_other_notes: "",
  });

  const initialValues = {
    professional_relation: "",
    who_brought_director: "",
    associate_agent_id: "",
    relationship_other_notes: "",
  };


  const validate = (values) => {
    const errors = {};
  
    // if (!values.professional_relation) {
    //   errors.professional_relation = "required";
    // }
  
    if (values.who_brought_director == '1' && !values.associate_agent_id) {
      errors.associate_agent_id = "required";
    }
  
    return errors;
  };

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));


  const handleSubmit = (values) => {
    let formData = new FormData();

     if( values.professional_relation) {
       formData.append("professional_relation", values.professional_relation);
     }

     if(values.who_brought_director){
       formData.append("who_brought_director", values.who_brought_director);
     }
     if(values.associate_agent_id){
       formData.append("associate_agent_id", values.associate_agent_id);
     }
     if(values.relationship_other_notes){
       formData.append("relationship_other_notes", values.relationship_other_notes);
     }

    formData.append("coachId", coachID);
    formData.append("is_profile_completed", 3);

    dispatch(editCoachAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate(`/coaches-contract/${Encryptedid(res?.payload?.data?._id)}`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  // useEffect(() => {
  //   dispatch(getCoachesDataById({ id: coachID }));
  // }, [dispatch]);

  useEffect(() => {
    CallApi();
    dispatch(getAgentListAction());
  }, [dispatch, coachID]);

  const CallApi = async () => {
    const data = await dispatch(getCoachesDataById({ id: coachID }));

    if (data?.payload.status === 200) {
      const res = data?.payload?.data;

      setCoachData({
        professional_relation: res?.professional_relation,
        who_brought_director: res?.who_brought_director ,
        associate_agent_id: res?.associate_agent_id,
        relationship_other_notes: res?.relationship_other_notes || "",
      });
    }
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Agent Relationship</h2>
            </div>
            <div className="common-outer mt-4">
              <div className="d-flex justify-content-between">
                <h3 className="h-20">Agent’s Relationship with Coach</h3>
              </div>

              <Formik
                enableReinitialize={true}
                initialValues={ coachData || initialValues}
                // validationSchema={validationSchema}
                validate={validate}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors }) => (
                  <FormikForm>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>My Coach</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="professional_relation"
                                value={"0"}
                                as="input"
                                checked={values.professional_relation == "0"}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="licensed-sec">
                            <h3>Associate agent’s Coach</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="professional_relation"
                                value={"1"}
                                as="input"
                                checked={values.professional_relation == "1"}
                              />
                            </div>
                          </div>
                        </Col>
                        <ErrorMessage
                          name="professional_relation"
                          component="div"
                          className="text-danger"
                        />
                      </Row>
                    </div>

                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">
                          Who Brought Coach to Current Team
                        </h3>
                      </div>

                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={4}>
                            <div className="licensed-sec">
                              <h3>Me</h3>
                              <div className="form-check form-switch">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="who_brought_director"
                                  value={"0"}
                                  checked={values.who_brought_director == "0"}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "who_brought_director",
                                      e.target.value
                                    );
                                    if (e.target.value == "0") {
                                      setFieldValue("associate_agent_id", "");
                                    }
                                  }}
                                  as="input"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="licensed-sec">
                              <h3>The Associate Agent</h3>
                              <div className="form-check form-switch">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="who_brought_director"
                                  value={"1"}
                                  as="input"
                                  checked={values.who_brought_director == "1"}
                                />
                              </div>
                            </div>
                          </Col>
                          <ErrorMessage
                            name="who_brought_director"
                            component="div"
                            className="text-danger"
                          />
                        </Row>
                      </div>
                    </div>

                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Name of Associate Agent</h3>
                      </div>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={4}>
                            <Form.Group
                              className="mb-3"
                              // controlId="associate_agent_id"
                            >
                              {/* <Field
                                as={Form.Control}
                                type="text"
                                disabled={values.who_brought_director != 1}
                                name="associate_agent_id"
                                placeholder="Search Agent Name"
                              /> */}

                              <Select
                                name="associate_agent_id"
                                placeholder="Search Agent Name"
                                options={associateAgentOptions}
                                isDisabled={values.who_brought_director != 1}
                                onChange={(option) =>
                                  setFieldValue(
                                    "associate_agent_id",
                                    option ? option.value : ""
                                  )
                                }
                                value={associateAgentOptions?.find(
                                  (elem) =>
                                    elem.value == values.associate_agent_id
                                )}
                              />

                              {values.who_brought_director == 1 && (
                                <ErrorMessage
                                  name="associate_agent_id"
                                  component="div"
                                  className="text-danger"
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Other Notes</h3>
                      </div>
                      <div className="cmn-form-fields">
                        <Row>
                          <Col lg={12}>
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                as="textarea"
                                name="relationship_other_notes"
                                rows={3}
                                className="form-control"
                                placeholder="Write here"
                              />

                              {/* <ErrorMessage
                                name="relationship_other_notes"
                                component="div"
                                className="text-danger"
                              /> */}
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button type="submit">Next</button>
                      </div>
                    </Col>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
