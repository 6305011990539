import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../utils/Becrypt";
import { getPlayerDataById } from "../Redux/Action/playerAction";
import {
  AvailabilityStatus,
  educationLevel,
  gender,
  martialStatus,
  NationalTeamPlayer,
  playerNationality,
  playerTrophy,
  positions,
  releaseClouse,
  teamLeague,
} from "../utils/rawjson";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";

export default function InternalSearchPlayerProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  console.log(playerData, "Dfgdfgdfgdfgdfg");

  const backgroundImageStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${playerData?.banner_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const copyToClipboard = () => {
    if (playerData?.unique_id) {
      navigator.clipboard
        .writeText(playerData.unique_id)
        .then(() => {
          console.log("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getFileName = (filePath) => {
    if (!filePath) return "No file available";
    return filePath.split("/").pop();
  };
  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Player Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {/* <img
                      className="play-img"
                      src={require("../Assets/images/player_image.png")}
                    /> */}
                    <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${playerData?.profile_image}`}
                    />
                    <div>
                      <h2>
                        {playerData?.full_name}, {calculateAge(playerData?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0" ref={inputRef}>
                          Player ID: {playerData?.unique_id}
                        </p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                      <div className="socio-icons">
                        <Link to="#">
                          <img
                            src={require("../Assets/images/fbblue.svg").default}
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src={
                              require("../Assets/images/snapblue.svg").default
                            }
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src={
                              require("../Assets/images/instablue.svg").default
                            }
                          />
                        </Link>
                        <Link to="#">
                          <img
                            src={require("../Assets/images/xblue.svg").default}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      {/* <Link to="#">
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link> */}
                    </div> 
                    <div className="play-info-box">
                      <div>
                        <h3>{gender[playerData?.gender]}</h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>{calculateAge(playerData?.dob)}</h3>
                        <p>Age</p>
                      </div>
                      <div>
                        <h3>
                          {AvailabilityStatus[playerData?.availability_status]}
                        </h3>
                        <p>Availability</p>
                      </div>
                      <div>
                        {playerData?.languages?.map((elem, i) => (
                          <h3 key={i}>{elem}</h3>
                        ))}
                        <p>Language</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Players Main Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Place of Birth:</h4>
                              <p>{playerData?.place_of_birth}</p>
                            </li>
                            <li>
                              <h4>Nationality (s):</h4>
                              {playerData?.nationality?.map((elem, i) => (
                                <p key={i}>{elem}</p>
                              ))}
                            </li>
                            <li>
                              <h4>Nationality ID:</h4>
                              <p>
                                {playerNationality[playerData?.nationality_id]}
                              </p>
                            </li>
                            <li>
                              <h4>National Team Player:</h4>
                              <p>
                                {
                                  NationalTeamPlayer[
                                    playerData?.national_team_player
                                  ]
                                }
                              </p>
                            </li>
                            <li>
                              <h4>National team:</h4>
                              <p>{playerData?.national_team}</p>
                            </li>
                            <li>
                              <h4>Position(s)</h4>
                              {playerData?.positions?.map((elem, i) => (
                                <p key={i}>{positions[elem]}</p>
                              ))}
                            </li>
                            <li>
                              <h4>Height:</h4>
                              <p>{playerData?.height}</p>
                            </li>
                            <li>
                              <h4>Languages(s):</h4>
                              {playerData?.languages?.map((elem, i) => (
                                <p key={i}>{elem}</p>
                              ))}
                            </li>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {playerData?.profile_other_notes
                                  ? playerData?.profile_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {playerData?.agent_other_notes
                                  ? playerData?.agent_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Other Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Education Level</h4>
                              <p>
                                {educationLevel[playerData?.education_level]}
                              </p>
                            </li>
                            <li>
                              <h4>Martial Status</h4>
                              <p>{martialStatus[playerData?.martial_status]}</p>
                            </li>
                            <li>
                              <h4>Children</h4>
                              <p>{playerData?.children}</p>
                            </li>

                            <li className="flex-column align-items-start">
                              <h4>Hobbies/Interests</h4>
                              <p className="mt-1">{playerData?.hobbies}</p>
                            </li>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {playerData?.other_info_other_notes
                                  ? playerData?.other_info_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="play-inner-box search-result mt-3">
                        <div className="d-flex align-items-center">
                          <img
                            className="small-images"
                            src={require("../Assets/images/user.png")}
                          />
                          <img
                            className="small-images"
                            src={require("../Assets/images/user.png")}
                          />
                          <img
                            className="small-images"
                            src={require("../Assets/images/user.png")}
                          />
                          <div className="ms-3">
                            <h4>Photos</h4>
                          </div>
                        </div>
                        <div
                          className="badge-img"
                          style={{ boxShadow: "none" }}
                        >
                          <img
                            src={require("../Assets/images/arrow.svg").default}
                          />
                        </div>
                      </div>
                      {/* <div className="play-inner-box search-result mt-3">
                        <div className="d-flex align-items-center">
                          <img
                            className="small-images"
                            src={require("../Assets/images/user.png")}
                          />
                          <img
                            className="small-images"
                            src={require("../Assets/images/user.png")}
                          />
                          <img
                            className="small-images"
                            src={require("../Assets/images/user.png")}
                          />
                          <div className="ms-3">
                            <h4>Videos</h4>
                          </div>
                        </div>
                        <div
                          className="badge-img"
                          style={{ boxShadow: "none" }}
                        >
                          <img
                            src={require("../Assets/images/arrow.svg").default}
                          />
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="play-inner-box search-result mt-3">
                        <div className="d-flex align-items-center">
                          {
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PATH}${playerData?.creator_id?.profile_image}`}
                            />
                          }
                          <div className="ms-3 d-flex align-items-center">
                            <h4>
                              {capitalize(playerData?.creator_id?.full_name)}
                            </h4>
                            <p
                              className="ms-2"
                              style={{ color: "#1D161780", fontSize: "13px" }}
                            >
                              Agent
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="play-inner-box search-result mt-3">
                        <div className="d-flex align-items-center">
                          <img src={require("../Assets/images/user.png")} />
                          <div className="ms-3 d-flex align-items-center">
                            <h4>
                              {capitalize(playerData?.associate_agent_name)}
                            </h4>
                            <p
                              className="ms-2"
                              style={{ color: "#1D161780", fontSize: "13px" }}
                            >
                              Associate Agent
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="search-cmn-box">
                        <h2>Career Trophies</h2>
                        <div className="search-inner-box">
                          <ul>
                            {playerData?.carrier_trophies?.map((elem, i) => (
                              <li>
                                <div className="d-flex">
                                  <img
                                    src={require("../Assets/images/smtrophy.png")}
                                  />
                                  <h4 className="ms-2">
                                    {playerTrophy[elem?.trophy]}
                                  </h4>
                                </div>
                                <p>
                                  {dateOfBirth(
                                    elem?.date_of_winning?.split("T")[0]
                                  )}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        <h2>Current Team</h2>
                        <div className="play-scroll-box">
                          <div className="play-inner-box mb-0">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>{capitalize(playerData?.team_name)}</h4>
                                <p>
                                  <span>{playerData?.team_country}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        <h2>Player’s CV</h2>

                        <div className="play-inner-box mb-0">
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../Assets/images/pdf.svg").default}
                            />
                            <div className="ms-3">
                              <h4>{getFileName(playerData?.player_cv)}</h4>
                            </div>
                          </div>
                          <a
                            href={playerData?.player_cv}
                            download
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <img
                              src={
                                require("../Assets/images/downbtn.svg").default
                              }
                              alt="Download"
                            />
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contract" title="Contract">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Current Contact Details</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Current Annual Salary:</h4>
                              <p>{playerData?.current_contract}</p>
                            </li>
                            <li>
                              <h4>Bonuses:</h4>
                              <p>
                                {playerData?.Bonuses
                                  ? playerData?.Bonuses
                                  : "-"}
                              </p>
                            </li>
                            <li>
                              <h4>Joining Date:</h4>
                              <p>{dateOfBirth(playerData?.joined_date)}</p>
                            </li>
                            <li>
                              <h4>Contract Start Date:</h4>
                              <p>
                                {dateOfBirth(playerData?.contract_start_date)}
                              </p>
                            </li>
                            <li>
                              <h4>Contract End Date:</h4>
                              <p>
                                {dateOfBirth(playerData?.contract_end_date)}
                              </p>
                            </li>
                            <li>
                              <h4>Release Clause:</h4>
                              <p>{releaseClouse[playerData?.release_clouse]}</p>
                            </li>
                            <li>
                              <h4>Buyout Clause: </h4>
                              <p>{releaseClouse[playerData?.buyout_clouse]}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {playerData?.contact_info_other_notes
                                  ? playerData?.contact_info_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>New Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Annual Asking Salary:</h4>
                              <p>{playerData?.new_contract_annual_salary}</p>
                            </li>
                            <li>
                              <h4>Contract Duration:</h4>
                              <p>{playerData?.new_contract_duration}</p>
                            </li>
                            <li>
                              <h4>Amount for Transfer:</h4>
                              <p>
                                {playerData?.new_contract_team_asking_amount}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {playerData?.new_contract_other_notes
                                  ? playerData?.new_contract_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {playerData?.contact_info_agent_notes
                                  ? playerData?.contact_info_agent_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Statistics" title="Statistics">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Current Contact Details</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Total Appearances:</h4>
                              <p>
                                {playerData?.total_appearence ?? "N/A"}/
                                {playerData?.total_appearence_out_of ?? "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Starting Eleven:</h4>
                              <p>
                                {playerData?.starting_eleven ?? "N/A"}/
                                {playerData?.starting_eleven_out_of ?? "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Enter as substitution:</h4>
                              <p>
                                {playerData?.enter_as_substitution ?? "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Out as substitution:</h4>
                              <p>{playerData?.out_as_substitution ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Goals:</h4>
                              <p>{playerData?.goals ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Assists:</h4>
                              <p>{playerData?.assists ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Goals Conceded: </h4>
                              <p>{playerData?.goals_cancelled ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Clean Sheets: </h4>
                              <p>{playerData?.clean_sheets ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Red Cards: </h4>
                              <p>{playerData?.red_card ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Yellow Cards: </h4>
                              <p>{playerData?.yellow_card ?? "N/A"}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {playerData?.statistics_other_notes}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Advanced Statistics</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="mb-0">
                              <h4>Click here to get advanced statistics</h4>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Health" title="Health">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Current Injury</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Injury Name:</h4>
                              <p>
                                {playerData?.current_injuries_issues?.join(
                                  ", "
                                ) || "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Date of Coming back:</h4>
                              <p>
                                {dateOfBirth(playerData?.date_of_coming_back)}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {playerData?.health_other_notes
                                  ? playerData?.health_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-cmn-box mt-4">
                        <h2>Health Problem</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {playerData?.health_problems
                                  ? playerData?.health_problems
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Past Injury (ies)</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              {playerData?.past_injuries?.map((elem, i) => (
                                <React.Fragment key={i}>
                                  <h4>Injury Name:</h4>
                                  <p> {elem}</p>
                                </React.Fragment>
                              ))}
                            </li>

                            {/* <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li> */}
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Proposals" title="Proposals">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                          {playerData?.proposed_team?.map((elem, i) => (
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Proposals</h2>
                        <div className="search-inner-box">
                          <h3 className="in-heading">
                            Teams that the Player has been proposed
                          </h3>
                            <div className="play-inner-box align-items-start">
                              <div className="d-flex">
                                <img
                                  src={require("../Assets/images/user.png")}
                                />
                                <div className="ms-3">
                                  <h4>{elem?.proposed_team_name} </h4>

                                  <p>
                                    <span>{elem?.proposed_team_country}</span>
                                  </p>
                                </div>
                              </div>
                              <p>
                                <span>
                                  {" "}
                                  {teamLeague[elem?.proposed_team_league]}
                                </span>
                              </p>
                            </div>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                              {elem?.proposed_associate_agent_note
                                  ? elem?.proposed_associate_agent_note
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                          ))}
                    <Col lg={4}>
                      {/* <div className="search-cmn-box">
                        <h2 className="mt-4"></h2>
                        <div className="search-inner-box">
                          <h3 className="in-heading">
                            Teams that the Player has been proposed
                          </h3>
                          <div className="play-inner-box align-items-start">
                            <div className="d-flex">
                              <img src={require("../Assets/images/user.png")} />
                              <div className="ms-3">
                                <h4>Team Name</h4>
                                <p>
                                  <span>Country/State</span>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>1st division</span>
                            </p>
                          </div>
                        </div>
                      </div>  */}

                      {/* <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                              {elem?.
                                  ? elem?.proposed_associate_agent_note
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col> 
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contact" title="Contact">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>{playerData?.phone_number
                              }</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>{playerData?.email}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Home Address:</h4>
                              <p className="mt-1">
                               {playerData?.address}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>{playerData?.instagram}</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>{playerData?.snapchat}</p>
                            </li>
                            <li>
                              <h4>X:</h4>
                              <p>{playerData?.twitter}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Players Lawyer:</h4>
                              <p>{playerData?.player_lawyer_name}</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>{playerData?.player_lawyer_phone_number}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Managing Company:</h4>
                              <p>{playerData?.player_managing_company_name}</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>{playerData?.player_managing_company_phone_number}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Sponsors</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Name:</h4>
                              <p>{playerData?.player_sponsors_name}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                              {playerData?.contact_info_other_notes
                                  ? playerData?.contact_info_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                              {playerData?.contact_info_agent_notes
                                  ? playerData?.contact_info_agent_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
