import { createSlice } from "@reduxjs/toolkit";
import { getMeetingIdAction, getMeetinglist } from "../Action/calenderAction";

const initialState = {
  loading: false,
  getMeetingData: [],
  getMeetingDataById: [],
  error: false,
};

const calenderAuthSlice = createSlice({
  name: "calenderAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMeetinglist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMeetinglist.fulfilled, (state, { payload }) => {
        state.getMeetingData = payload.data;
        state.loading = false;
      })
      .addCase(getMeetinglist.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
// get meeting id
      .addCase(getMeetingIdAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMeetingIdAction.fulfilled, (state, { payload }) => {
        state.getMeetingDataById = payload.data;
        state.loading = false;
      })
      .addCase(getMeetingIdAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  },
});

export default calenderAuthSlice.reducer;
