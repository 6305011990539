// import React, { useEffect, useMemo } from "react";
// import { Container, Form } from "react-bootstrap";
// import Select from "react-select";
// import { ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { Country, State } from "country-state-city";
// import ReactCountryFlag from "react-country-flag";

// export default function CountrySelect({ setFieldValue, errors, touched, values }) {
//   const [countries, setCountries] = React.useState([]);
//   const [cities, setCities] = React.useState([]);
//   const [loadingCities, setLoadingCities] = React.useState(false);

//   useEffect(() => {
//     const countryList = Country.getAllCountries();
//     setCountries(
//       countryList.map((country) => ({
//         value: country.isoCode,
//         label: (
//           <>
//             <ReactCountryFlag
//               countryCode={country.isoCode}
//               svg
//               style={{
//                 marginRight: "10px",
//                 width: "20px",
//                 height: "20px",
//               }}
//               title={country.name}
//             />
//             {country.name}
//           </>
//         ),
//       }))
//     );
//   }, []);

//   const fetchCities = async (countryCode) => {
//     setLoadingCities(true);
//     try {
//       const stateList = State.getStatesOfCountry(countryCode);
//       const cities = stateList.map((state) => ({
//         value: state.isoCode,
//         label: state.name,
//       }));
//       setCities(cities);
//     } catch (error) {
//       console.error("Error fetching cities:", error);
//     } finally {
//       setLoadingCities(false);
//     }
//   };

//   const handleCountryChange = async (selectedOption) => {
//     if (!selectedOption) return;

//     const countryData = Country.getAllCountries().find(
//       (country) => country.isoCode === selectedOption.value
//     );

//     if (countryData) {
//       setFieldValue("country", countryData.name);
//       setFieldValue("city", ""); // Reset city when country changes
//       await fetchCities(countryData.isoCode);
//     }
//   };

//   useEffect(() => {
//     if (values.country) {
//       const countryOption = countries.find(
//         (country) => country.label.props.children[1] === values.country
//       );
//       if (countryOption) {
//         fetchCities(countryOption.value);
//       }
//     }
//   }, [values.country, countries]);

//   useEffect(() => {
//     if (values.city && cities.length > 0) {
//       const cityOption = cities.find((city) => city.label === values.city);
//       if (cityOption) {
//         setFieldValue("city", cityOption.value);
//       }
//     }
//   }, [values.city, cities, setFieldValue]);

//   return (
//     <div >
//       <Container>
//         <div className="main-form d-flex justify-content-center">
//           <div className="cmn-form">
//             <h2 className="h-27 text-center mb-2">Select Your Country Below</h2>
//             <p className="sub-16 text-center mb-4">Fill in your details</p>

//             <div className="cmn-form-fields">
//               <Form.Group className="mb-3 form-flag">
//                 <Form.Label className="sub-16 mb-2">Select Country</Form.Label>
//                 <Select
//                   isSearchable={true}
//                   value={countries.find(
//                     (country) =>
//                       country.label.props.children[1] === values.country
//                   )}
//                   onChange={handleCountryChange}
//                   options={countries}
//                   placeholder="Select a country"
//                 />
//                 <ErrorMessage
//                   name="country"
//                   component="div"
//                   className="text-danger"
//                 />
//               </Form.Group>

//               {values.country && (
//                 <Form.Group className="mb-3 form-flag">
//                   <Form.Label className="sub-16 mb-2">Select City</Form.Label>
//                   <Select
//                     name="city"
//                     options={cities}
//                     isDisabled={loadingCities || !values.country}
//                     isLoading={loadingCities}
//                     value={cities.find((city) => city.value === values.city)}
//                     onChange={(selectedOption) => {
//                       if (selectedOption) {
//                         setFieldValue("city", selectedOption.value);
//                       }
//                     }}
//                     placeholder="Select a city"
//                     className={`react-select`}
//                     classNamePrefix="select"         
//                   />
//                   <ErrorMessage
//                     name="city"
//                     component="div"
//                     className="text-danger"
//                   />
//                 </Form.Group>
//               )}
//             </div>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// }



import React, { useEffect, useCallback } from "react";
import { Container, Form } from "react-bootstrap";
import Select from "react-select";
import { ErrorMessage } from "formik";
import { Country, State } from "country-state-city";
import ReactCountryFlag from "react-country-flag";

export default function CountrySelect({ setFieldValue, errors, touched, values }) {
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [loadingCities, setLoadingCities] = React.useState(false);

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: (
          <>
            <ReactCountryFlag
              countryCode={country.isoCode}
              svg
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
              }}
              title={country.name}
            />
            {country.name}
          </>
        ),
      }))
    );
  }, []);

  const fetchCities = async (countryCode) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryCode);
      const cities = stateList.map((state) => ({
        value: state.isoCode,
        label: state.name,
      }));
      setCities(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoadingCities(false);
    }
  };

  const handleCountryChange = async (selectedOption) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      setFieldValue("country", countryData.name);
      setFieldValue("city", ""); 
      await fetchCities(countryData.isoCode);
    }
  };

  const customFilter = useCallback((option, inputValue) => {
    const countryName = option.label.props.children[1].toLowerCase();
    const searchValue = inputValue.toLowerCase();
    return countryName.includes(searchValue);
  }, []);

  useEffect(() => {
    if (values.country) {
      const countryOption = countries.find(
        (country) =>
          country.label.props.children[1].toLowerCase() === values.country.toLowerCase()
      );
      if (countryOption) {
        fetchCities(countryOption.value);
      }
    }
  }, [values.country, countries]);

  useEffect(() => {
    if (values.city && cities.length > 0) {
      const cityOption = cities.find((city) => city.label === values.city);
      if (cityOption) {
        setFieldValue("city", cityOption.value);
      }
    }
  }, [values.city, cities, setFieldValue]);

  return (
    <div>
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Select Your Country Below</h2>
            <p className="sub-16 text-center mb-4">Fill in your details</p>

            <div className="cmn-form-fields">
              <Form.Group className="mb-3 form-flag">
                <Form.Label className="sub-16 mb-2">Select Country</Form.Label>
                <Select
                  isSearchable={true}
                  value={countries.find(
                    (country) =>
                      country.label.props.children[1].toLowerCase() === values.country.toLowerCase()
                  )}
                  onChange={handleCountryChange}
                  options={countries}
                  placeholder="Select a country"
                  filterOption={customFilter}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>

              {values.country && (
                <Form.Group className="mb-3 form-flag">
                  <Form.Label className="sub-16 mb-2">Select City</Form.Label>
                  <Select
                    name="city"
                    options={cities}
                    isDisabled={loadingCities || !values.country}
                    isLoading={loadingCities}
                    value={cities.find((city) => city.value === values.city)}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFieldValue("city", selectedOption.value);
                      }
                    }}
                    placeholder="Select a city"
                    className={`react-select`}
                    classNamePrefix="select"
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
