import { createSlice } from "@reduxjs/toolkit";
import { getTeamDataById, getTeamListAction, getTeamListByIdAction } from "../Action/teamAction";


const initialState = {
  loading: false,
  getTeamDetailById: [],
  getTeamListing: [],
  getTeamListingById: [],
  error: false,
};

const teamAuthSlice = createSlice({
  name: "teamAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeamDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamDataById.fulfilled, (state, { payload }) => {
        state.getTeamDetailById = payload.data
        state.loading = false;
      })
      .addCase(getTeamDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getTeamListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamListAction.fulfilled, (state, { payload }) => {
        state.getTeamListing = payload.data
        state.loading = false;
      })
      .addCase(getTeamListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })


      .addCase(getTeamListByIdAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamListByIdAction.fulfilled, (state, { payload }) => {
        state.getTeamListingById = payload.data
        state.loading = false;
      })
      .addCase(getTeamListByIdAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
    
    },
});


export default teamAuthSlice.reducer;
