
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRouteForProfile = () => {
const localStorageToken = localStorage.getItem('token1')

return (
localStorageToken ? <Outlet /> : <Navigate to={'/'} />
)
}

export default PrivateRouteForProfile
