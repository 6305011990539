import React from "react";
import { Tab, Tabs, Accordion, Row, Col } from "react-bootstrap";
import {
  agentMainInformation,
  coachRelation,
  directorRelation,
  playerRelation,
} from "../utils/rawjson";

const AgentFilterTab = ({
  renderSelectAgent,
  renderSelectAgent1,
  renderSelectAgent2,
  renderSelectAgent3,
  handleSearchForAgent,
}) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Associate Agents Main Information</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(agentMainInformation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectAgent(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Player Relation</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playerRelation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectAgent1(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Director Relation</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(directorRelation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectAgent2(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header> Coach Realtion </Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachRelation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectAgent3(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Row>
        <Col lg={12} className="mt-4 d-flex justify-content-end">
          <div className="cmn-light-btn">
            <button type="button" onClick={handleSearchForAgent}>
              Search
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AgentFilterTab;
