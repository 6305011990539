import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// create meeting
export const createMeetingAction = createAsyncThunk(
  "createMeetingAction/user/createMeeting",
  async (data) => {
    const response = await api.post("/user/createMeeting", data);
    return response.data;
  }
);

// upcoming meeting
export const getMeetinglist = createAsyncThunk("getMeetinglist/upcomingMeetings",async ({ date, pageNo, limit }, thunkAPI) => {
    const response = await api.get(`/user/upcomingMeetings`, {
      params: {
        date: date,
        page: pageNo,
        limit: limit,
      },
    });
    return response.data;
  }
);

// delete meeting
export const deleteMeeting = createAsyncThunk("deleteMeeting/delMeeting",async ({ id }, thunkAPI) => {
  const response = await api.delete(`/user/delMeeting`, {
    params: {
      meetingId: id,
    },
  });
  return response.data;
}
);


// get meeting detail
export const getMeetingIdAction = createAsyncThunk(
  "getMeetingIdAction/user/getMeetingDetailById",
  async (data) => {
    const response = await api.post("/user/getMeetingDetailById", data);
    return response.data;
  }
);


// edit meeting
export const editMeeingAction = createAsyncThunk("editMeeingAction/user/editMeeting", async (data) => {
  const response = await api.put("/user/editMeeting", data);
  return response.data;
}
)
