import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
import { Decryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { getTeamDataById } from "../Redux/Action/teamAction";
import { capitalize, dateOfBirth } from "../utils/helper";
import { teamLeague } from "../utils/rawjson";
import Loader from "../components/Loader";

export default function MyteamDetail() {
  const teamData = useSelector((e) => e?.teamAuthData?.getTeamDetailById);
  const loader = useSelector((e) => e?.teamAuthData?.loading);
  const backgroundImageStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${teamData?.banner_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };

  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamDataById({ id: IdVal }));
  }, [dispatch]);

  const handleTeamEdit = (id) => {
    if (id) {
      localStorage.setItem("teamId", id);
    }
  };

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Team’s Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${teamData?.profile_image}`}
                    />
                    <div>
                      <h2>{capitalize(teamData?.team_name)}</h2>

                      <div className="socio-icons">
                        {/* Instagram */}
                        {teamData?.instagram && (
                          <Link
                            to={teamData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {teamData?.snapchat && (
                          <Link
                            to={teamData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {teamData?.twitter && (
                          <Link
                            to={teamData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {teamData?.facebook && (
                          <Link
                            to={teamData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        to="/add-team"
                        onClick={() => handleTeamEdit(teamData?._id)}
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Team Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Team Country:</h4>
                              <p>
                                {teamData?.team_country
                                  ? teamData?.team_country
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team City:</h4>
                              <p>
                                {teamData?.team_city
                                  ? teamData?.team_city
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team League:</h4>
                              <p>
                                {teamData?.team_league
                                  ? teamLeague[teamData?.team_league]
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Annual Budget:</h4>
                              <p>
                                {teamData?.annual_budget
                                  ? teamData?.annual_budget
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team’s Owner:</h4>
                              <p>
                                {teamData?.team_owner
                                  ? capitalize(teamData?.team_owner)
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team’s President:</h4>
                              <p>
                                {teamData?.team_president
                                  ? capitalize(teamData?.team_president)
                                  : "N/A"}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                <p>
                                  {teamData?.info_other_notes
                                    ? teamData?.info_other_notes
                                    : "N/A"}
                                </p>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Career Trophies</h2>
                        <div className="search-inner-box">
                          <ul>
                            {Array.isArray(teamData?.carrier_trophies) &&
                            teamData?.carrier_trophies.length > 0 ? (
                              <>
                                {teamData?.carrier_trophies.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <div className="d-flex">
                                            <img
                                              src={require("../Assets/images/smtrophy.png")}
                                            />
                                            <h4 className="ms-2">
                                              Youth Trophie
                                            </h4>
                                          </div>
                                          <p>
                                            {dateOfBirth(ele?.date_of_winning)}
                                          </p>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <li>
                                {" "}
                                <p>No Data Found</p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* <div className="search-cmn-box mt-3">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          {/* <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div> */}
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Players" title="Players">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Players</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.players) &&
                          teamData?.players.length > 0 ? (
                            <>
                              {teamData?.players.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.player_name}</h4>
                                        <p>
                                          <span>
                                            {capitalize(
                                              ele?.player_team_contact_person_name
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="ms-3">
                                      <p>
                                        Player ID:
                                        <span>IVXJW007</span>
                                      </p>
                                      <p>
                                        Position:
                                        <span>Ivyx56364</span>
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Player I Proposed</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_players) &&
                          teamData?.proposed_players.length > 0 ? (
                            <>
                              {teamData?.proposed_players.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.player_name}</h4>
                                        <p>
                                          <span>Team Name</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="ms-3">
                                      <p>
                                        Player ID:
                                        <span>IVXJW007</span>
                                      </p>
                                      <p>
                                        Position:
                                        <span>Ivyx56364</span>
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Player Wanted by Team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_players) &&
                          teamData?.proposed_players.length > 0 ? (
                            <>
                              {teamData?.proposed_players.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.player_name}</h4>
                                        <p>
                                          <span>Team Name</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="ms-3">
                                      <p>
                                        Player ID:
                                        <span>IVXJW007</span>
                                      </p>
                                      <p>
                                        Position:
                                        <span>Ivyx56364</span>
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Directors" title="Directors">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Directors</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.directors) &&
                          teamData?.directors.length > 0 ? (
                            <>
                              {teamData?.directors.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.director_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Directors I Proposed</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_directors) &&
                          teamData?.proposed_directors.length > 0 ? (
                            <>
                              {teamData?.directors.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.director_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Directors wanted by Team</h2>
                        <div className="search-inner-box">
                          {Array.isArray(teamData?.directors) &&
                          teamData?.directors.length > 0 ? (
                            <>
                              {teamData?.directors.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.director_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Coaches" title="Coaches">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Coaches</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.coaches) &&
                          teamData?.coaches.length > 0 ? (
                            <>
                              {teamData?.coaches.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.coach_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coaches I Proposed</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.coaches) &&
                          teamData?.coaches.length > 0 ? (
                            <>
                              {teamData?.coaches.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.coach_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coaches wanted by Team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_coaches) &&
                          teamData?.proposed_coaches.length > 0 ? (
                            <>
                              {teamData?.coaches.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/user.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.coach_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>

              <Tab eventKey="Contact" title="Contact">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>{teamData?.phone_number}</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>{teamData?.email}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Home Address:</h4>
                              <p className="mt-1">{teamData?.address}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>{teamData?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>{teamData?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h4>X:</h4>
                              <p>{teamData?.facebook || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Players Lawyer:</h4>
                              <p>lorem ipsum</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>{teamData?.contact_person_phone_number}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Managing Company:</h4>
                              <p>lorem ipsum</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>lorem ipsum</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Sponsors</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Name:</h4>
                              <p>Lorem Ipsum</p>
                            </li>
                            <li>
                              <h4>Name:</h4>
                              <p>Lorem Ipsum</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
