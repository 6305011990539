// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";

// const token = window.localStorage.getItem("token");

// const PrivateRoute = () => {
//   return token ? (
//     <Outlet />
//   ) : (
//     <Navigate to="/" replace />
//   );
// };

// export default PrivateRoute;

// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";
// const PrivateRoute = () => {
//   const localStorageToken = localStorage.getItem("token");
//   return localStorageToken ? <Outlet /> : <Navigate to="/" />;
// };
// export default PrivateRoute;


import React, { useEffect } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoute = () => {
  const localStorageToken = localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    if (!localStorageToken) {
      window.history.replaceState({}, "", "/");
      window.location.reload();
    } else {
      console.log("Token found:");
    }
  }, [location, localStorageToken]);

  return localStorageToken ? <Outlet /> : <Navigate to="/" replace/>;
};

export default PrivateRoute;
