import React, { useState } from "react";
import { Container, Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import Select from "react-select";
import { countryCode } from "../utils/rawjson";
import { FaFilePdf } from "react-icons/fa6";
import toast from "react-hot-toast";

export default function CreateProfile({ setFieldValue, values }) {
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    setFieldValue("profile_image", file);
  };

  const handleLicensedDocChange = (e) => {
    const file = e.target.files[0];
    setFieldValue("licensed_doc", file);
  };

  const transformedCountryCodeOptions = countryCode?.map((option) => ({
    value: option.dial_code,
    label: option.dial_code,
  }));

  const getProfileImageUrl = () => {
    if (values.profile_image instanceof File) {
      return URL.createObjectURL(values.profile_image);
    }
    return require("../Assets/images/userico.svg").default;
  };

  const getLicensedDocUrl = () => {
    if (values.licensed_doc instanceof File) {
      return URL.createObjectURL(values.licensed_doc);
    }
    return null;
  };


  return (
    <Container>
      <div className="main-form d-flex justify-content-center mt-4">
        <div className="cmn-form">
          <h2 className="h-27 text-center mb-2">Tell Us Your Name!</h2>
          <p className="sub-16 text-center mb-4">Fill your details</p>
          <div className="cmn-form-fields">
            <div className="image-upload-input">
              <div className="image-upload-view">
              <img
                    className="view-img"
                    src={getProfileImageUrl()}
                    alt="Profile"
                  />
              </div>
              <div className="image-upload-camera">
                <input
                  type="file"
                  name="profile_image"
                  onChange={handleProfileImageChange}
                />
                <img
                  src={require("../Assets/images/camera.svg").default}
                  alt="Upload"
                />
              </div>
            </div>

            <BootstrapForm.Group className="mb-3 form-inner">
              <label className="sub-16 mb-2">Full Name</label>
              <Field
                name="name"
                type="text"
                placeholder="Full Name"
                className={`form-control`}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </BootstrapForm.Group>

            <BootstrapForm.Group className="mb-3 form-inner">
              <label className="sub-16 mb-2">Phone Number</label>
              <div className="phone-select">
                <Select
                  style={{ BorderRadius: "10px 0 0 10px" }}
                  name="country_code"
                  className="react-select-set"
                  placeholder="+357"
                  isSearchable={false}
                  options={transformedCountryCodeOptions}
                  value={transformedCountryCodeOptions.find(
                    (option) => option.value === values.country_code
                  )}
                  onChange={(selectedOption) => {
                    setFieldValue(
                      "country_code",
                      selectedOption ? selectedOption.value : ""
                    );
                    // setCountryCode(selectedOption ? selectedOption.value : "");
                  }}
                />
                <span className="hyphen-set">|</span>
                <Field
                  name="phone_number"
                  type="text"
                  placeholder="Ph. no"
                  className={`form-control `}
                />
              </div>
              <ErrorMessage
                name="phone_number"
                component="div"
                className="text-danger"
              />
            </BootstrapForm.Group>

            <div className="licensed-sec">
              <h3>Licensed Agent</h3>
              <div className="form-check form-switch">
                <Field
                  name="licensed_agent"
                  type="checkbox"
                  className={`form-check-input`}
                  onChange={(e) => {
                    setFieldValue("licensed_agent", e.target.checked ? 1 : 0);
                  }}
                />
              </div>
            </div>
            <ErrorMessage
              name="licensed_agent"
              component="div"
              className="text-danger"
            />

            {values.licensed_agent === 1 && (
              <div className="image-upload-main">
                <div className="image-upload-inner profile">
                  <div className="image-upload-placeholder">
                    {getLicensedDocUrl() ? (
                      <div>
                        <FaFilePdf />
                      </div>
                    ) : (
                      <>
                        <img
                          src={require("../Assets/images/upload.svg").default}
                          alt="Upload Placeholder"
                        />
                        <h4>Upload Licensed Document</h4>
                      </>
                    )}
                  </div>
                  <input
                    type="file"
                    name="licensed_doc"
                    onChange={handleLicensedDocChange}
                    className={`form-control-file`}
                    accept="application/pdf"
                  />
                </div>
                <ErrorMessage
                  name="licensed_doc"
                  component="div"
                  className="text-danger"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
