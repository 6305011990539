import React from "react";
import LoginHeader from "./LoginHeader";
import Footer from "./Footer";

const LoginLayout = ({ children }) => {
  return (
    <div className="light-background">
      <LoginHeader />
      {children}
      <Footer />
    </div>
  );
};

export default LoginLayout;
