import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { staticDataAction } from "../Redux/Action/staticAction";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import ProgressBar from "../CommonModals/ProgressBar";

const validationSchema = Yup.object({
  education_level: Yup.object().required("required"),
  martial_status: Yup.object().required("required"),
  children: Yup.object().required("required"),
  hobbies: Yup.string(),
  other_info_other_notes: Yup.string(),
});

const OtherInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const optionsEduation = staticListForPlayer?.education_level?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsMaritalStatus = staticListForPlayer?.martial_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsChildren = staticListForPlayer?.children?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const initialValues = {
    education_level:
      optionsEduation?.find(
        (option) => option.value === playerData?.education_level
      ) || null,
    martial_status:
      optionsMaritalStatus?.find(
        (e) => e.value === playerData?.martial_status
      ) || null,
    children:
      optionsChildren?.find((e) => e.value === playerData?.children) || null,
    hobbies: playerData?.hobbies || "",
    other_info_other_notes: playerData?.other_info_other_notes || "",
  };

  const transformValues = (values) => {
    const transformed = { ...values };
    transformed.education_level = values.education_level?.value ?? "0";
    transformed.martial_status = values.martial_status?.value ?? "0";
    transformed.children = values.children?.value ?? 0;
    return transformed;
  };

  const handleSubmit = (values) => {
    const transformedValues = transformValues(values);
    let formData = new FormData();

    for (const key in transformedValues) {
      if (transformedValues.hasOwnProperty(key)) {
        formData.append(key, transformedValues[key]);
      }
    }

    formData.append("playerId", paylerId);
    formData.append("is_profile_completed", 7);

    dispatch(editPlayerAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate(
          `/contact-information/${Encryptedid(res?.payload?.data?._id)}`
        );
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const handleBack = () => {
    navigate(`/health/${Encryptedid(paylerId)}`);
  }

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
          <ProgressBar currentStep={7} totalSteps={8} />
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Other Information</h2>

            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <FormikForm>
                  <div className="common-outer mt-4">
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="mb-3 form-inner">

                          <Select
                          placeholder="Education Level"
                            name="education_level"
                            options={optionsEduation}
                            onChange={(option) =>
                              setFieldValue("education_level", option)
                            }
                            value={values.education_level}
                          />
                          <ErrorMessage
                            name="education_level"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="mb-3 form-inner">
        

                          <Select
                          placeholder="Marital Status"
                            name="martial_status"
                            options={optionsMaritalStatus}
                            onChange={(option) =>
                              setFieldValue("martial_status", option)
                            }
                            value={values.martial_status}
                          />

                          <ErrorMessage
                            name="martial_status"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="mb-3 form-inner">

                          <Select
                          placeholder="Children"
                            name="children"
                            options={optionsChildren}
                            onChange={(option) =>
                              setFieldValue("children", option)
                            }
                            value={values.children}
                          />

                          <ErrorMessage
                            name="children"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} className="mt-3">
                        <Form.Group className="mb-3 form-inner">
                          <Form.Control
                            type="text"
                            name="hobbies"
                            placeholder="Hobbies/Interests enter here"
                            as={Field}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <div className="cmn-form-fields">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 form-inner">
                            <Field
                              as="textarea"
                              name="other_info_other_notes"
                              rows={3}
                              placeholder="Write here"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="other_info_other_notes"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                       <button type="button" onClick={handleBack}>Back</button>
                      <Button type="submit">Next</Button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default OtherInformation;
