import React from "react";
import { Tab, Tabs, Accordion, Row, Col } from "react-bootstrap";
import {
  directorCurrentContractDetails,
  directorIsProposed,
  directorNewContractDetails,
  DirectorProfessionalRelations,
  directorsMainInformation,
  teamsInterestedInDirector,
} from "../utils/rawjson";

const DirectorFilterTab = ({
  renderSelectDirector,
  renderSelectDirector1,
  renderSelectDirector2,
  renderSelectDirector3,
  renderSelectDirector4,
  renderSelectDirector5,
  handleSearchForDirector
}) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Director Main Information</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(directorsMainInformation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectDirector(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Professional Relations</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(DirectorProfessionalRelations).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectDirector1(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Current Contract Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(directorCurrentContractDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectDirector2(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>New Contract Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(directorNewContractDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectDirector3(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Director Proposal Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(directorIsProposed).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectDirector4(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Teams Interested in Director</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(teamsInterestedInDirector).map(
              (key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectDirector5(key)}
                    </div>
                  </div>
                </Col>
              )
            )} 
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Row>
        <Col lg={12} className="mt-4 d-flex justify-content-end">
          <div className="cmn-light-btn">
            <button type="button" onClick={handleSearchForDirector}>
              Search
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DirectorFilterTab;
