// import React from "react";
// import { Col, Container, Form, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";

// export default function OtpVerification() {
//   return (
//     <div className="main-section">
//       <Container>
//         <div className="main-form d-flex justify-content-center">
//           <div className="cmn-form">
//             <h2 className="h-27 text-center mb-2">OTP Verification</h2>
//             <p className="sub-16 text-center mb-3">
//               Enter the OTP code we just sent on
//               <br /> your registered Email
//             </p>
//             <label className="sub-16 mb-2">Code</label>
//             <div className="opt-verify-fields mt-2">
//               <Form.Group>
//                 <Form.Control type="number" />
//               </Form.Group>
//               <Form.Group>
//                 <Form.Control type="number" />
//               </Form.Group>
//               <Form.Group>
//                 <Form.Control type="number" />
//               </Form.Group>
//               <Form.Group>
//                 <Form.Control type="number" />
//               </Form.Group>
//             </div>
//             <Link to="/reset-password" className="cmn-red-btn mb-0">
//               Verify
//             </Link>
//             <div className="no-acc">
//               <h4>Didn’t get OTP? </h4>
//               <Link to="#">Resend OTP </Link>
//             </div>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  userOtpVerification,
  userResendOtpVerification,
} from "../Redux/Action/auth";

export default function OtpVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const userid = id && Decryptedid(atob(id));

  console.log(userid, "df4344444444444");

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleVerify = () => {
    const emailData = localStorage.getItem("userEmail");
    const forgotPasswdUser = localStorage.getItem("forgotPassword_id");

    const verifyObj = {
      userId: userid,
      otp: otp,
    };

    if (otp.length === 4) {
      if (timer === 0) {
        setError("Please resend OTP");
      } else {
        dispatch(userOtpVerification(verifyObj)).then((response) => {

          if (forgotPasswdUser == 0) {
            if (response?.payload?.status === 200) {
              navigate(
                `/reset-password/${Encryptedid(response?.payload?.data?._id)}`
              );
              localStorage.removeItem("forgotPassword_id");
              toast.success(response?.payload?.message);
            } else {
              toast.error(response?.payload?.message);
            }
          } else {
            if (response?.payload?.status === 200) {
              const { _id, token } = response?.payload?.data;
              const encryptedId = Encryptedid(_id);
              if (_id && token) {
                localStorage.setItem("userId", _id);
                localStorage.setItem("token1", token);

                window.location.href = `/profile-detail`;
                toast.success(response?.payload?.message);
                // navigate(`/create-profile/${Encryptedid(_id)}`);
              }
            } else if (response?.payload?.status === 400) {
              toast.error(response?.payload?.message);
            }
          }
        });
      }
    } else if (otp.length > 1 && otp.length < 4) {
      setError("Please enter a 4-digit OTP.");
    } else if (timer === 0 && otp.length === 0) {
      setError("Please resend OTP");
    } else {
      setError("Please enter a 4-digit OTP.");
    }
  };

  const handleResendFunc = () => {
    setLoading(true);
    // const emailData = localStorage.getItem("userEmail");
    // const forgotPasswdUser = localStorage.getItem("forgotPassword_id");

    const obj = {
      userId: userid,
    };

    const obj2 = {
      role: 3,
      // "email": userEmail,
    };

    // if (forgotPasswdUser == 0) {
    // dispatch(forgotPasswordAction(obj2))
    // .then((res) => {
    //   if (res?.payload?.status === 200) {
    //     toast.success(res?.payload?.message);
    //     setOtp("")
    //     setError("");
    //     setTimer(60);
    //   }
    // })
    // .catch((error) => {
    //   console.error("Error resending OTP:", error);
    // })
    // .finally(() => {
    //   setLoading(false);
    // });

    // }else {
    dispatch(userResendOtpVerification(obj))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setOtp("");
          setError("");
          setTimer(60);
        }
      })
      .catch((error) => {
        console.error("Error resending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    if (otp) {
      setError("");
    }
  }, [otp]);

  return (
    <div className="main-section">
      <Container>
        <Row className="box-center">
          <Col lg={5} className="m-auto">
            <div className="main-form d-flex justify-content-center">
              <div className="cmn-form">
                <h2 className="h-27 text-center mb-2">OTP Verification</h2>
                <p className="sub-16 text-center mb-3">
                  Enter the OTP code we just sent on your registered Email
                </p>
                <Form>
                  <label className="sub-16 mb-2">Code</label>
                  <div className="opt-verify-fields mt-2 mb-2">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      separator={<span style={{ width: "10px" }}></span>}
                      renderInput={(props) => <input {...props} />}
                      Style={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        // display: "flex",
                        borderBlock: "1px solid var(--Border, #E8ECF4)",
                        justifyContent: "space-between",
                      }}
                    />
                  </div>

                  <div className="mt-1 text-center">
                    {error && <p className="text-danger">{error}</p>}
                    <button
                      // to="/ResetPassword"
                      variant="primary"
                      type="button"
                      className="cmn-red-btn mb-0 w-100 mt-4"
                      onClick={handleVerify}
                    >
                      Verify
                    </button>
                  </div>

                  <div className="no-acc">
                    {timer > 0 ? (
                      <p>Resend OTP in {formatTime(timer)} seconds</p>
                    ) : (
                      <>
                        <h4>Didn’t get OTP? </h4>
                        <button
                          // className="btn btn-link resend-btn-txt"
                          onClick={handleResendFunc}
                          disabled={loading}
                        >
                          {loading ? "Sending..." : "Resend OTP"}
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
