import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
export default function Message() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showTransfer, setShowTransfer] = useState(false);

  const handleCloseTransfer = () => setShowTransfer(false);
  const handleShowTransfer = () => setShowTransfer(true);

  const [showAgent, setShowAgent] = useState(false);

  const handleCloseAgent = () => setShowAgent(false);
  const handleShowAgent = () => setShowAgent(true);

  const [showSelected, setShowSelected] = useState(false);

  const handleCloseSelected = () => setShowSelected(false);
  const handleShowSelected = () => setShowSelected(true);
  return (
    <LoginLayout>
      <section>
        <Container>
          {/* <div className="cmn-card"> */}
          <Row>
            {/* <div className="under-dev">
                <h3 className="">Under Development</h3>
              </div> */}

            <div className="animaton-text">
              <div className="home-animation-box">
                <div class="soccer"></div>
                <div className="upcoming-page">
                  <h1> Coming Soon </h1>
                  <p>
                    We're working hard to bring you something awesome. Stay
                    tuned!
                  </p>
                </div>
              </div>
            </div>

            {/* <Col lg={4}>
                <div className="message-left">
                  {" "}
                  <div className="search-input">
                    <img src={require("../Assets/images/search.svg").default} />
                    <input type="search" placeholder="Search here" />
                  </div>
                  <h5 className="heading">5 Message</h5>
                  <div className="messages-total">
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                    <div className="message-box mb-3">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="w-100">
                        <h3 className="text-end">03:00 PM | 30.11.2023</h3>
                        <h6>Belle Benson</h6>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <p>Ok, see you then.</p>
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            {/* <Col lg={8}>
                {" "}
                <div className="message-right">
                  <div className="d-flex justify-content-center">
              

                  </div>

                   <div className="user-top-info">
                    <div className="user-top-inner">
                      <img src={require("../Assets/images/user.png")} />
                      <div>
                        <h3>Belle Benson</h3>
                        <p>Agent</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <button
                        onClick={handleShow}
                        type="button"
                        className="me-4"
                        style={{ border: "none", background: "none" }}
                      >
                        <img
                          src={require("../Assets/images/calendor.svg").default}
                        />
                      </button>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img
                            src={require("../Assets/images/dots.svg").default}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">Block User</Dropdown.Item>
                          <Dropdown.Item onClick={handleShowTransfer}>
                            Transfer player/Director/Coach
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="message-box-sec">
                    <div className="left-user justify-content-end">
                      <div>
                        <h4>Have a great working week!!</h4>
                        <p>09:25 AM</p>
                      </div>
                    </div>
                    <div className="left-user">
                      <img src={require("../Assets/images/user.png")} />
                      <div>
                        <h4>Have a great working week!!</h4>
                        <p>09:25 AM</p>
                      </div>
                    </div>
                  </div>
                  <div className="message-send">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type message.."
                    />
                    <button type="button">
                      <img
                        src={require("../Assets/images/sendicon.svg").default}
                      />
                    </button>
                  </div> 
                </div>
              </Col> */}
          </Row>
          {/* </div> */}
        </Container>
      </section>
      <Modal
        className="cmn-modal"
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Create Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="create-met">
            <div className="cmn-form-fields">
              <Row>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Title</label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter here..."
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Add Participants</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ID/Name here"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <label className="sub-16 mb-2">Reminder</label>
                  <div className="licensed-sec">
                    <h3>Daily Reminder</h3>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Notes</label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Please enter here"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Add Participants</label>
                    <Form.Control
                      className="mb-3"
                      type="time"
                      placeholder="Please enter here..."
                    />
                    <Form.Control
                      type="date"
                      placeholder="Please enter here..."
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Location</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ID/Name here"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-1 form-inner">
                    <label className="sub-16 mb-2">Add Meeting Link</label>
                    <Form.Control type="url" placeholder="Add Link" />
                  </Form.Group>
                  <p style={{ fontSize: "10px" }}>
                    *You can paste here any Google meets/ Zoom meeting link to
                    schedule the meeting{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="primary">Schedule</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="cmn-modal"
        size="xl"
        show={showTransfer}
        onHide={handleCloseTransfer}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Schedule Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="create-met">
            <div className="cmn-form-fields">
              <Row>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">
                      Choose Player/Director/Coach
                    </label>
                    <Form.Control
                      type="text"
                      placeholder="Add the ID of Player, Direction or Coach"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3">
                    <label className="sub-16 mb-2">Transferring from:</label>
                    <div className="small-cmn-shadow">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>J Mathew</h3>
                    </div>
                    <div className="small-cmn-shadow justify-content-between mt-2">
                      <h4>Joined On</h4>
                      <input type="date" className="form-control" />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {" "}
                  <Form.Group className="mb-3">
                    <label className="sub-16">Transferring To:</label>

                    <div className="small-cmn-shadow justify-content-between mt-2">
                      <h4>Add Agent</h4>
                      <button type="button" onClick={handleShowAgent}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                            fill="#4C8BB4"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="small-cmn-shadow justify-content-between mt-2">
                      <h4>Transfer On</h4>
                      <input type="date" className="form-control" />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="primary">Done</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="cmn-modal"
        size="lg"
        show={showAgent}
        onHide={handleCloseAgent}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Choose Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="choose-agent">
            <div className="search-input">
              <img src={require("../Assets/images/search.svg").default} />
              <input
                type="search"
                placeholder="Search here"
                className="w-100"
              />
            </div>
            <div className="agents-main mt-4">
              <Row>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
                <Col lg={6} className="mb-2">
                  <div className="play-inner-box">
                    <div className="d-flex">
                      <img src={require("../Assets/images/user.png")} />
                      <div className="ms-3">
                        <h4>Andrew Addison</h4>
                        <p>
                          Player ID: <span>IVXJW007</span>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      style={{ background: "none", border: "none" }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0007 0.833496C8.18766 0.833496 6.41538 1.37111 4.90793 2.37836C3.40048 3.3856 2.22556 4.81724 1.53176 6.49223C0.837956 8.16722 0.656426 10.0103 1.01012 11.7885C1.36382 13.5666 2.23686 15.2 3.51884 16.482C4.80082 17.764 6.43417 18.637 8.21233 18.9907C9.99049 19.3444 11.8336 19.1629 13.5086 18.4691C15.1836 17.7753 16.6152 16.6003 17.6225 15.0929C18.6297 13.5854 19.1673 11.8132 19.1673 10.0002C19.1645 7.56989 18.1978 5.23998 16.4793 3.52152C14.7608 1.80306 12.4309 0.836363 10.0007 0.833496ZM14.1673 10.8335H10.834V14.1668C10.834 14.3878 10.7462 14.5998 10.5899 14.7561C10.4336 14.9124 10.2217 15.0002 10.0007 15.0002C9.77964 15.0002 9.56768 14.9124 9.4114 14.7561C9.25512 14.5998 9.16732 14.3878 9.16732 14.1668V10.8335H5.83399C5.61297 10.8335 5.40101 10.7457 5.24473 10.5894C5.08845 10.4331 5.00065 10.2212 5.00065 10.0002C5.00065 9.77915 5.08845 9.56719 5.24473 9.41091C5.40101 9.25463 5.61297 9.16683 5.83399 9.16683H9.16732V5.8335C9.16732 5.61248 9.25512 5.40052 9.4114 5.24424C9.56768 5.08796 9.77964 5.00016 10.0007 5.00016C10.2217 5.00016 10.4336 5.08796 10.5899 5.24424C10.7462 5.40052 10.834 5.61248 10.834 5.8335V9.16683H14.1673C14.3883 9.16683 14.6003 9.25463 14.7566 9.41091C14.9129 9.56719 15.0007 9.77915 15.0007 10.0002C15.0007 10.2212 14.9129 10.4331 14.7566 10.5894C14.6003 10.7457 14.3883 10.8335 14.1673 10.8335Z"
                          fill="#4C8BB4"
                        />
                      </svg>
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="cmn-modal"
        size="xs"
        show={showSelected}
        onHide={handleCloseSelected}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          className="pb-0"
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="choose-agent">
            <h3>Caution!</h3>
            <p>
              All of your Player Data will be transferred to the Agent,
              <b>
                {" "}
                except for your Notes, Coaches, Directors, Associate Agents, and
                Other Associate Agents Data
              </b>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: "none" }}
          className="d-flex justify-content-center"
        >
          <Button variant="primary">Confirm</Button>
        </Modal.Footer>
      </Modal>
    </LoginLayout>
  );
}
