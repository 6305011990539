import { createSlice } from "@reduxjs/toolkit";
import { homeListAction } from "../Action/homeAction";


const initialState = {
  loading: false,
  homeProfileDetail: [],
  error: false,
};

const homeAuthSlice = createSlice({
  name: "homeAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(homeListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(homeListAction.fulfilled, (state, { payload }) => {
        state.homeProfileDetail = payload.data
        state.loading = false;
      })
      .addCase(homeListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

    },
});


export default homeAuthSlice.reducer;
