import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Decryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editCoachAction, getCoachesDataById } from "../Redux/Action/coachAction";

const validationSchema = Yup.object({
  address: Yup.string().required("required"),
  email: Yup.string().email("Invalid email format").required("required"),
  phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  instagram: Yup.string().url("Invalid URL format"),
  facebook: Yup.string().url("Invalid URL format"),
  snapchat: Yup.string().url("Invalid URL format"),
  twitter: Yup.string().url("Invalid URL format"),
  coach_lawyer_name: Yup.string().required("required"),
  coach_lawyer_phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),
  coach_managing_company_name: Yup.string().required("required"),
  coach_managing_company_phone_number: Yup.number()
    .typeError("Phone number must be a number")
    .required("required"),

  contact_info_other_notes: Yup.string(),
  contact_info_agent_notes: Yup.string(),
});

const CoachContactInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const coachID = id && Decryptedid(atob(id));

  const coachData = useSelector((e) => e.coachAuthData.coachDetail);

  const handleSubmit = (values) => {

    let formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("coachId", coachID);
    formData.append("is_profile_completed", 6);

    dispatch(editCoachAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Success");
        localStorage.removeItem("coachId");
        navigate(`/my-coach`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getCoachesDataById({ id: coachID }));
  }, [dispatch]);

  const initialValues = {
    address: coachData?.address || "",
    email: coachData?.email || "",
    phone_number: coachData?.phone_number || "",
    instagram: coachData?.instagram || "",
    facebook: coachData?.facebook || "",
    snapchat: coachData?.snapchat || "",
    twitter: coachData?.twitter || "",
    coach_lawyer_name: coachData?.coach_lawyer_name || "",
    coach_lawyer_phone_number: coachData?.coach_lawyer_phone_number || "",
    coach_managing_company_name:
      coachData?.coach_managing_company_name || "",
    coach_managing_company_phone_number:
      coachData?.coach_managing_company_phone_number || "",
    contact_info_other_notes: coachData?.contact_info_other_notes || "",
    contact_info_agent_notes: coachData?.contact_info_agent_notes || "",
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Contact Information</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <FormikForm>
                  <div className="common-outer mt-4">
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="address"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter address"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="email"
                            as={Form.Control}
                            type="email"
                            placeholder="Enter email here"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Enter phone number"
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Social Media Links</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="instagram"
                            as={Form.Control}
                            type="url"
                            placeholder="Instagram"
                          />
                          <ErrorMessage
                            name="instagram"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="facebook"
                            as={Form.Control}
                            type="url"
                            placeholder="Facebook"
                          />
                          <ErrorMessage
                            name="facebook"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="snapchat"
                            as={Form.Control}
                            type="url"
                            placeholder="Snapchat"
                          />
                          <ErrorMessage
                            name="snapchat"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} className="mt-3">
                        <Form.Group className="form-inner">
                          <Field
                            name="twitter"
                            as={Form.Control}
                            type="url"
                            placeholder="twitter"
                          />
                          <ErrorMessage
                            name="twitter"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Coach Lawyer</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="coach_lawyer_name"
                            as={Form.Control}
                            type="text"
                            placeholder="Lawyer’s name enter here"
                          />
                          <ErrorMessage
                            name="coach_lawyer_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="coach_lawyer_phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Phone number enter here"
                          />
                          <ErrorMessage
                            name="coach_lawyer_phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Coach Managing Company</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="coach_managing_company_name"
                            as={Form.Control}
                            type="text"
                            placeholder="Company name enter here"
                          />
                          <ErrorMessage
                            name="coach_managing_company_name"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner">
                          <Field
                            name="coach_managing_company_phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Phone number enter here"
                          />
                          <ErrorMessage
                            name="coach_managing_company_phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <Form.Group className="mb-3 form-inner">
                      <Field
                        as="textarea"
                        name="contact_info_other_notes"
                        rows={3}
                        placeholder="Write here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="contact_info_other_notes"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Agents Notes</h3>
                    </div>
                    <Form.Group className="mb-3 form-inner">
                      <Field
                        as="textarea"
                        name="contact_info_agent_notes"
                        rows={3}
                        placeholder="Write here"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="contact_info_agent_notes"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                      <button type="submit">Save</button>
                    </div>
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default CoachContactInformation;

