import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { staticDataAction } from "../Redux/Action/staticAction";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import {
  editDirectorAction,
  getDirectorDataById,
} from "../Redux/Action/directorAction";

const validateForm = (values) => {
  const errors = {};

  // if (!values.Bonuses) {
  //   errors.Bonuses = "required";
  // } 
  // else if (isNaN(values.Bonuses)) {
  //   errors.Bonuses = "must be a number";
  // }

  if (!values.contract_start_date) {
    errors.contract_start_date = "required";
  } else if (isNaN(new Date(values.contract_start_date))) {
    errors.contract_start_date = "must be a valid date";
  }

  if (!values.contract_end_date) {
    errors.contract_end_date = "required";
  } else if (isNaN(new Date(values.contract_end_date))) {
    errors.contract_end_date = "must be a valid date";
  }

  if (!values.release_clouse) {
    errors.release_clouse = "required";
  }

  if (!values.new_contract_annual_salary) {
    errors.new_contract_annual_salary = "required";
  }

  if (!values.new_contract_duration) {
    errors.new_contract_duration = "required";
  }

  return errors;
};

export default function DirectorContract() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [inputType2, setInputType2] = useState("text");

  const { id } = useParams();
  const directorID = id && Decryptedid(atob(id));

  const directorData = useSelector((e) => e.directorAuthData.directorDetail);

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const optionsCurrentSalary = staticListForPlayer?.current_contract?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsClause = staticListForPlayer?.is_current_injuries?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsAskingAmount = staticListForPlayer?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsContractDuration = staticListForPlayer?.duration?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const initialValues =
    directorData?.is_profile_completed
      ? {
          current_contract:
            optionsCurrentSalary?.find(
              (elem) => elem.value === directorData?.current_contract
            ) || "",
          new_contract_annual_salary:
            optionsAskingAmount?.find(
              (elem) => elem.value === directorData?.new_contract_annual_salary
            ) || "",
          Bonuses: directorData?.Bonuses || "",

          contract_start_date: directorData?.contract_start_date
            ? directorData?.contract_start_date.split("T")[0]
            : "",
          contract_end_date: directorData?.contract_end_date
            ? directorData?.contract_end_date.split("T")[0]
            : "",
          release_clouse:
            optionsClause?.find(
              (elem) => elem.value === directorData?.release_clouse
            ) || "",

          current_contract_other_notes:
            directorData?.current_contract_other_notes || "",
          new_contract_other_notes:
            directorData?.new_contract_other_notes || "",

          new_contract_duration:
            optionsContractDuration?.find(
              (elem) => elem.value === directorData?.new_contract_duration
            ) || "",
        }
      : {
          current_contract: "",
          new_contract_annual_salary: "",
          Bonuses: "",

          contract_start_date: "",
          contract_end_date: "",
          release_clouse: "",

          current_contract_other_notes: "",
          new_contract_other_notes: "",
          new_contract_duration: "",
        };

  const transformValues = (values) => {
    return {
      release_clouse: values.release_clouse ? values.release_clouse.value : "",
      current_contract: values.current_contract?.value || "",

      new_contract_annual_salary:
        values.new_contract_annual_salary?.value || "",
      new_contract_duration: values.new_contract_duration?.value || "",
    };
  };

  useEffect(() => {
    dispatch(getDirectorDataById({ id: directorID }));
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Contract</h2>
            </div>
            <div className="common-outer mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={validateForm}
                onSubmit={(values) => {
                  console.log(values);
                  const transformedValues = transformValues(values);
                  let formData = new FormData();

                  for (const key in transformedValues) {
                    if (transformedValues.hasOwnProperty(key)) {
                      formData.append(key, transformedValues[key]);
                    }
                  }

                  console.log(transformedValues, "sdfgdgdfg34534534");

                  Object.entries(values).forEach(([key, value]) => {
                    if (
                      key !== "release_clouse" &&
                      key !== "current_contract" &&
                      key !== "new_contract_annual_salary" &&
                      key !== "new_contract_duration"
                    ) {
                      formData.append(key, value);
                    }
                  });

                  formData.append("directorId", directorID);
                  formData.append("is_profile_completed", 4);

                  dispatch(editDirectorAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/director-proposals/${Encryptedid(res?.payload?.data?._id)}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }}
              >
                {({ setFieldValue, values }) => (
                  <FormikForm>
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Current Contract Details</h3>
                    </div>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={4}>
                          <div className="profile-card mb-3">
                            <Select
                              placeholder="Current Annual Salary"
                              name="current_contract"
                              options={optionsCurrentSalary}
                              onChange={(option) =>
                                setFieldValue("current_contract", option)
                              }
                              value={values.current_contract || ""}
                            />
                            <ErrorMessage
                              name="current_contract"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form-group mb-3 form-inner">
                            <Field
                              type="number"
                              name="Bonuses"
                              className="form-control"
                              placeholder="Bonus Amount"
                            />
                            {/* <ErrorMessage
                              name="Bonuses"
                              component="div"
                              className="text-danger"
                            /> */}
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="form-group mb-3 form-inner">
                            <Field
                              type={inputType1}
                              name="contract_start_date"
                              className="form-control"
                              placeholder="Contract start date"
                              onFocus={() => setInputType1("date")}
                              onBlur={() => setInputType1("text")}
                            />
                            <ErrorMessage
                              name="contract_start_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form-group mb-3 form-inner">
                            <Field
                              name="contract_end_date"
                              type={inputType2}
                              className="form-control"
                              placeholder="Contract end date"
                              onFocus={() => setInputType2("date")}
                              onBlur={() => setInputType2("text")}
                            />
                            <ErrorMessage
                              name="contract_end_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="profile-card">
                            <Select
                              name="release_clouse"
                              options={optionsClause}
                              placeholder="Release Clauses"
                              onChange={(option) =>
                                setFieldValue("release_clouse", option)
                              }
                              value={values.release_clouse || ""}
                              // value={optionsClause?.find(
                              //   (opt) => opt.value === values.release_clouse
                              // )}
                            />
                            <ErrorMessage
                              name="release_clouse"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="form-group mb-3 form-inner">
                            <Field
                              type="text"
                              name="current_contract_other_notes"
                              className="form-control"
                              placeholder="Other notes write here"
                            />
                            <ErrorMessage
                              name="current_contract_other_notes"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="common-outer mt-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">
                          Director Requirement for new contract
                        </h3>
                      </div>
                      <Row>
                        <Col lg={4}>
                          <div className="profile-card">
                            <Select
                              options={optionsAskingAmount}
                              name="new_contract_annual_salary"
                              placeholder="Annual Asking Salary"
                              onChange={(option) =>
                                setFieldValue(
                                  "new_contract_annual_salary",
                                  option
                                )
                              }
                              value={values.new_contract_annual_salary}
                              // value={options.new_contract_annual_salary.find(
                              //   (opt) => opt.value === values.new_contract_annual_salary
                              // )}
                            />
                            <ErrorMessage
                              name="new_contract_annual_salary"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="profile-card">
                            <Select
                              options={optionsContractDuration}
                              name="new_contract_duration"
                              placeholder="Contract Duration Requirements"
                              onChange={(option) =>
                                setFieldValue("new_contract_duration", option)
                              }
                              value={values.new_contract_duration}
                              // value={options.new_contract_duration.find(
                              //   (opt) => opt.value === values.new_contract_duration
                              // )}
                            />
                            <ErrorMessage
                              name="new_contract_duration"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>

                        <Col lg={4} className="">
                          <div className="form-group mb-3 form-inner">
                            <Field
                              type="text"
                              name="new_contract_other_notes"
                              className="form-control"
                              placeholder="Other notes write here"
                            />
                            <ErrorMessage
                              name="new_contract_other_notes"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button type="submit">Next</button>
                      </div>
                    </Col>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
