import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
export default function Support() {
  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <Row>
              <Col lg={12}>
                {" "}
                <div className="message-right">
                  <div className="user-top-info">
                    <div className="user-top-inner">
                      <h6 className="h-25">Support</h6>
                    </div>
                  </div>
                  <div className="message-box-sec">
                    <div className="left-user justify-content-end">
                      <div>
                        <h4>Have a great working week!!</h4>
                        <p>09:25 AM</p>
                      </div>
                    </div>
                    <div className="left-user">
                      <img src={require("../Assets/images/user.png")} />
                      <div>
                        <h4>Have a great working week!!</h4>
                        <p>09:25 AM</p>
                      </div>
                    </div>
                  </div>
                  <div className="message-send">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type message.."
                    />
                    <button type="button">
                      <img
                        src={require("../Assets/images/sendicon.svg").default}
                      />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
