import { createSlice } from "@reduxjs/toolkit";
import { getCoachesDataById, getCoachListAction } from "../Action/coachAction";


const initialState = {
  loading: false,
  coachDetail: [],
  getCoachLists: [],
  error: false,
};

const coachAuthSlice = createSlice({
  name: "coachAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoachesDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCoachesDataById.fulfilled, (state, { payload }) => {
        state.coachDetail = payload.data
        state.loading = false;
      })
      .addCase(getCoachesDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })


      .addCase(getCoachListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCoachListAction.fulfilled, (state, { payload }) => {
        state.getCoachLists = payload.data
        state.loading = false;
      })
      .addCase(getCoachListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

    },
});


export default coachAuthSlice.reducer;
