import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../utils/Becrypt";
import { getDirectorDataById } from "../Redux/Action/directorAction";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import backgroundImage from "../Assets/images/stadium.png";
import {
  AvailabilityStatus,
  coachingLicense,
  gender,
  playerTrophy,
  previousStatus,
  teamLeague,
} from "../utils/rawjson";

export default function GlobalSearchDirectorProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { id } = useParams();
  const directorId = id && Decryptedid(atob(id));

  const directorData = useSelector((e) => e.directorAuthData.directorDetail);


  const backgroundImageStyle = {
    // backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${directorData?.banner_image})`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  useEffect(() => {
    if (directorId) {
      dispatch(getDirectorDataById({ id: directorId }));
    }
  }, [dispatch]);

  const copyToClipboard = () => {
    if (directorData?.unique_id) {
      navigator.clipboard
        .writeText(directorData.unique_id)
        .then(() => {
          console.log("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Director </h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {/* <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${directorData?.profile_image}`}
                    /> */}
                    <img src={require("../Assets/images/director-banner.png")} />

                    <div>
                      <h2>
                        {" "}
                        {directorData?.full_name},{" "}
                        {calculateAge(directorData?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0" ref={inputRef}>
                          Director ID: {directorData?.unique_id}
                        </p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}></Col>
              </Row>
            </div>
            <Row className="mt-4">
              <Col lg={4}>
                <div className="search-inner-box">
                  <div className="play-search-box">
                    <div>
                      <h3>{gender[directorData?.gender]}</h3>
                      <p>Gender</p>
                    </div>
                    <div>
                      <h3>{calculateAge(directorData?.dob)}</h3>
                      <p>Age</p>
                    </div>
                    <div>
                      <h3>
                        {" "}
                        {AvailabilityStatus[directorData?.availability_status]}
                      </h3>
                      <p>Availability</p>
                    </div>
                    <div>
                      {directorData?.languages?.map((elem, i) => (
                        <h3 key={i}>{elem}</h3>
                      ))}
                      <p>Language</p>
                    </div>
                  </div>
                </div>
                <div className="search-inner-box mt-4">
                  <ul>
                    <li>
                      <h4>Previous Status:</h4>
                      <p>
                        {directorData?.previous_status
                          ?.map(
                            (elem) => previousStatus[elem] || []
                          )
                          .join(", ")}
                      </p>
                    </li>
                    <li>
                      <h4>Coaching License:</h4>
                      <p>
                        {directorData?.coaching_licenses
                          ?.map(
                            (elem) => coachingLicense[elem] || []
                          )
                          .join(", ")}
                      </p>
                    </li>
                    <li>
                      <h4>Availability Status:</h4>
                      <p> {AvailabilityStatus[directorData?.availability_status]}</p>
                    </li>
                    <li>
                      <h4>Current/Previous Team League:</h4>
                      <p> {teamLeague[directorData?.current_team_league]}</p>
                    </li>
                    <li>
                      <h4>Annual Asking Salary:</h4>
                      <p>{directorData?.current_contract}</p>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                <div className="play-inner-box">
                  <div className="d-flex">

                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${directorData?.creator_id?.profile_image}`}
                    />

                    <div className="ms-3">
                      <h4>{capitalize(directorData?.creator_id?.full_name)}</h4>
                      <p>Agent</p>
                    </div>
                  </div>
                  <div className="badge-img">
                    <img src={require("../Assets/images/tiger.png")} />
                  </div>
                </div>
                <div className="search-cmn-box">
                  <h2>Career Trophies</h2>
                  <div className="search-inner-box">
                    <ul>
                      {directorData?.carrier_trophies?.map((elem, i) => (
                        <li>
                          <div className="d-flex">
                            <img
                              src={require("../Assets/images/smtrophy.png")}
                            />
                            <h4 className="ms-2">
                              {playerTrophy[elem?.trophy]}
                            </h4>
                          </div>
                          <p>
                            {dateOfBirth(elem?.date_of_winning?.split("T")[0])}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                {" "}
                <div className="search-inner-box p-0">
                  <div className="play-images">
                    <img src={require("../Assets/images/smllbanner.png")} />
                    <div className="players-value inner-tab">
                      <h3>CHECK THE MOST VALUABLE PLAYERS IN THE WORLD</h3>
                    </div>
                  </div>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
