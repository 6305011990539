import { createSlice } from "@reduxjs/toolkit";
import {
  staticDataAction,
  staticDirectorDataAction,
  staticOtherAgentDataAction,
  staticTeamDataAction,
} from "../Action/staticAction";

const initialState = {
  loading: false,
  userStaticDetail: [],
  directorStaticDetail: [],
  teamStaticDetail : [],
  otherAgentStaticDetail: [],
  error: false,
};

const staticAuthSlice = createSlice({
  name: "staticAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(staticDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(staticDataAction.fulfilled, (state, { payload }) => {
        state.userStaticDetail = payload.data;
        state.loading = false;
      })
      .addCase(staticDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // director
      .addCase(staticDirectorDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(staticDirectorDataAction.fulfilled, (state, { payload }) => {
        state.directorStaticDetail = payload.data;
        state.loading = false;
      })
      .addCase(staticDirectorDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // team

      .addCase(staticTeamDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(staticTeamDataAction.fulfilled, (state, { payload }) => {
        state.teamStaticDetail = payload.data;
        state.loading = false;
      })
      .addCase(staticTeamDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })


      // other agent
      .addCase(staticOtherAgentDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(staticOtherAgentDataAction.fulfilled, (state, { payload }) => {
        state.otherAgentStaticDetail = payload.data;
        state.loading = false;
      })
      .addCase(staticOtherAgentDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default staticAuthSlice.reducer;
