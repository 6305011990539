import React from "react";
import { Container, Form } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

export default function SocialLinks({ setFieldValue, errors, touched, values }) {
  return (
    <div >
      <Container>
        <div className="main-form d-flex justify-content-center">
          <div className="cmn-form">
            <h2 className="h-27 text-center mb-2">Add Your Social Accounts</h2>
            <p className="sub-16 text-center mb-4">Fill in your details</p>

            <div className="cmn-form-fields">
              {["instagram", "facebook", "snapchat", "x"].map((fieldName) => (
                <Form.Group key={fieldName} className="mb-3 form-inner">
                  <Form.Label className="sub-16 mb-2">
                    {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                  </Form.Label>
                  <Field
                    type="text"
                    name={fieldName}
                    placeholder={`Add ${fieldName} link`}
                    className={`form-control ${errors[fieldName] && touched[fieldName] ? "is-invalid" : ""}`}
                  />
                  <ErrorMessage
                    name={fieldName}
                    component="div"
                    className="invalid-feedback"
                  />
                </Form.Group>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
