import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { changePassword } from "../Redux/Action/auth";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export default function Settings() {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleClosesub = () => setShowsub(false);
  const handleShowsub = () => setShowsub(true);
  

  const [showsub, setShowsub] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ConfirmshowPassword, ConfirmsetShowPassword] = useState(false);
  const [NewshowPassword, NewsetShowPassword] = useState(false);

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .required("Old Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(6, "New Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-4 d-flex justify-content-between align-items-center">
              <h2>Settings</h2>
            </div>

            <Row className="mt-4 pt-2">
              <Col lg={4}>
                <div className="profile-card">
                  <Link to="/transfer">
                    <h3>Transfer’s</h3>
                    <img
                      src={require("../Assets/images/arrowdown.svg").default}
                    />
                  </Link>
                </div>
              </Col>
              <Col lg={4}>
                <div className="profile-card">
                  <button type="button">
                    <h3>Notification</h3>

                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </button>
                </div>
              </Col>
              <Col lg={4}>
                <div className="profile-card">
                  <button type="button" onClick={handleShowsub}>
                    <h3>Subscription</h3>
                    <img
                      src={require("../Assets/images/arrowdown.svg").default}
                    />
                  </button>
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div className="profile-card">
                  <Link to="/support">
                    <h3>Support</h3>
                    <img
                      src={require("../Assets/images/arrowdown.svg").default}
                    />
                  </Link>
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div className="profile-card">
                  <button type="button">
                    <h3>Terms of Service</h3>
                    <img
                      src={require("../Assets/images/arrowdown.svg").default}
                    />
                  </button>
                </div>
              </Col>
              <Col lg={4} className="mt-3">
                <div className="profile-card">
                  <button type="button" onClick={handleShow}>
                    <h3>Change Password</h3>
                    <img
                      src={require("../Assets/images/arrowdown.svg").default}
                    />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Modal
        className="cmn-modal"
        size="xs"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, {resetForm}) => {
              console.log(values);
              let objData = {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
              };
              dispatch(changePassword(objData)).then((res) => {
                if (res?.payload?.status === 200) {
                  toast.success(res?.payload?.message);
                  // resetForm();
                  handleClose();
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="cmn-form-fields">
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Old Password</label>
                    <Field
                      name="oldPassword"
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Old Password"
                    />
                    <button
                      type="button"
                      className="pass-show"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <img src={require("../Assets/images/eye.svg").default} />
                    </button>
                    <ErrorMessage name="oldPassword" component="div" className="text-danger" />
                  </Form.Group>
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">New Password</label>
                    <Field
                      name="newPassword"
                      type={NewshowPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="New Password"
                    />
                    <button
                      type="button"
                      className="pass-show"
                      onClick={() => NewsetShowPassword((prev) => !prev)}
                    >
                      <img src={require("../Assets/images/eye.svg").default} />
                    </button>
                    <ErrorMessage name="newPassword" component="div" className="text-danger" />
                  </Form.Group>
                  <Form.Group className="mb-3 form-inner">
                    <label className="sub-16 mb-2">Confirm Password</label>
                    <Field
                      name="confirmPassword"
                      type={ConfirmshowPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    <button
                      type="button"
                      className="pass-show"
                      onClick={() => ConfirmsetShowPassword((prev) => !prev)}
                    >
                      <img src={require("../Assets/images/eye.svg").default} />
                    </button>
                    <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                  </Form.Group>
                </div>
                <Modal.Footer style={{ borderTop: "none" }}>
                  <Button variant="primary" type="submit" className="w-100">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        className="cmn-modal"
        size="xs"
        show={showsub}
        onHide={handleClosesub}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="subscription">
            <h2 className="text-center">
              $2000/<span>Year</span>
            </h2>
            <h5 className="text-center">Premium Package</h5>
            <p className="text-center">
              Welcome to SoccerAgents.Live, the smartest tool ever created for
              football agents. Manage your agenda by listing all your clients
              and associates, and search for football players, coaches,
              technical directors, and more worldwide through this incredible
              network.
              <br />
              <br /> With SoccerAgents.Live, you can:
            </p>
            <ul>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Manage Your Agenda</h3>
                  <p>
                    {" "}
                    List all your clients in one place, including footballers,
                    technical directors, sports directors, coaches, teams,
                    associate agents, and more.
                  </p>
                </div>
              </li>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Unlimited Listings</h3>
                  <p>
                    {" "}
                    Manage an extensive network of clients without restrictions.
                  </p>
                </div>
              </li>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Search Your Agenda Efficiently</h3>
                  <p>
                    {" "}
                    Manage an extensive network of clients without restrictions.
                  </p>
                </div>
              </li>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Global Search</h3>
                  <p>
                    {" "}
                    Search globally for available football players, technical
                    directors, sports directors, and coaches, compare them, and
                    find those who meet your requirements.
                  </p>
                </div>
              </li>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Save Important Documents</h3>
                  <p>
                    {" "}
                    Save CV’s, Contracts, videos Links, photos and other Useful
                    Documents.
                  </p>
                </div>
              </li>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Internal Messaging and Push Notifications</h3>
                  <p>
                    {" "}
                    Open discussions with other agents through our integrated
                    messaging system..
                  </p>
                </div>
              </li>
              <li>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM7.93933 10.7793C7.68133 11.0373 7.342 11.166 7.00133 11.166C6.66067 11.166 6.318 11.036 6.05733 10.776L4.20267 8.97867L5.13133 8.02067L6.99333 9.82533L10.866 6.02467L11.802 6.97467L7.93933 10.7793Z"
                    fill="#4C8BB4"
                    fill-opacity="0.5"
                  />
                </svg>
                <div>
                  <h3>Manage Appointments</h3>
                  <p>
                    {" "}
                    Create and maintain your calendar to never miss an important
                    meeting.
                  </p>
                </div>
              </li>
            </ul>
            <h4 className="text-center">
              Join us now and
              <br /> discover the ultimate <br />
              tool for football agents!
            </h4>
          </div>{" "}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="primary" className="w-100">
            Grab Offer
          </Button>
        </Modal.Footer>
      </Modal>
    </LoginLayout>
  );
}
