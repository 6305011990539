import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// create team
export const createTeamAction = createAsyncThunk(
  "createTeamAction/user/createTeam",
  async (data) => {
    const response = await api.post("/user/createTeam", data);
    return response.data;
  }
);

// edit team
export const editTeamAction = createAsyncThunk(
  "editTeamAction/user/editTeam",
  async (data) => {
    const response = await api.put("/user/editTeam", data);
    return response.data;
  }
);


// get team detail by id

export const getTeamDataById = createAsyncThunk('getTeamDataById/getTeamById',async ({ id, shopId }, thunkAPI) => {
  const response = await api.get(`/user/getTeamById`, {
    params: {
      teamId: id,
    },
  });
  return response.data;
}
);

// get  team list
export const getTeamListAction = createAsyncThunk(
  "getTeamListAction/user/getMyTeams",
  async (data) => {
    const response = await api.get("user/getMyTeams", data);
    return response.data;
  }
);

export const getTeamListByIdAction = createAsyncThunk(
  "getTeamListByIdAction/user/getMyTeamsById",
  async (data) => {
    const response = await api.get("user/getMyTeamById", data);
    return response.data;
  }
);
